@import "assets/styles/globals.scss";

//label class
.container {
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: 4px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  // background-color: #eee;
  border: 1px solid $gray100;
  border-radius: .2rem;
  @include transition(background-color 300ms, color 300ms);
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: $white;
    font-size: 1.1rem;
  }
}

.checkmark:hover {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
// .container input:checked ~ .checkmark {
//   background-color: #15bcc7;
//   opacity: 100;
// }

.disabled {
  cursor: default;
  .checkmark:hover {
    background-color: #eee;
  }
}

//source: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
