@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 10px;
  font-family: $poppins;
  gap: 5px;
}

.title {
  color: $gray110;
  font-size: 0.9em;

  display: flex;
  align-items: center;
  padding-right: 20px;

  gap: 20px;
}

.edit {
  font-size: 0.85em;
  color: $gray60;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    color: $seafoam100;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.stat {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.statTitle {
  color: $gray100;
  font-size: 0.85em;
}

.result {
  color: $gray110;
  font-size: 0.9em;
}

.breakdown {
  display: flex;
  flex-direction: column;
}

.prop {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.propName {
  color: $seafoam100;
  font-size: 0.85em;

  display: flex;
  align-items: center;
  gap: 10px;
}

.piece {
  padding-left: 20px;
  font-size: 0.8em;
}

.percent {
  color: $gray110;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.editPiece {
  padding-left: 20px;
  font-size: 0.8em;
  width: fit-content;

  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  gap: 10px;
}

.pieceEditor {
  width: fit-content;
  border-radius: 5px;
  &:hover {
    background-color: $gray25;
  }
  &:focus {
    background-color: $gray25;
  }
}

.newPiece {
  min-width: 175px;
  border-radius: 5px;
  background-color: $gray25;

  // &:hover {
  //   background-color: $gray25;
  // }
  // &:focus {
  //   background-color: $gray25;
  // }
}

.removePiece {
  color: $gray60;
  transition: 0.3s all;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: $gray110;
  }
}

.plusPiece {
  color: $gray60;
  transition: 0.3s all;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: $gray110;
  }
}

.answerSection {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 20px;
  gap: 5px;
}

.answerHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.answerLabel {
  padding: 0px 10px;
  color: $seafoam100;
  font-size: 0.85em;
  border-bottom: 2px solid $sapphire20;
  // width: fit-content;
}

.selectableTip {
  font-size: 0.8em;
  color: $seafoam60;
  white-space: pre-wrap;
}

.answer {
  font-size: 0.8em;
  white-space: pre-wrap;
  color: $gray110;
}

@keyframes fade {
  0% {
    color: $gray110;
  }

  5% {
    color: $gray110;
  }

  40% {
    color: $gray100;
  }

  50% {
    color: $gray110;
  }

  55% {
    color: $gray110;
  }

  75% {
    color: $gray100;
  }

  90% {
    color: $gray110;
  }

  100% {
    color: $gray110;
  }
}

.selectableAnswer {
  font-size: 0.8em;
  white-space: pre-wrap;
  color: $gray110;
  padding: 10px;

  // box-shadow: $shadowLight;
  // border-radius: 5px;
  animation: fade 1.25s;
  animation-delay: 0.5s;

  position: relative;
}

.menu {
  display: flex;
  align-items: center;
  // gap: 10px;
  flex-wrap: wrap;
  position: absolute;
  z-index: 150;
  border-radius: 1em;
  box-shadow: $shadow;
  // padding: 5px;
  background-color: $white;
  font-size: 0.85em;
  overflow: hidden;
}

.addTo {
  color: $sapphire60;
  padding: 10px 5px 10px 10px;
}

.propToAddTo {
  padding: 10px 5px;
  color: $gray110;
  cursor: pointer;

  transition: 0.4s all;

  &:hover {
    background-color: $gray25;
    color: $black;
  }
}

.buttons {
  display: flex;
  align-self: center;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}
