@import "assets/styles/globals.scss";

.statContainer {
  font-family: $poppins;
  display: flex;
  flex-direction: column;

  .title {
    @include header-4-text;
    font-size: 14px;
    margin: 0;
    // text-transform: uppercase;
    // letter-spacing: .2em;
    font-weight: 500;
    text-underline-offset: 4px;
    // color: $gray100;
  }

  .value {
    font-size: 2em;
    color: $blue100;
    font-weight: 500;
    margin-top: -0.2em;
    display: flex;
  }
}

.headerStyle {
  font-family: $poppins;
  display: flex;
  flex-direction: column-reverse;
  gap: 0;
  align-items: flex-start;
  justify-content: flex-start;

  .title {
    @include header-4-text($gray110, 500);
    text-underline-offset: 4px;
    margin: 0;
    margin-top: -0.5rem;
  }

  .value {
    @include header-3-text($info, 500);
    margin: 0;
  }
}

.smallStyle {
  font-family: $poppins;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;

  .title {
    @include header-4-text;
    font-weight: 500;
    text-underline-offset: 4px;
  }

  .value {
    // border: 2px solid black;
    font-size: 1.2em;
    color: $info;
    font-weight: 600;
    display: flex;
  }
}

.outof {
  font-size: 0.3em;
  color: $gray60;
  padding-bottom: 17px;
  // border-bottom: 2px solid transparent;
}
