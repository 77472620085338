@import "assets/styles/globals.scss";

.imageImporter {
  display: flex;
  flex-direction: column;

  padding: 5px;
  font-family: $poppins;

  max-height: 300px;
  overflow: auto;
}

.imageUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border-bottom: 2px solid $gray25;
  padding: 20px 10px;

  font-size: 0.7rem;
}

.imageCatalog {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.upload {
  display: flex;
  align-items: center;
  gap: 7px;
  color: $black;
  background-color: $gray30;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s all;
  padding: 5px 15px;
  // border: 2px solid $gray60;

  &:hover {
    background-color: $gray60;
  }
}

.use {
  color: $white;
  background-color: $sapphire80;

  &:hover {
    // color: $black;
    background-color: $sapphire100;
  }
}

.catalogPick {
  width: 80px;
  height: 80px;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $gray40;
  }
}

.chosenImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 0px;
}

.editExample {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.editBox {
  border: 1px solid $gray60;
  position: relative;
}

.uploadedImage {
  max-height: 60px;
  max-width: 60px;
}

.adjuster {
  border: 1px solid black;
  height: 8px;
  width: 8px;
  background-color: white;

  position: absolute;
}

.topLeft {
  top: -4px;
  left: -4px;
  cursor: nwse-resize;
}

.topMiddle {
  top: -4px;
  cursor: ns-resize;
}

.topRight {
  top: -4px;
  right: -4px;
  cursor: nesw-resize;
}

.leftMiddle {
  left: -4px;
  cursor: ew-resize;
}

.rightMiddle {
  right: -4px;
  cursor: ew-resize;
}

.bottomLeft {
  bottom: -4px;
  left: -4px;
  cursor: nesw-resize;
}

.bottomMiddle {
  bottom: -4px;
  cursor: ns-resize;
}

.bottomRight {
  bottom: -4px;
  right: -4px;
  cursor: nwse-resize;
}
