@import "assets/styles/globals.scss";

.pagesTable {
  width: 100%;
  height: fit-content;

  table {
    border-collapse: collapse;
    width: 100%;
  }
  tr {
    border-bottom: 2px solid $gray30;
    transition: 0.3s all;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    // border: 2px solid $gray30;
  }
  tr:first-child {
    border-top: none;
    // border-top-left-radius: 0.9em;
    // border-top-right-radius: 0.9em;
  }
  tr:last-child {
    border-bottom: none;
    // border-bottom-left-radius: 0.9em;
    // border-bottom-right-radius: 0.9em;
  }
  tr:hover {
    background-color: $gray30;
  }
  td:first-child {
    width: 100%;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.page {
  flex-grow: 1;
  padding-left: 20px;
  font-family: $poppins;
  color: $gray110;
  min-height: 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  cursor: pointer;

  .name {
    max-width: 8em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    color: $blue100;
    font-weight: 700;
    padding-right: 3px;
  }
}

.icons {
  display: flex;
  gap: 5px;
  align-items: center;
  transition: 0.3s all;

  .icon {
    border-radius: 5px;
    padding: 5px;
    font-size: 1em;
    transition: 0.3s all;
    cursor: pointer;
  }
  .icon:hover {
    background-color: $gray100;
  }
}

.addpage {
  font-family: $poppins;
  color: $gray100;
  font-weight: 400;
  font-size: .9rem;
}

.grid {
  cursor: grab;

  i {
    color: $gray110;
    // font-size: 1em;
    padding: 0px;
  }
  i:hover {
    background-color: transparent;
  }
}
.grid:active {
  cursor: grab;
}

.exitOrSave {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
}

.inputLine {
  font-family: $poppins;
  font-size: 1em;
  color: $gray110;
  width: 100%;
  border: none;
  border-bottom: 2px solid $sapphire60;
  color: $gray110;
  font-family: $poppins;
  outline: none;
}

.headers {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
}
