@import "assets/styles/globals.scss";

.fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.lock {
  color: $gray60;
  font-size: 2rem;
}

.saveBtn {
  margin-top: 30px;
  height: 30px;
  // width: 425px;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  // border: 2px solid black;
}

.codeInstructions {
  color: $gray110;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 15px;
}

.codeCreatedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 15px 0px 10px;
}

.code {
  padding: 10px 25px;
  border-radius: 10px;
  color: $sapphire100;
  font-size: 1.4em;
  position: relative;
  background-color: $gray20;

  &:hover {
    background-color: $gray20;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-color: $gray100;
  }
}

.clipboard {
  position: absolute;
  right: -40px;
  top: 5px;
  color: $gray100;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  &:active {
    color: $gray110;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.checkmark {
  position: absolute;
  // top: -17px;
  left: 5px;
  top: 6px;
  animation-name: fadeIn;
  animation-duration: 1s;
}
