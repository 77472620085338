@import "assets/styles/globals.scss";

.container {
  padding: 30px;
}

.title {
  width: fit-content;
  padding: 0px 20px;
  border-bottom: 2px solid $sapphire20;
  color: $sapphire80;
  font-size: 1.2rem;
}

.sendLogo {
  color: $green100;
  font-size: 1.2rem;
}

.warning {
  padding: 10px;
  border-radius: 0.5rem;
  background: $red10;
  @include col;
  gap: 10px;
  align-items: center;
  max-width: 300px;
  white-space: wrap;
  font-size: 0.8rem;

  .warningTitle {
    font-size: 1rem;
    color: $red100;
    font-weight: 600;
    border-bottom: 2px solid $red60;
    width: fit-content;
    padding: 0px 10px;
  }
}

.info {
  padding: 10px;
  border-radius: 0.5rem;
  background: $green20;
  @include col;
  gap: 10px;
  align-items: center;
  max-width: 300px;
  white-space: wrap;
  font-size: 0.8rem;
  color: $gray110;

  .infoTitle {
    font-size: 1rem;
    color: $green100;
    font-weight: 600;
    border-bottom: 1px solid $green100;
    width: fit-content;
    padding: 0px 10px;
  }
}

.subTitle {
  color: $sapphire80;
  font-size: 0.9rem;
  border-bottom: 2px solid $sapphire20;
  width: fit-content;
  padding-right: 20px;
  padding-left: 5px;
}

.label {
  font-size: 0.85rem;
  color: $gray110;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
}

.minus {
  font-size: 0.85rem;
  color: $gray110;
  transition: 0.3s all;

  &:hover {
    color: $black;
    cursor: pointer;
  }
}

.red {
  color: $red100;
}

.searchField {
  font-family: $poppins;
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;

  .search {
    font-family: $poppins;
    background-color: white;
    border-radius: $radius2;
    border: 1px solid transparent;
    color: $gray110;
    outline: none;
    padding: 0.5rem 0rem 0.5rem 0.7rem;
    transition: 0.3s all;
    font-size: 0.9rem;
    box-shadow: $shadow6;

    &:hover {
      border: 1px solid $blue100;
    }
    &:focus {
      border: 1px solid $blue100;
    }

    &::placeholder {
      color: $gray60;
    }
  }
}

.contact {
  font-size: 0.8rem;
  text-align: center;
}

.anchor {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 120;
}

@keyframes grow {
  0% {
    height: 0px;
  }
}

.results {
  position: absolute;
  top: 5px;
  left: 0;

  @include animation(grow 0.3s);
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 240px;
  overflow-y: auto;

  background-color: $white;
  box-shadow: $shadow;
  border-radius: $radius3;
}

.option {
  padding: 5px;
  transition: 0.5s all;
  height: fit-content;

  &:hover {
    background-color: $gray20;
    cursor: pointer;
  }
}

.noData {
  text-align: center;
  font-family: $poppins;
  color: $gray100;
  padding-top: 10px;
  padding-bottom: 10px;
}

// Just PreTakeoffTest

.preSubTitle {
  color: $black;
  font-size: 0.8rem;
}

.unit {
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray25;
  cursor: pointer;
  transition: 0.3s all;
  padding: 3px;
  color: $gray110;

  &:hover {
    background-color: $gray60;
  }
}
