@import "assets/styles/globals.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 10px;


  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray30;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 0px;
    bottom: -2.6px;
    background-color: $gray60;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}

input:checked + .slider {
  background-color: $blue100;

  &:before {
    -webkit-transform: translateX(15.1px);
    -ms-transform: translateX(15.1px);
    transform: translateX(15.1px);
  }
}

.disabled {
  cursor: not-allowed;
}