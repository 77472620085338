@import "assets/styles/globals.scss";

.popoutBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4000000000;
}

.popout {
  font-family: $poppins;
  position: relative;
  background-color: $white;
  // padding: 30px;
  // padding: 1em;
  // padding-top: 2em;
  // height: 80%;
  width: 95%;
  max-width: 95%;
  height: 95%;
  max-height: 95%;
  border-radius: 1em;
  overflow: hidden;
  box-shadow: $shadow;
  // padding-bottom: 1rem;
  // border: 2px solid blue;
}

.exitBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px 5px;
  font-size: 12pt;
  cursor: pointer;
  border: 2px solid white;
  color: $gray110;
  background-color: white;
  border-radius: 6px;
  z-index: 130;
  transition: 0.3s;

  &:hover {
    color: $red60;
  }
  &:active {
    border: 2px solid $gray30;
  }
}

// ::-webkit-scrollbar {   // Chrome, Safari, Opera
//   display: none;
// }

// -ms-overflow-style:  // IE and Edge
// scrollbar-width      // Firefox

.text {
  font-size: 0.85em;
  // color: blue;
  white-space: pre-wrap;
}

.arrowDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

// .arrow {
//   background: linear-gradient(to top right, $red60 50%, #48a626 50%);
//   -webkit-background-clip: text; /* For Safari */
//   background-clip: text;
//   color: transparent; /* Hide original text */
// }

.red {
  color: $red60;
}

.green {
  color: #48a626;
}

.yellow {
  color: $yellow100;
}

.orange {
  color: $orange60;
}

.blue {
  color: $blue100;
}

.last {
  color: $gray100;
}

.mightTake {
  color: $gray100;
  font-family: $poppins;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}