@import 'assets/styles/globals.scss';

.matrixSettings {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  padding-left: 1.5em;
}

.toggleSetting {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 3px 0px;
}

.textEntry {
  display: flex;
  // border: 2px solid black;
  // height: 30px;
  // overflow: hidden;
  // font-size: .8em;
}