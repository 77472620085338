@import "assets/styles/globals.scss";

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

a {
  text-decoration: none;
}

.menuItem {
  position: relative;
  color: $sapphire100;
  padding: 0em 1em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-content: center;
  gap: 0.5em;
  width: 100%;
  width: 200px;
  min-width: 200px;
  height: 70px;
  box-shadow: $shadow6;
  // border: 2px solid black;
  @include transition(background-color 300ms, color 300ms);
  background-color: $white;
  font-family: $poppins;

  i {
    font-size: 1.3em;
  }

  div {
    margin-left: 8px;
    line-height: 110%;

    // .label {
    //   font-size: 1em;
    // }

    // .sublabel {
    //   font-size: 0.8em;
    // }
  }

  &:hover,
  &.activeItem {
    color: white;
    background-color: $sapphire100;
    // border: 2px solid #2a627c;
    cursor: pointer;
  }
}

.tooltip {
  color: #ffffff;
  background-color: #2a627c;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.1em;
  border-radius: 5px;
  width: fit-content;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 120%;
  @include transition(opacity 300ms 100ms);
}
.description {
  font-size: 0.7em;
}
