@import "assets/styles/globals.scss";

.container {
  // border-radius: $radius2;
  // box-shadow: $shadow;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.name {
  justify-self: center;
  color: $sapphire80;
  font-weight: 500;
  text-align: center;
  font-size: 1.5rem;
  // font-size: 1rem;
}

.iconHolder {
  position: absolute;
  z-index: 0;
  display: flex;
  align-items: center;
  height: 95%;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;

  .icon {
    color: $blue20;
    font-size: 7rem;
  }
}

.ends {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  flex-wrap: wrap;
  font-size: 0.9rem;
  color: $gray110;
}

.responseCount {
  color: $blue60;
}

.draft {
  border-radius: 10px;
  padding: 4px 12px;
  width: fit-content;
  background-color: $orange20;
  color: $orange100;
}

.pages {
  color: $sapphire100;
}
.visuals {
  color: $blue100;
  padding-left: 2px;
}

.orgWide {
  color: $blue100;
  font-size: 0.9rem;
}

.invalidHolder {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.invalid {
  border-radius: 10px;
  padding: 4px 12px;
  width: fit-content;
  background-color: $red60;
  color: $white;
  font-size: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
}

.invalidText {
  color: $red60;
}
