@import "assets/styles/globals.scss";
.App {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background-color: $white;
  max-width: 100vw;
  display: flex;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.noSignIn {
  height: 100vh;
  max-width: 100vw;
  border: 2px solid blue;
}

.footer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: $poppins;
  border-top: 1px solid $gray60;
  background-color: $white;
  row-gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .link {
    font-size: 0.6rem;
    color: $black;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 0.6rem;
    }
  }
}

.image {
  @media (max-width: 768px) {
    justify-content: center;
    flex: 1;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 1;
    right: -40em;
  }
  10% {
    right: 0;
  }
  90% {
    right: 0;
  }
  100% {
    opacity: 1;
    right: -40em;
  }
}

@include keyframes(stay-left) {
  0% {
    opacity: 1;
    right: -40em;
  }
  10% {
    right: 0;
  }
}

.notificationContainer {
  @include animation("slide-left 4s 1");
  border-radius: $radius2;
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  opacity: 0;
  align-items: center;
  gap: 1em;
  position: relative;
  pointer-events: none;
  width: 400px;
  backdrop-filter: blur(30px);

  .avatar {
    max-width: 45px;
    max-height: 45px;
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    overflow: hidden;
  }

  .notification {
    width: 100%;
    .time {
      color: $gray100;
      font-size: 0.8rem;
    }
    .notifTitle {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .header {
        font-family: $poppins;
        color: $black;
        font-weight: 600;
        font-size: 1.2em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 350px;
      }
    }

    .notifBody {
      font-size: 1em;
      color: $gray110;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 440px;
    }

    .notifHeader {
      font-size: 1em;
      color: $blue100;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 440px;
    }
  }
}

.notifContainer {
  position: absolute;
  bottom: 0.7rem;
  right: 0.7rem;
  z-index: 150;
  display: flex;
  flex-direction: column-reverse;
  gap: 1em;
  max-width: 78%;
  transition: 0.5s all;
}

.tasksContainer {
  @include animation("stay-left 4s 1");
  border-radius: $radius2;
  padding: 0.5rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  opacity: 1;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  width: 400px;
  max-width: 100%;
  backdrop-filter: blur(30px);

  .progress {
  }

  .avatar {
    max-width: 45px;
    max-height: 45px;
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    overflow: hidden;
  }

  .notification {
    width: 100%;
    .time {
      color: $gray100;
      font-size: 0.8rem;
    }
    .notifTitle {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .notifBody {
      font-size: 1em;
      color: $gray110;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 440px;
    }

    .notifHeader {
      font-size: 1em;
      color: $blue100;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 440px;
    }
  }
}
