@import "assets/styles/globals.scss";
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

.pageContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 130;
  transition: 0.3s all;
}

.projectTies {
  position: absolute;
  z-index: 130;
  background-color: $white;
  top: 2rem;
  left: 12.5rem;
  border-radius: $radius2;
  overflow: hidden;
  font-family: $poppins;
  color: $gray110;
  box-shadow: $shadow6;
}

.surveyRow {
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    background-color: $gray30;
  }
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: $gray110;
  pointer-events: all;
}

.pagesTableContainer {
  width: 100%;
  overflow: auto;
}

.pagesTable {
  width: 100%;
  // overflow-y: auto;

  table {
    border-collapse: collapse;
    width: 100%;
  }
  tr {
    border-bottom: 2px solid $gray30;
    transition: 0.3s all;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    width: 100%;
  }
  tr:first-child {
    border-top: 2px solid $gray30;
  }
  tr:last-child {
  }
  tr:hover {
    background-color: $gray30;
    cursor: pointer;
  }
}

.pagesRadius {
  tr {
    border-bottom: none;
  }
}

.pageTableRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}

.page {
  flex-grow: 1;
  padding: 0 0.5rem;
  font-family: $poppins;
  color: $gray110;
  min-height: 50px;
  align-items: center;
  display: flex;
  @include normal-text($gray110, 400, 0.8rem);
}

.editingPage {
}

.icons {
  display: flex;
  gap: 5px;
  align-items: center;
  transition: 0.3s all;
  i {
    padding: 5px;
    font-size: 0.9em;
    transition: 0.3s all;
    border-bottom: 1px solid transparent;
  }
  i:hover {
    // background-color: $gray100;
  }

  .editName:hover {
    color: $seafoam100;
    border-bottom-color: $seafoam60;
  }
  .trash:hover {
    color: $red60;
    border-bottom-color: $red60;
  }
  .copy:hover {
    color: $sapphire60;
    border-bottom-color: $sapphire60;
  }
}

.addpage {
  font-family: $poppins;
  color: $gray100;
  font-weight: 600;
}

.addPage {
  font-family: $poppins;
  color: $gray100;
  font-weight: 600;
  // width: fit-content;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s all;
  margin-left: 40px;
  margin-top: 10px;
}

.addPage:hover {
  background-color: $gray100;
  color: white;
}

.grid {
  // transform: rotate(90deg);

  i {
    color: $gray110;
    font-size: 1.1em;
    padding: 0px;
    transition: 0.3s all;

    // border-bottom: 2px solid transparent;
  }
  i:hover {
    // background-color: transparent;
    border-bottom-color: $gray60;
    cursor: grab;
  }
}
.grid:active {
  cursor: grabbing;
}

.exitOrSave {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  padding-right: 0px;
}

.inputLine {
  font-family: $poppins;
  font-size: 1em;
  color: $gray110;
  width: 100%;
  border: none;
  border-bottom: 2px solid $sapphire60;
  color: $gray110;
  font-family: $poppins;
  outline: none;
}

.backIcon {
  font-size: 2em;
  color: $gray110;
  cursor: pointer;
}

.reportHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  top: 4rem;
  position: sticky;
  z-index: 140;
  padding-bottom: 0.5em;
  justify-content: flex-end;
  pointer-events: none;
  margin-right: 20px;
}

.reportSettingsContainer {
  border: 2px solid transparent;
  width: fit-content;
  pointer-events: auto;
  border-radius: $radius2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  transition: 0.3s all;
  margin-top: 2px;
  font-size: 0.9em;
}

.addChart {
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: $radius2;
  background-color: $white;
  color: $gray110;
  padding: 7px 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  box-shadow: $shadow6;
  min-width: 70px;
  align-items: center;
  z-index: 135;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.addChart:hover {
  background-color: $gray30;
}

.chartTypeMenu {
  display: flex;
  animation: appear 0.3s;
  flex-direction: column;
  position: absolute;
  top: 3em;
  right: -5px;
  overflow-y: auto;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius2;
  color: $gray110;
}

.chartType {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  padding: 7px 16px;
  font-weight: 400;
  color: $gray110;
  transition: 0.3s all;
  cursor: pointer;

  i {
    font-size: 13pt;
    transition: 0.4s all;
    color: $gray60;
  }
}

.chartType:hover {
  background-color: $gray25;
  color: $black;
  // font-weight: 500;

  .chart {
    color: $red60;
  }
  .table {
    color: $blue60;
  }
  .metric {
    color: $sapphire100;
  }
  .notes {
    color: $yellow100;
  }
  .link {
    color: $blue100;
  }
}

.reportSettingsButton {
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: $radius2;
  background-color: $white;
  border: 2px solid transparent;
  color: $gray110;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 5px;
  align-items: center;
  box-shadow: $shadow6;
}

.reportSettingsButton:hover {
  // color: ;
  background-color: $gray60;
  // border: 2px solid transparent;
}

.reportSettingsButtonDownload {
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: 1em;
  background-color: $blue100;
  border: 2px solid $blue100;
  color: $white;
  padding: 7px 7px;
  display: flex;
  gap: 5px;
  align-items: center;
  box-shadow: $shadow;
  pointer-events: auto;

  &:hover {
    background-color: white;
    color: $blue100;
  }
}

.reportSettingsButtonShare {
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: 1em;
  background-color: $gray40;
  border: 2px solid $gray40;
  color: $gray110;
  padding: 7px 7px;
  display: flex;
  gap: 5px;
}
.reportSettingsButtonShare:hover {
  color: $white;
  background-color: $blue100;
  // border: 2px solid transparent;
}

.reportSettingsButtonOpen {
  background-color: white;
}

.cancel {
  color: $red60;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  padding: 10px 0px;
  transition: 0.3s all;
  font-size: 0.9em;
  text-align: center;
  min-width: 75px;
}

.cancel:hover {
  color: white;
  background-color: $red60;
}

.reportSettings {
  border: 2px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reportDescription {
  outline: none;
  border: 2px solid $gray30;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  color: $gray110;
  min-height: 250px;
  margin: 0px 20px;
}

.textArea {
  border: 2px solid $gray40;
  border-radius: $radius4;
  width: 100%;
  resize: none;
  outline: none;
  height: 100px;
  transition: all 0.3s;
  padding: 10px 20px;
  font-size: 0.8em;
  font-family: $poppins;
  color: $gray110;
  background-color: $white;
  // margin: 0px 0px 20px 20px;
}
.textArea:focus {
  border: 2px solid $gray100;
}
.textArea:hover {
  border: 2px solid $gray100;
}

.emptyMessage {
  font-family: $poppins;
  color: $gray110;
  border: 2px solid $gray30;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  padding: 80px 0px;
  a {
    color: $blue100;
    cursor: pointer;
  }
  // a:hover {
  //   text-decoration: underline;
  // }
}

.notifyText {
  font-family: $poppins;
  color: $gray110;
  padding: 0px 20px;
  a {
    color: $blue100;
    cursor: pointer;
  }
  a:hover {
    text-decoration: underline;
  }
  padding-bottom: 15px;
}

.reportpagename {
  font-family: $poppins;
  font-size: 1.5em;
  color: $gray110;
  font-weight: 600;
  z-index: 100;
  display: flex;

  input {
    border: none;
    font-family: $poppins;
    color: $gray110;
    font-weight: 600;
    font-size: 1em;
    // width: 100%
  }
}

.pagesPopout {
  position: absolute;
  background-color: white;
  border-radius: $radius2;
  max-height: 500px;
  overflow: auto;
  // min-height: 300px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 6em;
  left: 1em;
  // padding-top: 10px;
  // padding-bottom: 10px;
  min-width: 450px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  span {
    font-size: 1em;
  }
  font-weight: 400;
  font-size: 1.1em;
  z-index: 120;
  // font-size: 1em;

  // padding: 10px 0px;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0em 6em;
  position: relative;
  gap: 20px;
}

.share {
  position: relative;
  // height: 50px;
}
.howCome {
  visibility: hidden;
  min-width: 100px;
  max-width: 210px;
  padding: 10px 10px;
  background-color: $white;
  border: 2px solid $gray30;
  box-shadow: 4px 4px 5px #d8d9d9b2;
  font-size: 0.85em;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  bottom: 3.2em;
  right: 0;
  color: $gray100;
  white-space: nowrap;
}

// .howCome::after {
//   content: "";
//   position: absolute;
//   top: -1.2em;
//   right: 20%;
//   margin-top: -5px;
//   rotate: 90deg;
//   border-width: 10px;
//   border-style: solid;
//   border-color: transparent $blue60 transparent transparent;
// }

.share:hover .howCome {
  visibility: visible;
}

.publishAccess {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-left: 40px;
  span {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 1em;
    font-size: 0.8em;
  }
  a {
    color: $blue100;
  }
}

.filler {
  width: 80%;
  height: 100%;
}

.filter {
  position: absolute;
  top: 6rem;
  left: 1rem;
  width: 400px;
  max-height: 75vh;
  background-color: $white;
  padding-bottom: 1em;
  padding-top: 0.5em;
  box-shadow: $shadow6;
  border-radius: $radius2;
  overflow-y: auto;
  z-index: 125;
}
.liveDashFilter {
  top: 3rem;
}
.filterHeader {
  color: $gray110;
  font-family: $poppins;
  font-size: 1.3em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.closeFilters {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 109;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.viewingBy {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-family: $poppins;
  font-weight: 700;
  color: $gray100;
}

.appliedFilter {
  display: flex;
}

.pinnedNTemplate {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  .pinned {
    font-weight: 600;
    color: $seafoam100;
    display: flex;
    align-items: center;
    gap: 13px;
    cursor: pointer;
  }
}

.filtersSubtitle {
  text-align: center;

  display: flex;
  align-items: center;
  gap: 5px;

  i {
    color: $gray100;
  }

  font-family: $poppins;
  font-weight: 500;
  color: $gray110;
  padding-top: 10px;
  border-radius: $radius2;
  backdrop-filter: blur(10px);
  padding: 0 1rem;
}

.pageFilterCount {
  border-radius: $radius8;
  background-color: $info;
  color: $white;
  padding: 2px 8px;
  font-size: 0.9em;
}

.showSettings {
  position: absolute;
  height: fit-content;
  width: 300px;
  right: 0;
  top: 3em;
  background-color: white;
  border-radius: $radius4;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 120;

  i {
    position: absolute;
    right: 0.3em;
    top: 0.3em;
    color: $gray110;
    // z-index: 130;
  }

  .settingOption {
    padding: 1em 0em;
    padding-left: 1em;
    font-size: 1.2em;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 400;
    font-family: $poppins;

    &:hover {
      background-color: $gray60;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0rem 1.2rem;
  pointer-events: none;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}

.reportFooter {
  display: flex;
  pointer-events: auto;
  gap: 1rem;
  align-items: center;
  font-family: $poppins;
  color: $gray110;
  border-radius: $radius2;
  width: fit-content;
  height: 0px;
  font-weight: 500;
}
.reportFooterPage {
  display: flex;
  gap: 0.5rem;
  backdrop-filter: blur(10px);
  padding: 0.5rem;
  border-radius: $radius2;
}

.tiedToContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem 1rem;
}

.tiedToHeader {
  color: $seafoam100;
  font-family: $poppins;
  // cursor: pointer;
  font-weight: 500;
}

.tiedSurveys {
  min-width: 150px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 1rem;
}

.tiedSurvey {
  font-size: 0.9em;
  color: $gray110;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  font-family: $poppins;
  transition: 0.3s all;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: $gray30;
}
// .tiedSurvey:hover {
//   background-color: $gray30;

//   .deleteSurvey {
//     opacity: 1;
//   }
// }

.deleteSurvey {
  transition: 0.3s all;
  opacity: 1;
  cursor: pointer;

  i {
    font-size: 0.9em;
    color: $red60;
  }
}

.addSurvey {
  display: flex;
  align-self: center;
  justify-content: center;
}

.addProjectAnchor {
  position: relative;
}

.addProjects {
  position: absolute;
  z-index: 5;
  top: -40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;

  .search {
    background-color: $white;
    border-radius: 1em;
    box-shadow: $shadow3;
  }

  .searchSurveys {
    width: 100%;
    background-color: white;
    border-radius: $radius4;
    border: 2px solid $white;
    font-family: $poppins;
    color: $gray110;
    font-size: 1em;
    outline: none;
    padding: 8px 10px;
    overflow: none;
    transition: 0.3s all;

    &:hover {
      border: 2px solid $sapphire60;
    }
  }

  .projectOptions {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 1em;
    box-shadow: $shadow3;
    overflow: hidden;
  }

  .projectOption {
    padding: 8px 10px;
    font-family: $poppins;
    transition: 0.3s all;
    cursor: pointer;

    &:hover {
      background-color: $gray40;
    }
  }
}

.saveTies {
  display: flex;
  padding-top: 20px;
  align-self: center;
  gap: 15px;
  align-items: center;
}

.changeType {
  width: 100%;
  padding: 0em 2em;
}

.reportIcon {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  i {
    color: $blue100;
    font-size: 5em;
    padding: 0.2em 0.4em;
    border-radius: $radius2;
    cursor: pointer;
    &:hover {
      background-color: $gray60;
    }
  }
}

.iconShopper {
  position: absolute;
  z-index: 130;
  left: 10%;
  background-color: $white;
  box-shadow: $shadow6;
  top: 25rem;
  border-radius: $radius2;
  padding: 0.5em;
  height: fit-content;
  width: 80%;
  display: flex;

  i:hover {
    background-color: inherit;
  }
}

.zoomcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $gray25;

  @media (max-width: 768px) {
    padding-top: 5rem;
  }
}

.zoomcontrols {
  margin-bottom: 10px;
}

.scrollablearea {
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scrolling in both directions */
  position: relative;
  padding-top: 6rem;
  padding-bottom: 40px;
}

.liveDash {
  padding-top: 3rem;
}

.zoomablecontent {
  transform-origin: top left; /* Ensures zoom happens from the top left */
  will-change: transform; /* Improves performance during zooming */
}

.content {
  width: 1000px;
  min-width: 1000px; /* Adjust the width and height as needed */
  height: fit-content; /* Make the content large enough to enable scrolling */
  padding: 20px;
  background-color: transparent; /* Example background */
  box-sizing: border-box;
  border: 2px solid black;
}

.subContainer {
  width: 1000px;
  height: fit-content;
  transition: 0.3s all;
  padding-bottom: 4rem;
  // margin: auto;
  transform-origin: top left;
}

.container3 {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 150;
}

.container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  transition: 0.3s all;
  position: relative;
  gap: 1rem;
  scroll-behavior: smooth;
  background-color: $gray25;
  touch-action: none;
  padding-top: 3rem;
}
.back {
  // background-image: radial-gradient($gray100 1px, transparent 0);
  // background-size: 40px 40px;
}

.withNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.isEdit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid $gray30;
  border-radius: $radius1;
  padding: 0.25rem 0.5rem;
  @include normal-text($gray110, 400, 0.7rem);
  background-color: $white;
  span {
    @include link();
  }
}

.bubble {
  padding: 0.1rem 0.7rem;
  font-family: $poppins;
  border-radius: $radius1;
  font-weight: 400;
  font-size: 0.8rem;
}
.draft {
  border: 1px solid $orange100;
  background-color: $orange20;
  color: $orange100;
}
.live {
  border: 1px solid $blue100;
  background-color: $blue20;
  color: $blue100;
}

.nav {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  padding: 1rem 1rem;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply blur to the backdrop */
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2); /* Blurred border effect */
  // border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 1;
  flex-wrap: wrap;
}

.navPresent {
  position: sticky;
  top: 0rem;
  z-index: 2;
  width: 100%;
  padding: 0rem 0rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 0px;
  overflow: visible;
  .aa {
    border-radius: $radius2;
    backdrop-filter: blur(10px);
    padding: 0.2rem 0.4rem;
  }
  .spanner {
    border-radius: $radius2;
    backdrop-filter: blur(10px);
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background-color: $gray60;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.reportPageCon {
  display: flex;
  gap: 20px;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  left: 1.2rem;
}

.pageContainer2 {
  // width: 70px;
  height: 100%;
  display: flex;
  transition: 0.3s all;
  box-shadow: $shadow6;
  // z-index: 12;
  background-color: $white;
}

.configBox {
  position: relative;
  height: 100%;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  transition: 0.3s all;
  border-right: 1px solid $gray60;
  background-color: $white;
  padding-bottom: 30px;
  z-index: 110;
  align-items: center;
  right: 0px;
  position: relative;
}

.chartSettings {
  background-color: $gray20;
}

.analysisLoadingDock {
  display: flex;
  justify-content: center;
  padding-top: 125px;
}
