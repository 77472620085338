@import "assets/styles/globals.scss";

.selectContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: $radius1;

  .label6 {
    padding: 0.2rem 0.1rem;
  }
}
.select {
  width: 100%;
  height: 100%;
  // min-height: 2.75rem;
  border: 1px solid $white;
  border-radius: $radius2;
  padding: 0.5rem .7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: $poppins;
  background-color: $white;
  box-shadow: $shadow6;
  color: $black;
  .icon {
    color: $gray60;
    transition: all 0.3s;
  }
  align-items: center;
  transition: all 0.3s;
}
.select:hover {
  border: 1px solid $blue100;
  cursor: pointer;
  .icon {
    color: $blue100;
  }
}


.input {
  border: none;
  background-color: transparent;
  outline: none;
  font-family: $poppins;
  color: $black;
  transition: 0.3s all;
  border-radius: $radius2;
  // min-width: fit-content;
  width: fit-content;
  max-width: 100%;
  // border: 1px solid black;
  cursor: text;
  overflow: none;
  white-space: wrap;
  // padding: 2px 0px;
  // margin-left: -.5rem;
  flex-grow: 1;
  // width: 100%;

  flex-wrap: wrap;
  // color: blue;
  // background-color: $gray30;

  // &:hover {
  //   background-color: $gray30;
  // }

  // &:focus {
  //   background-color: $gray30;
  // }
  // &::placeholder {
  //   color: $gray60;
  // }
}

.disabled {
  background-color: $gray30;
  &:hover {
    border: 1px solid $gray30;
  }
}

.opened {
  border: 1px solid $blue100;
  cursor: pointer;
  .icon {
    color: $blue100;
  }
}
.activeOption {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 10px;
  align-items: center;
  // overflow-x: auto;
  overflow: hidden;
  flex-wrap: wrap;
  margin-right: 10px;
  // border:1px solid blue;
  .icon {
    font-size: 25px;
    color: $gray110;
  }
}

@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}
@include keyframes(show) {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}

.dropdown {
  @include animation(open 0.3s 1);
  transition: 0.3s all;
  position: absolute;
  background-color: $white;
  box-shadow: $shadow6;
  width: 100%;
  height: 240px;
  max-height: 240px;
  min-width: 10px;

  border-radius: $radius2;
  font-family: $poppins;
  // font-size: 0.9em;
  color: $gray110;
  overflow: auto;
  left: 0;
  top: 108%;
  z-index: 100;

  .option {
    @include animation(show 0.3s 1);
    padding: 0px 20px;
    // padding-left: 20px;
    height: 40px;
    transition: 0.3s all;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    .helperText {
      color: $blue100;
      opacity: 0;
      font-size: 0.9em;
      transition: 0.3s all;
    }

    &:hover {
      background-color: $seafoam10;
      .helperText {
        opacity: 1;
      }
    }
  }
}

.optionActive {
  @include animation(show 0.3s 1);
  padding: 0px 20px;
  // padding-left: 20px;
  height: 40px;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  &:hover {
    background-color: $seafoam10;
    .helperText {
      opacity: 1;
    }
  }
}

.searchableDropdown {
  position: absolute;
  height: fit-content;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  max-height: 200px;
  background-color: white;
  border-radius: $radius2;
  z-index: 120;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // padding: 10px 0px;
  font-family: $poppins;
  // min-height: fit-content;
}

.option {
  width: 100%;
  padding: 10px 20px;
  color: $gray110;
  font-size: 0.8rem;
}
.option:hover {
  background-color: $seafoam10;
  cursor: pointer;
}

.optionActive {
  background-color: $blue100;
  width: 100%;
  padding: 10px 20px;
  color: $white;
  cursor: pointer;
}

.searchInputer {
  height: 100%;
  width: 100%;
  font-family: $poppins;
  background-color: $blue5;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1rem;
}

.searchContainer {
  width: 100%;
  height: 100%;
  // max-width: 250px;
  // max-height: 4.2em;
  border: 1px solid $gray30;
  // border: 1px solid black;
  border-radius: $radius2;
  padding: 4px .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  color: $gray110;
  .icon {
    color: $gray60;
  }
  align-items: center;
  transition: all 0.1s;
  // border: 1px solid black;
  // overflow-x: auto;
}
.searchContainer:hover {
  border: 1px solid $blue100;
  cursor: pointer;
  .icon {
    color: $gray110;
  }
}

.activeSearchOption {
  // max-width: 300px;
  // max-height: 50px;
  min-height: 27px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-family: $poppins;
  // overflow: auto;
  // border: 1px solid black;
  margin-right: 10px;
  white-space: nowrap;
  overflow-x: auto;

  .icon {
    font-size: 25px;
    color: $gray110;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.activeSearchOption::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.activeSearchOption {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.selector {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
}

.chosenOption {
  display: flex;
  flex-direction: row;
  background-color: $gray25;
  border-radius: $radius1;
  border: 1px solid $gray60;
  color: $black;
  // padding: 3px 10px;
  white-space: nowrap;
  padding-left: 0.5rem;
  gap: 0.5rem;
  transition: all 0.5s;
  font-size: 0.8em;
  align-items: center;
  &:hover {
    background-color: $gray30;
    cursor: pointer;
  }
  .close {
    color: $red60;
    font-size: 1.2em;
    padding: 0.2rem;
    border: 1px solid transparent;
    border-radius: $radius1;
    transition: 0.2s all;
    &:hover {
      background-color: $red10;
      border: 1px solid $red60;
    }
    // height: 50px;
  }
  i:hover {
    color: $red60;
  }

  span {
    cursor: pointer;
  }
}

.iconContainer {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.newOptionText {
  color: $gray110;
  // position: absolute;
  right: 10px;
  top: 5px;
  padding: 10px 20px;
  font-size: 0.8em;
  // text-align: center;
  // border-bottom: 1px solid $gray30;
}
.newOptionText:hover {
  cursor: pointer;
  background-color: $seafoam10;
}

.placeholder {
  color: $gray60;
  padding-left: 7px;
}
