@import "assets/styles/globals.scss";

.supportTicketContainer {
    font-family: $poppins;
    // color: $gray110;
    font-size: 11pt;
    margin: 40px 0px 20px;
    height: 200px;
}

.invitation {
    text-align: center;
}

.inputContainer {
    // border: 2px solid $gray100;
    // border-radius: 5px;
    width: 75%;
    margin: auto;
    margin-top: 10px;
    
}

.submitBtn {
    margin: 7px 0px 0px 10px;
    // position: absolute;
    // right: 0;
    // top: 7px;


    
}



