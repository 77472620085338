@import "assets/styles/globals.scss";

.menu {
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em;

  // border: 2px solid black;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}
.addRole {
  display: flex;
  justify-content: center;
  // color: #2a627c;
  // padding: 0.4em 1em;
  // text-align: center;
}
