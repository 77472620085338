@import "assets/styles/globals.scss";

.exit {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;

  color: $gray110;
  transition: 0.3s all;

  &:hover {
    color: $black;
  }
}

@keyframes slideIn {
  0% {
    right: -130px;
  }

  35% {
    right: 25px;
  }

  45% {
    right: 25px;
  }

  100% {
    right: 15px;
  }
}

.indicator {
  @include animation(slideIn 1s);
  position: absolute;
  right: 15px;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  background-color: $yellow100;
  color: $white;
  cursor: pointer;
  font-size: 1em;
  transition: 0.3s all;
  padding: 0px 5px;
  border-radius: 8px;

  &:hover {
    .bpTitle {
      width: 100px;
    }
  }
}

.bpTitle {
  width: 0px;
  transition: 0.3s all;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
}

.showTitle {
  width: 100px;
}

@keyframes appear {
  0% {
    top: -100px;
    opacity: 0;
  }

  70% {
    top: 120%;
  }

  100% {
    opacity: 1;
  }
}

.list {
  width: fit-content;
  @include animation(appear 0.5s);

  position: absolute;
  top: 120%;
  right: 0px;

  padding: 15px;
  border-radius: 0.75em;
  box-shadow: $shadow;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-size: 0.8em;
  cursor: default;

  z-index: 2;
}

.problem {
  display: flex;
  color: $black;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
}

.divider {
  color: $gray60;
}

.ignore {
  color: $gray100;
  font-size: 0.9em;

  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: $red60;
  }
}
