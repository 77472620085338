@import "assets/styles/globals.scss";

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 1;
    margin-left: 40em;
  }

  100% {
    opacity: 1;
  }
}

.page {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 90vh;
  overflow: auto;
  // max-height: 700px;
}

.bar {
  border: 2px solid $sapphire20;
  border-radius: 10px;
  width: 80%;
  margin: auto;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.setting {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  i {
    color: $gray60;
    font-size: 1.3em;
  }
}

.buttons {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  gap: 1em;
}

.templates {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  // border: 2px solid white;
  position: relative;
  padding-top: 2em;
}

.chooseContainer {
  padding: 0px 100px;
}

.header {
  position: sticky;
  top: 0;
  background-color: $white;

  h1 {
    padding: 0em;
    margin: 0em;
    @include header-2-text;
  }

  span {
    @include description-text;
    margin-left: 1em;
  }
}

.chooseFrom {
  padding: 2em 1em;
  padding-bottom: 0em;
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
  width: 100%;
}

.box {
  border: 1px solid $gray30;
  text-align: center;
  padding-top: 1em;
  border-radius: $radius4;
  width: 200px;
  height: 180px;
  font-size: 0.6em;
  display: flex;
  gap: 1em;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  overflow: hidden;

  i {
    font-size: 7em;
    color: $background;
  }
  h1 {
    padding: 0em;
    margin: 0em;
    @include header-4-text($seafoam100);
    font-weight: 400;
  }

  span {
    @include description-text;
    font-size: 0.9em;
    margin: 0em;
    // margin-left: 1em;
  }

  &:hover {
    border: 1px solid $blue100;
    box-shadow: $shadow;
  }

  .boxheader {
    margin: 0em 2em;
    text-align: left;
  }
}

.boxfill {
  border: 1px solid $blue100;
  box-shadow: $shadow;
}

.fromTemplate {
  .fromheader {
    @include description-text;
    font-size: 0.8em;
    padding-left: 1em;
  }
}

.alltemps {
  width: 100%;
}

.chooseItemTemplate {
  width: 100%;
  // max-width: 800px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  justify-content: center;
  // gap: 2em;
  row-gap: 2em;
  column-gap: 2em;
  margin: auto;
  // gap: 2.8em;
}

.header {
  @include header-3-text;
  margin: 1em 0em;
}

.creating {
  display: flex;
  flex-direction: column;
  gap: 2em;
  font-family: $poppins;
  color: $gray110;
  align-items: center;
  padding: 3em;
}

.tiedSurveys {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
}

.tiedSurvey {
  font-size: 0.9em;
  color: $gray110;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-family: $poppins;
  transition: 0.3s all;
  padding: 2px 5px;
  border-radius: 5px;
}
.tiedSurvey:hover {
  background-color: $gray30;

  .deleteSurvey {
    opacity: 1;
  }
}

.careful {
  background-color: $gray30;
}

.deleteSurvey {
  transition: 0.3s all;
  opacity: 0;
  cursor: pointer;

  i {
    font-size: 0.9em;
    color: $gray110;
  }
}

.addSurvey {
  display: flex;
  justify-content: center;
  min-width: 150px;
  cursor: pointer;
  transition: 0.3s all;
  color: $sapphire60;

  &:hover {
    color: $gray110;
  }
}

.addProjectAnchor {
  position: relative;
}

.addProjects {
  position: absolute;
  z-index: 5;
  top: -40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;

  .search {
    background-color: $white;
    border-radius: 1em;
    box-shadow: $shadow3;
  }

  .searchSurveys {
    width: 100%;
    background-color: white;
    border-radius: $radius4;
    border: 2px solid $white;
    font-family: $poppins;
    color: $gray110;
    font-size: 1em;
    outline: none;
    padding: 8px 10px;
    overflow: none;
    transition: 0.3s all;

    &:hover {
      border: 2px solid $sapphire60;
    }
  }

  .projectOptions {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 1em;
    box-shadow: $shadow3;
    overflow: hidden;
  }

  .projectOption {
    padding: 10px;
    transition: 0.3s all;
    cursor: pointer;

    &:hover {
      background-color: $gray40;
    }
  }
}

.breakouts {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  gap: 5px;
}

.breakoutSetting {
  display: flex;
  align-items: center;
  gap: 15px;
}

.breakoutName {
  color: $gray100;
}
.breakoutChecked {
  color: $gray110;
}

.copyReportContainer {
  padding: 0px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.copyCategory {
  display: flex;
  flex-direction: column;
  // gap: 10px;
}

.copies {
  display: flex;
  flex-wrap: wrap;
  width: 515px;
  gap: 15px;
}

.copy {
  border: 1px solid $gray30;
  border-radius: $radius4;
  width: 250px;
  height: 120px;
  font-size: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  cursor: pointer;
  position: relative;

  span {
    @include description-text;
    font-size: 0.9em;
    margin: 0em;
    // margin-left: 1em;
  }

  &:hover {
    border: 1px solid $blue100;
    box-shadow: $shadow;
  }
}

.copyName {
  @include header-3-text($seafoam100);
  font-weight: 400;
  white-space: balance;
}

.copyChosen {
  border: 1px solid $blue100;
  box-shadow: $shadow;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  
  span {
    font-size: 5em;
    color: $blue20;
  }
}

.copyStats {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0px 10px 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  // border: 2px solid black;
}
