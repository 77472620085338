@import "assets/styles/globals.scss";

.page {
  display: flex;
  // flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
  gap: 0em;
  background-color: inherit;
  padding: .5rem .5rem;
  transition: .3s all;

}

.contactTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  // height: 40px;
  // position: sticky;
  // bottom: 0;
  height: 100%;
  max-height: 80px;
  background-color: $white;
  box-shadow: $shadow;
  width: 100%;
  padding: 30px 2em;
  // z-index: 2;
  // left: 0;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  // background-color: $white;
  border-radius: $radius2;
  // box-shadow: $shadow6;
  transition: .3s all;
  // background-color: $gray25;
  
}
.topContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  padding-bottom: 0;
  // border-bottom: 2px solid $gray25;
  align-items: center;
  height: fit-content;
  flex-wrap: wrap-reverse;
  row-gap: 1rem;
}
.topContainer2 {
  display: flex;
  justify-content: space-between;
  padding: 0em 1em;
  align-items: center;
  padding: 0.8em;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.search {
  display: flex;
  align-items: center;
}

.count {
  background-color: $info;
  border-radius: $radius4;
  color: $white;
  padding: 2px 10px;
  font-size: 0.9em;
}

.middleContent {
  display: flex;
  gap: 1.5em;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 10px 8px;
}

.buttons {
  display: flex;
  gap: 1em;
}

.sortingContent {
  font-family: $poppins;
  color: $gray100;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  gap: 1em;

  cursor: pointer;
  span {
    border-radius: $radius2;
    padding: .5rem .5rem;
    color: $gray110;
    display: flex;
    align-items: center;
    // gap: 1em;
    position: relative;
    width: 130px;
    min-width: fit-content;
    justify-content: space-between;
    background-color: $white;
    box-shadow: $shadow6;
  }
}

.tableHeader {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: fit-content;
}

.headerInner {
  display: flex;
  gap: 1em;
  // border: 2px solid black;
}

.titleTop {
  // border: 2px solid black;
}

.tpage {
  // display: flex;
  height: 100%;
  // max-height: 500px;
  border: 2px solid brown;
}

.tableTitle {
  @include header-3-text($gray110);
  font-weight: 400;
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
  // border: 2px solid black;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    font-size: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
}

.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;

  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}
@include keyframes(show) {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}

.settingsBox {
  @include animation(open 0.3s 1);

  position: absolute;
  // top: 33px;
  right: 0px;
  width: fit-content;
  white-space: nowrap;
  top: 2.4rem;
  // height: 100vh;
  // height: fit-content;
  // height: fit-content;
  // max-height: 100px;

  // min-height: fit-content;
  // max-height:fit-content;

  z-index: 130;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
 
  // transition: .3s all;
  // align-items: center;

  cursor: default;
  overflow: hidden;

  .menuItem {
    @include animation(show 0.3s 1);
    transition: none;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9rem;
    color: $black;
    padding: 0.5em 1em;
    height: 40px;
    min-width: 150px;
    cursor: pointer;
    span {
      // width: 60px
    }
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray60;
    }

    .description {
      color: $gray110;
      font-size: .8rem;
    }
  }
}

.saving {
  display: flex;
  gap: 0.5em;
  // color: $gray110;
  align-items: center;
  font-family: $poppins;
  height: 100%;
}

.editableFields {
  display: flex;
  align-items: center;
  color: $green100;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 500;
  i {
    font-size: 1.3em;
  }
}



.link {
  @include link;
  font-size: 0.9em;
  padding-left: 10px;
}

.filterIcons {
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: $gray110;
  padding-right: 1em;
}

.filterIcon {
  position: relative;
  display: flex;
  align-items: center;
  // font-size: 1.2em;
  gap: 0.2em;
  cursor: pointer;
  transition: 0.3s all;
  padding: 5px 10px;
  border-radius: $radius2;
  &:hover {
    background-color: $white;
    box-shadow: $shadow6;
  }
}

.activeFilterIcon {
  background-color: $white;
  box-shadow: $shadow6;
}

.iconCount {
  font-size: 1.2em;
}
.count {
  background-color: $info;
  border-radius: $radius4;
  color: $white;
  padding: 2px 7px;
  padding-bottom: 3px;
  font-size: 0.5em;
  display: flex;
}

@include keyframes(slide-right) {
  0% {
    opacity: 0;
    width: 0px;
    // max-width: 400px;
  }
  5% {
    opacity: 1;
    width: 0px;
  }
  100% {
    width: 400px;
    // max-width: 400px;
  }
}

// Define the reverse slide-right animation for exiting
@include keyframes(slide-left) {
  0% {
    width: 400px;
  }
  100% {
    width: 0px;
  }
}
.showDy {
  &.enter {
    @include animation("slide-right 0.3s forwards");
  }

  &.exit {
    @include animation("slide-left 0.3s forwards");
  }
  height: 100%;
  max-width: 400px;
  width: 400px;
  padding: .5rem 0rem;
  padding-left: .5rem;
  transition: .3s all;
  overflow: hidden;
}

