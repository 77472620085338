@import "assets/styles/globals.scss";

.container {
  position: absolute;
  left: 1em;
  bottom: 1em;
  height: fit-content;
  width: fit-content;
  border-radius: $radius3;
  padding: 12px 10px;
  box-shadow: $shadow;
  font-size: 0.8em;
  font-style: $poppins;
  background-color: $white;
  z-index: 150;
  width: 300px;
}

.userProfile {
  display: flex;
  align-items: center;
  text-align: center;
  overflow-x: auto;
  gap: 1em;
  padding-bottom: 10px;
}

.nameContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: $poppins;
  color: $gray110;
  .name {
    font-weight: 600;
  }
  .email {
    font-size: 0.85em;
    color: $gray100;
  }
}

.avatar {
  width: 55px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;
}

.myAccountDiv {
  padding: 10px 0px 10px 18px;
  color: $gray110;
  border-radius: 5px;
}

.myAccountDiv:hover {
  background-color: $sapphire20;
  cursor: pointer;
}

.divider {
  border: 1px solid $gray30;
  width: 95%;
  margin: auto;
}

.signOutDiv {
  padding: 10px 0px 1px 18px;
  span {
    cursor: pointer;
    color: $sapphire100;
  }
  span:active {
    color: $sapphire60;
  }
}

.imageContainer {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 0.25;
  box-shadow: $shadow5;

  img {
    height: 70px;
  }
}
