@import "assets/styles/globals.scss";

.calendar {
  height: 600px;
  width: 1000px;
  //   background-color: $white;
  //   padding: 2em;
  //   border-radius: 1em;
}

.calendarContainer {
  display: flex;
  justify-content: center;
}

.cell {
  background-color: white;
  display: flex;
  width: 100%;
  height: 100%;
  border-right: 1px solid #ddd;
  overflow: auto;
}

.cellOut {
  background-color: $gray30;
  border-right: 1px solid #ddd;
  display: flex;
  width: 100%;
  height: 100%;
}

.holiday {
  width: 100%;
  background-color: $yellow40;
  font-size: 0.7em;
  color: $gray110;
  font-family: $poppins;
  padding: 0.5em;
  display: flex;
  align-items: flex-end;
}

.empty {
  width: 100%;
  background-color: transparent;
  font-size: 0.7em;
  color: $gray110;
  font-family: $poppins;
  padding: 0.5em;
  display: flex;
  align-items: flex-end;
}

.header {
  @include header-3-text;
  padding-bottom: 1em;
}
.event {
  background-color: $blue100;
  border-radius: $radius1;
  display: flex;
  width: 100%;
  gap: 0.5em;
  padding: 0px 7px;
  font-weight: 700;
  font-size: 11px;
}

.eventSent {
  background-color: $green100;
  border-radius: $radius1;
  display: flex;
  width: 100%;
  gap: 0.5em;
  padding: 0px 7px;
  font-weight: 700;
  font-size: 11px;
}


.weekEvent {
  background-color: $blue100;
  height: 100%;
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  gap: .3em;
  border-radius: $radius1;
  overflow: auto;
}

.weekEventSent {
  background-color: $green100;
  height: 100%;
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  gap: .3em;
  border-radius: $radius1;
  overflow: auto;
}

.weektime {
  font-size: 0.9em;
}

.eventWrap {
  border: 2px solid black;
}
