@import "assets/styles/globals.scss";

.modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-family: $poppins;
  padding: 1em;
  border-radius: 1em;
}

.warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.logo {
  font-size: 3em;
  color: $blue60;
}

.questionList {
  font-size: .95em;
  color: $gray110;
}

.prompt {
  
}

.actions {
  display: flex;
  gap: 15px;
  align-items: center;
}
