@import "assets/styles/globals.scss";

.page {
  width: 100%;
  display: flex;
  height: fit-content;
  justify-content: center;
  gap: 10px;
  flex-direction: row;
  transition: 0.3s all;
  padding: 20px 20px;
  max-width: 1000px;
  min-width: 1000px;
  margin: auto;
}

.widescreen {
  max-width: none;
  min-width: none;
}

.analysisContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.3s all;
}

.chartSection {
  display: flex;
  flex-direction: row;
  height: 20vh;
  min-height: 50px;
  width: 100%;
  justify-content: center;
  resize: horizontal;
}

.rowSpacer {
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .dragBar {
    background-color: $gray60;
    .indicator {
      opacity: 1;
    }
  }

  .dragBar:hover {
    background-color: $gray100;
    .indicator {
      color: $gray100;
    }
  }

  .dragBar:active {
    background-color: transparent;
    .indicator {
      opacity: 0;
    }
  }
}

.dragBar {
  width: 100%;
  height: 2px;
  transition: 0.5s all;
  cursor: ns-resize;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragBarEmpty {
  width: 100%;
  height: 2px;
  transition: 0.5s all;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  opacity: 0;
  transition: 0.5s all;
  flex-shrink: 0;
  color: $gray60;
  max-height: 19px;
}

.pullPadding {
  position: absolute;
  width: 100%;
  z-index: 200;
  height: 150px;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $poppins;
  padding-bottom: 10px;
}

.analysisTitle {
  color: $seafoam60;
  font-weight: 600;
  font-size: 1.5em;
}

.addChart {
  color: $gray100;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  padding: 3px 5px;
  transition: 0.3s all;
  font-size: 0.9em;
  text-align: center;
}

.addChart:hover {
  color: white;
  background-color: $gray100;
}

.emptyMessage {
  font-family: $poppins;
  color: $gray110;
  border-radius: 5px;
  text-align: center;
  height: 700px;
  padding: 80px 0px;

  a {
    color: $blue100;
    cursor: pointer;
  }
}

.zoom {
  -moz-transform: scale(0.18);
  transform: scale(0.18);
  transform-origin: 0 0;
  width: 1050px;
  // zoom: 21%;
}
