@import "assets/styles/globals.scss";

// Answers Filter

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  transition: all 0.5s;
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  padding: 5px 10px;

  font-family: $poppins;
  font-weight: 400;
  font-size: 0.95em;
  color: #474848;
  // z-index: 200;
  // overflow-y: show;
  cursor: default;
}

.conditions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // overflow: show;
}

.condition {
  display: flex;
  gap: 10px;
  align-items: center;
  // justify-content: end;
  font-family: $poppins;
}

.display {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

// .listItem {
//   padding: 2px 10px;
//   border-radius: 10px;
//   width: fit-content;
// }

.surveyName {
  // color: $blue60;
  // background-color: $blue20;

  // color: $sapphire100;
  // background-color: #e8f1f3;
  // // background-color: #a4c6d017;

  // color: $sapphire100;
  // background-color: #bbd4dc;

  color: $gray100;
  border-bottom: 2px solid $sapphire20;
  padding: 0px 5px;
  width: fit-content;
}

.qName {
  color: $gray100;
  padding-left: 10px;
}

.answer {
  color: $gray110;
  padding-left: 10px;
}

.editContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.edit {
  padding: 5px;
  cursor: pointer;
  position: relative;
  font-size: 10pt;

  &:hover .editFill {
    opacity: 1;
  }
}

.editFill {
  transition: 0.2s all;
  position: absolute;
  left: 5px;
  top: 5px;
  opacity: 0;
}

.addNew {
  display: flex;
  padding-top: 5px;
  padding-left: 5px;
  border-top: 2px solid $sapphire20;
  justify-content: center;
}

.addFilter {
  color: $gray100;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s all;
}
.addFilter:hover {
  color: $gray110;
}

.addingNew {
  display: flex;
  flex-direction: column;
  // margin-left: 2em;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid $sapphire20;
  // justify-content: center;
}

.editableCondition {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: $gray110;
}

.section {
  display: flex;
  gap: 15px;
  align-items: flex-end;
  font-family: $poppins;
}

.inputHolder {
  flex-grow: 1;
  z-index: 130;
}

.preface {
  white-space: pre;
  padding-bottom: 10px;
}

.label {
  color: $seafoam100;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1em;
  // padding-left: 10px;
}

.textInput {
  width: 100%;
  background-color: white;
  border-radius: $radius4;
  border: 2px solid $gray30;
  font-family: $poppins;
  color: $gray110;
  font-size: 1em;
  outline: none;
  padding: 8px 10px;
  overflow: none;
  transition: 0.3s all;
}
.textInput:hover {
  border: 2px solid $gray60;
}
.textInput:focus {
  border: 2px solid $gray60;
  // background-color: $gray20;
}

.textInput::placeholder {
  color: $gray100;
}

.numberInput {
  padding-top: 15px;
  padding-bottom: 10px;
  width: 50px;

  input {
    width: 50px;
    border: 2px solid $gray30;
    border-radius: 5px;
    color: $gray100;
    transition: 0.3s all;

    &:hover {
      border: 2px solid $gray100;
    }
  }
}

.buttons {
  padding-top: 10px;
  // padding-bottom: 5px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn {
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: $shadow3;
  font-weight: 500;
  transition: 0.5s all;

  display: flex;
  align-items: center;
  gap: 5px;
}
.btn:hover {
  color: $white;
}

.apply {
  color: $white;
  background-color: $blue60;
}
.apply:hover {
  background-color: $white;
  color: $blue60;
}

.cancel {
  color: $red60;
  background-color: $white;
}
.cancel:hover {
  background-color: $red60;
}

.okay {
  // color: $green100;
  color: $white;
  background-color: $green100;
}
.okay:hover {
  // background-color: $green100;

  color: $green100;
  background-color: $white;
}

.pageFilter {
  flex-grow: 1;
  text-align: right;
  text-wrap: nowrap;
  color: $sapphire60;
  font-weight: 500;
  font-size: 0.95em;
}

.andOr {
  padding-left: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.active {
  color: $blue60;
}

.andOrToggle {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $sapphire60;
}
