@import "assets/styles/globals.scss";

.ex {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: $sapphire80;
}

.formula {
  color: $black;
  font-size: .8rem;
}

.desc {
  color: $gray100;
  font-size: .75rem;
}
