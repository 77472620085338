@import "assets/styles/globals.scss";
.tabBarItem {
  color: #738c91;
  font-weight: 500;
  font-family: $poppins;
  font-size: 0.9em;
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  white-space: nowrap;
  gap: 5px;

  background-color: transparent;
  position: relative;
  z-index: 0;
  // transition: .3x;
  // transition: .3s;
  &:hover {
    color: $blue100;
   
  }


  // padding: 5px 0px;
  // text-align: center;
  &.activeItem {
    color: $blue100;
    border-bottom: 2px solid $blue100;
    // background-color: $gray20;
    border-top-right-radius: $radius4;
    border-top-left-radius: $radius4;
    // border-radius: $radius4;
    width: 100%;
    

    // border-bottom: 2px solid $blue100;
  }


  &.activeItemWings {
    color: $blue100;
    // border-bottom: 2px solid $blue100;
    background-color: $gray25;
    border-top-right-radius: $radius4;
    border-top-left-radius: $radius4;
    // border-radius: $radius4;
    width: 100%;
    // border-bottom: 2px solid $gray30;
    // border-bottom: 2px solid $blue100;
  }

  &.activeItemWings::before {
    content: ''; /* To generate the box */
    position: absolute;
    top: 0; 
    right: -10px; 
    bottom: -2px; 
    left: -10px;
    z-index: -1;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    background: #ddd;
    // box-shadow: 0 2px hsla(0,0%,100%,.5) inset;
    transform: perspective(10px) rotateX(5deg);
    transform-origin: bottom;
    background-color: $gray25;
  }


  i {
    font-size: 1.1em;
  }

  &.overflow {
    padding: 7px 12px;
    // padding-top: 7px;
 
  }

  .tab_head {
    display: flex;
    gap: .5em;
    padding: 0px 15px;
    padding-top: 3px;
    // border: 2px solid black;
  }
}

.tabBarItem:hover {
  cursor: pointer;
}

.tab_head_bottom {
  position: absolute;
  background-color: black;
  z-index: 3;
  content: '';
  height: 20px;
  width: 10px;
  right: -10px;
  bottom: 0px;
  border-bottom-left-radius: $radius8;
  background-color: $white;
}
.tab_head_bottom2 {
  position: absolute;
  background-color: black;
  z-index: 3;
  content: '';
  height: 20px;
  width: 10px;
  left: -10px;
  bottom: 0px;
  border-bottom-right-radius: $radius8;
  background-color: $white;
}

.count {
  background-color: $info;
  border-radius: $radius4;
  color: $white;
  padding: 2px 10px;
  font-size: 0.9em;
}