@import "assets/styles/globals.scss";

.header {
    // margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;


    span {
        font-family: $poppins;
        font-weight: 400;
        font-size: 1.5em;
        color: $gray110;
    }
    i {
        font-size: 1.5em;
        color: $gray110;
        cursor: pointer;
    }
}

.pathText {
    font-family: $poppins;
    font-weight: 400;
    font-size: 1.5em;
    color: $gray110;
}