@import "assets/styles/globals.scss";
.thelabel {
    font-family: $poppins;
    width: 100%;
    color: $black;
    letter-spacing: .1em;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400; 
    font-size: .8em;
    padding-left: 1em;

    span {
        font-size: 0.8em;
        color: $gray100;
    }
}
.italics {
    font-style: italic;
}
.reverse {
    flex-direction: row-reverse;
}

.icon {
    margin-left: 2px;
}

.icon:hover {
    position: relative;
    .tooltip {
        opacity: 1;
        pointer-events: all;
    }
}
.tooltip {
    position: absolute;
    font-weight: 400;
    font-size: 0.75em;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 20em;
    padding: 10px;
    z-index: 150;
    text-align: left;
}
