@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  align-items: center;
  // border: 1px solid pink;
}

.stack {
  display: flex;
  flex-direction: column;
  // border: 1px solid gray;
}

.minMax {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray100;
  font-size: 0.85em;
  padding: 0px 2px 8px;
  font-family: $poppins;

  // border: 1px solid orange;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  // border: 1px solid brown;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray100;
  font-weight: bold;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 2px solid $gray60;
  cursor: pointer;
  @include transition(all 0.3s ease-in-out);
}

.itemCustom {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray100;
  font-weight: bold;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 2px solid $gray60;
  cursor: pointer;
  @include transition(all 0.3s ease-in-out);
}
