@import "assets/styles/globals.scss";

.pageContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: 20px;
    overflow: hidden;
    padding: 0px 15px;

  }

  .content {
    width: 100%;
    // border: 2px solid black
  }