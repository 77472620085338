@import "assets/styles/globals.scss";

.matrix {
  font-family: $poppins;
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8em;
  overflow: hidden;

  th {
    border-bottom: 2px solid $gray60;
    padding: 0px 10px 3px;
    font-weight: 400;
  }
  
  th:first-child {
    border-right: 2px solid $gray60;
    width: 1%;
    padding: 0px;
  }
  
  td:first-child {
    border-right: 2px solid $gray60;
  }
}

.noX {
  th {
    border-bottom: none;
  }
}

.noY {
  th:first-child {
    border-right: none;
  }
  td:first-child {
    border-right: none;
  }
}

.colHeader {
  // width: 20%;
}

.yAxisLabel {
  padding: 3px 10px 3px 5px;
}

.yAxisNumber {
  text-align: center;
  padding-right: 3px;
  // border: 1px solid brown;
}

.textEntry {
  padding: 5px;
}

.choiceInput {
  font-family: $poppins;
  border: none;
  border-bottom: 2px solid $white;
  outline: none;
  text-align: center;
  cursor: text;
  transition: 0.3s all;

  display: block;
  height: fit-content;
  overflow-x: hidden;
  white-space: normal;

  &:focus {
    border-bottom: 2px solid $sapphire20;
  }
}

.newChoiceInput {
  &::placeholder {
    transition: 0.3s;
    color: $gray60;
    font-weight: 500;
  }

  &:not(:focus) {
    cursor: pointer;
    &:hover {
      &::placeholder {
        color: $gray100;
      }
    }
  }
}

.topAbove {
  th {
    border: none;
  }
}

.minMaxContainer {
  padding: 0px 6px 10px;
}

.minMax {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray100;
}

.dragBar {
  position: absolute;
  top: 0px;
  right: -2px;
  width: 2px;
  // height: 105%;
  // background-color: $black;
  cursor: ew-resize;
  z-index: 200;
}

.pullPadding {
  width: 25px;
  height: 100%;
  position: absolute;
  z-index: 210;
}

.left {
  // background-color: $blue100;
  right: 0;
}

.right {
  // background-color: $orange100;
  left: 0;
}

.topLeft {
  position: relative;
}