@import "assets/styles/globals.scss";

.headerContainer {
  width: 100%;
  display: flex;
  border-bottom: 2px solid $gray40;
  padding: 1rem 1.5rem;
  height: fit-content;
  .header_3 {
    color: $black;
    font-weight: 400;
    margin: 0;
  }
}

.content {
  display: flex;
  height: 100%;
  padding: 1rem;
  overflow: auto;
}

.footerContainer {
  width: 100%;
  display: flex;
  border-top: 2px solid $gray40;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  height: fit-content;
  .header_3 {
    color: $black;
    font-weight: 400;
    margin: 0;
  }
}

.box {
  text-align: center;
  border-radius: $radius4;
  border: 2px solid transparent;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  align-items: center;
  background-color: $white;

  .square {
    border: 4px solid $gray60;
    height: 140px;
    width: 140px;
    border-radius: $radius4;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 3rem;
      color: $blue100;
    }
  }
  .text_3 {
    color: $gray110;
    max-width: 600px;
  }

  &:hover {
    background-color: $blue10;
  }
}

.boxfill {
  background-color: $blue10;
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-bottom: 1em;
  padding-top: 6rem;
  // gap: 1rem;
  .text_2 {
    color: $gray110;
    max-width: 600px;
    text-align: center;
  }

  .header_3 {
    color: $black;
        text-align: center;
  }
}

.progress {
  width: 600px;
  display: flex;
  align-items: center;
}

.maptable {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: start;
    @include normal-text($black);
    width: 40px;
    padding: 0.3rem 0.7rem;
  }

  td {
    padding: 0.1rem 0.5rem;
    width: 100%;
    height: 50px;
  }
  td:first-child {
    width: 300px;
  }
  td:last-child {
    width: 300px;
  }
}

.columninfile {
  width: 100%;
  border: 2px solid $gray40;
  background-color: $gray40;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: $radius2;
  padding: 0.6rem 0.5rem;
  font-family: $poppins;
  font-size: 0.9rem;
}
