@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  display: flex;
  padding-left: 5px;
}

.settings {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 8px;
  width: 100%;
  gap: 1rem;
}
