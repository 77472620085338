@import "assets/styles/globals.scss";

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  padding-bottom: 20rem;
  justify-content: center;
}

.provider {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: $poppins;
  color: $gray110;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 2px solid $gray40;
}

.linkpage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.linkpagecontent {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius2;
}

.accounts {
  border-radius: $radius2;
  width: 100%;

  .provider:last-child {
    border-bottom: none;
  }
}

.section {
  width: 100%;
  max-width: 700px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-left: 2px solid $gray40;
}
