@import "assets/styles/globals.scss";

.body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.setting {
  display: flex;
  flex-direction: column;
//   gap: 0.5rem;
}
