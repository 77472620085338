@import "assets/styles/globals.scss";

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 6rem;
  padding: 2rem;
  padding-bottom: 18rem;
  max-width: 1000px;
  margin: 0 auto;
  // height: 100%;

}

.colorBorder {
  border: 2px solid $black;
  border-radius: $radius1;
}
.pageSection {
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.logo {
  box-shadow: $shadow6;
  border-radius: $radius2;
  background-color: $white;
}

.section {
  border-radius: $radius2;
  width: 100%;
  max-width: 700px;
  height: fit-content;
  // min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  // padding-top: .5rem;
}

.pageSection2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 670px;
  gap: 1rem;
}

.editImage {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-width: 500px;
  min-width: 200px;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  padding: 1rem;
  height: fit-content;
}
.link {
  color: $blue100;
  cursor: pointer;
  width: 300px;
  text-align: center;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
.inputStyle {
  @include link;
}

.subSection {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.stats {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: fit-content;
  gap: 1rem;
}

.customFieldsBox {
  width: 100%;
  border-collapse: collapse;
  // margin: 20px 0;
  font-size: 0.9rem;
  // text-align: left;
  // table-layout: fixed; /* Ensures even distribution of cells */
  font-family: $poppins;
  color: $gray110;
  padding: 1rem;

  // th,
  // td {
  //   width: 33%; /* Optional: Specify width if you want specific percentages */
  // }
  thead {
    th {
      text-align: start;
      padding: 0.5rem;
      font-weight: 400;
      padding-left: 1rem;
      white-space: nowrap;
    }
    th:first-child {
      padding-left: 0.5rem;
    }
  }
  tbody {
    tr {
      transition: 0.3s all;
      border-radius: $radius2;
      cursor: pointer;

      td {
        padding: 0.5rem;
        padding-left: 1rem;
        white-space: nowrap;
      }
      td:last-child {
        border-top-right-radius: $radius2;
        border-bottom-right-radius: $radius2;
      }
      td:first-child {
        border-top-left-radius: $radius2;
        border-bottom-left-radius: $radius2;
        padding-left: 0.5rem;
      }
      &:hover {
        background-color: $blue10;
        color: $blue100;
      }
    }
  }
}

.addField {
  font-family: $poppins;
  font-size: 0.85rem;
  color: $gray100;
}

.sigs {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.orgSig {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: $radius2;
  box-shadow: $shadow6;
  gap: 0.5rem;
  transition: 0.3s all;

  // &:hover {
  //   box-shadow: $shadow;
  //   background-color: $gray25;
  // }
}

.sigTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigName {
  width: fit-content;
  @include normal-text($black, 400, 0.8rem);
}

.html {
  color: $sapphire80;
  font-size: 0.75rem;
}

.sigPreview {
  display: flex;
  flex-direction: column;
  background-color: $white;

  border-radius: $radius2;
  overflow: hidden;
}

.search {
  width: 100%;
}

.mfa {
  font-size: 0.9rem;
  color: $gray110;
  font-family: $poppins;
}

.customFieldsContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  padding-bottom: 5rem;
  gap: 1rem;
  width: 100%;
  height: fit-content;

  background-color: $white;
}

.column {
  width: 100%;
  height: 100px;
  border: 1px solid $gray60;
  border-radius: $radius1;
  padding: 1rem;
  background-color: $white;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    // background-color: $gray30;
    box-shadow: $shadow;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  min-width: 35px;
  border-radius: $radius1;
  border: 1px solid $gray100;
  background-color: $gray20;
  color: $gray110;
}

.status {
  @include normal-text($black, 500, 0.6rem);
  color: $blue100;
  background-color: $blue10;
  border: 1px solid $blue100;
  padding: 0.2rem 0.5rem;
  border-radius: $radius1;
  width: fit-content;
}

.sapphire {
  color: $sapphire100;
  background-color: $sapphire20;
  border: 1px solid $sapphire100;
}

.yellow {
  color: $yellow100;
  background-color: $yellow20;
  border: 1px solid $yellow100;
}

.orange {
  color: $orange100;
  background-color: $orange20;
  border: 1px solid $orange100;
}

.seafoam {
  color: $seafoam100;
  background-color: $seafoam10;
  border: 1px solid $seafoam100;
}


