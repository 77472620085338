@import "assets/styles/globals.scss";

.select {
  width: 100%;
  font-size: 0.9em;
  // max-width: 500px;
  // z-index: 500;
}

.label6 {
  padding: 0.2rem 0.5rem;
}

.selectStyle {
  font-family: $poppins;
  color: $gray110;
  outline: none;
  border: 2px solid $gray60;
  border-radius: $radius2;
  transition: 0.3s all;
  cursor: pointer;
  background-color: $white;
  &:hover {
    border: 2px solid $gray100;
  }
}
