@import "assets/styles/globals.scss";
.thelabel {
  font-family: $poppins;
  color: $seafoam100;
  display: flex;
  align-items: center;
  gap: 5px;

  font-size: 0.65rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.icon:hover {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: help;
  .tooltip {
    opacity: 1;
    pointer-events: all;
  }
}

.tooltip {
  position: absolute;
  right: -25px;
  top: 120%;
  // bottom: 100%;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 20px;
  z-index: 1;
  text-align: center;
}

.children {
  display: flex;
  align-items: center;
  gap: 5px;
}

.big {
  font-weight: 600;
  font-size: 0.75rem;
}
