@import "assets/styles/globals.scss";

$reactlightblue: #d5f3fe;
$reactdarkerblue: #2a627c;
$reactdarkblue: #2a627c81;
$reactorange: #edb57e;
$reactdarkorange: #ed9146;

@include keyframes(slide-left) {
  0% {
    margin-left: 2000px;
  }
  50% {
    margin-left: 0px;
  }
}
@include keyframes(slide-right) {
  0% {
    margin-right: 2000px;
  }
  50% {
    margin-right: 0px;
  }
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.top {
  height: fit-content;

}

.body {
  font-family: "Poppins", sans-serif;
  height: 100%;
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: 15px 20px;
  justify-content: center;
  position: relative;
}

.bodymanual {
  font-family: "Poppins", sans-serif;
  height: 100%;
  display: flex;
  width: 100%;
  gap: 2em;
  padding: 15px 20px;
  justify-content: center;
  position: relative;
}

.body2 {
  font-family: $poppins;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  overflow: auto;
}
.nodata {
  width: 100%;
  height: 100%;
  color: $gray110;
  border-radius: $radius2;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow6;
  // border: 2px solid black;
}

.data {
  width: 100%;
  height: 100%;
  color: $gray110;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  // border: 2px solid black;
  // overflow: hidden;
}



.left {
  @include animation("slide-left .7s 1");
}

.right {
  @include animation("slide-right .5s 1");
}

.aboutbox {
  // width: 100%;
  // height: 100%;
  //   margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // padding: 10px 10px;
  gap: 1em;
  // border-bottom: 2px solid #e3f2fd;
}
.container1 {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin-right: 30px;
  p {
    font-size: 1.2em;
  }
  border: 2px solid $gray30;
  border-radius: $radius2;
  padding: 5px 10px;
}
.container2 img {
  max-width: 300px;
}

.uploadbox {
  /* background-color: #fff; */
  width: fit-content;

  // margin: 50px 0px;
  /* box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 2px solid black;
}
.myForm {
  // border: 2px solid black;
  display: flex;
  flex-direction: column;
  gap: 0em;
  align-items: center;
  margin-top: 1em;
}
.submitButton {
  cursor: pointer;
  background-color: $blue100;
  border-style: none;
  border-radius: $radius2;
  border: 2px solid $blue100;
  color: white;
  padding: 8px 30px;
  font-size: 20px;
  margin-top: 20px;
  /* box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5); */
}
.submitButton:hover {
  background-color: white;
  border: 2px solid $blue100;
  color: $blue100;
  transition: all 0.3s;
}

/*Button*/
.csvFile::-webkit-file-upload-button {
  cursor: pointer;
  background-color: $orange100;
  border-style: none;
  border-radius: $radius2;
  border: 2px solid $orange100;
  color: white;
  padding: 1rem;
  margin-bottom: 20px;
  margin-right: 10px;
  box-shadow: $shadow6;
  font-weight: 500;
  /* box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5); */
}
.csvFile::-webkit-file-upload-button:hover {
  background-color: white;
  border: 2px solid $orange100;
  color: $orange100;
  transition: all 0.3s;
}
.csvFile::file-selector-button {
  cursor: pointer;
  background-color: $blue100;
  border-style: none;
  border-radius: $radius2;
  border: 2px solid $blue100;
  color: white;
  padding: .5rem 1rem;
  margin: 20px 20px;
  box-shadow: $shadow6;
  font-weight: 500;
  font-size: 1rem;
  font-family: $poppins;

  /* box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5); */
}
.csvFile::file-selector-button:hover {
  background-color: white;
  border: 2px solid $blue100;
  color: $blue100;
  transition: all 0.3s;
  box-shadow: $shadow6;
  font-weight: 500;
  // box-shadow: $shadow;
}

.settingsbox {
  // background-color: #fff;
  width: fit-content;
  margin: 0px 0px;
  padding: 5px 10px;

  //   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  box-shadow: $shadow6;
  background-color: $white;
  border-radius: $radius2;
  // border: 2px solid $gray30;
  min-width: 280px;
  height: 100%;
  overflow: auto;

}

.setting {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  label {
    color: $gray110;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span {
    @include description-text($gray60)
  }
  // font-family: 'Poppins', sans-serif;
}

.warningBox {
  padding: 10px 10px;
}
.switchbox {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  h5 {
    display: flex;
    flex-direction: row;
    gap: 100px;
  }
}
.bodybox {
  background-color: #fff;
  width: fit-content;
  margin: 50px auto;
  padding: 5px 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.outputbox {
  display: none;
}
.errortext u {
  border-bottom: 1px dotted red;
  text-decoration: none;
  color: red;
}

.charlengthbox {
  display: flex;
  flex-direction: column;
}
.charlengthtext {
  max-width: 250px;
  margin-right: 10px;
}
.charlengthnum {
  max-height: 25px;
  max-width: 50px;
  border: 2px solid $reactdarkerblue;
  border-radius: 5px;
}
.findandreplace {
  width: 150px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.configurefar {
  color: #4397db;
  background-color: white;
  border: none;
  font-size: 10px;
}

/* successful alert */
.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  opacity: 0;
  transform: scale(0);
  transition: 0.6s ease opacity, 0.6s ease transform;
  margin-bottom: 15px;
  border-radius: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  width: 100%;
  display: none;
}

.alert.success {
  background-color: #04aa6d;
}
.alert.info {
  background-color: #2196f3;
}
.alert.warning {
  background-color: #ff9800;
}


.previous {
  font-family: $poppins;
  color: $blue100;
  font-size: 0.9em;
  cursor: pointer;
  text-align: center;
  padding: 1em 0em;
  // width: fit-content;
  // margin-left: 3em;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  @include header-4-text;
  display: flex;
  justify-content: space-between;
}
.mainheader {
  @include header-4-text;
  font-weight: 500;
  padding-left: 2em;
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 1.2em;
  flex-wrap: wrap;

  span {
    font-weight: 400;
    font-size: 0.7em;
  }
  .active {
    color: $blue100;
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}

.uploading {
  width: 600px;
  display: inline;
  height: 100%;
  color: $gray110;
  font-weight: 500;
  text-align: center;
}

.success {
  padding: 2em 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  box-shadow: $shadow;
  align-items: center;
  padding: 1em;
  border-radius: $radius2;
  background-color: $white;
  color: $gray110;
  font-weight: 500;
}

.successContainer {
  padding: 2em 0em;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $gray110;
  font-size: 0.8em;

  input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px;
    transition: 0.3s all;
    cursor: pointer;
    background-color: $gray60;
    border: 4px solid $gray60;
  }
  input:checked {
    background-color: $white;
    border: 5px solid $blue60;
  }
  input:not(:disabled):not(:checked):hover {
    background-color: $gray60;
    border: 5px solid $gray60;
  }
  input:disabled {
    // border: 2px solid $gray40;
    // background-color: $blue100;
    cursor: default;
  }
}



// .footer {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   height: 3em;
//   gap: 10px;
//   width: 100%;
// }
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  // height: 40px;
  position: sticky;
  bottom: 0;
  background-color: $white;
  box-shadow: $shadow;
  width: 100%;
  padding: 30px 2em;
  // padding-bottom: 30px;
  // border-top-left-radius: $radius4;
  // border-top-right-radius: $radius4;
  left: 0;
}


.loading {
  display: flex;
  justify-content: center;
  // align-items: center;
  // height: 100%;
  // border: 2px solid black;
}

.link {
  @include link;
}

.page1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.section {
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  padding: 1rem;
  width: 100%;
  max-width: 700px;
  height: fit-content;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.option {
  border-radius: $radius2;
  border: 2px solid $gray60;
  padding: 1rem;
  transition: 0.3s all;
  cursor: pointer;
  padding-right: 4rem;
  max-width: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &:hover {
    box-shadow: $shadow6;
    background-color: $gray30;
    border: 2px solid $gray30;
  }
}

.active2 {
  box-shadow: $shadow6;
  background-color: $gray30;
  border: 2px solid $gray30;
}


.importer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-right: 2rem;
  // align-items: center;
  width: 100%;
  height: 100%;
}

.spin {
  // background-color: #3498db;
  // border-radius: 50%;
  animation: spin 2s linear infinite; /* Spins every 2 seconds */
  i {
    font-size: 10rem;
  }
}

/* Keyframes for spinning animation */
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}