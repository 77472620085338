@import "assets/styles/globals.scss";

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 3rem;
  border-radius: $radius2;
  height: fit-content;
  padding-bottom: 18rem;
  

}

.page1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: rem;
}

.section {
  border-radius: $radius2;
  width: 100%;
  max-width: 1000px;
  height: fit-content;
  // min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  // padding-top: .5rem;
}

.option {
  border-radius: $radius2;
  border: 2px solid $gray60;
  padding: 1rem;
  transition: 0.3s all;
  cursor: pointer;
  padding-right: 4rem;
  max-width: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &:hover {
    box-shadow: $shadow6;
    background-color: $gray30;
    border: 2px solid $gray30;
  }
}

.active {
  box-shadow: $shadow6;
  background-color: $gray30;
  border: 2px solid $gray30;
}


.importer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-right: 2rem;
  // align-items: center;
  width: 100%;
  height: 100%;
}



.maptable {
  border-collapse: collapse;
  width: 100%;
  flex-wrap: wrap;
  th {
    text-align: start;
    @include normal-text($black);
    width: 40px;
    padding-right: 1rem;
    // padding: 0.3rem 0.7rem;
  }

  td {
    // padding: 0.1rem 0.5rem;
    padding-right: 1rem;
    width: 100%;
    height: 60px;
    @include normal-text($black)
  }
  
}

.columninfile {
  width: 100%;
  border: 2px solid $gray40;
  background-color: $gray40;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: $radius1;
  padding: 0.6rem 0.5rem;
  font-family: $poppins;
  font-size: 0.9rem;
}

.object {
  border: 1px solid $gray60;
  border-radius: $radius1;
  padding: 0.3rem 0.5rem;
  background-color: $gray40;
  overflow: auto;
}