@import "assets/styles/globals.scss";
.tabBar {
    display: flex;
    // gap: 1rem;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 0.1em;
    //     background-color: $gray60;
    //     opacity: 1;
    //     transform: translate3d(0, 0em, 0);
    //     // transition: opacity 300ms, transform 300ms;
    //   }
}
