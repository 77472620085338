@import "assets/styles/globals.scss";

.imageContainer {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
}