@import "assets/styles/globals.scss";

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.included {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: $gray110;
}

.name {
  display: flex;
  gap: 10px;
  align-items: center;
  color: $gray110;
}

.indicator {
  padding: 2px 10px;
  font-size: 0.85em;
  width: fit-content;
  border-radius: $radius4;
}

.programIndicator {
  background-color: $dullBlue00;
  color: $dullBlue120;
}

.tagIndicator {
  background-color: $orange20;
  color: $orange60;
}

.remove {
  padding-right: 2px;
  transition: 0.3s all;
  color: $gray110;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: $gray40;
    color: $black;
  }
}

.selections {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.selection {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.95em;
}

.timeFrames {
  display: flex;
  flex-direction: column;
  padding-left: 20px;

}

.timeFrameHeading {
  color: $gray100;
  font-size: 0.9em;
}

.timeFrame {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.95em;
  padding-left: 10px;
}

.timeFrameInput {
  appearance: none;
  height: 12px;
  width: 12px;
  border: 2px solid $gray60;
  border-radius: 4px;
  transition: 0.3s all;
  cursor: pointer;

  &:checked {
    background-color: $sapphire60;
    border: 2px solid $sapphire60;
  }
  &:not(:checked):hover {
    background-color: $gray60;
  }

}

.inTimeFrameProjects {
  display: flex;
  flex-direction: column;
  padding: 3px 0px 8px 25px;
  font-size: 0.9em;
}

.inTimeFrameProj {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $gray100;
}

.selectionOptions {
  display: flex;
  flex-direction: column;
  padding: 3px 0px 8px 14px;
  font-size: 0.9em;
}

.selectionOption {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.95em;
}