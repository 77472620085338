@import "assets/styles/globals.scss";

.tableContainer {
  display: flex;
  flex-direction: column;
  // height: 100%;
  // overflow: hidden;
  width: 100%;
  gap: 1em;
  background-color: inherit;
  padding: 0em 1em;
  min-width: 0;
  // border-radius: 1em;
}
.tableHeader {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
}

.headerInner {
  display: flex;
  gap: 1em;
  // border: 2px solid black;
}

.titleTop {
  // border: 2px solid black;
}

.tpage {
  // display: flex;
  height: 100%;
  // max-height: 500px;
  border: 2px solid brown;
}

.tableTitle {
  @include header-3-text($gray110);
  margin: 0;
  font-weight: 400;
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
  // border: 2px solid black;
}

.table {
  border-radius: $radius2;
  overflow: hidden;
  box-shadow: $shadow;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    font-size: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
}

.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;

  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

.search {
  width: 250px;
}
