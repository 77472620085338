@import "assets/styles/globals.scss";

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: transparent;
  // justify-content: center;
  // align-items: center;
  // background-color: rgba(42, 98, 124, 0.6);
  // backdrop-filter: none;
  // background-color: rgba(0, 0, 0, 0.5);
  z-index: 150;
  // backdrop-filter: blur(2px);
  pointer-events: none;
}

.menuHolder {
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  padding: 15px 0px 10px 10px;
}

.quickPivotsMenu {
  font-family: $poppins;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  // box-shadow: $shadow4;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 3px 8px;
  // box-shadow: $gray110 0px 3px 8px;
  padding: 5px;
  background-color: $white;
  width: 250px;
  // max-height: 95vh;
  overflow: auto;
  pointer-events: auto;
}

.container {
  font-family: $poppins;
  position: absolute;
  top: -10px;
  // right: calc(100% + 10px);
  right: -10px;
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  // box-shadow: $shadow4;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 3px 8px;
  // box-shadow: $gray110 0px 3px 8px;
  padding: 5px;
  background-color: $white;
  width: 250px;
  max-height: 70vh;
  overflow: auto;
  pointer-events: auto;
  z-index: 200;
}

.closeOut {
  position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
  color: $gray100;
  transition: 0.3s all;

  &:hover {
    color: $gray110;
  }
}

.chartName {
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  // max-width: 200px;
    max-width: calc(100% - 30px);

  font-size: 0.75em;
  color: $gray110;
}

.toggle {
  display: flex;
  flex-direction: row;
  background-color: $gray20;
  box-shadow: $shadow;
  border-radius: $radius8;
  gap: 3px;
  align-items: center;
  font-size: 0.8em;
  position: relative;
  margin: 5px;
  align-self: center;
}

.toggleButton {
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 500;
  border-radius: $radius8;
  background-color: $gray20;
  color: $gray110;
  padding: 4px 6px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.toggleButton:hover {
  background-color: white;
  color: $black;
}

.number {
  color: $info;
  padding-left: 2px;
}

.active {
  background-color: $white;
}

.stuff {
  padding-top: 10px;
  font-size: 0.7em;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.saveContainer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.inSave {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 7px;
}