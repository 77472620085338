@import "assets/styles/globals.scss";


.page {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $gray22;

}

.tableContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  overflow: hidden;

}


.searchBox {
  position: relative;
}

.tableHeader {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  background-color: $white;
  border-bottom: 1px solid $gray60;
}