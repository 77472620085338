@import "assets/styles/globals.scss";

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $gray20;
  overflow: auto;
}

.top {
  background-color: $white;
  padding: 0 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $gray60;
  // margin-top: -1rem;
}


.organizations {
  white-space: nowrap;
  padding-bottom: 0em;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.organizations2 {
  white-space: nowrap;
  padding: 0.5em 0em;
  padding-bottom: 0em;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: $white;
  gap: 0.5em;
  width: 100%;
  align-items: center;
  border-radius: $radius4;
}

.orgs {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 5em;
  background-color: $white;
  border-top: 1px solid $gray60;
  margin-top: 1rem;
}

.orgContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;
}

.orgName {
  font-size: .9em;
  font-weight: 600;
  color: $sapphire100;
  font-family: $poppins;
  white-space: pre-wrap;
}

.orgActive {
  font-size: 0.8em;
  color: $gray110;
  span {
    color: $seafoam100;
    font-weight: 700;
  }
}

.orgBottom {
  display: flex;
  // justify-content: space-between;
  gap: 1em;
  width: 100%;
  a {
    color: $blue100;
    cursor: pointer;
    font-size: 0.8em;
  }
  a:hover {
    text-decoration: underline;
  }
  // border: 2px solid black
}

.org {
  display: flex;
  align-items: center;
  padding-left: 40px;
  overflow: auto;
  width: 100%;
  gap: 1em;
  font-family: $poppins;
  min-height: fit-content;
  height: 100px;
  border-bottom: 1px solid $gray60;
  width: 100%;
}

.backarrow {
  font-size: 1.2em;
  color: $gray110;
  position: absolute;
  top: 0.2em;
  left: 0.5em;
  cursor: pointer;
}

.joinOrgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 400px;
  // border: 2px solid black;
}

.avatar {
  width: 50px;
  height: 50px;
  white-space: pre-wrap;
  // min-width: 60px;
  // min-height: 60px;
  border-radius: 50%;
  overflow: hidden;
  // margin-right: 25px;
  border: 2px solid $gray60;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 0.5em;
  color: $sapphire100;

  span {
    // border: 2px solid black;
    text-align: center;
  }
}

.joinOrgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  // border: 2px solid black;
}

.createModal {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 400px;
}
.fromTemplate {
  height: 100%;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray110;
  font-size: 1.5em;
  font-family: $poppins;
  font-weight: 600;
  span {
    font-size: 0.5em;
    font-weight: 400;
    color: $gray100;
    padding: 0px 80px;
    // text-align: center;
  }
}
.fromTemplate:hover {
  background-color: $gray40;
  font-size: 1.8em;
}
.fromScratch {
  height: 100%;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray110;
  font-size: 1.5em;
  font-family: $poppins;
  font-weight: 600;
  span {
    font-size: 0.5em;
    font-weight: 400;
    color: $gray100;
    padding: 0px 80px;
    // text-align: center;
  }
}
.fromScratch:hover {
  background-color: $gray40;
  font-size: 1.8em;
}

