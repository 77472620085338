@import "assets/styles/globals.scss";

.header {
    margin-left: 80px;
}

.content {
    // border: 2px solid gray;
    margin-left: 60px;
    display: flex;
    // flex-direction: column;
    height: 100%;
}

.helpDoc {
    border: 2px solid $gray60;
    border-radius: 5px;
    width: 73%;
    min-height: 93%;
    margin-left: 50px;
    padding: 30px;
    font-family: $poppins;
    overflow-y: auto;

    .helpDescription {
        font-family: $poppins;
        // color: $gray110;
        font-size: 11pt;
    }

    li{
        margin: 5px 0px 10px 30px;

    }
    h4{
        text-align: center;
        // margin-bottom: -.15rem;
    }
}

.titleDivider {
    // padding-top: 2px;
    div{
        margin: auto;
        border: 2px solid $gray60;
        border-radius: 10px;
        width: 45%;
        margin-bottom: 25px;
    }
}

.images {
    width: 100%;
    border: 2px solid $gray100;
    border-radius: 5px;
    margin-bottom: 1rem;
    // position: relative;
    // right: 20px;
}




