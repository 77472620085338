@import "assets/styles/globals.scss";
.page {
  background-color: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerFooter {
  border-top: 2px solid $gray40;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  align-items: center;
}

.headerContainer {
  border-bottom: 2px solid $gray40;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .header_4 {
    margin: 0;
    color: $black;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fileContent {
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 18rem;
}

.maptable {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: start;
    @include normal-text($black);
    width: 40px;
    padding: 0.3rem 0.7rem;
  }

  td {
    padding: 0.1rem 0.5rem;
    width: 100%;
    height: 50px;
  }
  td:first-child {
    width: 300px;
  }
  td:last-child {
    width: 300px;
  }
}


.columninfile {
    width: 100%;
    border: 2px solid $gray40;
    background-color: $gray40;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    border-radius: $radius2;
    padding: 0.6rem 0.5rem;
    font-family: $poppins;
    font-size: 0.9rem;
  }

  .page1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  .section {
    background-color: $white;
    border-radius: $radius2;
    box-shadow: $shadow6;
    padding: 1rem;
    width: 100%;
    max-width: 700px;
    height: fit-content;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .option {
    border-radius: $radius2;
    border: 2px solid $gray60;
    padding: 1rem;
    transition: 0.3s all;
    cursor: pointer;
    padding-right: 4rem;
    max-width: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &:hover {
      box-shadow: $shadow6;
      background-color: $gray30;
      border: 2px solid $gray30;
    }
  }
  
  .active {
    box-shadow: $shadow6;
    background-color: $gray30;
    border: 2px solid $gray30;
  }