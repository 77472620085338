@import "assets/styles/globals.scss";

.logoContainer {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
}
// .logoContainer:hover {
//   border: 2px solid $blue100;
// }

.activeLogoContainer {
  // border: 2px solid $blue100;
}

@keyframes dragHandleAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logoBigContainer {
  padding: 0.5rem 1rem;
  padding-top: 1rem;
}

.logoBigContainer:hover .dragHandle {
  animation: dragHandleAppear 0.75s forwards;
}

.logoBigContainer {
  // border-top-left-radius: 1em;
  // border-top-right-radius: 1em;
}

.logoSettings {
  padding-top: 15px;
  background-color: $white;
  // border-radius: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.logoBox {
  padding: 1rem 0;
  // padding-bottom: .5em; // For mouse lag
}

.dragBox {
  position: relative;
  padding-bottom: 7px;
  width: fit-content;
  overflow-x: none;
}

.dragHandle {
  position: absolute;
  bottom: 0;
  font-size: 8pt;
  color: $gray100;
  transition: all 0.4s;
  opacity: 0;
}
.downRight {
  transform: rotate(-45deg);
}
.downLeft {
  transform: rotate(45deg);
}

.dragHandle:active {
  color: $black;
}
.dragHandle:hover {
  color: $black;
}

.changeLogoDiv {
  cursor: pointer;
  position: relative;
}

.changeLogoDiv:hover .logoCover {
  visibility: visible;
  animation: blur 0.5s forwards;
  animation-delay: 0.25s;
  display: flex;

  .edit {
    display: flex;
    animation: editBlur 0.5s forwards;
    animation-delay: 0.5s;
  }
}

@keyframes blur {
  100% {
    backdrop-filter: blur(5px);
  }
}

.logoCover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

@keyframes editBlur {
  100% {
    opacity: 1;
  }
}

.edit {
  display: none;
  padding: 15px;
  width: 100%;
  background-color: #f0f0f2aa;

  align-items: center;
  justify-content: center;

  opacity: 0;
}

.settingsBody {
  background-color: $white;
  padding: 0em 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.settingContainer {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  overflow: hidden;
}

.settingHorizontal {
  display: flex;
  align-items: center;
}

.colorContainer {
  // border: 2px solid $gray60;
  width: fit-content;
  border-radius: 0.4em;
}

.alignmentBtn {
  padding: 5px;
  transition: 0.5s all;
  border-radius: 0.5em;
  font-size: 1.2em;
  color: $gray110;
  width: fit-content;

  &:hover {
    background-color: $gray20;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
    cursor: pointer;
  }
}

.chosenAlignment {
  background-color: $gray20;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
}
