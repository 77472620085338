@import "assets/styles/globals.scss";
@import "assets/styles/globals.scss";

.container {
  width: 100%;
  height: 100%;
  // pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  // border: 2px solid $sapphire100;
  // padding: 5px;
  overflow: auto;
}

.metricStack {
  display: flex;
  flex-direction: column;

  // border: 2px solid $blue100;
  padding: 5px;

  // width: 100%; // for fill with even and column

  // overflow: auto;
}

.metricContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  // border: 2px solid pink;
  // width: 100%;
}

.textAndValue {
  display: flex;
  flex-direction: column;
  font-family: $poppins;

  // border: 2px solid brown;
  // flex-grow: 1
}

.icon {
  background-color: $sapphire60;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  // height: 24pt;
  // width: 24pt;
  // font-size: 12pt;

  color: $white;
  flex-shrink: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
}

.title {
  text-wrap: balance;
  width: 100%;
  padding: 0px 5px;
}

.value {
  width: 100%;
  padding: 0px 5px;
}

.subtitle {
  width: 100%;
  flex-grow: 0;
  align-self: flex-start;
  text-align: center;
  font-size: 0.8em;
  color: $gray100;
  font-weight: 600;
  font-family: $poppins;
}