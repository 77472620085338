@import "assets/styles/globals.scss";

.status {
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
}

.status.sent {
  background-color: $green20;
  color: $green100;
}
.status.draft {
  background-color: $orange20;
  color: $orange100;
}
.status.scheduled {
  background-color: $blue20;
  color: $blue100;
}

.message {
  font-size: 0.7em;
}

.sendTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  a {
    text-transform: none;
    display: flex;
    gap: 0.5em;
  }
}


.viewing {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  background-color: $white;
  padding: 1rem;
  border-radius: $radius1;
  box-shadow: $shadow6;
}

.table {
  flex:1;
  border: 2px solid black;
  @media (max-width: 768px) {
    // min-height: 500px;
  }
}

.html {
  // all: unset;
  // font-family: Arial, Helvetica, sans-serif !important;
}