@import "assets/styles/globals.scss";

.fontSelection {
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 0.75em;

  z-index: 1;
  position: relative;
}

.fontButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 5px;

  border-radius: 0.5em;
  overflow: hidden;

  transition: 0.3s all;
  background-color: $gray25;
  cursor: pointer;

  &:hover {
    background-color: $gray60;
  }
}

.fontMenu {
  position: absolute;
  top: -4px;
  left: 0;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  border-radius: 0.5em;
  box-shadow: $shadow;
  overflow: auto;
  white-space: nowrap;
}

.fontOption {
  color: $gray110;
  padding: 5px;
  background-color: $white;
  cursor: pointer;
  transition: 0.3s all;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  // border-bottom: 1px solid $gray60;

  &:hover {
    background-color: $gray30;
  }
}

.fontInput {
  background-color: $white;
  color: $gray110;
  padding: 7px;
  transition: 0.3s all;
  border-bottom: 1px solid $gray40;
}

.customFont {
  outline: none;
  border: none;

  background-color: $white;
  color: $gray110;
  padding: 5px;
  transition: 0.3s all;
  border-bottom: 1px solid $gray40;

  &::placeholder {
    color: $gray100;
    font-size: 0.9em;
  }

  &:hover {
    background-color: $gray20;
  }

  &:focus {
    background-color: $white;
  }
}

.check {
  color: $blue100;
}