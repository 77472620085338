@import 'assets/styles/globals.scss';

.colorBtn {
  display: flex;
  align-items: center;
  gap: 0.1em;
  // margin: 0px 3px;
  font-size: 0.7em;

  height: 100%;

  transition: 0.5s all;
  padding: 2px 3px 2px 6px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: $gray30;
  }
}

.color {
  border: 2px solid $white;
  display: flex;
  align-items: center;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  overflow: hidden;
  // margin: 0em 0.2em;
}
