$cell-font-size:                        .8em !default;
$context-menu-font-size:                .8em !default;

$cell-padding:                          0 4px !default;
$cell-focus-border-width:               2px !default;
$celleditor-border-width:               2px !default;
$celleditor-padding-top:                1px !default;

$fill-handle-area:                      40px !default;
$fill-handle-square:                    6.5px !default;
$fill-handle-border-width:              1px !default;

$resize-handle-area:                    11px !default;
$resize-handle-line-width:              6px !default;
$line-size:                             2px !default;

$partial-area-border-width:             1px !default;
$partial-area-selected-border-width:    1px !default;

$checkbox-size:                         18px !default;

$context-menu-option-padding:           8px 20px 8px 15px !default;