@import "assets/styles/globals.scss";

.button {
  font-family: $poppins;
  overflow: hidden;
  white-space: nowrap;
  height: 25px;
  width: 8em;
  border: 2px solid $blue100;
  background-color: $blue100;
  color: white;
  border-radius: 5px;
  transition: 0.5s all;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // position: relative;
}

.button:hover {
  background-color: white;
  color: $blue100;
  cursor: pointer;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  // border: 1px solid black;
  // align-items: center;
  gap: 1em;
}

.modalLeft {
  flex-direction: column;
  display: flex;
  // width: 48%;
  width: 100%;
  gap: 5px;
  // border-right: 10px solid white;
}

.modalCenter {
  height: 100%;
  border: 1px solid $gray60;
  // padding-right: 2px;
}

.modalRight {
  width: 50%;
  flex-direction: column;
  display: flex;
  // border-left: 10px solid white;
  // padding: 2px;
  align-items: center;
  // padding-left: 10px;
  // gap: 15px;
}

.modalFooter {
  // position: absolute;
  // bottom: 0.6em;
  display: flex;
  gap: 1em;
  // flex-direction: column;
  // border: 1px solid black;
  // width: 92%;
  // align-items: flex-end;
}

.imageContainer {
  width: fit-content;
  height: fit-content;
  // min-width: 60px;
  // min-height: 60px;
  border-radius: 10%;
  overflow: hidden;
  // margin-right: 25px;
  // border: 2px solid $gray60;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  font-size: 2em;
  color: $sapphire100;
  img {
    width: 240px;
  }

  span {
    // border: 2px solid black;
    text-align: center;
  }
}

.setting2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.modal {
  position: fixed;
  top: 0;
  background-color: $white;
  box-shadow: $shadow;
  padding: 1em;
  display: flex;
  justify-content: center;
  border-radius: $radius4;
  z-index: 2;
}

.page {
  background-color: $gray20;
  height: 100%;
  padding: 2rem;
  overflow: auto;
}

.image {
  background-color: $white;
  border: 1px solid $gray60;
  padding: 1rem;
  border-radius: $radius2;
  height: 400px;
  display: flex;
  align-items: center;
  // width: 650px;

  img {
    height: fit-content;
    max-height: 350px;
    max-width: 600px;
  }
}
