@import "assets/styles/globals.scss";
.fullPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  // overflow: scroll;
  // border: 2px solid black;
  height: 100%;
}

.actions {
  border: 2px solid $gray30;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray100;
  font-weight: 600;
  font-size: 0.9em;
  z-index: 10;
  width: 120px;
  position: relative;
  background-color: white;

  .actionsButton {
    min-height: 2.5em;
    width: 120px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;

    &:hover {
      cursor: pointer;
      // background-color: $gray20;
    }
  }

  .actionItem {
    padding: 6px 2px;
    text-align: center;
    min-height: 2.5em;
    width: 100%;
    border-top: 2px solid $gray30;

    &:hover {
      cursor: pointer;
      background-color: $gray20;
    }
  }

  .showActions {
    transform: rotate(-180deg);
  }

  i {
    transition: 0.5s all;
  }

  .itemContainer {
    position: absolute;
    top: 2.5em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 120px;
    border: 2px solid $gray30;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
    background-color: white;
  }
}

.tableOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // height: 2.5em;
}
.tableOptionsNoSelections {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // height: 2.5em;
  font-size: .85em;
  margin-bottom: -1em;
}
.tableOption {
  display: flex;
  flex-direction: row;
  gap: 1em;
  // height: 3em;
}

.scrollable {
  overflow-y: auto;
  // position: relative;
  // margin-left: 10px;
  // -ms-overflow-style: 5px; // IE and Edge
  // scrollbar-width: 5px;      // Firefox
}

// .scrollable::-webkit-scrollbar {
//   display: inline-block;
//   position: fixed;
//   right:1em;
//   width: 5px;
// }

// .scrollable::-webkit-scrollbar-thumb {
//   background: $gray100; 
//   border-radius: 10px;
//   margin-top: 30px;
// }


.fulltable {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  // padding-right: 10px;

  td {
    // border-top: 2px solid $gray30;
    border-bottom: 2px solid $gray30;
    font-family: $poppins;
    padding: 15px 10px;
    color: $gray110;
  }
  td:first-child {
    border-left: 2px solid $gray30;
    // border-bottom-right-radius: 5px;
  }
  td:last-child {
    border-right: 2px solid $gray30;
    // border-bottom-left-radius: 5px;
    // border: 2px solid black
  }
  thead {
    // display: block;
    position: sticky;
    top: 0;
  }
  th {
    position: sticky;
    top: 0;
    font-family: $poppins;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px 10px;
    // padding-left: 20px;
    border-top: 2px solid $gray30;
    border-bottom: 2px solid $gray30;
    color: $gray100;
    letter-spacing: 0.15em;
    text-align: left;
    // border-radius: 50px;
    // border-radius: 5px;
  }
  th:first-child {
    border-left: 2px solid $gray30;
    // border-top-left-radius: 5px;
  }
  th:last-child {
    border-right: 2px solid $gray30;
    // border-left: 2px solid $gray30;
    // border-top-right-radius: 5px;
    // padding: 5px 10px;
    // padding-right: 10px;
    // text-align: center;
    // width: 10px;
    // height: fit-content;
    // position: relative;
    // overflow: hidden;
  }
  th:last-child:hover {
    i {
      color: $blue100;
      cursor: pointer;
    }
  }
}

.header {
  background-color: $gray20;
}

.rowData:hover {
  background-color: $gray20;
  cursor: pointer;
}

.underTable {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 2.5em;
  align-items: center;
  padding: 0 20px;
  flex-wrap: wrap;
}

.pages {
  display: flex;
  flex-direction: row;
  gap: 0.25em;
  align-items: center;
  span {
    white-space: nowrap;
    font-family: $poppins;
    margin-right: 10px;
  }
}

.nextButton {
  border-radius: 5px;
  border: 2px solid $gray30;
  background-color: white;
  width: 30px;
  height: 30px;
  font-family: $poppins;
  font-weight: bold;
  color: $gray110;
  cursor: pointer;
  // padding: 0px 4px;
}

.titleActionContainer {
  display: flex;
  flex-direction: column;
}

.pageButton {
  border-radius: 5px;
  border: 2px solid $gray30;
  background-color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  font-family: $poppins;
  font-weight: bold;
  color: $gray110;
  //cursor: pointer;
}

.editHeaders {
  position: absolute;
  right: 20px;
  top: 0em;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  // overflow: hidden;
  height: fit-content;
  width: 200px;
}
.editableHeader {
  text-align: left;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.editableHeader:hover {
  background-color: $gray20;
}
.currPage {
  background-color: $blue100;
  border: 2px solid $blue100;
  border-radius: 5px;
  width: 30px;
  text-align: center;
  color: white;
}

.noSearch {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid $gray30;
  border-radius: 5px;
  height: 300px;
  width: 100%;
  padding: 50px;
  // max-width: 900px;
  // min-width: 600px;
  // margin: auto;
}

.emptyMessage {
  font-family: $poppins;
  color: $gray110;
  border: 2px solid $gray30;
  border-radius: 5px;
  text-align: center;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: $blue100;
    cursor: pointer;
  }
}

.tableTitle {
  font-family: $poppins;
  color: $gray110;
  font-size: 1.5em;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
