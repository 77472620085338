@import "assets/styles/globals.scss";

.setting2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 0.5em;
    width: 100%;
    gap: 1rem;
  }

  .setting {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    padding-right: 0.5em;
    text-align: center;
    gap: .5rem;
    // margin-left: 1em;
  }
  