@import "assets/styles/globals.scss";

.spacer {
  margin-bottom: 1rem;
}

.number {
  font-family: $poppins;
  padding-top: .3rem;
  width: 100%;
  max-width: fit-content;
  font-size: 11pt;
  position: absolute;
  right: 2rem;
  bottom: .5rem;
}

.number2 {
  font-family: $poppins;
  padding-top: .7rem;
  width: 100%;
  max-width: fit-content;
  font-size: 11pt;
}