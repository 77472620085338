@import "assets/styles/globals.scss";

.page {
  background-color: $background;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tabs {
  width: 100%;
  padding: 0rem 1rem;
  // height: 100%;
  max-height: fit-content;
  border-bottom: 2px solid $gray40;
  background-color: $white;
}
