@import "assets/styles/globals.scss";

.tableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $white;
}

.tableHeader {
  display: flex;
  // align-items: center;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  // background-color: $white;
  width: 100%;
  // height: 100%;
  height: fit-content;
  padding: 1rem;
  // box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
  z-index: 1;
}

.audienceContent {
  display: flex;
  gap: 0em;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.routes {
  height: 100%;
  width: 100%;
  // overflow: hidden;
}

.tableTitle {
  @include header-3-text($gray110);
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    font-size: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
}

.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;

  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

.search {
  width: 280px;
}

.searchBox {
  position: relative;
  z-index: 110;
}

.items {
  position: absolute;
  right: 0;
  top: 2.7em;
  background-color: $white;
  border-radius: $radius4;
  box-shadow: $shadow;
  width: 500px;

  // overflow: hidden;
  // border: 2px solid black;

  .item {
    padding: 0.5em 1em;
    color: $gray110;
    cursor: pointer;
    font-family: $poppins;
    display: flex;
    justify-content: space-between;
    // font-size: .9em;

    gap: 1em;
    // width: fit-content;
    .type {
      color: $blue100;
      font-size: 0.8em;
    }

    &:hover {
      background-color: $background;
    }
  }
}

.header {
  @include header-3-text;
  margin: 0em;
  padding: 0em;
  // padding-left: 2em;
}

.headerBox {
  // overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em 0em;
}
