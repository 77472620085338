@import "assets/styles/globals.scss";

.tabBarItem {
  // padding: 0.5rem;
  // border-radius: $radius2;
  @include header-5-text($black, 400, 0.8rem);
  display: flex;
  gap: 0.5rem;
  // box-shadow: $shadow6;
  padding: 1rem 0.8rem;
  cursor: pointer;
  transition: 0.3s all;
  // background-color: $white;
  margin: 0em;
  position: relative;
  overflow: hidden;

  // a {
  //   display: block;
  //   position: relative;
  //   padding: 0.2em 0;
  // }

  /* Fade in */
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: $blue100;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  &:hover {
    // background-color:  $sapphire80;
    // color: $white;
    color: $blue100;
  }
  z-index: 1;

  &::after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
  }

  &:hover::after,
  &:focus::after {
    transform: scale(1);
  }
}
.activeItem {
  // background-color:  $sapphire80;
  color: $blue100;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: $blue100;
    opacity: 1;
    transform: translate3d(0, 0em, 0);
    // transition: opacity 300ms, transform 300ms;
  }
}
