@import "assets/styles/globals.scss";

.hide {
  color: $blue100;
  font-family: $poppins;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 0.8em;
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 1em;
  position: absolute;
  // top: 0em;
  bottom: 5px;
  right: 10px;
  z-index: 120;
  cursor: pointer;
}
.hide:hover {
  text-decoration: underline;
}
