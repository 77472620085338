@import "assets/styles/globals.scss";

.questionContainer {
  position: relative;
  border-radius: $radius4;
  background-color: $white;
  padding: 1em 1em;
  border: 2px solid transparent;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
}

.activeQuestionContainer {
  border: 2px solid $blue100;
}

.openQuestion:hover {
  transition: 1s all;
  .trigger {
    opacity: 1;
  }
}

@include keyframes(showup) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.grip {
  @include animation("showup .3s 1");
  color: $gray110;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius1;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 50px;
  position: absolute;
  top: -0.7rem;
  z-index: 1;
  cursor: grab;
}

.dots {
  @include animation("showup .3s 1");
  color: $gray110;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius1;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  transition: 0.3s all;
  z-index: 1;
  &:hover {
    background-color: $gray25;
  }
}

.dragging {
  border: 2px solid $blue100;
  background-color: $blue20;
}

.deleteQ {
  position: absolute;
  left: -0.4rem;
  top: -0.4rem;
  color: $red60;
  background-color: $white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow6;
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    background-color: $gray35;
  }

  i {
    font-size: 16pt;
  }
}

.questionContainer:hover {
  .grip {
    opacity: 1;
  }
  .rightTopBtns {
    opacity: 1;
  }
  .clickToEdit {
    opacity: 1;
  }
  .conditional {
    opacity: 1;
  }
  .triggered {
    opacity: 1;
  }
  // border: 2px solid $blue100;
  // border-right: 2px solid $blue100;
}

.activeQuestionContainer:hover {
  .grip {
    opacity: 1;
  }
  .rightTopBtns {
    opacity: 1;
  }
}

.rightTopBtns {
  @include animation("showup .3s 1");
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 2px;
  right: 0.5rem;
  font-size: 0.9em;
  color: $gray110;
  opacity: 0;
  box-shadow: $shadow6;
  border-radius: $radius2;
  background-color: $white;
  // border: 2px solid $gray25;
  transition: 0.3s all;

  .item {
    padding: 5px 10px;
    width: 100%;
    text-align: center;
    transition: 0.3s all;
    font-size: 1em;
    cursor: pointer;
    &:hover {
      background-color: $gray30;
    }
  }
  .activeItem {
    background-color: $gray30;
  }
}

.activeBtns {
  // border: 2px solid $blue100;
}

.tooltip {
  background-color: blue;
}

.yellow {
  color: $yellow100;
}

.red {
  color: $red60;
}

.green {
  color: $green100;
}

.delete {
  transition: all 0.3s;
  &:hover {
    color: $red60;
    cursor: pointer;
  }
}

.copy {
  transition: all 0.3s;
  &:hover {
    color: $blue100;
    cursor: pointer;
  }
}

.logicAndEdit {
  display: flex;
  width: fit-content;
  position: absolute;
  top: 0em;
  left: 0em;
  color: $gray110;
  padding-top: 5px;
  // transition: all 0.3s;
  gap: 10px;
}

.clickToEdit {
  font-size: 0.8em;
  transition: all 0.3s;
  opacity: 0;
  padding-left: 5px;
}
.clickToEdit:hover {
  color: black;
}

.logic {
  cursor: pointer;
  transition: all 0.3s;
  font-size: 0.9em;
  opacity: 0;
}

.trigger {
  cursor: pointer;
  transition: all 0.3s;
  font-size: 0.9em;
  opacity: 0;
}

.settingsBox {
  position: absolute;
  top: 0px;
  right: 3.3em;

  z-index: 100;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: $radius2;

  display: flex;
  flex-direction: column;
  // align-items: center;

  cursor: default;
  overflow: hidden;
  transition: 0.3s all;

  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    cursor: pointer;
    white-space: nowrap;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }

  .copyView {
    background-color: $gray25;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray25;
    }
  }

  .disabled {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    cursor: pointer;
    white-space: nowrap;
    color: $gray60;
    background-color: $gray25;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray25;
    }
  }
}

.tag {
  border-radius: $radius2;
  background-color: $orange100;
  padding: 5px 10px;
  box-shadow: $shadow;
  color: $white;
  font-family: $poppins;
  white-space: nowrap;
  font-size: 0.9em;
}

.tags {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  gap: 15px;
}

.seafoam {
  background-color: $seafoam100;
}

.nps {
  border-radius: $radius2;
  background-color: $white;
  padding: 5px 10px;
  box-shadow: $shadow;
  color: $black;
  font-family: $poppins;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 7px;

  i {
    color: $blue100;
  }
}
