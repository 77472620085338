@import "assets/styles/globals.scss";

.apage {
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
}

.progBar {
  padding: 0.5em 0;
  width: 100%;
}

.qCover {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.stick {
  position: sticky;
  top: 0.5em;
  width: 100%;
  background-color: transparent;
  z-index: 130;
  padding: 0em 1em;
  border-radius: 1em;
}

.conditionPreview {
  // border: 2px solid $gray30;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  // box-shadow: $shadowLight;
  // background-color: $white;
  padding: 0.5rem 1rem;
  width: fit-content;

  display: flex;
  gap: 5px;

  font-size: 0.8rem;
  font-family: $poppins;

  // box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
}

.conditions {
  display: flex;
  flex-direction: column;
}

.logicIcon {
  color: $green100;
  transition: 0.5s all;
}

.group {
  display: flex;
  flex-direction: column;
}

.symbol {
  color: $gray100;
  // align-self: center;
  padding-left: 20px;
}

.condition {
  color: $gray100;
  display: flex;
  align-items: flex-start;
  gap: 3px;
}

.conditionDesc {
  color: $gray100;
  transition: 0.5s all;
}

.checkmark {
  color: $blue100;
  transition: 0.5s all;
  padding-left: 5px;
}

.component {
  border-radius: $radius4;
  background-color: $white;
  padding: 1rem;
  transition: 0.3s all;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
}

.faded {
  background-color: $gray25;
}

.active {
  border: 2px solid $blue100;
}
