@import "assets/styles/globals.scss";

.menu {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  position: absolute;
  z-index: 205;
  border-radius: 1em;
  box-shadow: $shadow;
  // padding: 5px;
  background-color: $white;
  overflow: hidden;
  // padding: 5px 10px;
  max-width: 380px;
  font-family: $poppins;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 10px;

  cursor: pointer;
  transition: 0.3s all;

  // &:hover {
  //   background-color: $gray20;

  //   .menutTitle {
  //     color: $black;
  //   }
  // }
}

.menuHover {
  background-color: $gray25;

  .menutTitle {
    color: $black;
  }
}

.menuIconHolder {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.menuIcon {
  font-size: 1.5rem;
}

.menuInfo {
  display: flex;
  flex-direction: column;
}

.menuTitle {
  font-size: 0.8rem;
  color: $gray110;
}

.menuDescription {
  color: $gray100;
  font-size: 0.6rem;
}

.fieldIcon {
  color: $blue60;
}

.linkIcon {
  color: $blue100;
}

.sigIcon {
  color: $sapphire100;
}

.imageIcon {
  color: $red60;
}

.divIcon {
  color: $gray100;
}

.customField {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: $blue60;
  font-size: 0.8rem;
  padding: 5px 10px;
  cursor: pointer;

  transition: 0.3s all;
}

.hoverField {
  background-color: $gray25;
  color: $sapphire100;
}

.fieldDesc {
  color: $gray100;
  font-size: 0.5rem;
}

.sigMenu {
  display: flex;
  flex-direction: column;
}

.sigOption {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  transition: 0.3s all;
  background-color: $white;
  font-size: 0.7rem;
  gap: 5px;
  cursor: pointer;
}

.hoverSig {
  background-color: $gray30;
}

.sigName {
  color: $gray110;
}

.sigPreview {
  padding: 5px 10px;
  border-top: 2px solid $gray40;
  transition: 0.3s all;
}

.hoverSigPreview {
  background-color: $gray25;
}

.dynamicSigPreview {
  color: $gray110;
}
.dynamicBar {
  color: $black;
}
