.react-time-picker {
  display: inline-flex;
  position: relative;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 2px solid #e9e9e9;
  border-radius: .5rem;
  color: #d8d9d9;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  transition: .3s all;
}
.react-time-picker__wrapper:hover {
  border: 2px solid #a3a4a8;
}
.react-time-picker__wrapper:focus{
  border: 2px solid #a3a4a8;
}
.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

}
.react-time-picker__inputGroup__divider {
  padding: 3px 0;
  padding-right: 2px;
  white-space: pre;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

}
.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 2px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  outline: none;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: text;


}
.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

}
.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.react-time-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
}
.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
  display: none;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.react-time-picker__button:enabled {
  cursor: pointer;
}
.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
}
.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
}
.react-time-picker__button svg {
  display: inherit;
  /* display: none; */
}
.react-time-picker__clock {
  display: none;
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-time-picker__clock--closed {
  display: none;
}
