@import "assets/styles/globals.scss";

.header {
  font-family: $poppins;
  color: $gray110;
  font-size: 1.3em;
}

.filtersBtn {
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 1rem;
  font-weight: 600;
  border-radius: $radius2;
  background-color: $white;
  color: $gray110;
  padding: 7px 10px;
  display: flex;
  gap: 3px;
  box-shadow: $shadow6;

  &:hover {
    color: $sapphire60;
  }
}

.filtersShowing {
  color: $sapphire60;
}

.downloadContainer {
  position: absolute;
  bottom: 1em;
  right: 1em;
  // left: 0;
  width: fit-content;
}

@include keyframes(tada) {
  0% {
    opacity: 0;
  }
}

.filter {
  @include animation(tada 0.3s);
  position: fixed;
  top: 4em;
  right: 2em;
  width: 400px;
  max-height: 90%;
  background-color: $white;
  padding-bottom: 1em;
  padding-top: 0.5em;
  box-shadow: $shadow;
  border-radius: 1em;
  overflow-y: auto;
  z-index: 120;
}

@include keyframes(slide-left) {
  0% {
    opacity: 0;
    cursor: pointer;
  }

  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    pointer-events: all;
    cursor: text;
  }
}

.container {
  width: 100%;
  flex-grow: 1;
  position: relative;
  background-color: $white;
  overflow: hidden;

  @include col;
  gap: 10px;
}

.scrollable {
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth !important;
}

.inside {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  // overflow: auto;
}

.tableHeader {
  font-family: $poppins;
  @include row;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.5rem 2rem 0rem 1rem;
  // padding-top: 10px;

  .title {
    font-size: 1rem;
    color: $black;
  }

  .rows {
    font-size: 0.85rem;
  }

  .rowCount {
    color: $gray100;
    font-size: 0.95rem;
  }

  .csvDownload {
    color: $gray110;
    padding: 2px 10px;
    background-color: $gray20;
    font-size: 0.8em;
    border-radius: 0.5em;

    display: flex;
    align-items: center;
    border: 1px solid $gray60;
    gap: 5px;
    font-family: $poppins;
    cursor: pointer;

    &:hover {
      background-color: $gray40;
    }
  }
}

.simpleBar {
  border: 2px solid black;
}

.fulltable {
  border-spacing: 0;
  font-family: $poppins;
  border-radius: $radius2;
  box-shadow: $shadow;

  thead {
    background-color: $gray60;
    position: sticky;
    top: 0;
    z-index: 101;
    tr {
      background-color: $gray60;
      padding: 0.25em;
      margin-bottom: 10px;
    }
    th {
      font-family: $poppins;
      text-transform: uppercase;
      font-size: 0.7em;
      padding: 10px 10px;
      color: $white;
      background-color: $blue60;
      letter-spacing: 0.2em;
      text-align: left;
      font-weight: 600;
      max-width: 200px;
      // flex-wrap: none;
      // align-items: center;

      .columnHeader {
        display: flex;
        align-items: center;
        gap: 3px;
      }

      .overflow {
        @include animation("slide-left 1s 1");
        position: absolute;
        z-index: 100;
        display: none;
        background-color: $white;
        box-shadow: $shadow;
        padding: 5px 10px;
        border-radius: $radius4;
        max-width: 400px;
        cursor: text;
        white-space: wrap;
        color: $gray110;
        // transition: 1s display;
      }

      cursor: pointer;
      i {
        font-size: 0.8em;
        padding-left: 0.25em;
      }
    }
    th:first-child {
      border-top-left-radius: $radius2;
    }
    th:last-child {
      border-top-right-radius: $radius2;
    }
    th:last-child:hover {
      .threeDots {
        color: $blue100;
        cursor: pointer;
      }
    }
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      .overflow {
        display: block;
      }
    }
  }

  td {
    padding: 5px 10px;
    color: $gray110;
    font-size: 0.85rem;
    max-width: 200px;

    .overflow {
      @include animation("slide-left 1s 1");
      position: absolute;
      z-index: 100;
      display: none;
      background-color: $white;
      box-shadow: $shadow;
      padding: 5px 10px;
      border-radius: $radius4;
      max-width: 400px;
      cursor: pointer;
      white-space: wrap;

      i {
        color: $blue100;
      }

      &:hover {
        background-color: $gray60;
      }
    }
  }
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}

.rowData:hover {
  background-color: $gray25;
}

.rowData {
  background-color: $white;
}
.gray {
  background-color: $gray20;
}

.editHeaders {
  position: absolute;
  right: 20px;
  top: 0em;
  background-color: white;
  border-radius: $radius2;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  max-height: 80vh;
  width: 200px;
  overflow: auto;
}
.editableHeader {
  text-align: left;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  color: $gray110;

  :first-child {
    flex-shrink: 0;
  }
}
.editableHeader:hover {
  background-color: $gray20;
}

.noDataContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.noData {
  padding: 40px;
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray100;
  font-weight: 600;
  // border: 2px solid $gray30;
  // border-radius: 5px;
  width: 90%;
  text-align: center;
}

.empty {
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.8rem;
  color: $gray100;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sortTriangle {
  flex-shrink: 0;
}

.saveSort {
  position: absolute;
  bottom: 0;
  right: 5px;
  bottom: 5px;

  display: flex;
  gap: 5px;
  align-items: center;
}

.save {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 2px solid $info;
  transition: 0.5s all;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  font-family: $poppins;
  color: $info;
  font-size: 0.8em;
  font-weight: 500;
  cursor: pointer;
  animation: fadeIn 0.5s;

  &:hover {
    color: $white;
    background-color: $info;
  }

  .upDown {
    display: flex;
    align-items: center;
  }
}

.dontSave {
  color: $black;
  cursor: pointer;
}

.scrollLeft {
  height: 30px;
  width: 30px;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 1em;
  top: 50%;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  color: $gray110;
  z-index: 100;
}

.scrollRight {
  height: 30px;
  width: 30px;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1em;
  top: 50%;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  color: $gray110;
  z-index: 100;
}

.spread {
  border-top: 1px solid $gray60;
  flex-grow: 1;
  width: 1400px;
}

.select {
  color: $gray110;
  padding: 2px 10px;
  background-color: $gray20;
  font-size: 0.8em;
  border-radius: 0.5em;
  width: 150px;
  display: flex;
  align-items: center;
  border: 1px solid $gray60;
  gap: 5px;
  font-family: $poppins;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}

.searchBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $white;
  @include transition(0.2s all);

  color: $gray110;
  padding: 5px 10px;
  border-radius: $radius4;
  box-shadow: $shadow6;
  cursor: pointer;
  z-index: 115;

  &:hover {
    color: $sapphire60;
  }
}

.searching {
  color: $sapphire60;
}

@include keyframes(slideIn) {
  0% {
    width: 0px;
  }

  100% {
    width: 250px;
  }
}

.searchDiv {
  @include animation(slideIn 0.5s forwards);

  width: 250px;
  display: flex;
  align-items: center;
  gap: 0em;
  z-index: 110;
  padding-left: 10px;

  .close {
    @include animation(show 0.5s);
    color: $gray100;
    cursor: pointer;
    z-index: 110;
    &:hover {
      color: $red60;
    }
  }
}

@include keyframes(appear) {
  0% {
    width: 0px;
  }
}

.search {
  @include animation(appear 0.5s);
  flex-grow: 1;
  background-color: $white;
  color: $gray110;
  outline: none;
  font-family: $poppins;
  transition: all 0.3s;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border: none;

  &::placeholder {
    color: $gray60;
  }
}

.shadow {
  box-shadow: $shadow6;
}

.options {
  @include animation(tada 0.3s);
  position: fixed;
  top: 3rem;
  right: 2rem;
  // width: 400px;
  // max-height: 90%;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: $radius2;
  overflow: auto;
  z-index: 120;
}
