@import "assets/styles/globals.scss";

.surveyWidget {
  width: 100%;
  height: 120px;
  border-radius: $radius2;
  padding: 1em;
  background-color: $white;
  display: flex;
  cursor: pointer;
  transition: 0.3s all;
  font-size: .9em;
  flex-shrink: 0;
  position: relative;
  padding-left: 2em;
  gap: 2em;
  overflow:visible;
  max-width: 1000px;
  box-shadow: $shadow6;

  .header_4 {
    color: $black;
  }

  &:hover {
    background-color: $gray30;
  }
}

.isCreate {
  border: 2px solid $blue100;
}

.surveyHeader {
  font-family: $poppins;

  display: flex;
  justify-content: space-between;
}

.blue {
  color: $blue100;
  font-size: 1.4em;
}
.buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: $radius2;
  border: 2px solid $blue100;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: .3s all;
  z-index: 1;
}

.surveyDescription {
  @include description-text;
  // transition: 0.3s all;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-left: 5px;
  font-size: 1em;
}

.surveyState {
  @include description-text;
  // transition: 0.3s all;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-left: 5px;
  font-size: 1em;
  width: fit-content;
  &:hover {
    color: $blue100;
    text-decoration: underline;
  }
}

.pills {
  display: flex;
  gap: 10px;
  height: fit-content;
  align-items: center;
  position: relative;
  font-family: $poppins;
}

.threeDots {
  color: $gray110;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: $radius2;
  transition: 0.2s all;
  position: absolute;
  right: .3em;
  top: .4em;
  height: fit-content;
  padding:2px 5px;


  &:hover {
    background-color: $gray25;

  }
}

.settingsBox {
  position: absolute;
 top: 0.7em;
  right: 0.7em;

  z-index: 140;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  // align-items: center;

  cursor: default;
  overflow: hidden;



  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    cursor: pointer;
    padding-right: 30px;

    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}

.owner {
  border-radius: $radius8;
  padding: 4px 8px;
  font-size: 1em;
  font-weight: 500;
  height: fit-content;
  background-color: $blue20;
  color: $blue60;
}

.owner.isopen {
  background-color: $green20;
  color: $green100;
}
.owner.isclosed {
  background-color: $orange20;
  color: $orange60;
}
.owner.isdraft {
  background-color: $yellow20;
  color: $yellow100;
}
.owner.type {
  background-color: $sapphire20;
  color: $sapphire100;
}
.program {
  border-radius: $radius8;
  padding: 6px 12px;
  font-size: 1em;
  font-weight: 700;
  height: fit-content;
  background-color: $dullBlue00;
  color: $dullBlue120;
}

.titleContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5px;
  // align-items: center;

  .surveyTitle {
    @include header-3-text;
    // font-size: 1.1em;
    // text-align: center;
    transition: 0.3s all;
  }
}

.isopen {
  color: $green100;
}

.isclosed {
  color: $orange100;
}

.isdraft {
  color: $yellow100;
}
.statsContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 400px;
  height: 100%;
  justify-content: space-between;
  color: $gray60;
}
.topLeft {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: .8em;
}

.startedText {
  color: $gray110;
  font-size: 1em;
  display: flex;
  font-weight: 600;
  white-space: nowrap;
  // width: 100%;s
  gap: 0.5em;

  span {
    font-weight: 300;
    // min-width: 65px;
    // text-align: right;
    color: $gray110;
  }
  // flex-direction: column;
}

.method {
  padding: 10px 15px;
  background-color: $blue100;
  color: $white;
  border-radius: $radius8;
  width: fit-content;
  font-size: 0.9em;
  font-weight: 700;
  display: flex;
  gap: 0.5em;
  align-items: center;
  text-transform: capitalize;
  i {
    font-size: 1.4em;
  }
}

.method.email {
  background-color: $sapphire60;
  color: $white;
}

.method.sms {
  background-color: $green100;
  color: $white;
}

.method.link {
  background-color: $primary;
  color: $white;
}

.method.personalLink {
  background-color: $seafoam100;
  color: $white;
}
.chart {
  width: 100%;
  padding-top: 20px;
}
.header {
  padding-top: 10px;
  width: 100%;
  text-align: center;
  font-family: $poppins;
  color: $gray110;
  font-weight: 500;
}

.headerChart {
  // padding-top: 10px;
  width: 100%;
  text-align: center;
  font-family: $poppins;
  color: $gray110;
  font-weight: 500;
  position: absolute;
  top: 30px;

}
