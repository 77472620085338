@import "assets/styles/globals.scss";

.filter {
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: auto;
  transition: 0.3s all;
  min-width: 60px;
}
@include keyframes(slide-left) {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

@include keyframes(slide-down) {
  0% {
    height: 100px;
    overflow: hidden;
  }
  100% {
    overflow: hidden;
    height: 340px;
  }
}

.open {
  position: absolute;
  top: 0.5rem;
  left: -1rem;
  z-index: 1110;
  background-color: $white;
  border: 2px solid $gray40;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
}

.page {
  height: 100%;
  width: 100%;
  // padding: .5rem;

  @include animation("slide-left .3s 1");
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;

  .label5 {
    color: $black;
  }

  .editFilter {
    @include animation("slide-down .3s 1");
    // background-color: $sapphire20;
    // border-radius: $radius2;
    // padding: 1rem;
    position: absolute;
    // box-shadow: $shadow6;
    z-index: 1000;
    width: 100%;
    right: 0rem;
    // right: 0.5rem;
    background-color: $white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: 0.3s all;
    height: fit-content;
    border: 1px solid $blue100;
    box-shadow: $shadow6;
    // border-bottom: 1px solid $blue100;
  }
  .filterSimple {
    // border-bottom: 2px solid $gray40;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    position: relative;

    // gap: 0.5rem;
    // border: 2px solid black;
  }

  .filterSimpleSticky {
    border-bottom: 2px solid $gray40;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // position: sticky;
    bottom: 0;
    background-color: $white;
    // z-index: 1100;
    border-top: 2px solid $gray40;
  }
}

.filterHeader {
  @include animation("slide-left .3s 1");
  font-family: $poppins;
  color: $gray110;
  display: flex;
  gap: 1em;
  font-size: 0.95em;
  align-items: center;
  padding: 0rem 0.5rem;

  .number {
    background-color: $info;
    width: fit-content;
    padding: 0px 10px;
    border-radius: $radius4;
    color: $white;
    font-size: 0.8em;
  }
  .number2 {
    background-color: $seafoam10;
    width: fit-content;
    padding: 0px 10px;
    border-radius: $radius4;
    color: $white;
    font-size: 0.8em;
  }
}

.col {
  border-radius: $radius2;
  background-color: $gray25;
  width: 100%;
  padding: 0.3em 0em;
  padding-left: 0.5rem;
  // cursor: pointer;
  display: flex;
  justify-content: space-between;
  // transition: 3s all;
  border: 2px solid transparent;
  font-size: 0.9em;

  .show {
    transition: 0.3s all;
    color: $black;
    opacity: 0;
    padding-right: 1em;
    cursor: pointer;
    &:hover {
      color: $red60;
    }
  }
  .show2 {
    transition: 0.3s all;
    color: $black;
    opacity: 0;
    padding-right: 1em;
    cursor: pointer;
    &:hover {
      color: $blue100;
    }
  }
  .show3 {
    transition: 0.3s all;
    // padding-right: 1em;
    color: $blue100;
    font-size: 1.1em;
  }
  .grip {
    transition: 0.3s all;
    cursor: pointer;
    &:hover {
      color: $blue100;
    }
  }
  .grab {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    color: $blue100;
  }
  &:hover {
    .show {
      opacity: 1;
    }
    .show2 {
      opacity: 1;
    }
  }
}
.checked {
  color: $blue100;
}
.gripped {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.colHidden {
  border-radius: $radius2;
  border: 2px solid $gray25;
  width: 100%;
  padding: 0.3em 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: 0.3s all;
  font-size: 0.9em;

  .show {
    transition: 0.3s all;
    color: $blue100;
    opacity: 0;
    padding-right: 1em;
  }

  &:hover {
    .show {
      opacity: 1;
    }
  }
}

.filterHeaderItem {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  padding-right: 15px;
  cursor: pointer;
  padding-bottom: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  .number {
    background-color: $info;
    width: fit-content;
    padding: 0px 10px;
    border-radius: $radius4;
    color: $white;
    font-size: 0.8em;
  }
}

.active {
  // border-bottom: 2px solid $blue100;
}

.link {
  width: fit-content;
  font-size: 0.8rem;
}

.field {
  padding: 0.5em 1em;
  font-family: $poppins;
  color: $gray110;
  border-radius: $radius4;
  display: flex;
  justify-content: space-between;
  background-color: $background;

  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    box-shadow: $shadow;
  }
}

.close {
  cursor: pointer;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }
}
.fade {
  @include animation("slide-left .5s 1");
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 0.9em;
}
.tip {
  @include description-text;
  font-size: 0.9em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

//Filter Accordian Below...

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  border-radius: $radius2;
  color: $black;
  // padding: 0em 15px;
  // padding-top: 0.25em;
  font-family: $poppins;
  height: 5rem;
  // text-transform: uppercase;
  border: 1px solid $blue100;
  background-color: $blue5;
  padding: 0.5rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 1em;
  gap: 0.5em;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.5s;
  align-items: center;
  height: 100%;
  // background-color: $sapphire20;

  &:hover {
    background-color: $yellow20;
  }

  .operator {
    color: $gray110;
    font-size: 0.7rem;
    text-transform: capitalize;
    padding: 0.25rem 0.5rem;
    padding-left: 0;
  }
  // border: 2px solid black;

  // .checknName {
  //   display: flex;
  //   align-items: center;
  //   gap: 10px;
  //   width: 100%;
  //   // padding-top: 1px;
  //   padding: 0.3em;
  //   // border: 2px solid black;
  // }
}

.finalItems {
  display: flex;
  gap: 0.5em;
  // border: 2px solid black;
  .chosenCount {
    color: $blue60;
  }
}

.accordian {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.header .accordionicon {
  // position: absolute;
  transition: all 0.5s;
  font-size: 1.1em;
}
@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}
@include keyframes(show) {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}

.body {
  @include animation(open 0.4s 1);
  position: absolute;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow;
  width: 100%;
  top: 36px;
  left: 0px;
  overflow: auto;
  max-height: 200px;
  height: 200px;
  z-index: 100;
  font-size: 0.9em;
  transition: 0.3s all;
}

.customField {
  @include animation(show 0.3s 1);
  transition: 0.3s all;
  padding: 0px 20px;
  // padding-left: 20px;
  height: 40px;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  color: $gray110;

  &:hover {
    background-color: $seafoam10;
  }
}

.search {
  position: relative;
  width: 100%;
  background-color: $white;

  letter-spacing: 1px;
  border: 2px solid $gray60;
  outline: none;
  border-radius: $radius2;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 0.5em;
  font-family: $poppins;
  transition: 0.3s all;
  i {
    color: $gray60;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    color: $gray110;
    font-family: $poppins;
    font-weight: 400;

    &::placeholder {
      color: $gray60;
      font-family: $poppins;
      // font-weight: ;
    }
  }
}
.opened {
  box-shadow: $shadow;
  border: 2px solid transparent;
}

.newFilter {
  background-color: $headerBackground;
  border-radius: $radius2;
  padding: 0.2em 0em;
  // box-shadow: $shadow6;
  margin: 0rem 0.5rem;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  // border: 1px solid $gray30;
  // gap: 1em;
}

.filterSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1em;
  margin: 0em 0.5em;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0em 10px;
  align-items: center;
  // justify-content: sp;
  // gap: .5em;
}

.trash {
  color: $gray110;
  cursor: pointer;
  transition: 0.3s all;
  padding: 4px;
  border-radius: $radius2;
  &:hover {
    color: $red60;
    background-color: $gray60;
  }
}

.plus {
  color: $gray110;
  font-size: 1.6em;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: -10px;
}

.profileImage {
  border-radius: 50%;
  min-height: 30px;
  min-width: 30px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  font-weight: 500;
  font-size: 0.8em;
  // border: 2px solid $gray110;
  text-align: center;
  // box-shadow: $shadow;
  span {
    color: $white;
    // border: 2px solid black;
    text-align: center;
    letter-spacing: 1px;
  }
}
