@import "assets/styles/globals.scss";

.surveyWidget {
  box-shadow: $shadow6;
  min-width: 220px;
  max-width: 220px;
  height: 300px;
  border-radius: $radius2;
  // padding: 1em;
  background-color: $white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.3s all;
  position: relative;
  font-size: 0.7em;

  &:hover {
    background-color: $gray30;

    .orgName {
      background-color: $gray30;
    }
    // box-shadow: $shadow2;
    // .surveyTitle {
    //   font-size: 1.75em;
    // }

    // .surveyDescription {
    //   font-size: 0.8em;
    // }
  }
}

.reportIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid black;
  z-index: 0;
  color: $blue20;
  span {
    font-size: 5em;
    color: $blue20;
  }
}

.surveyHeader {
  font-family: $poppins;
  z-index: 15;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 10px;
  padding-left: 20px;
}

.status {
  border-radius: $radius4;
  padding: 3px 10px;
  font-size: 0.8em;
  display: flex;
  gap: 0.5em;
  height: fit-content;
  z-index: 20;
  width: fit-content;
}

.published {
  color: $green100;
  font-weight: 700;
}

.notPublished {
  color: $yellow100;
  font-weight: 700;
}

.status.isResults {
  background-color: $blue20;
  color: $blue100;
  // font-weight: 500;
}
.status.isReport {
  background-color: $sapphire20;
  color: $sapphire100;
}
.status.isclosed {
  background-color: $orange20;
  color: $orange60;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  margin: 0px 10px;
  margin-top: 10px;
  margin-right: 3px;
  overflow: hidden;

  .surveyTitle {
    @include header-4-text;
    font-size: 1.1em;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: 0.3s all;
  }

  .surveyDescription {
    @include description-text;
    transition: 0.3s all;
  }
}

.bottomContainer {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  // flex-direction: column;
  align-items: center;
  font-family: $poppins;
  color: $gray110;
  font-size: 0.8em;
  padding-left: 10px;
  // padding-bottom: 10px;
  gap: 10px;
  i {
    font-size: 2em;
    color: $blue100;
  }
  span {
    // font-weight: 700;
  }
}

.startedText {
  color: $gray100;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.users {
  display: flex;
  gap: 0.5em;
  align-items: center;
  span {
    color: $gray60;
  }
}

.owner {
  width: 110px;
  span {
    color: $orange100;
    white-space: nowrap;
    // border: 2px solid black;
  }
}
.icons {
  // margin-top: -0.5em;
  display: flex;
  // flex-direction: co;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  bottom: 5px;
  right: 10px;
  cursor: pointer;
  z-index: 100;

  .star {
    i {
      color: $gray60;
      font-size: 1.2em;
    }

    &:hover {
      i {
        color: $yellow100;
        font-size: 1.2em;
      }
    }
  }
  .starFill {
    i {
      color: $yellow100;
      font-size: 1.2em;
    }
  }

  .dots {
    i {
    }
  }
}

.threeDots {
  color: $gray110;
  cursor: pointer;
  border-radius: $radius2;
  padding: 1px 5px;
  transition: 0.2s all;
  position: relative;
  font-size: 1.2em;

  &:hover {
    background-color: $gray25;
  }
  i {
    color: $gray110;
    font-size: 1.3em;
    cursor: pointer;
  }
}

.settingsBox {
  position: absolute;
  top: 90%;
  right: 10px;

  font-size: 1.2em;

  // z-index: 130;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  // align-items: center;

  cursor: default;
  overflow: hidden;

  .menuItem {
    z-index: 130;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    padding-right: 30px;
    cursor: pointer;
    white-space: nowrap;

    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}

.analContainer {
  border-bottom: 1px solid $gray30;
  padding: 20px;
  // border-radius: $radius4;
  height: 220px;
  overflow: hidden;
  pointer-events: none;
  position: relative;

  // background-color: ;
}

.shared {
  display: flex;
  flex-direction: column;
  font-family: $poppins;
  color: $gray110;
  gap: 5px;
  font-weight: 600;
  padding: 5px 10px;
  padding-top: 0px;
  font-size: 0.8em;
}

.orgName {
  position: absolute;
  font-family: $poppins;
  color: $gray110;
  bottom: 0px;
  left: 0px;
  padding: 5px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  // box-shadow: $shadow;
  background-color: $white;
  // font-weight: 700;
  z-index: 10;
  // border-radius: $radius4;
  transition: 0.3s all;
  span {
    color: $blue100;
  }
}

.orgName2 {
  font-family: $poppins;
  color: $gray110;
  width: 100%;
  span {
    color: $blue100;
  }
}

.draft {
  background-color: $orange40;
  color: $orange100;
  padding: 3px 5px;
  border-radius: 1em;
}

.cone {
  // background-color: $orange20;
  // padding: 0px 3px;
  // border-radius: 5px;

  i {
    color: #f59a40;
  }
}
