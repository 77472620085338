@import "assets/styles/globals.scss";

.page {
  // padding: 10px 20px;
  height: fit-content;
  // min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 40rem;
  max-width: 1000px;
  margin: 0 auto;
}

.headerContainer {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
}

.imageRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 1rem;
  height: fit-content;
}

.imageContainer {
  border-radius: $radius2;
  overflow: hidden;
  box-shadow: $shadow6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  font-size: 2em;
  color: $sapphire100;
  margin: 1rem 0rem;
  padding: 1rem;

  img {
    height: fit-content;
    width: 240px;
  }

  span {
    // border: 2px solid black;
    text-align: center;
  }
}

// .imageContainer:hover {
//   .imageDownload {
//     opacity: 100%;
//     cursor: pointer;
//   }
//   .imageDelete {
//     opacity: 100%;
//     cursor: pointer;
//   }
//   .imageEdit {
//     opacity: 100%;
//     cursor: pointer;
//   }
//   .imageInfo {
//     opacity: 100%;
//   }
//   img {
//     transition: all 0.3s;

//     opacity: 20%;
//   }
// }

.imageInfo {
  display: flex;
  flex-direction: column;
  position: absolute;
  flex-wrap: wrap;
  opacity: 0%;
  transition: all 0.3s;
  // align-items: center;
  width: 100%;
}

.info {
  padding: 0.5em 0.1em 0em 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: medium;
  // align-self: center;
}

.imageDownload {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-self: flex-end;
  opacity: 0%;
  padding: 5px 10px 5px 0px;
  top: 0;
  right: 1.3em;
  transition: all 0.3s;
}

.imageEdit {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-self: flex-end;
  opacity: 0%;
  padding: 5px 10px 5px 0px;
  top: 0;
  right: 2.6em;
  transition: all 0.3s;
}

.imageDelete {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-self: flex-end;
  opacity: 0%;
  padding: 5px 10px 5px 0px;
  top: 0;
  transition: all 0.3s;
}

.avatar {
  //   width: 200px;
  //   height: 200px;
  // min-width: 60px;
  // min-height: 60px;
  border-radius: 10%;
  overflow: hidden;
  // margin-right: 25px;
  //   border: 2px solid $gray60;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 2em;
  color: $sapphire100;

  span {
    // border: 2px solid black;
    text-align: center;
  }
}

.avatar:hover {
  transition: all 0.3s;
  cursor: pointer;
  // opacity: 20%;
}

.uploadImage {
  font-family: $poppins;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  border: 2px solid $blue100;
  background-color: $blue100;
  color: white;
  border-radius: 5px;
  transition: 0.5s all;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.uploadImage:hover {
  background-color: white;
  color: $blue100;
  cursor: pointer;
}
