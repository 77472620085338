@import "assets/styles/globals.scss";

.reactGrid {
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-left: 1px solid $gray30;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  height: 100%;
  // padding: 30px;
  position: relative;
  scroll-behavior: smooth !important;
  // font-family: $poppins;
  // border-radius: $radius4;
  // padding: 0px 1px;
  // border-right: 1px solid lightgray;
}

.sortButton {
  color: $gray110;
  display: flex;
  z-index: 100;
  justify-content: flex-end;
  padding-top: 4px;
  padding-right: 5px;
  cursor: pointer;
  transition: 0.3s all;
}

.buttons {
  display: flex;
  border: 1x solid black;
  height: 0px;
  //   position: relative;
  top: 0px;
  z-index: 110;
  color: $gray110;
  width: fit-content;
  position: sticky;
}

.focusValue {
  border-top: 1px solid $gray30;
  border-right: 1px solid $gray30;
  border-left: 1px solid $gray30;
  height: 30px;
  position: relative;
  margin-top: -24px;


  span {
    position: relative;
    padding-left:  20px;
  }

}
