@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  align-items: flex-end;
  gap: 10px;
}

.textField {
  width: 100%;
  border-radius: $radius2;
  border: 2px solid $gray60;
  font-family: $poppins;
  color: $black;
  font-size: 0.8rem;
  padding: 0.5rem;
  outline: none;
  transition: 0.3s all;
}

.plain:hover:not(:focus):not(:disabled) {
  border-color: $gray100;
}

.textField::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray100;
  opacity: 1; /* Firefox */
}

.textField:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray60;
}

.textField::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray60;
}

.blue {
  background-color: $blue5;
  border-radius: $radius1;
  border: 1px solid $info;
}

.disabled {
  cursor: default;
  background-color: $gray20;
}

.invalidMessage {
  font-family: $poppins;
  color: $red60;
  font-size: 0.6rem;
}

.invalidBorder {
  border: 2px solid $red60;
}

.blue.invalidBorder {
  border: 1px solid $red60;
}
