@import "assets/styles/globals.scss";

.checkList {
  // margin-top: -5px;
  display: flex;
  flex-direction: column;

  // gap: 0.5/em;
}

.checkListItem {
  display: flex;
  gap: .5em;
  align-items: center;
  // justify-content: start;
  height: 100%;
  span {
    width: 100px;
    text-align: right;
    font-family: $poppins;
    color: $gray110;
    font-weight: 400;
    font-size: 1em;
    &:hover {
      color: $blue100;
      text-decoration: underline;
      cursor: pointer;
    }

  }

  i {
    color: $gray60;
    font-size: 1.3em;
    -webkit-text-stroke: 2px;
    padding-left: 5px;

  }
}
