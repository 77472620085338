@import "assets/styles/globals.scss";

.color {
  height: 15px;
  width: 15px;
  border-radius: 5px;
}

.addColor {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 2px solid $gray60;
  color: $gray60;
  cursor: pointer;
  transition: 0.3s all;
  font-size: .65rem;
  line-height: .6rem;
}
.addColor:hover {
  background-color: $gray60;
  color: white;
}

.sketchPicker {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options {
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.5em 1em;
  width: 220px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  font-family: $poppins;
  // align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: -5px;
}

.info {
  color: $sapphire60;
}

.button {
  font-size: 0.8em;
  font-weight: 500;
  color: $sapphire60;
  padding: 5px 10px;
  border: 2px solid $sapphire20;
  border-radius: 7px;
  transition: 0.3s all;

  display: flex;
  gap: 5px;
  align-items: center;
}
.button:hover {
  cursor: pointer;
  color: $white;
  background-color: $sapphire60;
  border: 2px solid $sapphire60;
}

.blueButton {
  font-size: 0.8em;
  font-weight: 500;
  color: $white;
  background-color: $sapphire60;
  padding: 5px 10px;
  border: 2px solid $sapphire20;
  border-radius: 7px;
  transition: 0.3s all;

  display: flex;
  gap: 5px;
  align-items: center;
}
.blueButton:hover {
  cursor: pointer;
  color: $sapphire60;
  background-color: $white;
}
