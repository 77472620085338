@import "assets/styles/globals.scss";

.csvContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  //border: 2px solid $gray30;
  padding: 0px 0px 10px 0px;
}

.csvReader {
  display: flex;
  font-family: "Poppins", sans-serif;
  color: #616565;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  //border: 3px solid black;
  // padding-bottom: 15px;
}

.header {
  font-family: "Poppins", sans-serif;
  padding: 1em;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
}
.guide {
  font-family: "Poppins", sans-serif;
  color: $black;
  font-weight: 300;
  letter-spacing: 0.05em;
  //text-transform: uppercase;
  font-size: 0.75em;
  padding: 1em 2em;
  // text-align: center;
  border: 2px solid $gray30;
  border-radius: 1em;
}

.acceptedFile {
  //border: 1px solid $blue40;
  height: 45px;
  line-height: 2.75em;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.progressBarBackgroundColor {
  background-color: $blue40;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em 0em;
  span {
    color: $gray110;
    font-family: $poppins;
    text-align: center;
  }
}
