@import "assets/styles/globals.scss";


.heading {
    // position: relative;
   @include header-3-text
}


.linkpage {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 100%;
    padding-left: 1em;
    padding-top: 1em;
    position: relative;
}

.contacts {
    position: absolute;
    right: -5em;
    top: 5em;

    background-color: $white;
    border-radius: $radius4;
    box-shadow: $shadow;
    width: 500px;
    overflow: hidden;
    z-index: 100;
    max-height: 300px;
    overflow: auto;
    // border: 2px solid black;
  
  
    .contact {
      padding: .7em 1em;
      color: $gray110;
      cursor: pointer;
      font-family: $poppins;
      display: flex;
      justify-content: space-between;
      font-size: .9em;
      gap: 1em;
      // width: fit-content;
      .type {
        color: $blue100;
        font-size: .8em;
        display: flex;
        gap: 5px;
        align-items: center;
      }
  
      &:hover {
        background-color: $background;
      }
    }
  
  }