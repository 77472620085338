@import "assets/styles/globals.scss";

.page {
  background-color: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerContainer {
  border-bottom: 2px solid $gray40;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .header_4 {
    margin: 0;
    color: $black;
  }
}

.headerFooter {
  border-top: 2px solid $gray40;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  align-items: center;
}

.header_4 {
  color: $black;
}

.dropArea {
  width: 100%;
  height: 100%;
  border: 2px dashed $blue100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: $radius2;
  color: $black;
  font-size: 0.9rem;
}

.link {
  font-size: 0.9rem;
}

.drop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileContent {
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.uploadFile {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.box {
  border-radius: $radius2;
  border: 2px solid transparent;
  box-shadow: $shadow6;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  gap: 0.5rem;
  background-color: $white;
  height: 130px;
  justify-content: center;

  h1 {
    @include header-4-text($black, 400, 1.1rem);
    padding: 0em;
    margin: 0em;
    white-space: nowrap;
  }

  .icon {
    color: $blue100;
    margin-right: 0.5rem;
  }
  span {
    @include description-text;
    font-size: 0.9em;
    margin: 0em;
    // margin-left: 1em;
  }

  &:hover {
    border: 2px solid $blue100;
    box-shadow: $shadow6;
    .icon {
      color: $blue100;
      margin-right: 0.5rem;
    }
  }

  .boxheader {
    text-align: left;
  }
}

.boxfill {
  border: 2px solid $blue100;
  box-shadow: $shadow6;
  .icon {
    color: $blue100;
    margin-right: 0.5rem;
  }
}

.content {
  padding: 2rem;
  display: grid; /* Use CSS Grid */
  grid-template-columns: repeat(2, 1fr); /* Create 2 columns of equal width */
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  // height: 100%;

  // flex-wrap: wrap;
}

.maptable {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: start;
    @include normal-text($black);
    width: 40px;
    padding: 0.3rem 0.7rem;
  }

  td {
    padding: 0.1rem 0.5rem;
    width: 100%;
    height: 50px;
  }
  td:first-child {
    width: 300px;
  }
  td:last-child {
    width: 300px;
  }
}

.uploading {
  text-align: center;
  @include normal-text($black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.columninfile {
  width: 100%;
  border: 2px solid $gray40;
  background-color: $gray40;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: $radius2;
  padding: 0.6rem 0.5rem;
  font-family: $poppins;
  font-size: 0.9rem;
}

.information {
  border-radius: $radius2;
  background-color: $gray20;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 200px;
  align-items: center;
  padding: 0rem 2rem;
  border: 2px solid $gray60;
}

.label5 {
  color: $black;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $black;

  input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px;
    transition: 0.3s all;
    cursor: pointer;
    background-color: $gray60;
    border: 4px solid $gray60;
  }
  input:checked {
    background-color: $white;
    border: 5px solid $blue60;
  }
  input:not(:disabled):not(:checked):hover {
    background-color: $gray60;
    border: 5px solid $gray60;
  }
  input:disabled {
    // border: 2px solid $gray40;
    // background-color: $blue100;
    cursor: default;
  }
}

.loadingContainer {
  // border: 2px solid black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // padding: 2rem 0rem;
}
