@import "assets/styles/globals.scss";

.mappingPage {
  height: 100%;
  overflow: auto;
  padding: 2rem 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  color: $gray110;
  font-family: $poppins;

  table {
    height: fit-content;
    border-spacing: 0;
    border-radius: $radius2;
    background-color: $white;
    box-shadow: $shadow6;
    font-family: $poppins;

    td {
      padding: 10px 20px 10px 20px;
      border: 0.5px solid $gray30;
      min-width: 300px;
      color: $gray110;
    }

    td:first-child {
      min-width: 0px;
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: $radius2;
      }
      td:last-child {
        border-bottom-right-radius: $radius2;
      }
    }
    th:first-child {
      border-top-left-radius: $radius2;
      border-right: 0.5px solid $gray30;
    }
    th:last-child {
      border-top-right-radius: $radius2;
      border-left: 0.5px solid $gray30;
    }
    th {
      padding: 5px 20px 5px 20px;
      text-align: start;
      color: $white;
      background-color: $blue60;
      font-weight: 600;
    }
    .mheader {
      border-left: 0.5px solid $gray30;
      border-right: 0.5px solid $gray30;
    }
  }
}

.header {
  @include header-3-text;
}
.header2 {
  display: flex;
  gap: .5em;
  align-items: center;
  @include header-4-text($seafoam100);
  font-weight: 400;
  padding-left:10px; 
}
.not {
  //  color: $red60;
  @include link($red60);
}
.new {
  //  color: $red60;
  @include link($blue100);
}
.mapper {
  display: flex;
  gap: 0.5em;
}

.edit {
  @include link($gray110);
  i {
    font-size: 0.8em;
  }
}

