@import "assets/styles/globals.scss";

.editOptions {
  position: sticky;
  bottom: 0.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1em;
 

  .button {
    font-family: $poppins;
    color: $white;
    border-radius: $radius4;
    border: none;
    background-color: $blue100;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 1em;
    width: 100px;
    box-shadow: $shadow;
    border: 2px solid $blue100;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      color: $blue100;
      background-color: $white;
    }
  }
  .cancel {
    background-color: $red60;
    border: 2px solid $red60;
    &:hover {
      color: $red60;
      background-color: $white;
    }
  }
}

.saveBtn {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  align-items: center;
  gap: 1em;
}

.icon {
  display: flex;
  gap: 1em;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;

  i {
    color: $blue100;
    font-size: 1.7em;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  width: 100%;
  // border: 2px solid balck;
}
