@import "assets/styles/globals.scss";

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  font-family: $poppins;
  font-weight: 300;
  justify-content: space-between;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 0.65rem;
  color: $gray100;
  gap: 0.5em;
  padding-bottom: 5px;
}

.tag {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.tagName {
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: 7px;
}

.type {
  color: #bbb;
  font-size: 0.7rem;
  line-height: 0.7rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 10px;
}

.note {
  padding: 0px 0px 10px 15px;
  font-family: $poppins;
  font-weight: 300;
  justify-content: space-between;
  letter-spacing: 0.5px;
  font-size: 0.65rem;
  color: $gray110;
}

.matrix {
  color: $sapphire80;
  font-size: 0.7rem;
  // line-height: 0.7rem;
}

.matrixExample {
  color: $gray100;
  font-size: 0.7rem;
}
