@import "assets/styles/globals.scss";

.palettePicker {
  // border: 2px solid blue;
  flex-grow: 1;
  position: relative;
}

.currentPalette {
  display: flex;
  gap: 15px;
  align-items: center;
  border-radius: $radius2;
  border: 2px solid $gray30;
  transition: 0.5s all;
  cursor: pointer;
  padding: 5px;

  &:hover {
    box-shadow: $shadow6;
    border: 2px solid white;
    .select {
      color: $gray110;
    }
  }
}

.activePalette {
  box-shadow: $shadow6;
  border: 2px solid white;
  .select {
    color: $gray110;
  }
}

.nameNColors {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  // gap: 15px;
  // padding: 5px;
  // align-self: flex-end;
  // width: 100%;
}

.paletteName {
  flex-grow: 1;
  color: $gray110;
  font-family: $poppins;
  text-align: left;
  color: $gray110;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select {
  color: $gray100;
  transition: 0.5s all ease-in;
}

.currPaletteColors {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-self: flex-end;
}

.colorSwatch {
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

.optionsAnchor {
  // border: 2px solid black;
  position: relative;
  width: 100%;
}

.options {
  position: absolute;
  background-color: $white;
  z-index: 10;
  width: 100%;
  top: 5px;

  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
  font-family: $poppins;

  border-radius: $radius2;
  box-shadow: $shadow6;
}

.option {
  padding: 5px 10px 10px;
  cursor: pointer;
  transition: 0.5s all;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: $gray40;
  }
}

.optionName {
  color: $gray110;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.default {
  font-size: .8em;
  color: $sapphire60;
}

.currentOption {
  border-bottom: 2px solid $gray60;
}

.currentOptionName {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  .indicator {
    font-size: 0.8em;
    color: $seafoam100;
    font-weight: 500;
  }
}

.optionColors {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.createNew {
  padding: 10px;
  text-align: right;
  font-size: 0.8em;
  font-family: $poppins;
  color: $sapphire60;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: $gray20;
  }
}

.editPalette {
  position: absolute;
  bottom: -1.7em;
  right: 5px;
  font-family: $poppins;
  font-size: 0.8em;
  color: $seafoam60;
  cursor: pointer;
  letter-spacing: 1px;

  transition: 0.3s all;
  &:hover {
    color: $seafoam100;
  }
}
