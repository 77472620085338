@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  align-items: center;

  border-bottom: 1.5px solid $gray40;
}

.select {
  border-radius: $radius2;
  // padding: 2px 5px;
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  position: relative;
  transition: 0.3s all;
  cursor: pointer;
}

.label {
  @include header-4-text;
  font-weight: 400;
  font-size: 0.8em;
  color: #6793a5;
  white-space: wrap;
}

.input {
  border: none;
  background-color: transparent;
  outline: none;
  font-family: $poppins;
  color: $gray110;
  transition: 0.3s all;
  border-radius: $radius2;
  min-width: 150px;

  cursor: text;
  padding: 2px 5px;
  flex-grow: 1;
  flex-wrap: wrap;
  // color: blue;
  // background-color: $gray30;

  // &:hover {
  //   background-color: $gray30;
  // }

  // &:focus {
  //   background-color: $gray30;
  // }
  // &::placeholder {
  //   color: $gray60;
  // }
}

.nohover {
  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
  }
}

.searchIcon {
  color: $gray60;
}
.searchinput {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 2px;
  font-family: $poppins;
  color: $gray110;
  transition: 0.3s all;
  border-radius: $radius2;
  width: 100%;
  cursor: text;
  white-space: wrap;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // background-color: $gray30;

  &:hover {
    background-color: $gray30;
  }

  &:focus {
    background-color: $gray30;
  }
  &::placeholder {
    color: $gray60;
  }
}

.selected {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 2px 7px;
  font-family: $poppins;
  color: $gray110;
  transition: 0.3s all;
  border-radius: $radius2;
  font-size: 0.8rem;
  //   cursor: pointer;
  // font-size: 0.85em;
}

.selectMultiple {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 2px 9px;
  font-family: $poppins;
  color: $gray110;
  transition: 0.3s all;
  border-radius: $radius2;
  //   cursor: pointer;
  // font-size: 0.85em;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  flex-wrap: wrap;
}

.text {
  display: flex;
  width: 100%;
  height: fit-content;
  white-space: nowrap;
  align-items: center;
  padding: 5px;
}

.icon {
  color: $gray110;
  cursor: pointer;
}

@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}

@include keyframes(show) {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}

.dropdown {
  @include animation(open 0.3s 1);
  transition: 0.3s all;
  position: absolute;
  background-color: $white;
  box-shadow: $shadow;
  width: 50%;
  height: 240px;
  max-height: 240px;
  min-width: 160px;
  border-radius: $radius2;
  font-family: $poppins;
  // font-size: 0.9em;
  color: $gray110;
  overflow: auto;
  left: 0;
  top: 108%;
  z-index: 100;
}

.option {
  @include animation(show 0.3s 1);
  padding: 0px 20px;
  height: 40px;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  .helperText {
    color: $blue100;
    opacity: 0;
    font-size: 0.8em;
    transition: 0.3s all;
  }
  .checked {
    color: $blue100;
    font-size: 1.5em;
    transition: 0.3s all;
  }

  &:hover {
    background-color: $seafoam10;
    .helperText {
      opacity: 1;
    }
  }
}

.shadow {
  box-shadow: $shadow6;
}

.manageAudiences {
  justify-content: center;
  // height: fit-content;
  padding: 15px 0px 3px;

  &:hover {
    background-color: $white;

    .manage {
      border-bottom: 2px solid $sapphire20;
    }
  }
}

.manage {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $sapphire80;
  font-size: 0.75rem;
  padding: 0px 12px 3px;
  border-bottom: 2px solid transparent;
  transition: 0.5s all;
}

.chosenFrom {
  color: $gray110;
  background-color: $white;
  border-radius: $radius2;
  border: 1px solid $seafoam30;

  width: fit-content;
  padding: 2px 5px;
  display: flex;
  gap: 0.2em;
  align-items: center;
  font-size: 0.8rem;

  .chosenText {
    white-space: wrap;
    text-wrap: wrap;
  }
}

.chosenAudience {
  color: $seafoam100;
  background-color: $white;
  border: 1px solid $seafoam30;

  border-radius: $radius2;
  max-width: 100%;
  padding: 2px 5px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0.2em;
  align-items: center;
  font-size: 0.8rem;
  white-space: nowrap;

  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: $gray110;
    border: 1px solid $gray100;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  }

  .chosenText {
    white-space: wrap;
    text-wrap: wrap;
  }
}

.remove {
  color: $gray110;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    color: $red60;
  }
}

.audienceOptionName {
  display: flex;
  align-items: center;
  gap: 10px;

  .members {
    color: $blue100;
  }
}

.chosenAudienceName {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;

  .members {
    color: $sapphire60;
  }
}

.chosenDynamic {
  width: 100%;
  white-space: wrap;

  color: $gray100;

  .conditionField {
    color: $sapphire80;
  }
}

.aliasOption {
  @include animation(show 0.3s 1);
  padding: 7px 20px;
  // height: 40px;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;

  .checked {
    color: $blue100;
    font-size: 1.5em;
    transition: 0.3s all;
  }

  &:hover {
    background-color: $seafoam10;
    .helperText {
      opacity: 1;
    }
  }
}

.makeDynamic {
  padding: 7px 0px;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  font-size: 0.8em;
}

.fromLabel {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
}

.fromDropdown {
  @include animation(open 0.3s 1);
  transition: 0.3s all;
  position: absolute;
  background-color: $white;
  box-shadow: $shadow;
  width: 100%;
  height: 250px;
  max-height: 250px;
  min-width: 160px;
  border-radius: $radius2;
  font-family: $poppins;
  color: $gray110;
  overflow: auto;
  left: 0;
  top: 108%;
  z-index: 100;
}

.dynamicContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
  padding: 1rem;
  padding-top: 0.5rem;
}

.conditionRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.dynamicSelect {
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray25;
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;
  padding: 0px;
  font-size: 0.8rem;
}

.dynamicSelect:hover {
  background-color: $gray60;
}

.blueText {
  // font-family: $poppins;
  color: $blue100;
  text-align: center;
}

.sigContainer {
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: $gray110;
  gap: 10px;
  padding: 1em 2em;

  &:first-child {
    border-top-left-radius: $radius4;
    border-top-right-radius: $radius4;
  }
  // height: 200px;
}
.sigContainer:hover {
  background-color: $seafoam10;
  cursor: pointer;
}

.else {
  border-top: 2px solid $gray40;
  padding-top: 0.5rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; /* Ensures the selected items wrap to the next line if necessary */
  align-items: center;
  flex-grow: 1; /* Makes the input container expand to fill the remaining space *
  gap: 4px; /* Adds space between selected users and the input */
}

.replyContainer {
  display: flex;
  flex-direction: row;
  width: 40%;
  position: relative;
  align-items: center;
  padding-left: 7px;

  border-left: 1.5px solid $gray40;
}
