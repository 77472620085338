@import "assets/styles/globals.scss";
.Wrapper {
  list-style: none;
  box-sizing: border-box;
  padding-left: var(--spacing);
  margin-bottom: -1px;
  width: 100%;
  max-width: 1000px;

  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-left: 10px;
    padding-top: 5px;

    .TreeItem {
      --vertical-padding: 5px;

      padding-right: 24px;
      border-radius: 4px;
      box-shadow: $shadow6
    }
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .TreeItem {
        position: relative;
        padding: 0;
        height: 8px;
        border-color: $blue100;
        background-color: $blue100;

        // &:before {
        //   position: absolute;
        //   left: -8px;
        //   top: -4px;
        //   display: block;
        //   content: "";
        //   width: 12px;
        //   height: 12px;
        //   border-radius: 50%;
        //   border: 1px solid $blue100;
        //   background-color: #ffffff;
        // }

        > * {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .TreeItem > * {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.TreeItem {
  --vertical-padding: 2px;

  position: relative;
  display: flex;
  align-items: center;
  padding: var(--vertical-padding) 2px;
  background-color: #fff;
  // border: 1px solid #dedede;
  color: #222;
  box-sizing: border-box;
  border-radius: $radius2;
  box-shadow: $shadow6;
  font-size: 0.9em;


}
.hover {
  cursor: pointer;
  &:hover {
    background-color: $gray30;
  }
}

.TreeItemOpen {
  background-color: $gray30;
}

.Text {
  flex-grow: 1;
  // padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @include header-4-text;
}

.Count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: $radius2;
  background-color: $blue100;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {
  .Text,
  .Count {
    user-select: none;
    -webkit-user-select: none;
  }
}

.Collapse {
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }
}

.isopen {
  color: $info;
  font-weight: 700;
  // font-size: 1.2em;
}

.startedText {
  color: $gray110;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // flex-direction: column;
}

.audienceHeader {
  font-family: $poppins;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0.5em;
}

.icon {
  width: fit-content;
  font-size: 1.5rem;
  color: $blue100;
  padding: 0em .3em;
}


@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px) rotate(-1deg);
  }
  40% {
    transform: translateX(5px) rotate(1deg);
  }
  60% {
    transform: translateX(-5px) rotate(-1deg);
  }
  80% {
    transform: translateX(5px) rotate(1deg);
  }
  100% {
    transform: translateX(0);
  }
  // 60% {
  //   transform: translateX(5px) rotate(1deg);
  // }
  // 70% {
  //   transform: translateX(-5px) rotate(-1deg);
  // }
  // 80% {
  //   transform: translateX(5px) rotate(1deg);
  // }
  // 90% {
  //   transform: translateX(-5px) rotate(-1deg);
  // }
  // 100% {
  //   transform: translateX(0);
  // }
}

.earthquake {
  animation: shake .3s;
}

.remove {
  transform: rotate(45deg);
  position: absolute;
  top: -5px; 
  left: -5px;
  font-size: 1.2em;
  // background-color: black;
  border-radius: $radius2;
  // height: 15px;
  // margin: -2px 0px;

  i {
    color: $gray100;
    transition: .3s all;
    &:hover {
      color: $red60;
    }
  }
}