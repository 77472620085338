@import "assets/styles/globals.scss";

.pageContainer {
    // border: 2px solid black;
    // background-color: $blue40;
    position: relative;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
    padding: 0px 15px;
    padding-bottom: 10px;
    background-color: white;
    width: 100%;
}


.settingsContainer {
    // border: 2px solid black;
    overflow-y: auto;
    width: 35%;
    min-width: 200px;
}

.questions {
    height: 100%;
}

.expandedPreview {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 100;
    overflow-y: auto;

    i {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
}

.disablePage {
    position: absolute;
    width: 100%;
    background-color: transparent;
    // top: 120px;
    height: 100%;
}




