@import "assets/styles/globals.scss";

.status {
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
}

.status.delivered {
  background-color: $green20;
  color: $green100;
}
.status.draft {
  background-color: $orange20;
  color: $orange100;
}
.status.scheduled {
  background-color: $blue20;
  color: $blue100;
}
.status.inactive {
  background-color: $red60;
  color: red;
}
.status.active {
  background-color: $sapphire60;
  color: $sapphire100;
}

.page {
  position: relative;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3em;
  @media (max-width: 768px) {
    padding: 0rem;
  }
  
}

.composeContainer {
  position: absolute;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  right: 14.5em;
  top: 60px;
  width: 6.7em;
  height: fit-content;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  span {
    padding: 10px 15px;
    font-family: $poppins;
    color: $gray110;
  }
  span:hover {
    background-color: $blue20;
    cursor: pointer;
  }
}
.copySurvey {
  padding-left: 0.5em;
  transition: all 0.3s;
}

.copySurvey:hover {
  padding-left: 0.7em;
  color: $blue100;
}

