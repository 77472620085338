@import "assets/styles/globals.scss";

.container {
  font-family: $poppins;
  height: 100%;
}

.back {
  color: $sapphire80;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: $gray110;
  }
}

.title {
  font-weight: 500;
  color: $sapphire80;
  padding-left: 10px;
}

.bulk {
  flex-grow: 1;
  border: 2px solid $gray60;
  border-radius: 10px;
  overflow: hidden;
}

.leftSide {
  display: flex;
  flex-direction: column;
  background-color: $gray25;

  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px;
  border-right: 2px solid $gray60;

  height: 100%;
  width: 250px;
}

.main {
  display: flex;
  flex-direction: column;
  border-left: none;
  flex-grow: 1;
  height: 100%;
}

.name {
  border-bottom: 2px solid $gray60;
  padding: 2px;
  display: flex;
  border-left: none;
  border-right: none;
}

.result {
  color: $black;
  font-size: 0.95rem;
}

.error {
  color: $red60;
  font-size: 0.95rem;
}

.saving {
  color: $gray100;
  font-size: .85rem;

  i {
    font-size: 1rem;
    color: $gray60;
  }
}