.fullScreen {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffffab;
  z-index: 200;
  backdrop-filter: blur(4px);
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
}
