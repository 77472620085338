@import "assets/styles/globals.scss";

.page {
  width: 100%;
  display: flex;
  gap: 1rem;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section {
  width: 100%;
  max-width: 700px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-left: 2px solid $gray40;
}
