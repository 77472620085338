@import "assets/styles/globals.scss";

.status {
    border-radius: 5px;
    padding: 3px 5px 3px 5px;
}

.status.isopen {
    background-color: #edb57e4a;
    color: #EDB57E;
}
.status.isclosed {
    background-color: #7fcfd35b;
    color: #7FCFD3;
}

.linkpage {
    // position: absolute;
    // width: 500px;
    // height: 1000px;
    // margin-left: 40px;
    // min-width: 500px;
    max-width: 800px;
    border: 1em;
}

.shareablelink {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}

.customDiv {
    width: 100%;
    border-radius: 5px;
    border: 2px solid $gray30;
    color: $gray110;
    font-family: $poppins;
    padding: 8px 10px;
}

.radiobutton {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    label {
        font-family: "Poppins", sans-serif;
    }
    input {
        accent-color: black;
    }
    margin-left: 20px;
}

.generatelink {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
}

.buttonContainerSave {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    height: 55px;
    gap: 10px;
    // height: 20px;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $gray110;
    font-weight: 700;
}

.statusDisplay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: $poppins;
    font-weight: 600;
    margin-bottom: 30px;
}

.changeStatusDiv {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: $poppins;
    font-weight: 600;

    position: relative;
}

.statusBox {
    padding: 10px 20px;
    color: $blue100;
    font-size: 1.15em;
}

.changeStatusdiv {
    
    color: $gray110;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.changeStatusBox {
    font-size: .8em;
    // position: absolute;
    color: $gray110;
    text-align: center;
    &:hover {
        cursor: pointer;
        color: $red60;
    }
}

.statusOptionsDiv {
    position: fixed;
    top: 15%;
    right: 15%;
    // right: 125px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 2px solid $gray60;
    border-radius: 5px;
    margin-top: 10px;
    font-size: .9em;
    width: 98px;
}

.tooltip {
    width: 250px;
    border: 2px solid $sapphire100;
    text-align: center;
    padding: 10px 20px;
    color: $gray110;
    border-radius: 6px;
    position: absolute;
    right: 120px;
    top: 0;
    font-size: .9em;
    background-color: white;
}

.statusOption {
    padding: 5px 20px;
    font-family: $poppins;
    font-weight: 600;
    border-top: 2px solid $gray60;
    color: $gray110;
    text-align: center;

    &:hover {
        background-color: $gray20;
        cursor: pointer;
    }
}

.statusOption:first-child {
    border-top: none;
}

.stat {
    color: $blue100;
    font-family: $poppins;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 1em;
    text-align: center;
}

.horizDiv {
    padding-top: 5px;
    display: flex; 
    justify-content: center;
    div {
        background-color: $gray30;
        border-radius: 10px;
        width: 170px;
        height: 5px;
    }
    padding-bottom: 30px;
    
}
.heading {
    // position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;
    padding-bottom: 10px;
}

.changeStatusContainer {
    // position: relative;
    display: flex;
    flex-direction: column;
}

.currentStatus {
    font-family: $poppins;
    color: $gray110;
    span {
        color: $blue100;
        font-weight: 900;
    }
    span:hover {
        color: $blue100;
        cursor:auto;
    }
}