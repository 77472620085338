@import "assets/styles/globals.scss";

.container {
  font-family: $poppins;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.timeDisplay {
  display: flex;
  align-items: center;
  gap: 15px;
}

.label {
  font-size: 0.9rem;
  color: $sapphire80;
}

.time {
  border: 2px solid $gray60;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;

  .dots {
    color: $black;
  }

  .timeInput {
    outline: none;
    border: none;
    width: 1.75rem;
    font-family: $montserrat;
    color: $gray110;
    font-size: 1rem;
  }

  .hour {
    text-align: right;
  }

  .minute {
    text-align: left;
  }

  .adjuster {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;

    .adjustArrow {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 7px;
      transition: 0.3s all;
      background-color: $gray20;
      color: $gray60;
      font-size: 7pt;
      cursor: pointer;

      &:hover {
        background: $gray30;
        color: $gray100;
      }

      &:active {
        color: $gray110;
      }
    }
  }
}

.amPm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 2.5rem;
  height: 3.5rem;
  font-size: 0.75rem;
  color: $gray60;
}

.toggle {
  padding: 3px;
  transition: 0.5s all;
}

.chosen {
  font-size: 0.85rem;
  color: $seafoam60;
  font-weight: 600;
}

.divider {
  height: 2px;
  width: 70%;
  background: $gray22;
}

.notChosen:hover {
  font-weight: 600;
}

.suggestion {
  font-family: $georgia;
  color: $gray110;
  font-size: 0.8rem;
  text-align: left;
  width: 100%;
  padding-bottom: 3px;
}

.clock {
  position: relative;
  display: flex;
  width: 200px;
  height: 200px;
  border: 3px solid $gray100;
  border-radius: 50%;
  background: #fff;
  box-sizing: content-box;
  transition: 0.5s all;
  box-shadow: $shadow8;
  cursor: pointer;
  background-color: $bpRed;

  overflow: hidden;
}

.morning {
  border-color: #add4e0;
}

.afternoon {
  border-color: $orange60;
}

.evening {
  // border-color: #d1978e; // Sunset
  border-color: #798cad;
}

.night {
  border-color: $sapphire100;
}

.hand {
  position: absolute;

  left: 98.5px;
  top: 25px;

  width: 3px;
  height: 75px;

  transform-origin: bottom center;
  transform: rotate(0deg);
  transition: transform 0.05s linear;

  background: $gray110;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  // animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.crossBar {
  position: absolute;
  background-color: $gray100;
  transform-origin: center;
  width: 2px;
  height: 200px;
  top: 0px;
  left: 99px;
}

.oneSeven {
  transform: rotate(30deg);
}

.twoEight {
  transform: rotate(60deg);
}

.threeNine {
  transform: rotate(90deg);
}

.fourTen {
  transform: rotate(120deg);
}

.fiveEleven {
  transform: rotate(150deg);
}

.numAngle {
  position: absolute;
  top: 0px;
  left: 92px;
  height: 200px;
  padding: 5px 0px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transform-origin: center;
  pointer-events: none;
}

.number {
  font-size: 16px;
  line-height: 16px;
  transform-origin: center;
  text-align: center;
  width: 16px;
}

.piePiece {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100px 100px;
  transition: .3s all;
  opacity: 0;
}

.fifteen {
  clip-path: polygon(100px 0px, 100px 100px, 113px 0px);
}

.thirty {
  clip-path: polygon(100px 0px, 100px 100px, 126.5px 0px);
}

.oneHour {
  clip-path: polygon(100px 0px, 100px 100px, 158px 0px);
}

.twoHours {
  clip-path: polygon(100px 0px, 200px 0px, 200px 42px, 100px 100px);
}

.green {
  background: $bpGreen;
}

.yellow {
  background: $bpYellow;
}

.fiveToSeven {
  transform: rotate(150deg);
}

.fiveThirtySevenThirty {
  transform: rotate(165deg);
}

.show {
  opacity: 1;
}

.great {
  color: #5aaf65;
}

.okay {
  color: #e8a63c;
}

.not {
  color: #e03b25;
}
