@import "assets/styles/globals.scss";

.customButton {
  font-family: $poppins;
  overflow: hidden;
  white-space: nowrap;
  height: 35px;
  min-width: 100px;
  border: 2px solid;
  border-radius: 5px;
  padding: 0px 1em;
  transition: 0.3s all;
  font-weight: 600;
  text-align: center;
  font-style: italic;
  color: $blue100;
  cursor: pointer;
}
