@import "assets/styles/globals.scss";

.page {
  position: relative;
  padding: 0px 10px;
  width: 100%;
  // max-width: 1200px;
}
.basicStatsContainer {
  // border: 1px solid black;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.tableDate {
  display: flex;
  flex-direction: row;
  height: 5%;
  align-items: center;
  gap: 15px;
  width: 45%;
  padding: 10px 10px;
  // border: 2px solid black;
}

.schueduleDateContainer {
  //border: 3px solid gray;
  display: flex;
  width: 30%;

  flex-direction: column;
}

.date {
  padding: 10px 5px;
  gap: 5px;
}

.chart {
  width: 100%;
  height: 20%;
  //padding: 5px;
}
