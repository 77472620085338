@import "assets/styles/globals.scss";

.palettes {
  // width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  // align-items: center;
  // gap: 1em;
  // padding-left: 20px;
  // padding-top: 10px;
}

.palette {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: fit-content;
  padding: 10px;
}

.editBox {
  display: flex;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: ease-out 0.25s;
}

.editBox:hover {
  backdrop-filter: blur(3px);
  .edit {
    display: flex;
  }
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}

.edit {
  display: none;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  background-color: $white;
  color: $sapphire60;
  animation: appear 0.5s forwards;
  border-radius: 0.5em;
  padding: 5px 20px;
  opacity: 0;
  font-weight: 500;
  cursor: pointer;

  transition: 0.25s;

  &:hover {
    color: $sapphire100;
    box-shadow: rgba(0, 0, 0, 0.215) 0px 5px 15px;
  }
}

.name {
  font-family: $poppins;
  display: flex;
  padding-top: 5px;
  padding-bottom: 3px;
}

.defaultAnchor {
  position: relative;
}

.default {
  position: absolute;
  left: 10px;
  top: 0.3em;
  font-size: 0.8em;

  color: $sapphire60;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  // padding-bottom: 5px;
}

.color {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.createNewContainer {
  display: flex;
  width: fit-content;
}




.link {
  font-family: $poppins;
  color: $blue100;
  cursor: pointer;
  width: 300px;
  text-align: center;
  font-size: .8em;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}