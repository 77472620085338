@import "assets/styles/globals.scss";


.adjustBox {
  display: flex;
  justify-content: center;
  transition: .3s all;
  height: 100%;
  position: relative;
  overflow: auto;
  padding: 0rem;
  gap: 1rem;
  background-color: $background;
}