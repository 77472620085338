@import "assets/styles/globals.scss";

.manualPage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: $white;
    height: 100%;
    border-radius: $radius2;
    box-shadow: $shadowLight;
    overflow: auto;
}