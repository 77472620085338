@import "assets/styles/globals.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  // border: 2px solid black;
}

.pallet {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  gap: 7px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.addColor {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-bottom: 0.1em;
  // padding-left: 0.05em;
  // font-size: .8em;
  font-weight: 600;
  border: 2px solid $gray60;
  color: $gray60;
  cursor: pointer;
  transition: 0.3s all;
}
.addColor:hover {
  background-color: $gray60;
  color: white;
}
