@import "assets/styles/globals.scss";

.page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow: auto;
}

.navContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: $white;
  border-bottom: 2px solid $gray40;
  padding-left: 1rem;
  padding-top: 1rem;
  gap: .5rem;
  

  @media (max-width: 768px) {
    h2 {
      font-size: 1rem;
    }
    .settings {
      width: fit-content;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  font-family: $poppins;
  height: 100%;
  width: 55%;
  margin-right: .5rem;
}

.header_4 {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
