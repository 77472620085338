@import "assets/styles/globals.scss";
.menu {
  display: flex;
  flex-direction: row;
  overflow-y: visible;
  min-width: fit-content;
  gap: 10px;
  // padding-left: 1em;
  height: 100%;
  align-items: center;
}

.menuMobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-width: fit-content;
  gap: 10px;
  left: 1em;
  top: .8em;
  background-color: $white;
  padding: 10px;
  border-radius: $radius2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: flex-end;
}

.menuContainer {
  position: relative;
  margin-right: 1em;

}

.showStyle {
  .icon {
    background-color: white;
    color: $sapphire100;
  }
}

.icon {
  cursor: pointer;
  font-size: 1.5em;
  background-color: $blue100;
  color: white;
  border-radius: $radius2;
  padding: 4px 6px;
  transition: all .3s;
  box-shadow: $shadow6;
  margin: 1rem;
}
.icon:hover {
  background-color: white;
  color: $blue100;
}

