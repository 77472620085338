@import "assets/styles/globals.scss";

.headerContainer {
  width: 100%;
  padding: 1rem;
  border-bottom: 2px solid $gray40;
}

.bottomContainer {
  width: 100%;
  padding: 1rem;
  border-top: 2px solid $gray40;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.columns {
  width: 325px;
  height: 400px;
  border-radius: $radius2;
  // border: 2px solid $gray40;
  padding: .5rem 0;
  padding-bottom: 3rem;
  padding-right: 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding-top: 1rem;
  width: 100%;
  .label5 {
    padding: 0 0.5rem;
    color: $black;
    width: 325px;
  }
  
  // border: 2px solid black;
}

.outSide{
  display: flex;
  width: 100%;
  gap: .5rem;
  align-items: center;
}

.grip {
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    color: $blue100;
  }
}
.grab {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  // color: $blue100;
}


.column {
  width: 100%;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: $poppins;
  font-size: 0.9rem;
  transition: 0.3s all;
  border: 1px solid $gray60;
  border-radius: $radius2;
  background-color: $white;
  &:hover {
    background-color: $blue10;
  }
}

.iconHide {
  transition: 0.3s all;
  opacity: 0;
}

.icon {
  transition: 0.3s all;
  opacity: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  color: $gray60;
  background-color: $white;
  font-size: 1.1rem;
}