@import "assets/styles/globals.scss";

.menuIcon {
  padding: 0 0.5rem;
  text-align: center;
  font-size: 1.1rem;
}
.menuIconActive {
  padding: 0 0.5rem;
  text-align: center;
  font-size: 1.1rem;
}

.menuItemName {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.menuItemNameActive {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

// .collapsed {
//   // justify-content: center;
//   // font-size: .8rem;
// }

.menu_item {
  // color: $white;
  padding: 0.3rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}

// .menu_item_active {
//   // color: $white;
//   // background-color: $sapphire20;
// }
