@import 'assets/styles/globals.scss';

.bold {
  padding: 2px 3px 2px 5px;
  color: $gray110;
  transition: 0.4s all;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
}

.bolden {
  box-shadow: $shadow6;
}
