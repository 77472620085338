@import "assets/styles/globals.scss";
.pageContainer {
  width: 100%;
  font-size: .9em;
  position: relative;
  height: 100%;
  // m-bottom: 140px;
}

.nav {
  width: 100%;
  padding: 0rem 1rem;
  // height: 100%;
  max-height: fit-content;
  border-bottom: 2px solid $gray40;
  background-color: $white;
  padding-top: .5rem;
  // border: 2px solid black;
}


.page {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    min-height: 800px;
  }
}


.subContainer {
  width: 100%;
  max-width: 1000px;
  height: fit-content;
}



.header {
  @include header-4-text;
  margin-left: 1em;
  font-size: 1.2em;
}

.responses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
  padding: 1em 2em 1em 2em;
  box-shadow: $shadow;
  border-radius: $radius4;
  margin: 2em 1em;
  margin-top: 1em;
  background-color: $white;
  width: fit-content;
  align-items: center;
}

.wantMore {
  font-size: 0.7em;
  font-family: $poppins;
  color: $gray110;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  a {
    color: $blue100;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  background-color: $white;
  border-radius: $radius4;
  box-shadow: $shadow;
  left: 1em;
  bottom: 1em;
  padding: 1em;
  z-index: 100;
  margin-left: 2em;
}

.top {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 1em;
  //   border: 2px solid black;
  //   flex-wrap: wrap-reverse;
  //   gap: 0.5em;
}
.stats2 {
  display: grid;
  grid-template-columns: 200px 200px;
  margin-left: 4em;
  margin-top: 1.5em;

  // width: 100%;
  // padding: 2em;
  // height: 400px;
}

.stats {
  //   display: grid;
  //   grid-template-columns: 200px 200px;
  //   margin-left: 4em;
  //   margin-top: 1.5em;
  width: 100%;
  // padding-right: 2em;
  height: 200px;
  width: 300px;
  // border: 2px solid black;
}
.surveyStats {
  // width: 100%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  //   border: 2px solid black;
  max-width: 450px;

  // border: 2px solid black;
}

.statsBox {
  width: 100%;
  //   max-width: 450px;
  display: flex;
  flex-wrap: wrap;
  //   flex-direction: column;

  justify-content: center;
  gap: 2em;
  //   margin-top: 1em;
  // border: 2px solid black;
}

.question {
  box-shadow: $shadow;
  background-color: $white;
  border-radius: $radius4;
  width: 500px;
  height: 400px;
  // max-height: 500px;
  // min-height: 350px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  // overflow: auto;
  //   justify-content: center;
  //   align-items: center;
  padding: 0.5em;
}

.questionName {
  font-size: 0.8em;
  color: $gray110;
  font-family: $poppins;
}

.questionsBox {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}

.questions {
  display: flex;
  gap: 2em;
  // margin-left: 4em;
  flex-wrap: wrap;
  //   border: 2px solid blue;
  align-items: center;
  justify-content: center;
  padding: 0em 1em;
  // width: 100%;

  //   display: grid;
  //   margin: 0 auto;
  //   gap: 1rem;
  // grid-template-columns: 500px 500px;
  //   grid-template-columns: repeat(2, 1fr);
  // margin-left: 4em;
  // margin-top: 1.5em;
}

.questionFooter {
  width: 100%;
  display: flex;
  padding: 0em 1em;
  justify-content: space-between;
  align-items: center;

  span {
    font-family: $poppins;
    font-size: 0.7em;
    color: $info;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.viewComments {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: $poppins;
    font-size: 0.7em;
    color: $info;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.text {
  font-family: $poppins;
  color: $gray110;
  text-align: center;
  padding-top: 3em;
}
