@import "assets/styles/globals.scss";
.blockPicker {
  > div {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  }
}

.sketchPicker {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete {
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.5em 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: -5px;
}

.addColor {
  height: 1.25em;
  width: 1.25em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.1em;
  // padding-left: .05em;
  // font-size: .8em;
  font-weight: 600;
  border: 2px solid $gray60;
  color: $gray60;
  cursor: pointer;
  transition: 0.3s all;
}
.addColor:hover {
  background-color: $gray60;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  gap: 0.2rem;
  border-radius: $radius2;
  background-color: $gray22;
  padding: .5rem .7rem;
}

.picker {
  display: flex;
  // border: 1px solid $gray60;
  // box-shadow: $shadow6;
  border-radius: $radius2;
  gap: 0.5rem;
  cursor: pointer;
  transition: 0.3s all;
  width: 100%;
  background-color: transparent;
  &:hover {
    background-color: $gray30;
  }
  span {
    width: 8rem;
    outline: none;
    border: none;
    // border: 1px solid $gray60;
    // border-radius: $radius2;
    @include normal-text($gray110, 400, 0.8rem);
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    align-items: center;
  }
}


.pickerCon {
  padding: .2rem;
}