@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}
.container2 {
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100%;
  max-width: 800px;
  align-items: flex-start;
  gap: 0.5em;
}

.setting {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-left: 25px;
}
.text {
  font-family: $poppins;
  color: $gray110;
}

.links {
  width: 100%;
  max-width: 800px;
}

.status {
  font-size: 0.9em;
  align-items: flex-end;
}

.status.active {
  color: $green100;
}

.status.inactive {
  color: $red60;
}

.qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: .5em;
}
.bottomContent {
    margin-top: 4em;
    display: flex;
    gap: 5em;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap-reverse;
}

.icons {
    display: flex;
    gap: .5em;
}

.icon {
    box-shadow: $shadow;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}