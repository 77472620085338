@import "assets/styles/globals.scss";

.settingsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 0.85em;
  font-family: $poppins;
}

.questionName {
  color: $gray110;
  font-size: 0.95em;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0.3em;
  right: 0.5em;
  z-index: 100;
}

.currentTagsContainer {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-right: 30px;
}

.tag {
  border-radius: $radius8;
  padding: 3px 10px;
  box-shadow: $shadowLight;
  display: flex;
  align-items: center;
  transition: 0.3s;

  &:hover {
    .deleteTag {
      width: 20px;
    }
  }

  .tagName {
    padding: 0px 3px;
  }

  .tagIcon {
    padding-top: 3px;
    padding-left: 4px;
  }
}

.tagColor {
  width: 11px;
  height: 11px;
  border-radius: 4px;
}

.deleteTag {
  width: 0px;
  padding-top: 2px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $black;
  transition: 0.3s;
  font-size: 10pt;

  i {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: $red60;
    }
  }
}

.npsScore {
  color: $white;
  background-color: $blue100;
  width: fit-content;
}

.npsComment {
  color: $white;
  background-color: $green100;
  width: fit-content;
}

.plusTag {
  margin-left: 20px;
  padding: 3px 10px;
  // background-color:
  color: $sapphire60;
  width: fit-content;
  border-radius: $radius4;

  transition: 0.4s;

  // box-shadow: $shadowLight;

  &:hover {
    background-color: $sapphire60;
    color: $white;
    cursor: pointer;
  }
}

.closeAddNew {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: $gray110;
  transition: 0.3s;
  color: $gray110;

  &:hover {
    color: $sapphire60;
  }
}

.twoCreateMethods {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding-top: 5px;
}

.searchContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  // border: 2px solid $blue60;
}

.createNewContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // border: 2px solid gray;
}

.divider {
  display: flex;
  align-items: center;

  .bar {
    border: 3px solid $gray30;
    height: 80%;
    border-radius: 20px;
  }
}

.createNew {
  align-self: center;
  color: $sapphire60;
  border-bottom: 2px solid $sapphire20;
  width: fit-content;
  padding: 0px 30px 3px;
}

.nameInput {
  max-width: 200px;
  flex-grow: 1;
  background-color: $white;
  border: none;
  border-bottom: 2px solid $sapphire20;
  font-family: $poppins;
  color: $gray110;
  outline: none;
  // padding: 0px 10px;
  display: flex;
  overflow-x: scroll;
  overflow: hidden;
  transition: 0.5s;
}

.nameInput:hover {
  background-color: $gray20;
}
.nameInput:focus {
  background-color: $white;
}
.nameInput::placeholder {
  color: $gray100;
}

.entry {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $gray100;
  font-weight: 500;
}

.searchDiv {
  display: flex;
  // align-items: center;
  flex-direction: column;
  gap: 2px;

  .searchLabel {
    padding-left: 5px;
    color: $sapphire60;

    // font-weight: 300;
    font-size: 0.95em;
  }
}

.search {
  // width: 100%;
  border-radius: $radius2;
  background-color: $white;
  border: 2px solid $gray30;
  // border: none;
  font-family: $poppins;
  color: $gray110;
  font-size: 1.1em;
  outline: none;
  // padding: 8px 10px;
  padding: 2px 7px;
  overflow: none;
  transition: 0.3s all;

  &:hover {
    border: 2px solid $sapphire20;
    background-color: $white;
  }
  &:focus {
    border: 2px solid $sapphire20;
    background-color: $white;
  }
  &::placeholder {
    color: $gray100;
    font-size: 0.85em;
  }
}

.resultsAnchor {
  position: relative;
  // border: 2px solid black;
}

.results {
  position: absolute;
  top: 5px;
  border-radius: 8px;
  box-shadow: $shadowLight;
  background-color: $white;
  width: 100%;

  max-height: 400px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  // border: 1px solid purple;
}

.tagOption {
  padding: 5px;
  color: $gray110;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.3s;

  &:hover {
    background-color: $gray30;
  }
}

.noneLeft {
  padding: 5px;
  color: $gray100;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagType {
  color: #a4c6d0a2;
  font-weight: 400;
  font-size: 0.9em;
}

.noTags {
  color: $gray100;
  text-align: center;
  width: 100%;
}