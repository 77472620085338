@import "assets/styles/globals.scss";

.chartContainer {
  height: 100%;
  width: 100%;
  border-radius: $radius2;
  padding: 0.25rem 0.5rem;
  box-shadow: $shadow6;
  background-color: $white;
  // overflow: hidden;
  border: 2px solid transparent;

  &:hover {
    .settingsBtn {
      opacity: 1;
    }

    .settingsMenu {
      opacity: 1;
    }

    .quickPivots {
      opacity: 1;
    }

    .grip {
      opacity: 1;
    }
  }
}

.popoutBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 150;
}

.active {
  border: 2px solid $blue100;
  // &:after {
  //   content: "";
  //   height: 3px; //You can change this if you want smaller/bigger borders
  //   width: 90%;
  //   position: absolute;
  //   bottom: 0px;
  //   left: 5%; // If you want to set a smaller height and center it, change this value
  //   background-color: $blue100; // The color of your border
  // }
}

.popout {
  font-family: $poppins;
  position: relative;
  background-color: $white;
  padding: 10px 20px;
  height: 85%;
  width: 90%;
  border-radius: 20px;
  overflow: hidden;
}

.asChart {
  height: 100%;
  width: 100%;
  position: relative;
}

.selectable:hover {
  cursor: pointer;
  background-color: $gray20;
}

.vizDisplay {
  height: 100%;
}

.chartWithTitle {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.titleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.title {
  font-family: $poppins;
  text-align: center;
}

.subtitle {
  text-align: center;
  font-size: 0.8em;
  color: $gray100;
  font-weight: 600;
  font-family: $poppins;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.settingsBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 0.5rem;
  right: 0.5rem;
  height: 25px;
  width: 25px;
  opacity: 0;
  z-index: 1;
  transition: 0.2s all;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius1;
  cursor: pointer;

  &:hover {
    background-color: $gray30;
  }
}

.gearIcon {
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
  background-color: $white;
  box-shadow: $shadow6;
  padding: 5px 7px;
  border-radius: $radius2;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: $gray110;
    opacity: 1;
  }
}

.gearIconFill {
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  i {
    color: $gray110;
    opacity: 0;
    transition: 0.2s all;
  }
}
.gearIconFill:hover {
  i {
    opacity: 1;
  }
}

.settingsMenu {
  position: absolute;
  top: 0;
  right: 0px;
  display: flex;
  flex-direction: column;
  z-index: 107;
  opacity: 0;
  background-color: white;
  width: 170px;
  border-radius: $radius2;
  box-shadow: $shadow;
  overflow: hidden;
  z-index: 140;
  font-size: 0.9em;
  transition: 0.2s all;

  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    background-color: $white;
    cursor: pointer;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}

@include keyframes(grow) {
  0% {
    height: 0px;
  }

  100% {
    height: 60px;
  }
}

.copyMenu {
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  @include animation(grow 0.5s);
  height: fit-content;

  .menuItem {
    justify-content: flex-end;
    gap: 5px;
    background-color: $gray20;
    transition: 0.3s all;
  }

  .toPage {
    background-color: $gray25;
  }

  .pageMenuItem {
    justify-content: flex-start;
    background-color: $gray25;
    transition: 0.3s all;

    span {
      width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  .newPage {
    color: $gray100;
    &:hover {
      color: $black;
    }
  }
}

.closeMenu {
  @include animation(shrink 0.5s forwards);
}

@include keyframes(shrink) {
  0% {
    height: 60px;
  }

  100% {
    height: 0px;
  }
}

.dashList {
  animation: none;
}

.dashItem {
  display: flex;
}

.dashLoading {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.quickPivot {
  i {
    transform: rotate(90deg);
  }
}

.quickPivots {
  // opacity: 0; TODO put back on
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 107;

  width: 250px;

  display: flex;
  flex-direction: column;
  border-radius: 1em;
  box-shadow: $shadow;
  padding: 5px;
  background-color: $white;
}

// .expandIcon {
//   height: 30px;
//   // width: 50px;
//   color: $gray110;
//   // visibility: hidden;
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // top: -0.1em;
//   // right: .75em;
//   left: 40px;
//   top: 5px;
//   cursor: pointer;
//   // font-size: 0.8em;
//   color: $gray110;
//   width: fit-content;
//   padding: 5px 5px 0px;
//   z-index: 105;
//   transition: 0.2s all;
//   opacity: 0;
//   box-shadow: $shadow;
//   font-size: 0.9em;
//   padding: 5px 9px;
//   border-radius: $radius8;
//   background-color: $white;
// }
.downloadIcon {
  color: $gray110;
  // visibility: hidden;
  position: absolute;
  top: -0.2em;
  // right: .75em;
  left: 25px;
  cursor: pointer;
  font-size: 0.8em;
  color: $gray110;
  width: fit-content;
  padding: 5px 5px 0px;
}

.exitBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12pt;
  cursor: pointer;
  z-index: 110;

  // border: 2px solid black;
}

.scrollableBox {
  border: 2px solid $gray30;
  border-radius: 5px;
  overflow-y: auto;
}

.exportas:hover {
  background-color: $blue100;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .exportasoptioncontainer {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  .exportasoption:hover {
    background-color: $blue100;
    color: white;
    cursor: pointer;
  }
}

.exportasoptioncontainer {
  position: absolute;
  display: none;
  border: 2px solid $blue100;
  top: -24px;
  transition: 0.2s all;
  // width: 106px;
  // justify-content: center;
  // color: $blue100;
  // font-size: .9em;
  // font-weight: 700;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: white;
  // gap: 5px
  // border-radius: 5px;
}

.exportasoption {
  // border: 2px solid $blue100;
  width: 102px;
  justify-content: center;
  color: $blue100;
  font-size: 0.9em;
  font-weight: 700;
  text-align: center;
  padding: 1.5px 0px;
  padding-bottom: 5.5px;
  background-color: white;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  // border-radius: 5px;
}

.answerCount {
  font-family: $poppins;
  color: $gray110;
  // font-weight: 600;
}

.downLoadButton {
  button {
    overflow: hidden;
    white-space: nowrap;
    height: 100%;
    border: 2px solid $blue100;
    background-color: white;
    color: $blue100;
    border-radius: 5px;
    font-weight: 600;
    font-family: $poppins;
    transition: 0.5s all;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  button:hover {
    background-color: $blue100;
    color: white;
    cursor: pointer;
  }
}

.noData {
  text-align: center;
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray100;
  font-weight: 600;
}

.thumbnailtext {
  font-size: 5pt;
  font-family: $poppins;
  padding: 0px 10px;
}

.mocktableContainer {
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.mocktable {
  border: 0.5px solid $gray60;
  border-radius: $radius2;
  height: 100%;
  overflow: hidden;
}

.mockrowfirst {
  background-color: $gray30;
  border-bottom: 0.5px solid $gray60;
  height: 10%;
}

.mockrow {
  border-bottom: 0.5px solid $gray60;
  height: 10%;
}
