@import "assets/styles/globals.scss";


.form {
    max-width: 700px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: $gray20;

    .header {
        background-color: $white;
        padding: 2rem;
        border-bottom: 1px solid $gray60;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;

    }
}