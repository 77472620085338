@import "assets/styles/globals.scss";
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 1;
    margin-left: 40em;
  }

  100% {
    opacity: 1;
  }
}

.pageCreate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: 0.3s all;
}

.formats {
  display: flex;
  flex-direction: column;
}

.format {
  font-family: $poppins;
  padding: 2em 1em;
  padding-top: 2.3em;
  cursor: pointer;
  transition: 0.5s all;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.format:hover {
  background-color: $gray30;
  .formatTitle {
    color: $black;
  }
}

.formatTitle {
  font-size: 1.7em;
  text-align: center;
  color: $gray110;
  font-weight: 700;
  transition: 0.5s all;
}

.formatDesc {
  font-size: 0.8em;
  color: $gray100;
  text-align: center;
  font-weight: 600;
}

.formatIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  color: rgba(127, 207, 211, 0.08);
  i {
    font-size: 5em;
  }
}

.fromBlurBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
  gap: 15px;
  flex-wrap: wrap;
  background-color: rgba(42, 98, 124, 0.6);
  backdrop-filter: blur(2px);
  z-index: 110;
}

.from {
  border-radius: 1em;
  padding: 2em 1em;
  min-height: 250px;
  width: 300px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.5s all;
}
.from:hover {
  background-color: $gray30;
  .fromTitle {
    color: $black;
  }
}

.fromTitle {
  font-size: 1.5em;
  text-align: center;
  color: $gray110;
  font-weight: 700;
  transition: 0.5s all;
}

.fromDesc {
  font-size: 0.9em;
  color: $gray100;
  text-align: center;
  font-weight: 600;
}

.fromIcon {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue20;
  font-size: 7em;
}

.createContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  padding: 1em;
  gap: 30px;
}

.entries {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  padding: 10px;
}

.createHeader {
  font-family: $poppins;
  color: $gray110;
  font-weight: 700;
  font-size: 2em;
  padding-bottom: 15px;
  text-align: center;
}

.createBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.templateBlurBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.5em;
  gap: 15px;
  flex-wrap: wrap;
  background-color: rgba(42, 98, 124, 0.6);
  backdrop-filter: blur(2px);
  z-index: 110;
}

.templateTitle {
  font-size: 1.5em;
  text-align: center;
  color: $gray110;
  font-weight: 700;
  transition: 0.5s all;
}

.templateDesc {
  font-size: 0.9em;
  color: $gray100;
  text-align: center;
  font-weight: 600;
}

.page {
  height: 100%;
  width: 100%;
  transition: 0.5s all;
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.chooseContainer {
  width: 100%;
  padding: 0em 1em;
  padding-bottom: 15em;
}

.header {
  position: sticky;
  top: 0;
  background-color: $white;

  h1 {
    padding: 0em;
    margin: 0em;
    @include header-2-text;
    white-space: wrap;
  }

  span {
    @include description-text;
    margin-left: 1em;
  }
}

.chooseItem {
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
  width: 100%;
}

.box {
  text-align: center;
  border-radius: $radius4;
  border: 2px solid transparent;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  align-items: center;
  background-color: $white;

  .square {
    border: 4px solid $gray60;
    height: 140px;
    width: 140px;
    border-radius: $radius4;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 3rem;
      color: $blue100;
    }
  }
  .text_3 {
    color: $gray110;
  }

  &:hover {
    background-color: $blue10;
  }
}

.boxfill {
  background-color: $blue10;
}

.fromTemplate {
  width: 100%;
  display: flex;
  justify-content: center;
  .fromheader {
    @include description-text;
    font-size: 0.8em;
    padding-left: 1em;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-bottom: 1em;
  padding-top: 6rem;
  // gap: 1rem;

  .header_3 {
    color: $black;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  position: relative;
  padding-bottom: 4rem;
  overflow: auto;
  height: 100%;
}
.setting {
  display: flex;
  flex-direction: column;
  i {
    color: $gray60;
    font-size: 1.3em;
  }
}

.settingSection {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  font-size: 0.8em;
  width: 100%;
  max-width: 600px;
  height: 100%;
}

.typeBox {
  border-radius: $radius4;
  border: 2px solid $gray30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  transition: 0.3s all;
  cursor: pointer;
  position: relative;

  h3 {
    @include header-3-text($seafoam100);
    font-size: 1.1em;
    margin: 0em;
    font-weight: 400;
  }

  &:hover {
    background-color: $gray30;
    box-shadow: $shadow;
  }
}

.typeBoxFill {
  background-color: $gray30;
  box-shadow: $shadow;
}

.link {
  @include link;
}

.timeDelay {
  width: 80px;
  height: 35px;
  border: 2px solid $gray30;
  border-radius: 5px;
  background-color: transparent;
  font-family: $poppins;
  font-size: 0.8em;
  padding-left: 0.5em;
  color: $gray110;
  // -moz-appearance: none; /* Firefox */
  // -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    border: 2px solid $blue100;
  }

  outline-color: $blue100;
}

.description {
  @include description-text;
  font-size: 1em;
}

.description2 {
  @include description-text;
  font-size: 0.9em;
  margin: 2em;
  text-align: center;
}

.buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: $white;
  z-index: 100;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 1.95px 2.95px 2px;
  height: 140px;
}

.header {
  @include header-3-text;
  margin: 1em 0em;
}

.creating {
  display: flex;
  flex-direction: column;
  gap: 2em;
  font-family: $poppins;
  color: $gray110;
  align-items: center;
  justify-content: center;
  padding: 3em;
  width: 100%;
}

.createModal {
  // padding: 20px 10px;
  display: flex;
  flex-direction: column;
  // gap: 10px;
  width: 100%;
  height: 100%;
}
.createModalTemplate {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: c;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: fit-content;
}

.mode {
  height: 200px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray110;
  font-size: 1.5em;
  font-family: $poppins;
  font-weight: 600;
  span {
    font-size: 0.5em;
    font-weight: 400;
    color: $gray100;
    padding: 0px 80px;
    // text-align: center;
  }
}
.mode:hover {
  background-color: $gray40;
  font-size: 1.8em;
}

.check {
  position: absolute;
  right: 0.5em;
  top: 0.5em;

  i {
    color: $green100;
  }
}

.navContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 1em;
  height: 100%;
  min-height: 70px;
  max-height: 70px;
  padding: 1rem;
  background-color: $white;
  // z-index: 130;
}

.chooseItemTemplate {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 32rem);
  // justify-content: center;
  row-gap: 2rem;
  column-gap: 2rem;
  margin: auto;
}

.boxTemplate {
  display: flex;
  padding: 1rem;
  border-radius: $radius4;
  background-color: $blue10;
  width: 32rem;
  height: 15.5rem;
  gap: 1rem;

  .imagecont {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    border-radius: $radius2;
    position: relative;
  }

  .boxheader {
    display: flex;
    flex-direction: column;
    width: 30rem;
    height: 100%;
    gap: 0.5rem;
  }

  span {
    height: 100%;
    font-family: $poppins;
    font-size: 0.8rem;

    // max-height: 5rem;
    overflow: scroll;
  }

  .header_4 {
    white-space: nowrap;
    color: $black;
    margin: 0;
  }
}

.templates {
  display: flex;
  flex-wrap: wrap;
  // gap: 100px;
  justify-content: center;
  position: relative;
  width: 100%;

  .header_3 {
    color: $black;
  }
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_3 {
    color: $black;
  }
}

.template {
  border-radius: 1em;
  padding: 2em 1em;
  min-height: 250px;
  min-width: 300px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.5s all;
}
.template:hover {
  background-color: $gray30;
  .templateTitle {
    color: $black;
  }
}

.alltemps {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.templatename {
  position: absolute;
  font-family: $poppins;
  color: $white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  top: 45%;
}
