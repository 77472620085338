@import "assets/styles/globals.scss";

.customReport {
  width: 100%;
  height: 100%;
  font-size: 0.9em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  transition: 0.3s all;
  background-color: $gray25;
}

.loadingDock {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 100px;
  justify-content: center;
  // align-items: center;
  background-color: $gray25;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.taskContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $shadow;
  border-radius: 1rem;
  gap: 15px;
  padding: 20px;
  font-family: $poppins;
  min-width: 200px;
  width: 50vw;
  max-width: 600px;
  background-color: $white;
  @include animation(appear .5s);
  height: fit-content;
}

.title {
  color: $gray110;
  display: flex;
  align-items: center;
  // flex-direction: column;
  gap: 5px;
  font-size: 1.2rem;
  color: $gray100;
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0em 1em;
  gap: 1em;
  transition: 0.3s all;
  width: 100%;
}

.fillProgress {
  width: 100%;
  background-color: $gray60;
  border-radius: 10px;
  height: 5px;
  transition: 0.3s all;
  display: flex;
}

.progressBar {
  height: 5px;
  border-radius: 10px;
  transition: 0.3s all;
  background-color: $blue100;
}

.progressReport {
  // width: 2rem;
  color: #c0c0c0;
  font-size: 0.8rem;
  white-space: nowrap;
  text-align: center;
}

// Dashboard

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1em;
  padding: 10px 1em;
}

.dashReport {
  width: 100%;
  height: 100%;
  font-size: 0.9em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1rem;
    background-color: $white;
    border-bottom: 1px solid $gray60;
  }

}



.solidHeader {
  border-bottom: 1px solid $gray30;
}

.welcome {
  flex: 1;
  font-family: $poppins;
  font-size: 1.5em;
  color: $gray110;
  font-weight: 400;
  white-space: nowrap;
}

.topButtons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}

.pickDifferentUser {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.pageContent {
  flex-grow: 1;
  padding: 0px 1rem 1rem;
  transition: all 1s;
  background-color: $background;
}
