@import "assets/styles/globals.scss";

.page {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-bottom: 10px;

}

.formContainer {
  background-color: $white;
  box-shadow: $shadow;
  border-radius: .75em;
  padding: 2em 2em;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
  span {
    font-family: $poppins;
    color: $gray110;
  }
  span:hover {
    color: $gray110;
  }
  hr {
    width: 50%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: '5px';
    background-color: $gray100;
  }
  p {
    font-family: $poppins;
    margin-top: 30px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: $gray110;
  }
}

.imageContainer {
  img {
    width: 200px;
  }

  // margin-bottom: 60px;
}

.imageContainer2 {
  img {
    width: 100%;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
}

.splitPageContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  height: 100%;
  flex-wrap: wrap-reverse;
  // border: 2px solid black;
}

.signInOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
}

.aLink {
  color: $blue100;
  cursor: pointer;
}
.aLink:hover {
  color: $blue100;
}

.sending {
  font-family: $poppins;
  color: $gray110;
}