@import "assets/styles/globals.scss";

.surveys {
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  row-gap: 1rem;
  column-gap: 1rem;
  grid-auto-rows: minmax(100px, auto);
  // gap: 2em;
  flex-wrap: wrap;
  // overflow: auto;
  align-items: center;
  justify-content: flex-start;
  padding: 0em 0em;
  min-height: 250px;

}

.add {
  box-shadow: $shadow;
  width: 300px;
  min-width: 300px;
  height: 15em;
  border-radius: $radius4;
  padding: 1em;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  color: $blue100;

  &:hover {
    background-color: $gray30;
    // .surveyTitle {
    //   font-size: 1.75em;
    // }

    // .surveyDescription {
    //   font-size: 0.8em;
    // }
  }
}

.more {
  display: flex;
  align-items: center;

  span {
    color: $blue100;
    font-family: $poppins;
    font-size: 0.8em;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
