@import "assets/styles/globals.scss";

.page {
  height: 95%;
  // min-height: 100vh;
  padding: 2em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: $poppins;
  color: $gray110;
  // border: 2px solid black;
}

.errorheaders {
  color: $orange100;
  padding-left: 1em;
}
.bad {
  //   @include link($red60);
  color: $red60;
}

.good {
  @include link;
}

.button {
}

.modalErrors {
  color: $red60;
  font-family: $poppins;
}
.modalError {
  color: $gray110;
  font-family: $poppins;
}

.textField {
  font-family: $poppins;
  min-width: 100%;
  //   font-size: 0.8em;
  padding: 5px 4px;
  border: none;
  outline: none;
  border-radius: $radius2;
  color: $gray110;
  background-color: transparent;
  margin: 0em;

  &:active {
    box-shadow: $shadow;
    background-color: $white;
  }

  &:focus {
    box-shadow: $shadow;
    background-color: $white;
  }
  // outline: none;
  // width: fit-content;
}


.header {
  display: flex;
  gap: 1em;
  align-items: center;
  span {
    @include header-3-text
  }
}