@import "assets/styles/globals.scss";

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // -moz-transition: background-color 0.2s ease-in;
  // -o-transition: background-color 0.2s ease-in;
  // -webkit-transition: background-color 0.2s ease-in;
  // transition: background-color 0.2s ease-in;
  @include transition(background-color 0.2s ease-in);

  .checkmark {
    height: 21px;
    width: 21px;
    background-color: $gray30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: $shadow;

    &:after {
      content: "";
      display: none;
      width: 9px;
      height: 9px;
      color: $white;
      background-color: $white;
      border-radius: 50%;

      @include transition(background-color 0.2s ease-in);
    }

    &.multi {
      border-radius: 5px;

      &:after {
        content: "\F26E";
        background-color: transparent;
        width: 11px;
        height: 11px;
        color: $white;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: bold !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &:hover input ~ .checkmark {
    background-color: $gray60;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    -webkit-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
  }

  input {
    display: none;

    &:checked ~ .checkmark {
      background-color: $blue100;
      -moz-transition: background-color 0.2s ease-in;
      -o-transition: background-color 0.2s ease-in;
      -webkit-transition: background-color 0.2s ease-in;
      transition: background-color 0.2s ease-in;
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.containerRank {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // -moz-transition: background-color 0.2s ease-in;
  // -o-transition: background-color 0.2s ease-in;
  // -webkit-transition: background-color 0.2s ease-in;
  // transition: background-color 0.2s ease-in;
  @include transition(background-color 0.2s ease-in);

  .checkmarkRank {
    height: 20px;
    width: 20px;
    background-color: $gray30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ranking {
      display: none;
      color: $white;
      font-size: 0.8em;
      font-family: $poppins;
    }

    // &:after {
    //   content: "";
    //   display: none;
    //   width: 11px;
    //   height: 11px;
    //   color: $white;
    //   background-color: $white;
    //   border-radius: 50%;

    //   @include transition(background-color 0.2s ease-in);
    // }

    &.multiRank {
      border-radius: 5px;

      &:after {
        background-color: transparent;
        width: 11px;
        height: 11px;
        color: $white;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: bold !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &:hover input ~ .checkmarkRank {
    background-color: $gray60;
    border-radius: 5px;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    -webkit-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
  }

  input {
    display: none;

    &:checked ~ .checkmarkRank {
      background-color: $blue100;
      -moz-transition: background-color 0.2s ease-in;
      -o-transition: background-color 0.2s ease-in;
      -webkit-transition: background-color 0.2s ease-in;
      transition: background-color 0.2s ease-in;
      .ranking {
        display: block;
      }
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
