@import "assets/styles/globals.scss";

.accordionsContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 5px;
  // padding: 0px 10px;
  overflow: auto;
  // border: 2px solid black
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.savedIcon {
  color: $blue100;
  position: absolute;
  right: -16px;
  top: 4px;
  animation-name: fadeIn;
  animation-duration: 3s;
  opacity: 1;
}

//Filter Accordian Below...

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  color: $gray110;
  padding: 3px 5px;
  font-family: $poppins;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.7rem;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.5s;

  .checknName {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 3.5px;
  }
}

.header:hover {
  background-color: $gray20;
}

.finalItems {
  display: flex;
  gap: 0.5em;
  // border: 2px solid black;
  .chosenCount {
    color: $blue60;
  }
}

.accordionactive {
  .accordionicon {
    transform: rotate(270deg);
  }
}

.header .accordionicon {
  // position: absolute;
  transition: all 0.5s;
}

.accordionactivecontainer {
  background-color: $gray20;
  border-radius: 1em;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
  transition: all 0.5s;
  max-height: 310px;
  border-top: none;
  border-radius: 0px 0px 0.5rem 0.5rem;
  padding: 5px 0px 5px 15px;
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.95em;
  color: #474848;
  overflow-y: auto;
  cursor: default;
  // letter-spacing: 1px;
}

.item {
  padding: 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 0.8rem;

  span {
    max-width: 250px;
  }
}

// .body::-webkit-scrollbar {
//   display: flex;
// }

// .body::-webkit-scrollbar-track

.search {
  width: 100%;
  background-color: $gray30;
  // color: #949494;
  font-size: 0.65rem;
  font-family: $poppins;
  // text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  border: none;
  outline: none;
  border-radius: 1em;
  padding: 0.3em;
  padding-left: 10px;
}

.nothingToFilter {
  color: $gray100;
  text-align: center;
  padding: 5px;
}

.clearFilters {
  margin-top: 5px;

  margin-left: 20px;
  display: flex;
  justify-content: center;

  .clearFiltersBtn {
    width: fit-content;
    display: flex;
    padding: 5px 10px 5px 15px;
    border-radius: 0.5em;
    font-size: 0.9em;
    font-weight: 800;
    color: $gray100;
    cursor: pointer;

    &:hover {
      color: $gray110;
      background-color: $gray20;
    }
  }

  .funnels {
    position: relative;
    overflow: hidden;
  }

  .emptyFunnel {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }

  @keyframes emptyOut {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .empty {
    animation: emptyOut 0.75s forwards;
  }
}

.disabled {
  cursor: not-allowed;
}

.pageFilter {
  color: $sapphire60;
  font-weight: 500;
  font-size: 0.95em;
}
