@import "assets/styles/globals.scss";
.audiencePage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
 background-color: $background;
 
}

.text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.legistics {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.legistic {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // border: 2px solid black;
}

.finalbuttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  padding-top: 30px;
}
.footer {
  margin-top: 2em;
  font-family: $poppins;
  color: $gray110;
  text-align: center;
  a {
    color: $blue100;
  }
}

.previous {
  font-family: $poppins;
  color: $blue100;
  font-size: 0.9em;
  cursor: pointer;
  width: fit-content;
  padding: 30px;
  width: 100%;


  span {
    &:hover {
      text-decoration: underline;
    }
  }
}

