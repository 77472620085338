@import "assets/styles/globals.scss";

.category {
  color: $sapphire60;
  width: fit-content;
  padding: 0px 40px 0px 5px;
  border-bottom: 1px solid $sapphire20;
  display: flex;
  gap: 10px;
  align-items: center;
}


.op {
  padding: 0px 10px;
  font-size: 0.9rem;
}

.desc {
  color: #b7b7b7;
  font-size: .75rem;
}

.hint {
  font-size: 0.7rem;
  color: #ccc;
  padding-left: 7px;
}

.ex {
  padding-left: 5px;
  font-size: 0.7rem;
  color: $seafoam60;
}

.blank {
  font-size: 0.65rem;
  color: #b7b7b7;
  border-bottom: 0.5px solid #b7b7b7;
  padding: 0px 9px;
}
