@import "assets/styles/globals.scss";

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  flex-grow: 1;
  position: relative;

  height: fit-content;
  min-height: 100vh;
  // overflow: auto;
}

.alreadyTookIt {
  padding-top: 20px;
  font-family: $poppins;
  color: $gray110;
  text-align: center;
}

.constructionZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
  position: inherit;
  height: fit-content;
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-width: 200px;
  padding: 1em;
  position: relative;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 1em 0px;
  margin-right: 40px;
  align-items: center;
  height: 3.5em;
  padding-bottom: 2em;
}
.surveyDescriptionContainer {
  border: 2px solid $gray30;
  border-radius: 5px;
  background-color: white;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.progressContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fillProgress {
  width: 75%;
  background-color: #d8d9d9;
  border-radius: 30px;
  height: 4px;
}

.progressBar {
  height: 4px;
  border-radius: 40px;
}

.progressReport {
  width: 25%;
  color: #c0c0c0;
  font-style: $poppins;
  font-size: 0.8em;
  text-align: center;
  font-weight: bold;
}
.surveyDescription {
  font-family: $poppins;
}

.pageHeader {
  width: 100%;
  padding: 1rem 0;
  border-radius: $radius4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pageCounter {
  font-family: $poppins;
  color: $seafoam100;
  font-weight: 600;
  font-size: 0.8em;
  font-style: italic;
  letter-spacing: 0.05em;
  display: flex;
  text-align: center;
  padding: 0px 20px;
}

.progBar {
  padding: 0.5em 0;
  width: 100%;
}

.stick {
  position: sticky;
  top: 0.5em;
  width: 100%;
  background-color: transparent;
  z-index: 130;
  padding: 0em 1em;
  border-radius: 1em;
}

.shadow {
  border-radius: 1em;
  padding: 0.5em 1em;
  background-color: $white;
  box-shadow: $shadow;
}


.footer {
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .9em;
  font-family: $poppins;
  color: $gray110;
  min-height: 200px;

  .flex {
    display: flex;
    gap: 1em;
    align-items: center;
    flex-wrap: wrap;

    a {
      @include link($gray110);
      &:hover {
        color: $blue100;
      }
    }
  }
  .poweredBy {
    display: flex;
    gap: 1em;
    align-items: center;

    a {
      @include link;

    }
  }

}