@import "assets/styles/globals.scss";

.settingHeader {
  .title {
    @include header-3-text;
    font-size: 1.3em;
  }

  .description {
    @include description-text;
  }
  border-bottom: 2px solid $gray40;
  padding: 1rem;
}

.settingContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  height: 100%;
  font-size: 0.9em;
}

.surveyName {
  color: $blueDark;
}

.textarea {
  border-radius: $radius4;
}
.header_4 {
  color: $black;
  width: fit-content;
  white-space: nowrap;
  margin: 0;
}

.label {
  width: fit-content;
  min-width: 150px;
  white-space: nowrap;
  padding-top: 0.7em;
  justify-content: flex-end;
}

.setting {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 100%;
  align-items: flex-start;
  max-width: 500px;
}

.boxes {
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 500px;
  align-items: center;
  gap: 0.5em;
}

.boxesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  padding-bottom: 3rem;
  border-bottom: 2px solid $gray40;
  flex-wrap: wrap;
  // margin-top: 2em;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  // padding-right: 2em;
  flex-wrap: wrap;
  gap: 2em;
}

.publish {
  display: flex;
  align-items: center;
  width: 400px;
  span {
    @include description-text;
  }
}

.access {
  display: flex;
  justify-content: space-around;
  padding: 1em;
  flex-wrap: wrap-reverse;
}

.saveBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.saveBtn2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 1rem;
  // border: 2px solid black;
}

.buttonsContainer {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  padding: 1rem;
}

.internalResults {
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em 0em;
  span {
    color: $gray110;
    font-family: $poppins;
    text-align: center;
  }
}

.button {
  display: flex;
  gap: 0.5em;
  align-items: center;
  i {
    font-size: 1.1em;
  }
  padding: 0 1rem;
}

.buttonred {
  display: flex;
  gap: 0.5em;
  align-items: center;
  color: $red60;
  i {
    font-size: 1.1em;
  }
  &:hover {
    text-decoration: underline;
  }
}

.or {
  font-family: $poppins;
  color: $gray110;
  font-weight: 700;
  font-size: 1.3em;
}

.header {
  font-family: "Poppins", sans-serif;
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
}

// .labelContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   padding-right: 20px;
//   align-items: end;
//   gap: 10px;
// }
.checkforquestions {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 1em;
  justify-content: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0em 1em;
  padding-bottom: 5em;
}

.questionContainer {
  width: 700px;
  cursor: pointer;
  padding: 2px;
  border-radius: $radius4;
  transition: 0.3s all;
  position: relative;
}

.buttons {
  display: none;
  gap: 10px;
  position: absolute;
  z-index: 110;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  color: $gray110;
}

.question {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  &:hover {
    .hover {
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
      background-color: $blue100;
    }
  }
  &:hover {
    .buttons {
      display: flex;
    }
  }
}

.tag {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.generate {
  position: sticky;
  width: 100%;
  bottom: 0;
  box-shadow: $shadow;
  background-color: $white;
  z-index: 120;
  display: flex;
  justify-content: center;
  padding: 1em;
  gap: 10px;
}
