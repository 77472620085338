@import "assets/styles/globals.scss";

.tableContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  overflow: hidden;
  // overflow: auto;
}
.headerBox {
  // overflow: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 1em 0em;
}
.tableHeader {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
}

.tableTitle {
  @include header-3-text($gray110);
  padding-left: 1em;
  position: sticky;
  top: 0.5em;
  z-index: 100;
}

.basicContainer {
  // display: flex;
  padding: 0em 2em;
  // flex-direction: column;
  // max-height: 80%;
}

.isdraft {
  color: $yellow100;
  font-weight: 800;
}

.isclosed {
  color: $orange100;
  font-weight: 800;
}

.isopen {
  color: $green100;
  font-weight: 800;
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    font-size: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
  background-color: $white;
}

.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  // padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;

  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

.searchBox {
  position: relative;
}

.moreHeader {
  background-color: $white;
  border-bottom: 2px solid $gray30;
}

.items {
  position: absolute;
  left: 0;
  top: 2.5em;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow;
  width: 100%;
  // overflow: auto;
  max-height: 380px;
  z-index: 1;

  .item {
    padding: 10px 20px;
    color: $gray110;
    cursor: pointer;
    font-family: $poppins;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.8em;

    &:hover {
      background-color: $background;
    }
  }
}

.sortingContainer {
  position: relative;
 
}

.sortingContent {
  border-radius: $radius1;
  background-color: $white;
  font-family: $poppins;
  padding: 0.3rem 1rem;
  font-size: 0.8em;
  font-weight: 400;
  color: $black;
  box-shadow: $shadow6;
  display: flex;
  gap: 0.5em;
  align-items: center;
  height: 2.1rem;

  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background-color: $gray25;
  }
  span {
    @include normal-text($black, 400, 0.8rem);
  }
}

.sortSettings {
  position: absolute;
  box-shadow: $shadowLight;
  width: 300px;
  padding: 1em;
  border-radius: $radius2;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 1em;
  top: 0;
  left: 0;
  z-index: 100;
  font-size: 0.8em;
}

.descend {
  display: flex;
  justify-content: space-between;
}

.descendBox {
  font-family: $poppins;
  border: 2px solid $gray30;
  border-radius: $radius2;
  padding: 7px 20px;
  color: $gray110;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s all;
  background-color: $white;
  &:hover {
    background-color: $blue100;
    border: 2px solid $blue100;
    color: $white;
    box-shadow: $shadow;
  }
}

.descendBox.fill {
  background-color: $blue100;
  color: $white;
  box-shadow: $shadow;
  border: 2px solid $blue100;
}

.header {
  @include header-3-text;
  margin: 0em;
  padding: 0em;
  // padding-left: 2em;
}

.close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  color: $gray110;
  transition: 0.3s all;

  &:hover {
    color: $red60;
  }
}
