@import "assets/styles/globals.scss";

.userFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow: visible;
  // padding: 1rem 0rem;
}

.noneFields {
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 500;
  color: $gray60;
}

.userFieldsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  row-gap: 0rem;
  // flex-direction: column
  justify-content: center;
  column-gap: 1.5em;
  width: 100%;
  flex-wrap: wrap;
}

.customField {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // gap: 2px;

  position: relative;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: $radius2;
  padding: 0.25rem 0.5rem;
  // border: 1px solid black;
  td {
    padding: 0rem 0.5rem;
    width: 100%;
    height: 100%;
  }
  tr {
    width: 100%;
  }

  .label6 {
    color: $gray110;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    // border: 2px solid black;
    white-space: nowrap;
    height: 35px;
  }

  .editThem {
    position: absolute;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    background-color: $white;
    padding: 0.5rem;
    padding-bottom: 0.7rem;
    box-shadow: $shadow6;
    border-radius: $radius2;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .text_3 {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    // position:  relative;
  }

  transition: 0.3s all;
  i {
    font-size: 1rem;
  }
  .editcon {
    opacity: 0;
    transition: 0.3s all;
    color: $gray110;
    // font-size: 1rem;
    border-top-right-radius: $radius1;
    border-bottom-right-radius: $radius1;
    padding: 0;
    padding-right: 0.5rem;

    // width: 100%;
    // border: 2px solid black;
  }
  .status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $blue100;
  }
  .green {
    background-color: $green100;
  }
  .yellow {
    background-color: $yellow100;
  }
  .red {
    background-color: $red60;
  }

  &:hover {
    background-color: $blue10;
    .editcon {
      opacity: 1;
    }
  }
  .customFieldName {
    background-color: $blue100;
    border-radius: 1.5em;
    padding: 0.5em 1em;
    font-size: 0.8em;
    color: $white;
    font-family: $poppins;
    font-weight: 600;
    cursor: default;
  }
}

.customFieldContainer {
  position: relative;
}
.edit {
  position: absolute;
  z-index: 1000;
  width: 100%;
  top: 0rem;
  left: 0;
  box-shadow: $shadow6;
  border-radius: $radius2;
  padding: 0.5rem;
  background-color: $white;
}

.selectField {
  width: 350px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.minusField {
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
  color: $gray110;

  &:hover {
    color: $red60;
  }
  // pointer-events: none;
}

.plus {
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s;
  color: $seafoam60;
  // margin-top: 10px;
  padding: 5px 10px;
  border-radius: 1.5em;
  transition: all 0.5s;

  &:hover {
    color: $gray110;
    background-color: $gray30;
  }
}

.customDropDown {
  width: fit-content;
  min-width: 300px;
  // width: fit-content;
  position: absolute;
  border-radius: 1em;
  overflow-y: auto;
  overflow-x: hidden;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 7px 7px;
  z-index: 100;
  background-color: $white;
}

.customDropDown .choice {
  padding: 10px;
  text-align: center;
  &:hover {
    cursor: pointer;
    background-color: $gray20;
  }
}

.addNew {
  color: $gray110;
  // font-weight: 500;
  &:hover {
    color: $blue100;
  }
}

.text {
  font-family: $poppins;
  color: $gray110;
}
