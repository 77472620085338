@import "assets/styles/globals.scss";
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.menuItem {
  position: relative;
  color: $black;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: fit-content;
  font-family: $poppins;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  height: 50px;
  @include transition(background-color 300ms, color 300ms);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: $blue100;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  &:hover {
    // background-color:  $sapphire80;
    // color: $white;
    color: $blue100;
  }
  z-index: 1;

  &::after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
  }

  &:hover::after,
  &:focus::after {
    transform: scale(1);
  }
  cursor: pointer;

  i {
    font-size: 1.3em;
  }

  div {
    // margin-left: 8px;
    line-height: 110%;

    .label {
      font-size: 1em;
    }

    .sublabel {
      font-size: 0.8em;
    }
  }

  &.activeItem {
    color: $blue100;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: $blue100;
      opacity: 1;
      transform: translate3d(0, 0em, 0);
      // transition: opacity 300ms, transform 300ms;
    }
  }
}

.menuItemMobile {
  position: relative;
  color: $white;
  background-color: $blue100;
  border-radius: $radius3;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 0.9em;
  box-shadow: $shadow;
  white-space: nowrap;
  width: 150px;
  font-family: $poppins;
  // margin-bottom: 10px;
  @include transition(background-color 300ms, color 300ms);
  cursor: pointer;

  i {
    font-size: 1.3em;
  }

  div {
    // margin-left: 8px;
    line-height: 110%;

    .label {
      font-size: 1em;
    }

    .sublabel {
      font-size: 0.8em;
    }
  }

  &:hover,
  &.activeItem {
    color: $blue100;
    background-color: $white;
  }
}

.hover {
  &:hover {
    border-bottom-left-radius: 0em;
    border-bottom-right-radius: 0em;
    .subMenus {
      display: flex;
    }
  }
}

.view {
  display: flex;
  align-items: center;
  // border: 2px solid black;
  gap: 0.5em;
  padding: 0.4em 0.5em;
  min-height: 40px;
}

.submenuItem {
  padding: 1em;
  @include header-4-text;
  font-weight: 500;
  border-radius: $radius3;
  &:hover {
    background-color: $sapphire20;
    box-shadow: $shadow;
  }
}

.subMenus {
  display: none;
  flex-direction: column;
  padding: 1em;
  position: absolute;
  background-color: inherit;
  top: 39px;
  z-index: 120;
  left: -15px;
  border-radius: $radius3;
  // border-top-left-radius: 0em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.subMenu {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  padding: 0.5em 0em;

  // opacity: 0;

  span {
    @include header-4-text($sapphire100);
  }
}

.tooltipContainer {
  color: #ffffff;
  // background-color: #2a627c;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  // padding: 1.1em;
  // padding-left: 20px;
  // border-radius: 5px;
  padding-left: 25px;
  width: fit-content;
  white-space: nowrap;
  position: absolute;
  // top: 0;
  // left: 0;
  @include transition(opacity 300ms 100ms);
  z-index: 1000;
}
.tooltip {
  background-color: #2a627c;
  padding: 1.1em;
  // padding-left: 20px;
  border-radius: 5px;
  width: fit-content;
}

.gap {
  min-width: 2px;
  min-height: 30px;
  background-color: $gray60;
  border-radius: $radius4;
  margin: 0rem .5rem;
}
