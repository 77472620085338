@import "assets/styles/globals.scss";

.chooseLink {
  font-family: $poppins;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whatFor {
  display: flex;
  padding: 20px 10px 0px 10px;
  align-self: center;
}

.for {
  padding: 5px 15px;
  border-bottom: 2px solid $gray60;
  transition: 0.3s all;
  cursor: pointer;

  color: $gray100;
}

.active {
  border-bottom: 2px solid $blue60;
  color: $gray110;
}
.inactive:hover {
  // border-bottom: 2px solid $gray100;
  color: $gray110;
  background-color: $gray20;
  // border-bottom: 2px solid $blue60;
}

.search {
  display: flex;
  align-items: center;
  padding: 0px 15px;
}

.options {
  display: flex;
  flex-direction: column;

  height: 350px;
  overflow: auto;
}

.option {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  color: $sapphire80;
  transition: 0.3s all;
  font-size: 0.85rem;

  &:hover {
    background-color: $gray25;
    cursor: pointer;
  }
}

.launched {
  font-size: 0.65rem;
  color: $gray110;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.responseCount {
  color: $blue60;
  font-size: 0.75rem;
}

.draft {
  border-radius: 10px;
  padding: 3px 7px;
  width: fit-content;
  font-size: 0.65rem;
  background-color: $orange20;
  color: $orange100;
}

.pages {
  color: $sapphire100;
}
.visuals {
  color: $blue100;
  padding-left: 2px;
}

.orgWide {
  color: $blue100;
  font-size: 0.9rem;
}

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 25px 0px 15px;
}

.text {
  color: $gray110;
  font-size: 1rem;
  padding: 0px 20px;
}

.nameConf {
  color: $sapphire80;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-top: 10px;
}

.inBtn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.preview {
  border-radius: $radius2;
  box-shadow: $shadow;
  padding: 10px;
  display: flex;
  width: 220px;
  height: 170px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.name {
  justify-self: center;
  color: $sapphire80;
  font-weight: 500;
  text-align: center;
  // font-size: 1rem;
}

.iconHolder {
  position: absolute;
  z-index: 0;
  display: flex;
  align-items: center;
  height: 95%;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;

  .icon {
    color: $blue20;
    font-size: 5rem;
  }
}

.ends {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  flex-wrap: wrap;
  font-size: 0.65rem;
  color: $gray110;
}
