/* EmailVerification.css */
@import "assets/styles/globals.scss";

.page {
    font-family: $poppins;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom right, $white, $info);
    height: 100vh;
    width: 100vw;
    color: $gray110;
}

.email_container {
    font-family: Arial, sans-serif;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.logo {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-left: -5px;
    img {
        width: 200px;
    }
    span {
        font-family: $poppins;
        color: $sapphire100;
        font-weight: 400;
        font-size: 1.5em;
    }
}

.logo_dot {
    color: #38c8d4;
}

.title {
    font-size: 24px;
    margin: 10px 0;
}

.message,
.resend_message {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    margin-bottom: 20px;
}

.email_link {
    color: #38c8d4;
    text-decoration: none;
    font-weight: bold;
}

.resend_button {
    display: flex;
    border: none;
    padding: 0px 20px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    justify-content: center;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.footer_button {
    background-color: #38c8d4;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.or_text {
    color: #555;
    font-size: 14px;
}

.footer_link {
    margin-top: 20px;
    font-size: 14px;
    color: #777;
}

.footer_anchor {
    color: #38c8d4;
    text-decoration: none;
    font-weight: bold;
}

.copyrightBar {
    position: fixed;
    bottom: 0;
    margin-bottom: 1%;
}