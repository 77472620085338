@import "assets/styles/globals.scss";


.settingsBox {
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  z-index: 110;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  width: fit-content;
  // align-items: center;

  cursor: default;
  overflow: hidden;

  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    white-space: nowrap;
    cursor: pointer;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}
.options {
  position: relative;
}