@import "assets/styles/globals.scss";

.builder {
  display: flex;
  flex-direction: column;
  font-family: $poppins;
  font-size: 0.85rem;
}

.editPencil {
  padding: 0.2em;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  transition: 0.3s all;
  position: relative;
  color: $gray100;
  cursor: pointer;
}
.editPencil:hover {
  background-color: $gray60;
}

.edit {
  height: 0;
  width: 0;
  display: none;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  border-radius: 0.5em;
  z-index: 130;
  padding: 12px;

  // i {
  //   color: $gray100;
  //   cursor: pointer;
  //   font-size: 0.9em;
  // }
}

.group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.select {
  width: 100px;
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray40;
  cursor: pointer;
  transition: 0.3s all;
  height: 100%;
  padding: 2px;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    background-color: $gray60;
  }
}

.staticSelect {
  color: $gray110;
}

.textInput {
  border: 1px solid $gray60;
  padding: 3px 5px;
  outline: none;
  border-radius: 0.5em;
  font-family: $poppins;
  transition: 0.3s all;
  width: 130px;

  &::placeholder {
    color: $gray100;
  }

  &:hover {
    background-color: $gray20;
  }
  &:focus {
    background-color: $white;
  }
}

.conditions {
  // overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.condition {
  cursor: pointer;
  // border-radius: 5px;
  padding: 7px 10px;
  transition: 0.3s all;
  color: $gray110;
  cursor: pointer;
  white-space: pre-wrap;
  @include row;
  width: fit-content;

  &:hover {
    background-color: $gray25;
  }

  &:first-child {
    padding-top: 10px;
  }
}

.hover {
  background-color: $gray25;
  width: 100%;
  // justify-content: center;
  color: $gray110;

  &:hover {
    background-color: $gray25;
  }
}

.example {
  color: $gray100;
}

.exampleName {
  color: $blue60;
  white-space: pre;
}

.divider {
  height: 2px;
  border-radius: 2px;
  background-color: $sapphire60;
  width: 88%;
  align-self: center;
}

.option {
  display: flex;
  align-items: center;
  gap: 1px;
}

.cancelOption {
  color: $gray60;
  transition: 0.3s all;

  &:hover {
    color: $gray110;
    cursor: pointer;
  }
}

.buildNew {
  width: 100%;
  // justify-content: center;
  color: $gray110;
}

.mustHave {
  color: $red60;
  padding-left: 5px;
  font-size: 0.8rem;
}
