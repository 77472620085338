@import "assets/styles/globals.scss";
$reactlightblue: #d5f3fe;
$reactlighterblue: #d5f3fe5e;
$reactdarkerblue: #2a627c;
$reactdarkblue: #2a627c81;
$reactorange: #edb57e;
$reactdarkorange: #ed9146;
$reactbluegray: #738c91;

// .th_content:first-child {
//     border-radius: 5px;
//     background-color: #2a627c;
// }

.th_content {
  color: $sapphire100;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
//   background-color: $reactlightblue;
  border-radius: 5px;
}

.th_content:first-child {
    border-radius: 5px;
}

.th_content:hover {
  background-color: $sapphire60;
  z-index: 200;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}

.tr:last-child .td {
  border-bottom: 0;
}

.svg_icon svg {
  position: relative;
  height: 1.5em;
  width: 1.5em;
}

.svg_icon_sm svg {
  position: relative;
  height: 1.25em;
  width: 1.25em;
}

.svg_gray svg {
  stroke: #9e9e9e;
}

.svg-text svg {
  stroke: #424242;
}

.icon_margin {
  margin-right: 4px;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  touch-action: none;
}

.resizer:hover {
  background-color: #8ecae6;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  overflow: hidden;
}

.bg_white {
  background-color: white;
}

.shadow_5 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12),
    0 16px 32px rgba(0, 0, 0, 0.12);
}

.border_radius_md {
  border-radius: 5px;
}

.form_input {
  padding: 0.375rem;
  background-color: #eeeeee;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #424242;
}

.propertytype {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: gray;
}

.isfullwidth {
  width: 100%;
}

.sort_button {
  padding: 0.25rem 0.75rem;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  color: #757575;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.sort_button:hover {
  background-color: #eeeeee;
}
