@import "assets/styles/globals.scss";

.designContainer {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  transition: 0.3s all;
}

.settingHorizontal {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  gap: 1rem;
}
.settingVertical {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
  // align-items: center;
  // font-size: 0.9em;
}

.headerContainer {
  color: $gray100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  transition: 0.3s all;

  background-color: $gray25;
}
.headerContainer:hover {
  background-color: $white;
}
.header {
  font-family: $poppins;
  font-weight: 700;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em;
  letter-spacing: 0.1em;
}
.headerContainerActive {
  color: $gray100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  transition: 0.3s all;
  background-color: $white;
}

@include keyframes(show) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  // 100% {
  //   height: 100%;
  // }
}

.body {
  @include animation(show 0.5s 1);
  background-color: $white;
  padding-top: 1rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  width: 18rem;
  overflow: auto;
  height: 100%;
  border-right: 1px solid $gray60;
}

.line {
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: $gray60;
}

.theme {
  background-color: $background;
  border-radius: $radius2;
  border: 2px solid $gray60;
  height: 10rem;
  width: 100%;
  transition: 0.3s all;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 2px solid $info;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  overflow: hidden;

  .themeq {
    background-color: $white;
    border-radius: $radius1;
    width: 100%;
    box-shadow: $shadow6;
    min-height: 3rem;
    padding: 0.5rem;
  }

  .themen {
    background-color: transparent;
    border-radius: $radius1;
    width: 100%;
    min-height: 3rem;
    padding: 0.5rem;
  }

  .themed {
    background-color: $white;
    border-radius: $radius1;
    width: 100%;
    min-height: 3rem;
    padding: 0.5rem;
    box-shadow: $shadow6;
  }

  .progre {
    border-radius: $radius1;
    width: 100%;
    background-color: $blue100;
    min-height: 0.1rem;
  }
}
.active {
  border: 2px solid $info;
  cursor: default;
}

.designContent {
  width: 0rem;
  height: 100%;
  transition: .4s all;
  border-right: 1px solid $gray60;
}

.colorContainer {
  width: 3.5rem;
  display: flex;
  justify-content: flex-start;
}

.logoEntry {
  display: flex;
  min-height: 28px;
  font-size: 0.85em;
  flex-wrap: wrap;
  color: black;
  font-family: $montserrat;
  letter-spacing: 1px;
  gap: 0.3em;
  align-items: center;

  label {
    font-family: $poppins;
    overflow: hidden;
    white-space: nowrap;
    height: 100%;
    border: 2px solid $blue100;
    background-color: $blue100;
    color: white;
    border-radius: 5px;
    transition: 0.2s all;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;
  }

  label:hover {
    background-color: white;
    color: $blue100;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }
  .file {
    font-size: 0.8em;
    padding: 0em 0.3em;
  }
}

.alignmentContainer {
  border: 2px solid #d8d9d9;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  padding: 4px 6px;
  font-weight: 600;
  color: #a8a8a8;
  transition: all 0.5s;
}

.alignmentHeader {
  // border: 2px solid blue;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  .chosenAlgmnt {
    // border: 2px solid green;
    width: 100%;
  }
  .algmntToggleBtn {
    // border: 2px solid black;
    position: absolute;
    right: 2px;
    cursor: pointer;
  }
}

.algnmtStgsDvdr {
  height: 2px;
  border: 1px solid #d8d9d980;
  border-radius: 2px;
  margin: 3px 5px;
  // border-style: dashed;
}

.numSlider {
  // border: 2px solid black;
  // height: fit-content;
  margin-top: -5px;
}

.confettiColors {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  gap: 6px;
}
