@import "assets/styles/globals.scss";

.chartWithTitle {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.titleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: $poppins;
  white-space: pre-wrap;
}

.title {
  width: fit-content;
}

.subtitle {
  font-size: 0.8em;
  color: $gray100;
  font-weight: 600;
  font-family: $poppins;
  width: fit-content;

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.answerCount {
  font-family: $poppins;
  color: $gray110;
}

.noData {
  text-align: center;
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray100;
  font-weight: 600;
}