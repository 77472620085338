@import "assets/styles/globals.scss";

.container {
  height: 100%;
  width: 100%;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $poppins;
}

// .editable:hover .toolbar {
//   display: flex;
// }

.editable .entry:hover {
  // background-color: $gray30;
  // border-radius: 8px;
}

.entry {
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;

  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  padding: 3px 5px;
}

.border {
  border: 2px solid $gray60;
  border-radius: $radius4;
  padding: 5px;
}
// .editable .entry:hover {
//   border-bottom: 2px solid $gray40;

// }

.showBorder {
  // border-bottom: 2px solid $gray60;
  // background-color: $gray25;
  box-shadow: $shadow6;
  border-radius: $radius2;
}
.showOnHover:hover {
  box-shadow: $shadow6;
  border-radius: $radius2;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  font-size: 11pt;
  color: $gray100;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;

  // border: 1px solid black;
  padding: 3px 5px;
}

.borderPlace {
  padding: 5px;
  padding-top: 6px;
}

.toolbarHolder {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
}

.toolbar {
  position: absolute;
  bottom: 5px;
  display: none;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
  border-radius: 8px;
  // background-color: $gray30;
  background-color: $white;
  // border: 1px solid $gray100;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  box-shadow: $shadow;
  padding: 2px 0px;
}

.btn {
  transition: 0.5s all;
  padding: 0px 8px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: $gray60;
  }
}

.active {
  background-color: $gray60;
}

.vert {
  color: $gray110;
}

.color {
  border: 2px solid $white;
  display: flex;
  align-items: center;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  overflow: hidden;
  margin: 0em 0.2em;
  cursor: pointer;
}

.fontSelect {
  height: 100%;
  width: 3em;
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray30;
  cursor: pointer;
  transition: 0.3s all;
}
.fontSelect:hover {
  background-color: $gray60;
}

.divider {
  color: $gray60;
}

.transparent {
  color: $gray60;
  &:hover {
    color: $white;
  }
}
.notTransparent {
  color: $gray110;
}
