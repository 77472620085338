@import "assets/styles/globals.scss";

.page {
  background-color: $background;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
}

.top {
  border-bottom: 1px solid $gray60;
  padding: 1rem;
  background-color: $white;
  width: 100%;
}

.users {
  display: grid;
  grid-template-columns: repeat(auto-fill, 13.5rem);
  row-gap: 2rem;
  column-gap: 2rem;
  grid-auto-rows: minmax(11rem, auto);
  // gap: 2em;
  flex-wrap: wrap;
  // overflow: auto;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
  min-height: 250px;
  max-width: 1250px;
  width: 100%;
}

.user {
  box-shadow: $shadow6;
  background-color: $white;
  border-radius: $radius2;
  width: 13.5rem;
  height: 11rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.iconContainer {
  border-radius: $radius2;
  transition: 0.3s all;
  padding: 0.5rem;
  margin-right: -0.7rem;
  margin-top: -0.7rem;
  cursor: pointer;

  &:hover {
    background-color: $gray30;
  }
}

.permission {
  @include header-6-text($gray110, 600);
  font-family: $montserrat;
}

.role {
  @include normal-text($sapphire100, 0, 0.8rem);
}

.activity {
  @include normal-text($gray100, 0, 0.7rem);
}

.progContainer {
  width: 100%;
  max-width: 600px;
  margin-left: -5px;
//   border: 2px solid black;
//   height: 100%;

}

.section_label6 {
    color: $gray100;
}