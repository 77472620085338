@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  font-family: $poppins;
  gap: 10px;
  transition: 0.5s all;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  color: $gray100;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 1px;
  font-size: 0.9em;
}

.list {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 5px;
  color: $gray100;
  font-size: 0.85em;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.optionLabel {
  display: flex;
  align-items: center;
  gap: 5px;
}

.red {
  color: $red60;
}

.green {
  // color: $green100;
  color: #48a626;
}

.yellow {
  color: $yellow100;
}

.orange {
  color: $orange60;
}

.blue {
  color: $blue100;
}

.stackedOptionLabel {
  display: flex;
  gap: 6px;
}

.top {
  padding-bottom: 5px;
}

.middle {
  display: flex;
  align-items: center;
}

.bottom {
  padding-top: 5px;
}

.npsHelp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;

  padding: 0px 10px 5px;
  gap: 5px;
  color: $sapphire60;
  font-size: 0.9em;
}

.npsOptionStack {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.npsOption {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.arrows {
  display: flex;
  flex-direction: column;
  gap: 0.85em;
}

.passiveArrows {
  justify-content: center;
  padding: 0px 5px;
}

.passiveOptions {
  display: flex;
  flex-direction: column;
  gap: 2.2em;
}

.anyChanges {
  font-size: 0.9em;
  color: #bbb;
  display: flex;
  align-items: center;
  gap: 10px;
  // padding-left: 60px;
  padding-left: 6.5em;
  padding-bottom: 5px;
}

.tippingPoint {
}

.choiceFilters {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.or {
  width: 100%;
  text-align: center;
  color: $gray60;
  // font-weight: 500;
}

.addOr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .addOrButton {
    cursor: pointer;
    padding: 5px 15px;
    transition: 0.4s all;
    border-radius: 0.75em;

    &:hover {
      box-shadow: $shadowLight;
      color: $seafoam100;
    }
  }
}
