@import "assets/styles/globals.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0px;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray100;
  // font-weight: bold;
  padding: 3px;
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  border: 2px solid $gray60;
  cursor: pointer;
  transition: .3s ease-in-out;
}

.number {
  width: 1.95em;
  height: 1.95em;
  font-size: .9em;
}