@import "assets/styles/globals.scss";

.fieldSettings {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s all;
  width: 400px;
  background-color: $gray20;
}
.tabs {
  padding: 1rem;
  padding-top: 0;
  border-bottom: 1px solid $gray60;
}

.header_3 {
  padding: 1rem;
  padding-bottom: 0;
  white-space: nowrap;
  width: 100%;
}
.label5 {
  // margin-top: 2rem;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  max-width: 150px;
}

.customFieldsBox {
  margin-top: 0.8em;
  display: flex;
  flex-direction: row;
  gap: 0.8em;
  width: 100%;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
}

.fields {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.section {
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 3rem;
  flex-wrap: wrap;
  row-gap: 2rem;
  border-bottom: 2px solid $gray40;
}

.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: $gray20;
}

.savebar {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
}


.name {
  color: $blueDark;
  display: flex;
  gap: .5rem;
  font-weight: 600;
  width: fit-content;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // gap: 1rem;
  padding: 1rem 0rem;
  // overflow: auto;
  padding-bottom: 3rem;
  // border: 2px solid black;
}

.cField {
  border-radius: $radius2;
  color: $white;
  font-family: $poppins;
  font-weight: 600;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  overflow: hidden;
  box-shadow: $shadow6;
  background-color: $blue100;
}

.count {
  color: #ededed;
  padding: 0px 4px 0px 2px;
}

.otherSide {
  color: $gray110;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 5px;
  padding-left: 2px;
  transition: 0.3s all;
  cursor: pointer;
}

.delete:hover {
  background-color: $gray100;
}

.editable:hover {
  -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
  filter: blur(1px);
}

.saveDivider {
  height: 1.5em;
  border-bottom: 2px solid $blue40;
  margin-bottom: 1em;
}

.addAttContainer {
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  width: 70%;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  font-size: 0.9rem;
  height: fit-content;
}

.attName {
  padding: 3px 10px;
  transition: 0.3s all;

  &:hover {
    -webkit-filter: blur(1px);
    filter: blur(1px);
  }

  &:focus {
    -webkit-filter: none;
    filter: none;
  }
}
