@import "assets/styles/globals.scss";

.textField {
  background-color: white;
  color: $gray110;
  border-radius: 5px;
  padding: 5px 20px;
  outline: none;
  width: 100%;
  flex-grow:1;
  @include transition(background-color 0.2s ease-in);

  ::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
  :-ms-input-placeholder, /* Internet Explorer 10-11 */
  ::-ms-input-placeholder /* Microsoft Edge */ {
    color: $gray110;
    opacity: 1; /* Firefox */
  }

  &.disabled {
    background-color: #ffffff00;
    @include transition(background-color 0.2s ease-in);
    padding-left: 0;
    padding-right: 0;
  }
}

.editableTextField {
  outline: none;
}
.textField:hover {
  border: 2px solid $gray110
}