@import "assets/styles/globals.scss";

.page {
  width: 100%;
  width: 280px;
  height: 100%;
  max-height: fit-content;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: .5em;
  overflow: auto;
  transition: 0.3s all;
  min-width: 60px;
  font-family: $poppins;
  color: $gray110;
  font-size: .8rem;
}


.column {
    border-radius: $radius2;
    background-color: $gray25;
    width: 100%;
    padding: 0.3em 0.5em;
    // cursor: pointer;
    display: flex;
    justify-content: space-between;
    // transition: 3s all;
    border: 2px solid transparent;
    font-size: 0.9em;
  
    .show {
      transition: 0.3s all;
      color: $black;
      opacity: 0;
      padding-right: 1em;
      cursor: pointer;
      &:hover {
        color: $red60;
      }
    }
    .show2 {
      transition: 0.3s all;
      color: $black;
      opacity: 0;
      padding-right: 1em;
      cursor: pointer;
      &:hover {
        color: $blue100;
      }
    }
    .show3 {
      transition: 0.3s all;
      // padding-right: 1em;
      color: $blue100;
      font-size: 1.1em;
    }
    .grip {
      transition: 0.3s all;
      cursor: pointer;
      &:hover {
        color: $blue100;
      }
    }
    .grab {
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      color: $blue100;
    }
    &:hover {
      .show {
        opacity: 1;
      }
      .show2 {
        opacity: 1;
      }
    }
  }