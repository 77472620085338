@import "assets/styles/globals.scss";

.textField {
  width: 100%;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  // width: 350px;
}

.toggleLabel {
  width: 75px;
  display: flex;
  justify-content: right;
  // color: $sapphire100;
}

.bucketDivider {
  // border: 2px solid black;
  // border-bottom: 2px solid $gray60;
  display: flex;
  justify-content: flex-start;
  gap: 1em;
  margin-top: 0.5em;
  // background-color: $;
}

// buckets

.buckets {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.bucket {
  padding-right: 0.3em;
  display: flex;
  gap: 1em;
  align-items: flex-end;
}

.bucketProperty {
  padding: 0.3em;
}

.deleteButton {
  cursor: pointer;
  transition: all 0.3s;
}
.deleteButton:hover {
  color: $red60;
}

.addprop {
  margin-left: 2.2em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  margin-right: 1em;

  font-size: 0.9em;
  margin-top: 1em;

  border-radius: 0.2em;
  cursor: pointer;
  transition: all 0.3s;
}
.addprop:hover {
  background-color: $gray60;
}
