@import 'assets/styles/globals.scss';

.switchboard {
  // border-top: 1.5px solid $gray60;
  @include col;
  gap: 10px;
  width: fit-content;
  max-width: 280px;
  padding-top: 15px;
}

.title {
  color: $gray100;
  font-size: .8rem;
  width: fit-content;
  align-self: center;
  padding: 0px 25px;
  border-bottom: 1.5px solid $gray60;
}

.aside {
  color: $gray100;
  font-size: .7rem;
  padding-top: 10px;
}

.switchLabel {
  font-size: .8rem;
  color: $gray100;
  transition: .3s all;
}

.checked {
  color: $black;
}

.note {
  font-size: .7rem;
  color: $gray100;
  padding-left: 8px;
}

.allElse {
  transition: .5s all ease-in-out;
  padding-left: 0px;
}

.slide {
  padding-left: 20px;
}