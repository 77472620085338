@import "assets/styles/globals.scss";
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.accordion {
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  font-size: 0.9em;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.tableContainer {
  overflow: hidden;
  width: 100%;
}

.navContainer {
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  max-height: fit-content;
  // z-index: 131;
  width: 100%;
}

.dataHeader {
  display: flex;
  flex-direction: column;
  padding: 1em;
  padding-top: 0.5em;
  // z-index: 130;
  gap: 1em;
  align-items: center;
  height: fit-content;
  @include animation("slide-left .4s 1");
  // border: 2px solid yellow;
}

.nav {
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: $poppins;
  color: $gray100;
  width: 100%;
  background-color: transparent;
  position: sticky;
  top: 0em;
  // z-index: 110;
  // border: 2px solid brown;
  height: fit-content;
  width: 100%;
  max-width: 315px;

  // border-radius: $radius4;
  // gap: 10px;
}

.nav2 {
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-family: $poppins;
  color: $gray100;
  // width: 100%;
  background-color: transparent;
  position: sticky;
  top: 0em;
  // z-index: 110;
  // border: 2px solid blue;
  height: fit-content;
  width: 100%;
  max-width: 315px;
  // border-radius: $radius4;
  // gap: 10px;
}

.navItem {
  height: 40px;
  width: 100%;
  // max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  cursor: pointer;

  font-weight: 700;
  font-size: 0.8em;
  letter-spacing: 1pt;
  transition: 0.3s all;
  // border: 2px solid blue;

  &:hover {
    color: $gray110;
  }
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em 1em;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s all;
}
.header:hover {
  background-color: $white;
}

.headervisible {
  background-color: $white;
  border-radius: $radius2;
  background-color: $white;
  box-shadow: $shadow6;
  display: flex;
  flex-direction: column;
  padding: 1em;
  z-index: 1;
  cursor: default;

  .accordionicon {
    transform: rotate(270deg);
  }

  &:hover {
    background-color: $white;
  }
}

.header .accordionicon {
  transition: all 0.5s;
  font-size: 1em;
}

.accordionTitle {
  font-family: $poppins;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.85em;
  color: $gray100;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  font-family: $poppins;
  //
  font-weight: 300;
  justify-content: space-between;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 0.65rem;
  color: $gray100;
  gap: 0.5em;
  padding-bottom: 5px;
  // border: 2px solid black;
}

.active {
  color: $gray110;
}

.body {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

// .setting {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   padding-right: .5em;
//   input {
//       width: 50px;
//       border: 2px solid $blue100;
//       border-radius: 5px;
//       color: $gray100;
//   }
//   span {
//       font-family: 'Poppins', sans-serif;
//       color: $sapphire100;
//   }
// }

.settingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: center;
}

.settingContainer2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  justify-content: center;
}

.setting {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  padding-right: 0.5em;
  text-align: center;
  gap: 5px;
  input {
    width: 50px;
    // border: 2px solid $blue100;
    // border-radius: 5px;
    // color: $gray100;
  }
  // span {
  //   font-family: "Poppins", sans-serif;
  //   color: $sapphire100;
  // }
}

.setting2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 0.5em;
  // text-align: center;
  width: 100%;
  gap: 10px;
  // border: 2px solid black;
  // input {
  //   width: 50px;
  //   border: 2px solid $blue100;
  //   border-radius: 5px;
  //   color: $gray100;
  // }
  // span {
  //   font-family: "Poppins", sans-serif;
  //   color: $sapphire100;
  // }
}

.settingwithlabel {
  display: flex;
  flex-direction: column;
  gap: 0.1em;

  // width: 100%;
  // span {
  //   color: $sapphire100;
  //   font-family: "Poppins", sans-serif;
  //   font-weight: 900;
  //   font-size: 0.8em;
  // }
}

.pointRadiusEx {
  border-radius: 50%;
  background-color: $sapphire100;
}

.sortDataHeading {
  margin-top: 10px;
  // text-decoration: underline;
  // text-underline-offset: 4px;

  // color: $sapphire100;
  //       font-family: 'Poppins', sans-serif;
  //       font-weight: 900;
  //       font-size: .8em;
  // border-bottom: 1px solid $sapphire100;
}

.horizDiv {
  width: 60%;
  border-top: 2px solid $sapphire20;
  margin-bottom: 2px;
}

.sortOptions {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.radioLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 0.9em;
  // font-weight: 50;
  font-family: $poppins;
  color: $sapphire100;
  align-items: center;
  padding-left: 20px;
  gap: 20px;
  // border: 2px solid black;

  input {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0px;
    transition: 0.3s all;
    cursor: pointer;
    background-color: $gray40;
    border: 4px solid $gray40;
  }
  input:checked {
    background-color: $white;
    border: 4px solid $blue60;
  }
  input:not(:disabled):not(:checked):hover {
    background-color: $gray60;
    border: 4px solid $gray60;
  }
  input:disabled {
    // border: 2px solid $gray40;
    // background-color: $blue100;
    cursor: default;
  }
}

.sliderSetting {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // padding-right: .5em;
  // padding-left: 15px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: $sapphire100;
  // border: 2px solid black;
  align-items: center;
  div {
    // padding-right: .5em;
    // padding-left: 15px;
    width: 100%;
    max-width: 300px;
  }
  .onAScale {
    display: block;
    font-size: 0.8em;
  }
  .defaults9 {
    display: block;
    font-size: 0.7em;
    // padding-top: 2px;
  }
}

.label6 {
  color: $seafoam100;
}

.chartStyleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 18px 5px 0px;
  gap: 10px;
  justify-content: center;
}
.imgContainer {
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s all;
}
.imgContainer:hover {
  background-color: $gray60;
  cursor: pointer;
}

.disabledImgContainer {
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffffbb;
  position: relative;
}

.disabledImgContainer:hover .imgBlurer {
  visibility: visible;
}

.imgBlurer {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffffbb;

  .message {
    text-align: center;
    font-size: 0.65em;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $sapphire100;
    font-weight: 500;
    gap: 0px;
  }
}

.saveBtnDiv {
  // border: 2px solid gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
}

.segmentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // border: 2px solid black;
  gap: 10px;
  width: 100%;

  .segLabel {
    font-family: $poppins;
    color: $gray110;
    letter-spacing: 0.1em;
    font-size: 0.8em;
    cursor: pointer;
  }
}

.segLabels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.5s;
  gap: 0.5em;
  width: 100%;
  padding-left: 5px;
}

.segLabelContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: left;
}

.segLabelEditor {
  border-radius: $radius2;
  height: 30px;
  // padding: 1rem;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $gray25;
  }
  &:focus {
    background-color: $gray25;
  }
}

.horizontalSetting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.showOnlyOptions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pivots {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  color: $gray100;
}

.horizAxisBy {
  font-size: 0.85em;
  text-align: center;
  color: $gray110;
  // margin-left: 30px;
  border-bottom: 2px dashed $sapphire20;
  margin: 0px 10px;
}

.item {
  padding: 5px 40px 5px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $gray110;
  font-family: $poppins;

  font-weight: 500;
  font-size: 0.85em;
}

.disabled {
  color: $gray60;
}

.pivotSectionHeading {
  font-size: 0.85em;
  text-align: center;
  color: $gray110;
  border-bottom: 2px dashed $sapphire20;
  padding-bottom: 2px;
  margin: 0px 12px;
}

.horizDisabled {
  .item {
    color: $gray60;
  }
  .horizAxisBy {
    border-color: $gray40;
    color: $gray60;
  }
}

.answerTypeOptions {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  font-size: 0.8em;
  padding: 5px 0px;
}

.answerTypesContainer {
  font-family: $poppins;
  color: $gray60;
  display: flex;
  flex-direction: column;

  .answerTypeOptions {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-size: 0.8em;
  }

  .pivotRadio {
    display: flex;
    align-items: center;
    gap: 5px;
    // border: 1px solid black;

    input {
      appearance: none;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0px;
      transition: 0.3s all;
      cursor: pointer;
      background-color: $gray40;
      border: 4px solid $gray40;
    }
    input:checked {
      background-color: $white;
      border: 4px solid $blue60;
    }
    input:not(:disabled):not(:checked):hover {
      background-color: $gray60;
      border: 4px solid $gray60;
    }
    input:disabled {
      // border: 2px solid $gray40;
      // background-color: $blue100;
      cursor: default;
    }
  }
}

.flywheel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flywheelToggle {
  display: flex;
  gap: 15px;
  align-items: center;
}

.optionsActive {
  color: $gray110;
  .pivotSectionHeading {
    color: $gray110;
    border-color: $sapphire20;
  }
}

.linkButton {
  cursor: pointer;
  color: $sapphire60;
}

.currDataQuestion {
  padding: 0em 0.2em;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 320px;
  width: 100%;
  // margin: auto;
  text-overflow: ellipsis;
  border: 1px solid brown;
}
.currDataQuestionBox {
  font-family: $poppins;
  // color: $blue100;
  font-size: 0.8em;
  background-color: $gray40;
  // border-radius: 5px;
  // padding: .2em .5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // gap: 5px;
  // max-width: 25em;
  width: 100%;

  // margin: auto;
}

.currDataQuestionBoxDark {
  border: 1px solid red;
  font-family: $poppins;
  color: $gray110;
  font-size: 0.8em;
  background-color: $gray40;
  border-radius: 5px;
  // padding: .2em .5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // max-width: 28em;
  // margin: auto;
  padding-left: 0.5em;
  cursor: default;
  width: 100%;
  // gap: .5em;
}

.qClarifierBackground {
  // In ChartStyles.jsx
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(42, 98, 124, 0.6);
  z-index: 110;
  // border: 2px solid blue;
}

.qClarifierPopout {
  font-family: $poppins;
  position: relative;
  background-color: $white;
  padding: 30px;
  padding-bottom: 45px;
  // height: fit-content;
  // height: 80%;
  max-width: 80%;
  border-radius: 1em;
  // border: 2px solid blue;

  .qTypeSelectorDiv {
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .qType {
      display: flex;
      gap: 25px;

      .keep {
        color: $sapphire100;
      }

      .qTypeTitle {
        font-weight: 600;
        color: $blue60;
      }

      .Qs {
        padding-top: 1em;
      }

      .Q {
        color: $gray100;
      }
    }
  }
}

.exitBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px 5px;
  font-size: 12pt;
  cursor: pointer;
  border: 2px solid white;
  background-color: white;
  border-radius: 6px;

  &:active {
    border: 2px solid $gray30;
  }
  tr {
    width: 100%;
  }
  td {
    // border-bottom: 2px solid $gray60;
    padding: 0.6em 0em;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    width: 100%;
    position: relative;
  }
}

.tableBodyPivot {
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  padding-bottom: 5px;
  // border: 2px solid $gray60;
  // border-bottom-right-radius: 5px;
  // border-bottom-left-radius: 5px;
  // border-top: none;
  margin-top: -13px;
  padding-top: 5px;
}

.tableAnswerTypeSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 3px;
}

.tableAnswerTypeBig {
  text-align: center;
}

.tableAnswerTypeTiny {
  font-size: 0.7em;
  cursor: pointer;
  padding: 2px 5px;
  color: #5b8ca388;
  border-radius: 4px;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: $sapphire20;
  }
}

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userFieldsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  font-family: $poppins;
  padding: 15px 0px 10px;
}

.byUserFields {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;

  padding-left: 20px;
  font-family: $poppins;

  .by {
    color: $gray100;
    font-weight: 500;
    font-size: 0.9em;
  }

  .addField {
    cursor: pointer;
    font-size: 0.9em;
    transition: all 0.3s;
    color: $seafoam60;
    margin-left: 5px;
    padding: 3px 10px;
    border-radius: 1.5em;
    transition: all 0.5s;

    &:hover {
      color: $gray110;
      background-color: $gray30;
    }
  }
}

.constraints {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.constraint {
  display: flex;
}

.constraintName {
  display: flex;
  background-color: $blue100;
  width: fit-content;
  border-top-left-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
  padding: 0.4em;
  padding-right: 0.5em;
  padding-left: 1em;
  font-size: 0.8em;
  color: $white;
  font-family: $poppins;
  font-weight: 600;
  cursor: default;
}

.delete {
  cursor: pointer;
  height: 30px;
  padding-left: 0em;
  padding-right: 0.2em;
  border-top-right-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
  transition: all 0.2s;
  color: $gray110;
  background-color: $blue100;
  display: flex;
  align-items: center;
}
.delete:hover {
  background-color: $gray60;
}

.customFieldHolder2:hover {
  .edit {
    opacity: 1;
  }
  .customField {
    -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
    filter: blur(1px);
  }
}
.customFieldHolder2 {
  transition: all 0.3s;
  position: relative;
  display: flex;
  height: 100%;
}

.addConstraints {
  min-width: 50%;
  text-align: center;
  border-radius: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;

  .newField {
    padding: 7px 20px;
    font-size: 0.9em;
    cursor: pointer;
  }
  .newField:hover {
    background-color: $gray20;
  }
}

.notAsRankingExplanation {
  color: $seafoam100;
  font-size: 0.8em;
  text-align: center;
}

.togglespread {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $gray60;
  // font-size: 0.8em;
  position: relative;
  width: fit-content;
  top: 0em;

  i {
    position: absolute;
    right: 8px;
    top: 11px;
    z-index: 130;
    pointer-events: none;
    color: $gray60;
  }
}

.select {
  color: $gray110;
  padding: 10px 10px;
  background-color: $white;
  font-size: 0.8em;
  border-radius: $radius8;
  width: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid $gray60;
  gap: 5px;
  font-family: $poppins;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 25px;
}

.reportSettingsButton {
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: $radius8;
  background-color: $gray40;
  border: 2px solid $gray40;
  color: $gray110;
  padding: 2px 4px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.reportSettingsButton:hover {
  // color: ;
  background-color: white;
  // border: 2px solid transparent;
}

.reportSettingsButtonOpen {
  background-color: white;
}

.number {
  border-radius: $radius8;
  background-color: $info;
  color: $white;
  padding: 2px 8px;
}

.settings {
  display: flex;
  flex-direction: row;
  width: fit-content;
  background-color: $gray40;
  box-shadow: $shadow;
  border-radius: $radius8;
  height: fit-content;
  gap: 3px;
  align-items: center;
  font-size: 0.8em;
  z-index: 200;
}

.settingsPopout {
  position: absolute;
  box-shadow: $shadow6;
  border-radius: $radius2;
  background-color: $white;
  left: 0px;
  top: 8rem;
  width: 100%;
  max-height: 84vh;
  overflow: auto;
  z-index: 200;
}

.xicon {
  color: $gray110;
  font-size: 1em;
  cursor: pointer;
  padding: 0.3em 0.5em;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.xicon:hover {
  color: $black;
}

.itemA {
  color: $gray110;
  border-radius: $radius2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  transition: 0.3s all;
}

.activeItem {
  box-shadow: $shadow6;
}

.itemB {
  color: $gray110;
  border-radius: $radius2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  transition: 0.3s all;

  i {
    position: relative;
    display: block;
  }
}

.inputContainer {
  input {
    width: 50px;
  }
}

.customDropDown {
  width: 80%;
  border-radius: 1em;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 7px 7px;
  font-family: $poppins;
  position: absolute;
  background-color: $white;
  top: 0px;
  // right: 0px;

  .smallMetric {
    padding: 5px 0px;
    border-radius: 3px;
    &:hover {
      cursor: pointer;
      background-color: $gray20;
    }
  }
}

.smallMetric {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: $poppins;
  width: 155px;
  .icon {
    padding: 2px;
    background-color: $sapphire60;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: $white;
    height: 2em;
    width: 2em;
    flex-shrink: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
  }

  .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.2em;
    white-space: nowrap;

    .mTitle {
      font-weight: 400;
      color: $gray100;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }
  }

  .trash {
    transition: 0.3s all;
    cursor: pointer;
    &:hover {
      color: $red60;
    }
  }
}

.addContainer {
  width: 70%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  // border: 2px solid black;
}

.tableColumn {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  flex-direction: column;
  width: 100%;

  transition: 0.3s all;

  // background-color: $gray20;
  border-radius: 10px;
  padding: 5px 2px 5px 10px;
}

.surveySubtitle {
  color: $gray100;
  padding-left: 30px;
  letter-spacing: 1px;
  font-size: 0.8em;
}

// width: "100%",
// // backgroundColor: active ? "#E9E9E9" : "",
// display: "flex",
// flexDirection: 'column',
// gap: "5px",
// alignItems: "center",
// marginLeft: "20px",
// flexShrink: -1,

.tableColumnNameInput {
  // margin-left: -3px;
  // flex-grow: 1;
  // background-color: $gray20;
  // color: $gray110;
  // font-family: $poppins;
  // border: none;
  // outline: none;

  // &::placeholder {
  //   color: #b6b9b9;
  // }

  flex-grow: 1;

  letter-spacing: 1px;
  font-size: 0.8em;
  background-color: $white;
  text-wrap: wrap;
  color: $gray110;
  font-family: $poppins;
  border: none;
  outline: none;
  transition: 0.3s all;

  &::placeholder {
    color: #b6b9b9;
  }
  &:hover {
    background-color: $gray20;
  }
  &:focus {
    background-color: $gray20;
  }
}

.mergeContainer {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 30px;
  // gap: 4px;
  border-radius: 5px;
  overflow: hidden;
}

.mergeWith {
  color: $seafoam100;
  font-weight: 500;
  border-bottom: 2px solid $sapphire20;
  padding-left: 5px;
  // font-size: .9em;
}

.mergeableField {
  cursor: pointer;
  padding: 4px 2px;
  transition: 0.4s all;
  padding-left: 10px;

  letter-spacing: 1px;
  font-size: 0.7em;

  &:hover {
    background-color: $gray40;
  }
}

.includeOverallLabel {
  font-family: $poppins;
  color: $seafoam100;
  font-size: 0.7rem;

  display: flex;
  align-items: center;
  gap: 0.35em;
}

.overallLabelInput {
  flex-grow: 1;
  font-family: $poppins;
  font-size: 1em;
  font-weight: 500;
  padding: 0px;
  border: none;
  background-color: $gray20;

  color: $seafoam100;
  transition: 0.3s all;
  outline: none;

  &::placeholder {
    color: $seafoam100;
  }
}

.bar {
  padding: 5px 5px 5px 0px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: $gray110;
  font-family: $poppins;

  font-weight: 500;
  font-size: 0.85em;
}

.barLabelInput {
  // border-bottom: 1px solid $gray40;

  &:hover {
    background-color: $gray20;
  }
  &:focus {
    background-color: $gray20;
    // color: $gray100;
    text-transform: none;
    // border-bottom: 2px solid $sapphire20;
  }
}

.barLabel {
  padding-left: 3px;
  flex-grow: 1;
}

.grayed {
  color: $gray100;
}

.switchType {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-left: 1em;
  padding-top: 0.5em;
  color: $gray100;
  font-family: $poppins;
  color: $seafoam100;
  font-size: 0.9em;
  z-index: 130;

  .selectContainer {
    width: 200px;
  }
}

.sideBySideExample {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.7rem;
  font-family: $poppins;
  color: $gray110;
  // background-color: $gray20;

  padding: 5px 15px;
  border-radius: 5px;
  // box-shadow: $shadowLight;

  .last {
    color: $gray100;
  }
  i {
    color: #48a626;
  }
}

.barGrid {
  font-size: 10pt;
  color: $gray60;
  transition: 0.3s all;

  &:hover {
    color: $gray110;
  }
}

.borderShadowLabel {
  // border: 1px solid black;
  padding: 5px 10px;
  border-radius: 0.75em;
  box-shadow: $shadowLight;
  transition: 0.5s all;
}

.bucketSettings {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  padding-left: 15px;
  padding-top: 10px;
  gap: 5px;
}
