@import "assets/styles/globals.scss";


.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  // min-height: fit-content;
  flex-grow: 1;
  background-color: $white;
  // background-image: linear-gradient(to bottom right, $white, $dullBlue00);
  // border: 2px solid red;
  // overflow-y:auto;
  
}