@import "assets/styles/globals.scss";

.usingCustom {
  color: $sapphire60;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid $sapphire60;
}

.gradientSection {
  // padding: 20px 20px 10px 0px;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.toggle {
  display: flex;
  gap: 10px;
}

.exampleGradient {
  display: flex;
  // padding-left: 2px;
  gap: 4px;
}

.gradientColor {
  width: 10px;
  height: 10px;
  border-radius: 3px;
}

.startingColor {
  color: $sapphire60;
  display: flex;
  gap: 10px;
  align-items: center;
  animation: slideIn 0.5s;
}

.startGradient {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 5px;
}

.stopGradient {
  padding: 10px 0px 0px 15px;
  letter-spacing: 0.1em;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 50;
  color: $gray100;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  &:hover {
    color: $red10;
    cursor: pointer;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.gradientSettings {
  padding: 5px 0px;
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  width: 100%;
}

.gradientSetting {
  display: flex;
  gap: 5px;
  align-items: center;
  // border: 2px solid black;
}

.settingName {
  font-family: $poppins;
  font-weight: 50;
  font-size: 0.8em;
  color: $seafoam100;
}

.gradients {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: flex-start;
}

.gradientSegment {
  display: flex;
  gap: 20px;
  align-items: center;

  font-family: $poppins;
  color: $seafoam100;
  font-size: 0.85em;
}

.gradientColors {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.switch {
  color: $sapphire60;
  font-weight: 500;
}
