@import "assets/styles/globals.scss";

.numberbox {
  input {
    // min-width: 50px;
    // width: fit-content;
    border: none;
    border-radius: $radius2;
    background-color: $gray25;
    color: $gray110;
    outline: none;
    @include normal-text();
    font-size: .8rem;
    padding-left: .5rem;
    transition: .3s all;
    &:hover {
      background-color: $gray30;
    }
    &:focus {
      background-color: $gray60;
    }
  }
}
