@import "assets/styles/globals.scss";

.designMainContainer {
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    // overflow-y: hidden;
}

.saved {
    position: absolute;
    top: 90px;
    left: 322px;
    color: $gray60;
}

// Survey Design Component \/

.surveyDesignContainer {
    // border: 2px solid black;
    border-radius: 5px; 
    width: 63%; 
    // height: 100%;
    overflow-y: auto;
}

.sampleContainer {
    border: 2px solid #d8d9d9;
    border-radius: 5px;
    margin: 2px;
    padding: 15px;
    background-color: white;
}

.brandHeader {
    // border: 2px solid black;
    padding-bottom: 13px;
    display: flex;
    padding-top: 5px;
    padding: 5px 2px 13px;

    .logoCenter {
        margin: auto;
    }
}

.colorPickerContainer {
    display: flex;
    justify-content: center;
}

.progressContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0px 10px;
}

.fillProgress {
    width: 75%;
    background-color: #d8d9d9;
    border-radius: 30px;
    height: 4px;
    
}

.progressBar {
    // width: 40%;
    height: 4px;
    border-radius: 40px;
}

.progressReport {
    width: 25%;
    // padding-right: 30px;
    color: #c0c0c0;               
    font-style: $poppins;
    font-size: .8em;
    text-align: center;
    font-weight: bold;
}

// .descriptionContainer {
//     p {
//         font-style: $poppins;    
//         font-size: .8em;
//         color: #a8a8a8;              
//         font-weight: bold;
//     }
// }

.questionsDisplay {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.backnNextDiv {
    display: flex;
    height: 30px;
    margin-top: 30px;
    margin-bottom: 15px;
    padding-right: 15px;
    align-items: center;
    .spacer {
        // width: 74%;
        width: 59%;
    }
}

.customButton {
    height: 100%;
    button {
        font-family: $poppins;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;
        border: 2px solid;
        border-radius: 5px;
        padding: 0px 20px;
        transition: 0.5s all;
        font-weight: 600;
        font-size: 0.9em;
        text-align: center;
        width: 100%;
    }
}

.buttonboxblue {
    height: 100%;
    button {
      
      transition: 0.5s all;
      font-weight: 600;
      font-size: 0.9em;
      text-align: center;
    }
    button:hover {
      background-color: white;
      color: $blue100;
    }
  }

// .buttonsBox {
//     display: flex;
//     flex-direction: column;
//     // justify-content: center;
//     align-items: center;
//     gap: 8px;
// }
//                   For if you want to make the buttons on the top or bottom
// .buttonsWrapper {
//     display: flex;
// }



.pageCounter {
    font-family: $poppins;         
    font-size: .8em;
    font-weight: bold;
    color: #c0c0c0;               
    // border: 2px solid gray;
    display: flex;
    text-align: center;
    padding: 0px 20px;
}

.pillMDContainer {
    display: flex;
    justify-content: space-between;  
    padding: 8px 3px 4px;
    align-items: center;
    height: 41px;
    font-size: .8em; 
    i {
        padding-right: 10px;
    }
}

.emptySurvey {
    height: 400px;
    // width: 100%;
    border: 2px solid #d8d9d9;
    border-radius: 5px;
    margin: 2px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #c0c0c0;               
    font-style: $poppins;
    font-size: 1.3em;
    font-weight: bold;
}

// Survery Design Component ^

// Design Configurations Component \/

.emptyDesign {
    height: 250px;
    width: 35%;
    border: 2px solid #d8d9d9;
    border-radius: 5px;
    margin: 2px;
    // padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c0c0c0;               
    font-style: $poppins;
    font-size: 1em;
    font-weight: bold;
}

.underneath {
    font-size: .8em;
}


.designConfigContainer {
    // border: 2px solid black;
    // height: 500px;
    width: 35%;
    overflow-y: auto;
}

.menuItemContainer {
    color: #484848;
    border: 2px solid #d8d9d9;
    border-radius: 5px;
    padding: 0px 12px 12px;
    margin-bottom: 10px;

}

.menuItemHeading {
    // border: 2px solid black;
    // font-family: $poppins;
    font-weight: 600;
    font-size: .8em;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    i{
        padding-right: 10px;
        // height: 5px;
        // cursor: pointer;
    }
}

.settingHeading {
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 625;
    padding-bottom: 5px;
    letter-spacing: 1px;
}

.titleSettingInput {
    border: 2px solid #d8d9d9;
    border-radius: 5px;
    width: 100%;
    font-weight: 550;
    margin-bottom: 5px;
}

.logoEntry {
    display: flex;
    min-height: 28px;
    margin: 1px 0px 15px;
    font-size: .85em;
    flex-wrap: wrap;
    color: black;
    font-family: $montserrat;
    justify-content: space-between;
    letter-spacing: 1px;

    label{
        font-family: $poppins;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;
        border: 2px solid $blue100;
        background-color: $blue100;
        color: white;
        border-radius: 5px;
        // padding: 0px 20px;
        transition: 0.2s all;
        font-weight: 600;
        font-size: 0.9em;
        text-align: center;
        padding: 0px 20px;
        display: flex;
        align-items: center;
    }

    label:hover {
        background-color: white;
        color: $blue100;
        cursor: pointer;
    }

    .hidden {
        display: none;
    }
    
}

.alignmentContainer {
    border: 2px solid #d8d9d9;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    padding: 4px 6px;
    font-weight: 600;
    color: #a8a8a8;
    transition: all .5s;
}

.alignmentHeader {
    // border: 2px solid blue;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    .chosenAlgmnt{
        // border: 2px solid green;
        width: 100%;
    }
    .algmntToggleBtn {
        // border: 2px solid black;
        position: absolute;
        right: 2px;
    }
}


.algnmtStgsDvdr {
    height: 2px;
    border: 1px solid #d8d9d980;
    border-radius: 2px;
    margin: 3px 5px;
    // border-style: dashed;
}

.buttonStyleDisplay {
    margin: 7px 0px;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-evenly;
    height: 30px;
}

.progressConfigs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    // gap: 10px;
    // align-items: center;
    margin-bottom: 10px;
    font-style: $poppins;         
    font-size: .8em;
    color: #a8a8a8;             
    font-weight: bold;
    div {
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

}

.leftAligned {
    cursor: pointer;
    padding-left: 2px;
}

.centeredLayout {
    display: flex; 
    justify-content: space-around;
    padding-right: 22px;
    cursor: pointer;
}

.rightAligned {
    display: flex; 
    flex-direction: row-reverse;
    padding-right: 20px; 
    cursor: pointer;
}

.saveBackAndRedo {
    margin-left: 30px;
    padding-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.restore {
    margin-left: 138px;
    margin-top: 15px;
}

.disabledButton {
    button {
        background-color: $blue60;
        border-color: $blue60;
    }
    button:hover {
        background-color: $blue60;
        border-color: $blue60;
        cursor: default;
        color: white;
    }
  cursor: default;
}

.expandedPreview {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 30px;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 100;
    overflow-y: auto;

    i {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
}

.savedStatus {
    position: absolute;
    top: 102px;
    left: 350px;
    color: $gray60;
}