@import "assets/styles/globals.scss";

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconCatalog {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  justify-content: center;
  // max-width: 200px;

  max-height: 200px;
  overflow-y: auto;
  padding-bottom: 5px;

  .icon {
    padding: 2px;
    background-color: $sapphire60;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: $white;
    height: 2em;
    width: 2em;
    flex-shrink: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
  }

  .iconDark {
    color: $gray110;
    font-size: 1.5em;
    transition: 0.3s all;
  }
  .iconDark:hover {
    font-size: 1.8em;
    color: $blue100;
  }
}

.searchSurveys {
  border: 2px solid $gray40;
  border-radius: 5px;
  color: $gray110;
  font-size: 0.9em;
  padding: 8px 5px;
  font-weight: 400;
  font-family: $poppins;
  outline: none;
  transition: all 0.3s;
  width: 100%;
}
.searchSurveys:hover {
  border: 2px solid $gray60;
}
.searchSurveys:focus {
  border: 2px solid $gray60;
}
.searchSurveys::placeholder {
  color: $gray60;
}

.optionsBox {
  margin-top: 5px;
  width: 100%;
  border-radius: 1em;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 7px 0px;
  overflow-y: auto;

  .option {
    font-family: $poppins;
    font-size: 0.9em;
    padding: 0.5em 10px;
    cursor: pointer;
    color: $gray110;
    // border: 2px solid blue;
  }
  .option:hover {
    background-color: $blue20;
  }
}

.smallMetric {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: $poppins;

  .icon {
    padding: 2px;
    background-color: $sapphire60;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: $white;
    height: 2em;
    width: 2em;
    flex-shrink: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
  }

  .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.2em;

    .mTitle {
      font-weight: 400;
      color: $gray100;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }
  }
}

.minus {
  cursor: pointer;
  padding-left: 3px;
  padding-right: 5px;
  color: $gray110;
  // border: 1px solid gray;
}

.grip {
  color: $gray100;
}
.grip:hover {
  color: $gray110;
  cursor: grab;
}

.openMetricSetting {
  background-color: $white;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  .accordionicon {
    transform: rotate(180deg);
  }
}

.metricSettingToggle {
  cursor: pointer;
  color: $gray60;
  font-size: 1em;
  transition: 0.5s all;
}

.plusMetric {
  font-size: 0.9em;
  font-weight: 500;
  color: $sapphire60;
  background-color: $white;
  padding: 5px 10px;
  border: 2px solid $sapphire20;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
  transition: 0.3s all;
  display: flex;
  gap: 5px;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $sapphire60;
  }
}

.customDropDown {
  width: 100%;
  border-radius: 1em;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  // box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 7px 7px;
  box-shadow: $shadow;
  font-family: $poppins;
}

.smallMetric {
  padding: 5px 0px;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    background-color: $gray20;
  }
}

.customMetricBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(42, 98, 124, 0.6);
  backdrop-filter: blur(2px);
  z-index: 200;
}

.customMetricPopout {
  position: relative;
  background-color: $white;
  padding: 30px 30px;
  font-family: $poppins;
  max-height: 80%;
  max-width: 90%;
  // width: fit-content;
  border-radius: 1em;
  // display: flex;
  // gap: 30px;
}

.customMetricExit {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px 5px;
  font-size: 12pt;
  cursor: pointer;
  border: 2px solid white;
  background-color: white;
  border-radius: 1em;
}

.customMetricSettings {
  // border: 2px solid blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 200px;
}

.customMetricDisplay {
  // border: 2px solid brown;
  display: flex;
  align-items: center;
}

.spinIcon {
  font-size: 1.2em;
  color: $seafoam100;
  cursor: pointer;
  transition: 0.5s all;
}

.spin {
  transform: rotate(180deg);
}

.smallIcon {
  // font-size: 8pt;
  // padding: 2px;
  background-color: $sapphire60;
  // border-radius: .5em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  // height: 2em;
  // width: 2em;
  // font-size: 1em;

  height: 25px;
  width: 25px;
  flex-shrink: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
}

.metricSettingBorder {
  border-bottom: 2px solid $sapphire60;
  height: 2px;
  box-shadow: 0px 5px 3px 0px rgba(100, 100, 111, 0.2);
}

.bigMetric {
  display: flex;
  flex-direction: column;
  color: $black;

  // border: 2px solid brown;
  padding: 0px 10px;

  .MTitle {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
  }

  .Mmetric {
    text-align: center;
    font-size: 3em;
    font-weight: 700;
  }
}

.customizerQProjName {
  color: $gray100;
  font-size: 0.8em;
}

// .smallMetric {
//   display: flex;
//   gap: 4px;
//   align-items: center;
//   font-family: $poppins;

//   .icon {
//     font-size: 8pt;
//     // padding: 2px;
//     background-color: $sapphire60;
//     border-radius: 2px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: $white;
//     // height: 2em;
//     // width: 2em;
//     height: 15px;
//     width: 15px;
//     flex-shrink: 0;
//     box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
//   }

//   .text {
//     font-size: 5pt;

//     display: flex;
//     flex-direction: column;
//     // line-height: 1.2em;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;

//     .mTitle {
//       font-weight: 400;
//       color: $gray100;
//     }

//     .mmetric {
//       // white-space: nowrap;
//       // text-overflow: ellipsis;
//       // overflow: hidden;
//     }
//   }
// }

.dropDownListTitle {
  text-align: center;
  color: $sapphire60;
  font-weight: 500;
  border-bottom: 2px solid $sapphire20;
  // padding-bottom: px;
  width: 90%;
  align-self: center;
}

.forSurveys {
  font-size: 1.2em;
}
.addSurvey {
  position: relative;
  margin-top: 10px;
  padding-top: 5px;
}
.addMetric {
}

.cancelFor {
  position: absolute;
  cursor: pointer;
  top: -5px;
  left: 0;
  color: $gray100;

  transform: rotateX(180deg);

  transition: 0.3s all;

  &:hover {
    color: $sapphire60;
  }
}

.surveyOption {
  color: $gray110;

  padding: 5px 0px 5px 3px;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    background-color: $gray20;
  }
}

.included {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.for {
  color: $sapphire60;
  font-weight: 500;
}

.includedSurvey {
  display: flex;
  color: $gray110;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
}

.removeSurvey {
  padding-right: 2px;
  transition: 0.3s all;
  color: $gray110;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: $gray40;
    color: $black;
  }
}

.addToIncluded {
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;
  margin-left: 10px;
  color: $sapphire60;
  font-size: 0.9em;

  &:hover {
    color: $gray110;
  }
}

.alignmentBtn {
  padding: 5px;
  transition: 0.5s all;
  border-radius: 0.5em;
  font-size: 1.2em;
  color: $gray110;

  &:hover {
    background-color: $gray20;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
    cursor: pointer;
  }
}

.chosenAlignment {
  background-color: $gray20;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
}

.singleMetric {
  display: flex;
  flex-direction: column;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-radius: 16px;
}

.active {
  background-color: $gray20;
  box-shadow: $shadow3;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s all;
  overflow: hidden;
  padding: 5px;
  padding-right: 10px;

  border-radius: 16px;
}

.openHeader .metricSettingToggle {
  transform: rotate(270deg);
  color: $gray100;
}

.inactive:hover {
  background-color: $gray20;
}

.metricTitle {
  font-weight: 400;
  color: $gray100;
  font-family: $poppins;
  padding-left: 1em;
}

.singleSettings {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.globalSettingsHeader {
  font-size: 1.1em;
  padding-bottom: 4px;
  border-bottom: 2px solid $sapphire20;
  width: 80%;
  align-self: center;
  text-align: center;
  color: $seafoam60;
  font-weight: 500;
}

.microSetting {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.microSettingTitle {
  color: $sapphire60;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 5px;
}

.subHeader {
  font-size: 1.1em;
  padding-bottom: 4px;
  color: $seafoam60;
  font-weight: 500;
  text-wrap: nowrap;
}

.mockCurve {
  border-bottom: 2px solid $sapphire20;
  border-right: 2px solid $sapphire20;
  height: 10px;
  width: 10px;
  border-bottom-right-radius: 5px;
}

.delete {
  font-size: 1em;
  font-weight: 500;
  color: $red60;
  background-color: $white;
  padding: 5px 10px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
  transition: 0.3s all;
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  align-self: center;
  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $red60;
  }
}

.orderButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  box-shadow: $shadowLight;

  padding: 7px;
  border-radius: 16px;

  transition: 0.3s all;

  &:hover {
    cursor: pointer;
    box-shadow: $shadow4;
  }
}

.changeableIcon {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;

  cursor: pointer;
}

.metricProject {
  font-family: $poppins;
  color: $gray110;
  padding-left: 10px;

  width: fit-content;

  display: flex;
  gap: 10px;
  align-items: center;
}

.addDynamic {
  display: flex;
  flex-direction: column;
  // padding-bottom: 5px;
}

.searchFor {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px 0px;
}

.whatSearchingFor {
  color: $sapphire60;
  font-size: 0.85em;
  padding-left: 10px;
}

.search {
  width: 100%;
  border-radius: $radius2;
  background-color: $gray20;
  border: 2px solid $gray20;
  // border: none;
  font-family: $poppins;
  color: $gray110;
  font-size: 1em;
  outline: none;
  // padding: 8px 10px;
  padding: 2px 7px;
  overflow: none;
  transition: 0.3s all;

  &:hover {
    border: 2px solid $sapphire20;
    background-color: $white;
  }
  &:focus {
    border: 2px solid $sapphire20;
    background-color: $white;
  }
  &::placeholder {
    color: $gray100;
    font-size: 0.85em;
  }
}

.programOption {
  color: $gray110;

  padding: 5px 0px 5px 3px;
  border-radius: 3px;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  // flex-wrap: wrap;

  &:hover {
    cursor: pointer;
    background-color: $gray20;
  }
}

.indicator {
  padding: 2px 10px;
  font-size: 0.85em;
  border-radius: $radius4;
}

.programIndicator {
  background-color: $dullBlue00;
  color: $dullBlue120;
}

.tagIndicator {
  background-color: $orange20;
  color: $orange60;
}

@keyframes slideIn {
  0% {
    height: 0px;
    // opacity: 0;
  }

  100% {
    height: fit-content;
    // opacity: 1;
  }
}

.addContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  height: 0px;
  overflow: hidden;
  transition: 0.5s all;
}

.addProj {
  transition: 0.4s;
  padding: 2px 4px;
  color: $gray110;
  border-radius: 5px;
  font-size: 10pt;

  &:hover {
    background-color: $gray30;
    cursor: pointer;
  }
}

.forWhatSurveys {
  .removeSurvey {
    opacity: 0;
  }

  &:hover {
    .removeSurvey {
      opacity: 1;
    }
    .addContainer {
      height: 27px;
    }
  }
}

.addToSingleContainer {
  padding-top: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  // gap: 5px;

  .surveyOption:hover {
    background-color: $gray30;
  }

  .programOption:hover {
    background-color: $gray30;
  }

  .search {
    background-color: $white;
    border: 2px solid $white;
  }
}
