@import "assets/styles/globals.scss";
.tabPill {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap-reverse;
    // border: 3px solid $gray30;
    background-color: transparent;
    border-radius: $radius4;
    list-style: none;
    // box-shadow: $shadow5;
    transition: .3s all;
    // border: 2px solid black;
    // width: 100%;
    // height: 100%;
}
.tabBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  // padding: 5px 0px;
  // height: 100%;
  // border: 2px solid black;
}

.shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
