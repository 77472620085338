@import "assets/styles/globals.scss";

.page {
  //   position: absolute;
  background-color: rgba(255, 255, 255, 0.803);
  backdrop-filter: blur(5px);
  // backdrop-filter: brightness(60%);
  // backdrop-filter: contrast(40%);
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 110;
  overflow: auto;
  grid-row-start: 1;
  grid-column-start: 1;
  position: absolute;
}

.tableHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0em 2em;
}
.header {
  @include header-3-text;
  margin: 0em;
  padding: 0em;
  padding-left: 50px;
}

.description {
  @include description-text;
  padding-left: 80px;
  font-size: 15px;
}

.setting {
  width: 500px;
}

.settingSection {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 1em;
  padding-top: 3em;
  width: 100%;
  height: fit-content;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.close {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  color: $gray110;
  transition: .3s all;
  &:hover {
    color: $red60;
  }
  z-index: 110;

}