@import "assets/styles/globals.scss";

.selectContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select {
  // width: 100%;
  height: 2.7rem;
  border: 1px solid $white;
  border-radius: $radius2;
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $gray110;
  background-color: $white;
  i {
    color: $gray60;
  }
  align-items: center;
  transition: all 0.1s;
  cursor: pointer;
  box-shadow: $shadow6;

}

.label6 {
  padding: 0.17rem 0.2rem;
}
// .select:hover {
//   border: 2px solid $blue100;
//   cursor: pointer;
//   i {
//     color: $gray110;
//   }
// }

.clear {
  // position: absolute;
  right: 1.8em;
  top: 0;
  color: $gray100;
  font-size: 1.5em;
  z-index: 1;
}
.clear:hover {
  // border: 2px solid $blue100;
  cursor: pointer;
  i {
    color: $red60;
  }
}

.activeOption {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  i {
    font-size: 25px;
    color: $gray110;
  }
  font-family: $poppins;
  color: $gray110;
}

.dropdown {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  max-height: 400px;
  overflow-y: auto;
  // width: 100%;
  position: absolute;
  top: 4.3rem;
  background-color: white;
  border-radius: $radius2;
  z-index: 100000000000000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
}

.option {
  width: 100%;
  padding: 10px 20px;
  color: $gray110;
}
.option:hover {
  background-color: $blue20;
  cursor: pointer;
}

.optionActive {
  background-color: $blue100;
  width: 100%;
  padding: 10px 20px;
  color: $white;
  cursor: pointer;
}

.searchInput {
  padding: 4.5px 5px;
  // border: 2px solid black;
  height: 100%;
  width: 100%;
  font-family: $poppins;
  outline: none;
  border: none;
}

.searchContainer {
  width: 100%;
  height: 100%;
  border: 2px solid $gray30;
  border-radius: 5px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $gray110;
  i {
    color: $gray60;
  }
  align-items: center;
  transition: all 0.1s;
}
.searchContainer:hover {
  border: 2px solid $blue100;
  cursor: pointer;
  i {
    color: $gray110;
  }
}

.activeSearchOption {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 5px 5px;
  height: 100%;
  width: 330px;
  font-family: $poppins;
  // font-size: 13px;
  overflow-x: auto;
  i {
    font-size: 25px;
    color: $gray110;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.activeSearchOption::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.activeSearchOption {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chosenOption {
  display: flex;
  flex-direction: row;
  background-color: $orange20;
  border-radius: 5px;
  color: $orange100;
  padding: 0px 5px;
  gap: 3px;
  transition: all 0.5s;
  button:hover {
    color: black;
  }
}

.timePickerContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: -5px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.newOptionText {
  color: $gray110;
  // position: absolute;
  right: 10px;
  top: 5px;
  padding: 10px 20px;
  // text-align: center;
  // border-bottom: 2px solid $gray30;
}
.newOptionText:hover {
  cursor: pointer;
  background-color: $blue20;
}

.label {
  font-family: $poppins;
  font-weight: 600;
  color: $seafoam100;
  margin-left: 10px;
}

.topHeader {
  padding: 1em;
  border-bottom: 1px solid $gray60;

  .label2 {
    font-family: $poppins;
    color: $gray110;
    font-weight: 500;
    font-size: 0.8em;
    text-transform: none;
  }
}

.calendarContainer {
  display: flex;
  // padding: 1em 1em;
  gap: 7em;
  justify-content: center;
  position: relative;
}

.dateandtime {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1em;
}

.timePicker {
  // background-color: ;
  height: 45px;
  // width: 100%;
  font-family: $poppins;
  // color: $gray110;
}

.calendar {
  font-family: $poppins;
}