@import "assets/styles/globals.scss";

.box {
  font-family: $poppins;
  color: $gray110;
  min-width: 330px;
  background-color: $white;
  border-radius: 1em;
  box-shadow: $shadow;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  padding: 0.5em 1em;
  span {
    @include header-4-text;
    font-size: 1.2em;
  }

  div {
    @include header-2-text;
  }

  .yellow {
    color: $yellow100;
  }

  .red {
    color: $red60;
  }
}
.items {
  display: flex;
  flex-direction: column;
  background-color: $gray25;
  height: 100%;
  width: 100%;
  font-family: $poppins;
  overflow: auto;
  // gap: .5em;
}

.backArrow {
  //   font-size: 0.7em;
  i {
    color: $gray110;
    font-size: 1.5em;
    cursor: pointer;
  }
}

.top {
  display: flex;
  font-family: $poppins;
  color: $gray110;
  gap: 1em;
  align-items: flex-end;
  @include header-4-text;
  font-weight: 400;
  // padding: 1em;
  justify-content: space-between;
  background-color: $white;
  padding-right: 1em;
  // border: 2px solid black;
}

.tableContainer {
  width: 100%;
  height: 100%;
  padding: 1em;
  background-color: $gray20;
}


.audName {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 10px 0px;
  @include header-4-text;
  font-size: .9em;
  font-weight: 400;
  // border: 2px solid black;
  height: 100%;
}

.goback {
  display: flex;
  align-items: center;
  gap: .5em;
  padding: 2px 10px;
  margin: 5px 0px;
  margin-left: 1em;
  @include header-4-text;
  font-size: .9em;
  font-weight: 400;
  cursor: pointer;
  transition: .3s all;
  border-radius: $radius2;
  &:hover {
    background-color: $blue20;
  
  }
  // border: 2px solid black;
}

.preview {
  display: flex;
  justify-content: center;
  padding: 1em;
  padding-top: 2em;
}