@import "assets/styles/globals.scss";

.tableContainer {
  display: flex;
  height: fit-content;
  width: 100%;
  gap: 1em;
  justify-content: center;
  // flex-wrap: wrap;
}

.content {
  display: flex;
  gap: 1em;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.content2 {
  display: flex;
  gap: 1em;
  justify-content: center;
  width: 100%;
}
.tableHeader {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em 0em;
}

.tableTitle {
  @include header-3-text($gray110);
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    font-size: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
  background-color: $white;
}

.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;

  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

.search {
  width: 15em;
}

.searchBox {
  position: relative;
}

.items {
  position: absolute;
  left: 0;
  top: 2.7em;
  background-color: $white;
  border-radius: $radius4;
  box-shadow: $shadow;
  width: 500px;
  overflow: auto;
  z-index: 100;
  // border: 2px solid black;

  .item {
    padding: 0.5em 1em;
    color: $gray110;
    cursor: pointer;
    font-family: $poppins;
    display: flex;
    justify-content: space-between;
    // font-size: .9em;
    gap: 1em;
    // width: fit-content;
    .type {
      color: $blue100;
      font-size: 0.8em;
    }

    &:hover {
      background-color: $background;
    }
  }
}

.icons {
  color: $gray110;
  display: flex;
  flex-direction: column;
  font-size: 2em;
}

.nomessages {
  display: flex;

  gap: 1em;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.nomessageContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  // width: 100%;
  max-width: 500px;
  justify-content: center;
  align-items: flex-start;
}
.nomessagePage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  gap: 2em;
  font-family: $poppins;
  color: $gray110;
}

.description {
  @include description-text;
  max-width: 300px;
}

.header {
  width: 100%;
  margin-left: 50px;
  @include header-4-text;
  padding: 0em;
  letter-spacing: normal;
}

.messages {
  // border: 1px solid black;
  padding: 0em 1em;
  width: 100%;
  // max-width: 1000px;
}
.menu {
  background-color: $white;
  width: 100%;
  max-width: 400px;
  border-radius: $radius4;
  box-shadow: $shadowLight;
  margin: 1em;
  padding: 1em;
  gap: 1em;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.switch {
  display: flex;
  padding: 0em 1em;
}

.addreminder {
  border: 2px solid $gray60;
  margin-left: 1em;
  border-radius: $radius2;
  padding: 1px 5px;
  font-family: $poppins;
  color: $gray110;
  font-size: 0.9em;
  width: fit-content;
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    color: $white;
    background-color: $gray60;
  }
}
