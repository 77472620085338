@import "assets/styles/globals.scss";


.provider {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: $poppins;
    color: $gray110;
    padding: 1rem 1rem;
    border-bottom: 2px solid $gray60;
  }
  
  .linkpage {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    // border: 2px solid black;
  }
  
  .linkpagecontent {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // background-color: $white;
    
    // box-shadow: $shadow;
    // border-radius: $radius4;
  }
  .linkpagesection {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $white;
    padding: 1rem;
    box-shadow: $shadow6;
    border-radius: $radius2;
  }
  
  .accounts {
    border: 2px solid $gray60;
    border-radius: $radius4;
    width: 100%;
  
    .provider:last-child {
      border-bottom: none;
    }
  }

  .top {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $white;
    padding: 2rem 1rem;
    padding-bottom: 3rem;
    box-shadow: $shadow6;
    border-radius: $radius2;
  }

  .page1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: $white;
    box-shadow: $shadow;
    border-radius: $radius4;
  }
  
  .section {
    background-color: $white;
    border-radius: $radius2;
    box-shadow: $shadow6;
    padding: 1rem;
    width: 100%;
    // max-width: 700px;
    height: fit-content;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .option {
    border-radius: $radius2;
    border: 2px solid $gray60;
    padding: 1rem;
    transition: 0.3s all;
    cursor: pointer;
    padding-right: 4rem;
    // max-width: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &:hover {
      box-shadow: $shadow6;
      background-color: $gray30;
      border: 2px solid $gray30;
    }
  }
  
  .active2 {
    box-shadow: $shadow6;
    background-color: $gray30;
    border: 2px solid $gray30;
  }

  
  .header_4 {
    color: $black;
  }