@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
}

.explanation {
  font-size: 0.85em;
  color: $gray110;
  display: flex;
  flex-direction: column;
}

.parsedContent {
  font-size: .9em;
  background-color: $gray40;
  color: $black;
  border-radius: 5px;
  border: 2px solid $gray60;
  min-width: 100%;
  max-width: 100%;
  min-height: 2.5em;
  padding: 5px 20px;
  outline: none;
  font-family: $poppins;
  color: $gray110;
  cursor: text;
}

.entryBox {

}

.participant {
  color: $seafoam100;
}

.recipient {
  color: $seafoam100;
}

.answer {
  color: $sapphire100;
}

.attribute {
  color: $blue100;
}

.bracket {
  color: $sapphire100;
}


.textarea {
  background-color: $gray40;
  color: $black;
  border-radius: 5px;
  border: 2px solid $gray60;
  min-width: 100%;
  max-width: 100%;
  min-height: 2.5em;
  padding: 5px 20px;
  outline: none;
  // margin-top: 10px;
  font-family: $poppins;

  ::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
  :-ms-input-placeholder, /* Internet Explorer 10-11 */
  ::-ms-input-placeholder /* Microsoft Edge */ {
    color: $gray100;
    opacity: 1; /* Firefox */
  }

  &.disabled {
      color: $gray100
  }

  &:focus {
    background-color: $gray20;
    border: 2px solid $sapphire100;
  }
}



