@import "assets/styles/globals.scss";

.surveys {
  // display: flex;
  // gap: 2em;
  // flex-flow: row wrap;
  // align-items: center;
  // justify-content: center;

  // display: grid;
  // grid-template-columns: repeat(auto-fill, 300px);
  // row-gap: 2em;
  // column-gap: .5em;
  // grid-auto-rows: minmax(100px, auto);
  // border: 2px solid black;
  // justify-content: space-evenly;
  // justify-items: center;
  // align-content: space-evenly;
  // align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em 0em;
  min-height: 250px;
  align-items: center;
}

.add {
  box-shadow: $shadow;
  width: 300px;
  min-width: 300px;
  height: 15em;
  border-radius: $radius4;
  padding: 1em;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  color: $blue100;

  &:hover {
    background-color: $gray30;
    // .surveyTitle {
    //   font-size: 1.75em;
    // }

    // .surveyDescription {
    //   font-size: 0.8em;
    // }
  }
}

.more {
  display: flex;
  align-items: center;

  span {
    color: $blue100;
    font-family: $poppins;
    font-size: 0.8em;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
