@import "assets/styles/globals.scss";
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    opacity: 0;
    // display: none;
    // cursor: pointer;
  }

  // 80% {
  //   display: block;
  // }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    pointer-events: all;
    cursor: text;
  }
}

.container {
  @include animation("slide-left 1s 1");
  position: absolute;
  z-index: 100;
  bottom: 25px;
  left: 0px;
  width: fit-content;
//   max-width: 150px;
  text-overflow: inherit;
  white-space: nowrap;
  box-shadow: $shadow;
  background-color: $white;
  padding: 5px 10px;
  border-radius: $radius2;
}
