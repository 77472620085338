@import "assets/styles/globals.scss";

.page {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editImage {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  

  .link {
    color: $blue100;
    cursor: pointer;
    width: 300px;
    text-align: center;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.dashControls {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.orgDashControl {
  // align-self: flex-start;
}

.roleDashControls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashControl {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  background-color: $white;
  border-radius: $radius2;
  box-shadow: $shadow6;
  border: 1px solid $white;
  padding: .5rem 1rem;
  font-size: 0.9em;
  width: 100%;
  max-width: 400px;
  align-items: center;
}

.roleDashboardsTitles {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  .border {
    width: 90%;
    border: 1px solid $sapphire60;
  }
}

.label {
  font-family: $poppins;
  font-weight: 600;
  color: $sapphire60;
}

.role {
  display: flex;
  flex-direction: column;
  font-family: $poppins;
}

.roleDescription {
  color: $gray100;
  font-size: 0.75em;
  // padding-left: 10px;
}
