@import "assets/styles/globals.scss";
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-left) {
  0% {
    margin-right: -1000px;
  }
  100% {
    margin-right: 0px;
  }
}

@include keyframes(enter) {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {

  .modal__wrap {
    @include animation("enter .5s 1");
    box-shadow: $shadow;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    min-height: 100px;
    max-height: 100%;
    max-width: 100%;
    background-color: #fff;
    align-self: center;
    box-shadow: $shadow;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;
    transform: scale(0);
    z-index: 1000;
  }
  

}

@media (min-width: 768px) {

  .modal__wrap {
    @include animation("enter .5s 1");
    box-shadow: $shadow;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    min-height: 100px;
    max-height: 95%;
    max-width: 95%;
    background-color: #fff;
    align-self: center;
    box-shadow: $shadow;
    border-radius: $radius2;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;
    transform: scale(0);
    z-index: 1000;
  }
  

}

  .modal {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    overflow-x: hidden;
    backdrop-filter: blur(30px);
    pointer-events: auto;
    opacity: 1;
    transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;
  }

.dark {
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0.3);
}

.rightAlign {
  justify-content: flex-end;
}

.visible {
  //   transition: all 300ms ease-in-out;
}


.modal_fixed {
  @include animation("slide-left .2s 1");
  max-height: 100%;
  max-width: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal_grow {
  @include animation("grow .5s 1");
}

@include keyframes(grow) {
  0% {
    height: 0px;
  }
}

.visible .modal__wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonClose {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 0.2s all;
  color: $gray110;
  z-index: 100;
}
.buttonClose:hover {
  color: $red60;
  cursor: pointer;
}