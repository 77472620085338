@import "assets/styles/globals.scss";

.completedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-direction: column;
  
  border-radius: 5px;
  //   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  padding: 10px 10px;
  // height: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
  height: fit-content;
  width: 100%;
  min-width: 200px;
  max-width: 700px;
}

.textContainer {
  // display: flex;
  // justify-content: center;
  width: 100%;
  // border-radius: 1em;
}

.text {
  // font-family: $poppins;
  // color: $gray110;
  width: 100%;
  // a {
  //   color: $blue100;
  // }  
}

.insideButton {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backArrow {
  transform: rotateX(180deg);
  padding-top: 3px;
}