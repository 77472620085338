@import "assets/styles/globals.scss";

.page {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: $gray20;
}
.content {
  width: 100%;
  padding-top: 0.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 10rem;
  max-width: 800px;
}

.layout {
  max-width: 800px;
  width: 100%;
  padding: 2rem 2rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sortableItemContainer {
  display: flex;
  gap: 1rem;
  width: 100%;
  min-height: 3rem;
  position: relative;
}

.rowsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.save {
  display: flex;
  gap: 1rem;
  background-color: transparent;
  backdrop-filter: blur(5px);
  width: 100%;
  justify-content: center;
  // padding: 2rem;
  // position: sticky;
  bottom: 0;
}
