@import "assets/styles/globals.scss";

.page {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: $background;
  // border: 2px solid black;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1em;
  padding: 10px 1em;
  padding-bottom: 1px solid $gray60;
  background-color: $white;
  
  
  // transition: .75s all;
}

.welcome {
  flex: 1;
  font-family: $poppins;
  font-size: 1.5em;
  color: $gray110;
  font-weight: 400;
  white-space: nowrap;
  padding-bottom: 10px;
}

.whosDash {
  flex: 2 1;
  // min-width: 400px;
}

.topButtons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.topBtn {
  color: $white;
  border-radius: 1em;
  cursor: pointer;
  font-weight: 700;
  padding: 7px 15px;
  transition: 0.3s all;
  white-space: nowrap;
  font-size: 0.9em;
  text-align: center;
  font-family: $poppins;
  box-shadow: $shadow;
}

.blueBtn {
  background-color: $sapphire100;
}
.blueBtn:hover {
  color: $sapphire100;
  background-color: $white;
}

.greenBtn {
  background-color: $green100;
}
.greenBtn:hover {
  color: $green100;
  background-color: $white;
}

.redBtn {
  background-color: $red60;
}
.redBtn:hover {
  color: $red60;
  background-color: $white;
}

.gridContainer {
  height: 100%;
  background-color: $background;
  .gridBox {
    // max-width: 80;
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 1em;
  }
}

.noWidgets {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: $poppins;
  position: relative;
}

.blankDropTarget {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 1em;
  cursor: grabbing;
  background-color: #e0dcdcbb;
  backdrop-filter: blur(1px);
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px) rotate(-1deg);
  }
  20% {
    transform: translateX(5px) rotate(1deg);
  }
  30% {
    transform: translateX(-5px) rotate(-1deg);
  }
  40% {
    transform: translateX(5px) rotate(1deg);
  }
  50% {
    transform: translateX(-5px) rotate(-1deg);
  }
  60% {
    transform: translateX(5px) rotate(1deg);
  }
  70% {
    transform: translateX(-5px) rotate(-1deg);
  }
  80% {
    transform: translateX(5px) rotate(1deg);
  }
  90% {
    transform: translateX(-5px) rotate(-1deg);
  }
  100% {
    transform: translateX(0);
  }
}

.widgetHolder {
  height: 100%;
  position: relative;
}

.earthquake {
  animation: shake 0.6s;

  .delete {
    visibility: visible;
  }
}

@keyframes shrink-grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.setInStone {
  animation: shrink-grow 0.4s;
}

.delete {
  visibility: hidden;
  position: absolute;
  top: 30px;
  right: 7px;
  cursor: pointer;
}

.trash {
  color: $gray100;
  opacity: 1;
}

.trashFill {
  color: $black;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.2s all;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}

.settings {
  position: absolute;
  top: 7px;
  right: 7px;
  visibility: visible;
  cursor: pointer;
  z-index: 200;
  color: $blue100;
  font-size: 1em;
  background-color: $white;
}

.gearIcon {
  opacity: 1;
}

.gearIconFill {
  position: absolute;
  top: 0;
  i {
    opacity: 0;
    transition: 0.2s all;
  }
}
.gearIconFill:hover {
  i {
    opacity: 1;
  }
}

.pickDifferentUser {
  display: flex;
  justify-content: center;
}


.pageContent {
  flex-grow: 1;
  padding: 1em;
  transition: all 1s;
  background-color: $background;
}

.pageContentEdit {
  flex-grow: 1;
  padding: 1em;
  transition: all 1s;
  background-color: $background;
}

.changeDash {
  height: 100%;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}