@import "assets/styles/globals.scss";

.toggleOptions {
  display: flex;
  flex-direction: column;
  // padding-left: 1.5em;  
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 3px 0px;
}

.numberSetting {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  input {
    color: $gray100;
    width: 60px;
  }
}