@import "assets/styles/globals.scss";

.icon {
  transition: all 0.3s;
  color: $gray110;
  cursor: pointer;
  // z-index: 100;
  position: relative;
}
.icon:hover {
  // // padding-right: 0.3em;
  // color: $blue100;
  .tooltip {
    display: flex;
  }
}

.tooltip {
  position: fixed;
  z-index: 100;
  box-shadow: $shadow;
  font-family: $poppins;
  font-size: 0.8em;
  color: $gray110;
  display: none;
  // right: -6rem;
  // top: 1.5rem;
  // background-color: $white;
  border-radius: $radius2;
  max-width: 300px;
  min-width: 100px;
  width: fit-content;
  padding: 1em;
  // white-space: nowrap;
  pointer-events: none;
  text-align: left;
  background-color: $background;
}

.icon.blue {
  color: $blue100;
}
.icon.red {
  color: $red60;
}
.icon.yellow {
  color: $yellow100;
}
.icon.orange {
  color: $orange100;
}
.icon.sapphire {
  color: $sapphire100;
}
.icon.seafoam {
  color: $seafoam100;
}
.icon.info {
  color: $info;
}
.icon.white {
  color: $white;
}
.icon.green {
  color: $green100;
}


.spin {
  animation: spin 2s linear infinite; /* Spins every 2 seconds */
}

/* Keyframes for spinning animation */
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}