@import "assets/styles/globals.scss";

.container {
  width: 100%;
  border: 1px solid $white;
  background-color: $white;
  min-height: 2.6rem;
  border-radius: $radius2;
  box-shadow: $shadow6;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  font-family: $poppins;
  overflow: hidden;
}

.label6 {
  padding: 0rem .1rem;
}

.editBox {
  min-height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $white;
  background-color: $white;
  min-width: 2.8rem;
  border-radius: $radius2;
  box-shadow: $shadow6;
  cursor: pointer;
  transition: 0.3s all;
  

  &:hover {
    border: 1px solid $gray110;
    background-color: $gray25;
  }
  i {
    font-size: 1.2rem;
  }
 
}


.editBoxDelete {
  min-height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $white;
  background-color: $white;
  min-width: 2.8rem;
  border-radius: $radius2;
  box-shadow: $shadow6;
  cursor: pointer;
  transition: 0.3s all;
  
  

  &:hover {
    border: 1px solid $red60;
    background-color: $red10;
  }
  i {
    font-size: 1.2rem;
  }
 
}
