@import "assets/styles/globals.scss";


.pageContent {
  // margin: .5em;
  background-color: $white;
  flex-grow: 1;
  // border-radius: 1em;
  // border-top-left-radius: $radius4;
  // border-bottom-left-radius: $radius4;
  padding: 1em;
  // overflow: auto;
  transition: all 1s;
  // border: 2px solid black;
}

.smoothGray {
  transition: all .75s;
  background-color: $gray30;
}