@import "assets/styles/globals.scss";

.page {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: 80vh;
}


.section {
  width: 100%;
  max-width: 700px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}
