@import "assets/styles/globals.scss";

.textEntry {
  width: 100%;
  border-radius: $radius2;
  border: 2px solid $gray60;
  font-family: $poppins;
  color: $black;
  font-size: 0.8rem;
  padding: 0.5rem;
  outline: none;
  transition: 0.3s all;

  &:hover:not(:focus) {
    border: 2px solid $gray100;
  }
}