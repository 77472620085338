@import "assets/styles/globals.scss";

.calculation {
  border-radius: $radius2;
  background: $white;
  box-shadow: $shadow6;
  padding: 10px 15px;
  width: 100%;
  max-width: 450px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: 0.3s all;
  justify-content: space-between;
  // overflow: hidden;

  &:hover {
    box-shadow: $shadow;
  }
}

.desc {
  color: $gray100;
  font-size: 0.8rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.trash {
  color: $black;
  transition: 0.3s all;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    color: $red60;
  }
}

.edit {
  font-size: 0.85rem;
  color: $sapphire80;
  transition: 0.3s all;
  width: 10%;
  &:hover {
    color: $sapphire60;
  }
}

.error {
  font-size: 0.9rem;
  color: $red60;
}
