@import "assets/styles/globals.scss";

.descriptionContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.Qnum {
  font-family: $poppins;
  padding-top: 4px;
  width: fit-content;
  color: $gray100;
}
