.react-calendar {
  width: 280px;
  max-width: 100%;
  background: white;
  /* border: 2px solid #E9E9E9; */
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.125em;
  color: #616565;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 40px;
  /* margin-bottom: 1em; */

}
.react-calendar__navigation button {
  min-width: 40px;
  border-radius: 5px;
  background: none;
  color: #616565;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  color: #616565;

}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
  color: #616565;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: bold;
  font-size: 0.75em;
  color: #616565;
  font-family: 'Poppins', sans-serif;

}
.react-calendar__month-view__weekdays__weekday {
  padding: .5em;
  color: #616565;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  color: #616565;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  color: #616565;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 3px;
  background: none;
  text-align: center;
  /* line-height: 15px; */
  border-radius: 50%;
  color: #616565;
  font-family: 'Poppins', sans-serif;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #E9E9E9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.react-calendar__tile--now {
  background: #edb57e;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #f4e3c2;
}
.react-calendar__tile--hasActive {
  background: #15bcc7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #15bcc7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.react-calendar__tile--active {
  background: #15bcc7;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #15bcc7;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
