@import "assets/styles/globals.scss";

.surveys {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  padding: 1em 1.5em;
  min-height: 250px;
  height: fit-content;
  // overflow: auto;
  width: 100%;
  align-items: center;
  padding-bottom: 4rem;
}

.top {
  width: 100%;
  max-width: 1000px;
  display: flex;
  gap: 1rem;
  // position: sticky;
  // top: 1rem;
  // z-index: 1;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.add {
  box-shadow: $shadow;
  width: 300px;
  min-width: 300px;
  height: 15em;
  border-radius: $radius4;
  padding: 1em;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  color: $blue100;

  &:hover {
    background-color: $gray30;
  }
}

.more {
  display: flex;
  align-items: center;

  span {
    color: $blue100;
    font-family: $poppins;
    font-size: 0.8em;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
