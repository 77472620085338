@import 'assets/styles/globals.scss';

.smallCheckbox {
  height: 14px;
  width: 14px;
  background-color: #eee;
  border-radius: 4px;
  transition: 0.3s all;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: #ccc;
  }

  input {
    display: none;
  }
}

.checked {
  background-color: $blue60;

  &:hover {
    background-color: $blue60;
  }
}