@import "assets/styles/globals.scss";

// .page {
//   border: 2px solid black;
//   overflow: auto;
//   height: 100%;
// }

.profileImage {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  font-weight: 500;
  font-size: 0.8em;
  // border: 2px solid $gray110;
  text-align: center;
  // box-shadow: $shadow;
  span {
    color: $white;
    // border: 2px solid black;
    text-align: center;
    letter-spacing: 1px;
  }
}

.header {
  @include header-4-text;
  font-weight: 500;
  padding-left: 2em;
  padding-top: 15px;
  display: flex;
  align-items: center;
  gap: 1em;
  height: 100%;
  // margin-bottom: -5px;
  max-height: 50px;
  font-size: 1.2em;
  margin: 0px;

  span {
    font-weight: 400;
    font-size: 0.7em;
  }
}

.table {
  height: 10px;
  display: flex;
  flex: 1;
  min-height: 500px;
  // border: 2px solid black;
}

.goback {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 2px 10px;

  @include header-4-text;
  font-size: 0.6em;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: $radius2;
  &:hover {
    background-color: $blue20;
  }
  // border: 2px solid black;
}

.topheader {
  @include header-4-text;
  font-weight: 500;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  font-size: 1.2em;
  white-space: nowrap;
  box-shadow: $shadow6;
  background-color: $white;
  margin: 0px;
  height: 100%;
  max-height: 80px;

  span {
    font-weight: 400;
    font-size: 0.7em;
  }
  .active {
    color: $blue100;
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}

.createModal {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  flex-wrap: wrap;
  width: 100%;
  gap: 2em;
  height: fit-content;
}

.fromScratch {
  height: 280px;
  width: 250px;
  border-radius: $radius4;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: center;
  color: $gray110;
  font-size: 0.9em;
  font-family: $poppins;
  font-weight: 600;
  background-color: $white;
  box-shadow: $shadow;
  span {
    font-size: 0.7em;
    font-weight: 400;
    color: $gray100;
  }
  div {
    display: flex;
    flex-direction: column;
    height: 50px;
    gap: 5px;
  }
}
.fromScratch:hover {
  background-color: $gray40;
  // font-size: 1.6em;
}

.icon {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 75px;
  font-size: 4em;
  color: $blue100;
}

.icon2 {

  font-size: 2rem;
  color: $blue100;
}

.reportpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.report {
  border-radius: $radius2;
  background-color: $white;
  box-shadow: $shadow;
  padding: 1em;
  width: 400px;
  cursor: pointer;
  transition: 0.3s all;
  color: $gray110;
  font-family: $poppins;

  &:hover {
    background-color: $gray25;
  }
}

.contacttabelcontainer {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  bottom: 0;
  background-color: $white;
  box-shadow: $shadow;
  width: 100%;
  padding: 30px 2em;
  height: 100%;
  max-height: 80px;
}
