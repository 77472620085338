@import "assets/styles/globals.scss";

.name {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  padding: 0px 8px 4px 0px;
}

.desc {
  color: #b7b7b7;
  // gap: 5px;
  font-size: 0.75rem;
}

.ex {
  font-size: 0.7rem;
  color: $seafoam60;
  padding-left: 5px;
}
