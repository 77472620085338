@import "assets/styles/globals.scss";

.pathContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-bottom: 1rem;
}

.circle {
  min-height: 40px;
  min-width: 40px;
  border: 2px solid $gray60;
  border-radius: 50%;
}

.list {
  margin: 0;
  padding-left: 1.5rem;
}

.dot {
  // position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $blue100;
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 100;
  align-items: center;
  border: 3px solid transparent;
  &:hover {
    .name {
      @include link;
    }
  }

  .name {
    font-family: $poppins;
    // text-align: right;
    font-size: 0.7em;
    // margin-left: 20px;
    margin-top: 40px;
    white-space: nowrap;
    color: $gray110;
    display: flex;
    gap: 3px;

    // border: 2px solid black;
  }
}

.hollow {
  background-color: $gray20;
  border: 3px solid $blue100;
}

.hollowLine {
  position: absolute;
  border-top: solid 4px $gray20;
  height: 1px;
  max-height: 1px;
  // margin: 0px -1px ;
  transform-origin: 0% 0%;
}

.line {
  position: absolute;
  border-top: solid 4px $blue100;
  height: 1px;
  max-height: 1px;
  // margin: 0px -1px ;
  transform-origin: 0% 0%;
}

//   .line {
//     position: absolute;
//     --c:10px; /* control the size */

//     // width: 50px;
//     // margin-top:-100px;
//     display:inline-block;
//     box-sizing:border-box;
//     border: solid var(--c) transparent;
//     border-radius:50%;
//     border-bottom-color:$blue100;
//     background:
//       radial-gradient(farthest-side,$blue100 98%,transparent) left  15% bottom 14%,
//       radial-gradient(farthest-side,$blue100 98%,transparent) right 15% bottom 14%;
//     background-size:var(--c) var(--c);
//     background-origin:border-box;
//     background-repeat:no-repeat;
//   }

//   /* maintain the square ratio */
//   .line::before {
//     content:"";
//     display:block;
//     padding-top:100%;
//   }
