@import "assets/styles/globals.scss";

.textarea {
  background-color: $gray40;
  color: $black;
  border-radius: 5px;
  border: 2px solid $gray60;
  width: 100%;
  // height: 2.5em;
  min-height: 30px;
  padding: 5px 20px;
  outline: none;
  // margin-top: 10px;
  font-family: $poppins;

  &.disabled {
    color: $gray100;
  }

  &:focus {
    background-color: $gray20;
    border: 2px solid $sapphire100;
  }
}

.textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray60;
  opacity: 1; /* Firefox */
}

.textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray60;
}

.textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray60;
}
