@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  width: fit-content;
  gap: 5px;
  // font-size: .8em;
}

.border {
  border: 2px solid $sapphire100;
  border-radius: $radius2;
}

.title {
  color: $gray110;
  // margin-bottom: -4px;
  font-family: $poppins;
  // height: 100%;
  // border: 2px solid purple;
  width: 100%;
  text-align: center;
}

.legend {
  display: flex;
  gap: .5rem;
  // border: 2px solid purple;
  // padding-top: 7px;
}

.row {
  max-width: 85%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.column {
  flex-direction: column;
  overflow-y: auto;
  max-height: 90%;
}

.item {
  display: flex;
  flex-shrink: 0;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.color {
}

.text {
  font-family: $poppins;
  font-size: 0.9rem;
  color: $gray110;
  // flex-shrink: 0;  // Maybe for Left or Right position
  flex-wrap: wrap;
}
