@import "assets/styles/globals.scss";

.settingContainer {
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: 350px;
  justify-content: space-between;
}

.setting {
  font-family: $poppins;
  font-size: 0.8em;
  font-weight: 400;
  color: $black;
}

.thick {
  font-weight: 700;
  font-family: $poppins;
  font-size: 0.9em;
}

.thickContainer {
  margin-left: 0em;
}

.check {
  // box-shadow: $shadow;
  // border-radius: 5px;
  background-color: $white;
  border: 1px solid $gray100;
}
