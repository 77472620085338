@import "assets/styles/globals.scss";

.accordian {
  @include col;
  background: $gray25;
  height: 100%;
  overflow: auto;
}

.dialog {
  padding: 1em;
  margin: -1em;
  margin-top: 1em;
  border-bottom-right-radius: $radius4;
  border-bottom-left-radius: $radius4;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  font-family: $poppins;
  color: $gray110;
  font-weight: 400;
  background-color: $white;
}

.body {
  height: fit-content;
  padding: 1rem;
  border-top: 2px solid $gray60;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dialogButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 0.6em;
  flex-wrap: wrap;
}

.settingHorizontal {
  display: flex;
  align-items: center;
}
.colorContainer {
  border: 2px solid $gray60;
  width: fit-content;
  border-radius: 0.4em;
}

.settingsContainer {
  z-index: 107;
  top: 0;
  width: 100%;
}

// change Q type

.questionTypesContainer {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.questionType {
  display: flex;
  gap: 25px;
  align-items: center;
  border-radius: 10px;
  border: 2px solid $gray30;
  transition: 0.5s all;
  cursor: pointer;
  padding: 5px 10px;
  // border: 2px solid black;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    border: 2px solid white;
    .select {
      color: $gray110;
    }
  }
}

.type {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 5px;

  i {
    color: $sapphire100;
  }
}

.active {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border: 2px solid white;
  .select {
    color: $gray110;
  }
}

.select {
  color: $gray100;
  transition: 0.5s all ease-in;
}

.optionsAnchor {
  position: relative;
  width: 100%;
}

.options {
  position: absolute;
  background-color: $white;
  z-index: 10;
  width: 100%;
  top: 5px;

  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
  font-family: $poppins;

  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.option {
  padding: 8px 15px 8px 15px;
  cursor: pointer;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 15px;

  &:hover {
    background-color: $gray40;
  }

  i {
    color: $sapphire100;
    // font-size: 14pt;
  }
}
