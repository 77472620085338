@import "assets/styles/globals.scss";

.textarea {
  // background-color: $gray40;
  color: $gray110;
  border: 2px solid white;
  border-radius: 5px;
  // border: 2px solid $gray60;
  width: 100%;
  // min-height: 2.5em;
  // padding: 5px 20px;
  outline: none;
  // margin-top: 10px;
  font-family: $poppins;
  // transition: .5s all;
  padding: 5px 0px;
  resize: none;

  cursor: pointer;
  &:disabled {
    background-color: white;
    // border: 2px solid $sapphire100;
    // padding: 0px 10px;
  }
  &:focus {
    background-color: white;
    // border: 2px solid $sapphire100;
    // padding: 0px 10px;
    
    cursor:auto;
  }
  
  
}