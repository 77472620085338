@import "assets/styles/globals.scss";

.simpleFormContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.label {
  color: #616565;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: .8em;
  text-align: left;
  margin-bottom: 4px;
}

.simpleFormDiv {
  width: 100%;
  border-radius: 5px;
  border: 2px solid $gray30;
  color: $gray110;
  font-family: $poppins;
  padding: 8px 10px;
}

.saveBtn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}