@import "assets/styles/globals.scss";

.progBar {
  border-radius: 1em;
  transition: 0.5s all;
  overflow: hidden;
}

.progBarInactive {
  padding: 0.5em 0;
}

.progBarInactive:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.progBarActive {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  padding-top: 15px;
}

.activeBarHolder {
  padding-bottom: 15px;
}
