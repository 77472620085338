@import "assets/styles/globals.scss";

.imageContainer {
    border-radius: 50%;
    // border: 2px solid $gray60;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F9FBFC;

    // position: relative;

    // pointer-events: /;
    img {
        // height: 100%;
        // width: 100%;
    }
    input {
        cursor: pointer;
    }
}

.container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: $shadow;
}
.edit {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
    transition: .3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    button {
        opacity: 0;
    }

    &:hover {
        backdrop-filter: blur(10px);
        button {
            opacity: 1;
        }
    }
}

.disable {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
    transition: .3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    // background-color: $blue100;
    // pointer-events: none;
}



.alt {
    background-color: $white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: .9em;
    font-family: $poppins;
    color: $sapphire100;
    // border: 2px solid black;
}