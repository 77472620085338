@import "assets/styles/globals.scss";

.bannerBackground {
  position: fixed;
  height: 0px;
  width: 100vw;
  z-index: 1001;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  // backdrop-filter: blur(1px);
}

@include keyframes(banner) {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  85% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

.banner {
  position: absolute;
  top: 20;
  @include row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  width: 80%;
  background-color: $red10;
  border-radius: 1rem;
  padding: 15px 2rem;
  box-shadow: $shadow;
  @include animation(banner 0.5s);
  font-family: $poppins;
  font-size: 1rem;
  overflow: visible;
  cursor: pointer;
}

@include keyframes(closeUp) {
  0% {
    transform: translateY(0);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.closeUp {
  @include animation(closeUp 0.5s forwards);
}

.close {
  position: absolute;
  cursor: pointer;
  top: 0.25rem;
  right: 0.5rem;
  @include transition(0.3s all);
  color: $black;
  font-size: 0.85rem;

  &:hover {
    color: $gray110;
  }
}

.cone {
  color: $red100;
  font-size: 1.5rem;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: $poppins;
  color: $gray110;
  padding: 50px;
  background-color: $white;
  overflow: auto;
}

.tokenContent {
  background-color: $blue5;
  border: 2px solid $blue100;
  padding: 2rem;
  max-width: 650px;
  border-radius: $radius2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .text_2 {
    text-align: center;
  }
}

.something {
  padding: 15px 20px;
  border-radius: 0.5rem;
  background-color: $red10;
  color: $black;
  @include row;
  gap: 20px;
  justify-content: center;
  width: fit-content;

  i {
    font-size: 2rem;
    color: $red60;
  }
}

.error {
  color: $gray100;
  font-weight: 500;
}
.errorCode {
  font-weight: 500;
  color: $red60;
}

.message {
  font-size: 0.7em;
  display: flex;
  flex-wrap: wrap;
}

.email {
  color: $blue100;
  cursor: pointer;
}

.errorForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 80%;
  padding-top: 40px;
}

.formHeader {
  color: $gray100;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border-radius: 1em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

  .formSectionTitle {
    color: $gray100;
    font-weight: 500;
  }

  .formItem {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 10px;
  }
}

.nameAndIcon {
  display: flex;
  align-items: center;
  gap: 7px;
}

.icon {
  color: $gray60;
}

.subFormTitle {
  font-size: 0.9em;
  color: $gray100;
  padding-top: 10px;
  padding-left: 10px;
}

.subForm {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 10px;
}

.textLine {
  font-size: 0.9em;

  &:hover {
    border: 2px solid $gray60;
  }
  &:focus {
    background-color: $white;
    border: 2px solid $gray30;
  }
}

.textLineTheme {
  padding-left: 3px;
  font-size: 0.9em;
  transition: 0.5s all;

  &:hover {
    background-color: $gray20;
  }
  &:focus {
    border-bottom: 2px solid $gray30;
    background-color: $white;
  }
}

.textArea {
  background-color: $white;
  border: 2px solid $gray30;
  font-family: $poppins;
  padding: 5px 8px;
  min-height: 7em;
  color: $gray110;
  transition: 0.5s all;

  &:hover {
    border: 2px solid $gray60;
  }

  &:focus {
    background-color: $white;
    border: 2px solid $gray30;
  }
}

.smallerTextArea {
  min-height: 5em;
}

.errorSubmitting {
  color: $gray100;
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
}

.thankYou {
  // color: $gray100;
}
