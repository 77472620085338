@import "assets/styles/globals.scss";

.addy {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addy2 {
  display: flex;
  gap: 1rem;
}