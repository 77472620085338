@import "assets/styles/globals.scss";

.page {
  background-color: $background;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  overflow: auto;
  position: relative;
}

.top {
  background-color: $white;
  border-bottom: 1px solid $gray60;
  padding: 1rem;
}

.noRoleSelected {
  position: relative;
  font-family: $poppins;
  font-style: italic;
  color: $gray110;
  padding: 2rem;
  // height: 800px;
}

.textBox {
  display: flex;
  justify-content: space-between;
  gap: 7px;
  width: 100%;
}

.accordianContainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 25px;
  transition: all 0.5s;
  width: 100%;
}

.splitContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1em;
  padding-left: 2em;
  margin-bottom: 10rem;
  flex-grow: 1;
}

.item {
  padding: 5px 40px 5px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 250px;
}

.buttonsDiv {
  display: flex;
  height: 35px;
  width: 100%;
  gap: 2rem;
  position: sticky;
  bottom: 1rem;
  // top: 1rem;
  justify-content: flex-end;
}

@keyframes saved {
  from {
    color: $blue100;
  }
  to {
    color: $gray60;
  }
}

.saved {
  // animation-name: saved;
  // animation-duration: 5s;
  display: flex;
  gap: 0.5rem;
}

.savedStatus {
  position: absolute;
  top: 15em;
  left: 17.5em;
  color: $gray60;
}
.pageContent {
  max-width: 800px;
  background-color: $white;
  box-shadow: $shadow6;
  border-radius: $radius2;
  padding: 2rem;
  // border: 2px solid black;
}

.header {
  @include header-4-text($black);
  font-size: 1.2em;
  padding-left: 1em;
}

.header2 {
  @include header-3-text($black);
  margin: 0;
  font-weight: 400;
}
.description2 {
  @include description-text($gray110);
}

.permissions {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.allPermissions {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-width: 800px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.setting {
  .header_5 {
    width: 100px;
  }
}

.saving {
  font-size: 0.9rem;
  color: $gray60;
  align-self: flex-start;
}

.error {
  color: $red60;
}
