@import "assets/styles/globals.scss";

.chartContainer {
  position: relative;
  height: 100%;
  // border-radius: $radius2;
  background-color: transparent;
  flex-grow: 1;
  min-width: 200px;

  &:hover {
    .grip {
      opacity: 1;
    }
  }
}

// Opening

.window {
  // border: 2px solid black;
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: $gray20;
}

@keyframes whoosh {
  0% {
    height: 50%;
  }

  100% {
    height: 0%;
  }
}

.top {
  top: 0;
  animation: whoosh 0.75s forwards;
  border-radius: $radius2;
}

.bottom {
  bottom: 0;
  animation: whoosh 0.75s forwards;
  border-radius: $radius2;
}

.shutter {
  // border: 2px solid black;
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  background-color: $gray20;
}

@keyframes slide {
  0% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}

.leftShutter {
  left: 0;
  animation: slide 0.75s forwards;
  border-radius: $radius2;
}

.rightShutter {
  right: 0;
  animation: slide 0.75s forwards;
  border-radius: $radius2;
}

// New Spots

@keyframes up {
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.newSpotUp {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $gray20;

  display: flex;
  align-items: flex-end;
  overflow: hidden;

  animation: up 0.75s forwards;
}

.hood {
  height: 1em;
  width: 100%;
  background-color: transparent;
  // border: 1px solid gray;

  display: flex;

  .topOfHood {
    width: 100%;
    height: 100%;
    background-color: $white;
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
  }
}

@keyframes down {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

.newSpotDown {
  animation: down 0.5s forwards;
  height: 0%;
}

// deleting

.blinds {
  // border: 2px solid black;
  height: 0%;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: $gray30;
}

@keyframes close {
  0% {
    height: 0%;
  }

  100% {
    height: 50%;
  }
}

.topBlind {
  top: 0;
  animation: close 0.5s forwards;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.bottomBlind {
  bottom: 0;
  animation: close 0.5s forwards;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.curtains {
  // border: 2px solid black;
  height: 100%;
  width: 0%;
  position: absolute;
  top: 0;
  background-color: $gray30;
}

@keyframes slideShut {
  0% {
    width: 0%;
  }

  100% {
    width: 50%;
  }
}

.leftCurtain {
  left: 0;
  animation: slideShut 0.5s forwards;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.rightCurtain {
  right: 0;
  animation: slideShut 0.5s forwards;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.grip {
  position: absolute;
  left: 1rem;
  top: -0.7rem;
  cursor: grab;
  font-size: 1.3em;
  color: $gray110;
  border-radius: $radius1;
  opacity: 0;
  transition: 0.2s all;
  box-shadow: $shadow6;
  height: 20px;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  z-index: 1;
}

.grip:active {
  cursor: grabbing;
}

.emptyRow {
  width: 100%;
}

.spacer {
  flex-shrink: 0;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  // border: 2px solid black;
  &:hover {
    .dragBar {
      background-color: $gray60;
      .indicator {
        opacity: 1;
      }
    }
  }

  .dragBar:hover {
    background-color: $gray100;
    .indicator {
      color: $gray100;
    }
  }

  .dragBar:active {
    background-color: transparent;
    .indicator {
      opacity: 0;
    }
  }
}

.dragBar {
  height: 95%;
  width: 2px;
  transition: 0.5s all;
  cursor: ew-resize;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragBarEmpty {
  width: 100%;
  height: 2px;
  transition: 0.5s all;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  opacity: 0;
  transform: rotate(90deg);
  transition: 0.5s all;
  flex-shrink: 0;
  color: $gray60;           
  max-width: 18px;
  width: 18px;
  position: relative;
  right: 1px;
}

.pullPadding {
  position: absolute;
  height: 100%;
  z-index: 200;
  width: 150px;
}

.left {
  right: 0;
}

.right {
  left: 0;
}
