@import "assets/styles/globals.scss";

.page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 140;
  overflow: auto;
}
