@import "assets/styles/globals.scss";

.headerContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 1em;
}

.container {
  display: flex;
  justify-content: center;
  background-color: $gray25;
  height: 100%;
  padding: 30px 20px 20px;
}

.holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

@keyframes showDesc {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.backToTable {
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  border-radius: 10px;
  color: $gray100;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
}
.backToTable:hover {
  box-shadow: $shadow;
  .descriptionAnchor {
    display: flex;
    animation: showDesc 0.5s forwards;
    animation-delay: 750ms;
  }
}

.tableIcon {
  font-size: 1.3em;
}

.backArrow {
  padding-left: 5px;
  transform: scaleY(-1);
  -ms-transform: scaleY(-1); /* IE 9 */
  -webkit-transform: scaleY(-1); /* Chrome, Safari, Opera */
  font-size: 0.8em;
}

.descriptionAnchor {
  position: relative;
  width: 0;
  align-self: flex-start;
  padding-top: 4px;
  opacity: 0;
  display: none;
}
.description {
  font-size: 0.9em;
  position: absolute;
  white-space: nowrap;
  left: 25px;
}

.roleTableTitle {
  font-family: $poppins;
  color: $gray110;
  font-size: 1.2em;
  // font-weight: 600;
  text-align: center;
}

.roleHelp {
  color: $gray100;
  font-size: 0.9rem;
  gap: 10px;
}

.manageRoles {
  color: $blue100;
  cursor: pointer;
  font-size: 0.85rem;
  &:hover {
    text-decoration: underline;
  }
}

.roleTableHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.tableWrapper {
  border-radius: $radius2;
  box-shadow: $shadow;
  overflow: hidden;
  max-height: 80vh;
}

.roleTable {
  width: 100%;
  border-spacing: 0;
  background-color: inherit;
  overflow: auto;

  .tableHeader {
    text-align: left;
    background-color: $blue60;
    white-space: nowrap;
    height: fit-content;
    font-family: $poppins;
    color: $white;
    position: sticky;
    top: 0;
    font-size: 0.8rem;
    z-index: 10;
    width: 100%;

    th {
      padding: 5px 10px;
      border-bottom: 1px solid $gray60;
      font-weight: 500;
    }
  }

  tbody {
    width: 100%;
    tr {
      transition: 0.3s all;

      &:nth-child(even) {
        background-color: $gray20;
      }
      &:nth-child(odd) {
        background-color: $white;
      }
      &:hover {
        background-color: $gray30;
      }
    }

    td {
      min-height: 30px;
      font-family: $poppins;
      color: $gray110;
      font-size: 0.8rem;
      padding: 7px 10px;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100vw;
    }
  }

  .sorry {
    text-align: center;
    color: $gray110;
    cursor: default;
  }
  .noHover:hover {
    background-color: $white;
  }
}

.copyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.button {
  display: flex;
  align-items: center;
  gap: 7px;
}

.check {
  color: $blue100;
  transition: 0.3s all;
}

.usersTableTop {
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
}

.search {
  width: 250px;
  height: fit-content;
}
