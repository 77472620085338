@import "assets/styles/globals.scss";


.page {
    // border: 2px solid black;
    width: 100%;
    background-color: $gray22;
    height: 100%;
    overflow: hidden;
    // padding: 1em;
}