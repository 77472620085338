@import "assets/styles/globals.scss";

.header {
  font-family: $poppins;
  color: $gray110;
  font-size: 1.3em;
}

.reportSettingsButton {
  cursor: pointer;
  transition: 0.3s all;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: $radius2;
  background-color: $background;
  border: 2px solid $background;
  color: $gray110;
  padding: 7px 10px;
  display: flex;
  gap: 3px;
  box-shadow: $shadow6;
}
.reportSettingsButton:hover {
  // color: ;
  background-color: $white;
  border: 2px solid $white;
  // border: 2px solid transparent;
}

.headerContainer {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  padding: 1em 2em;
}

.buttons {
  display: flex;
  gap: 0.5em;
}

.downloadContainer {
  position: absolute;
  bottom: 1em;
  right: 1em;
  // left: 0;
  width: fit-content;
}

.filter {
  position: fixed;
  top: 4em;
  right: 2em;
  width: 400px;
  max-height: 90%;
  background-color: $white;
  padding-bottom: 1em;
  padding-top: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1em;
  overflow-y: auto;
  z-index: 120;
}

.search {
  width: 250px;
}



.selection {
  border-top: 2px solid $gray30;
  border-right: 2px solid $gray30;
  border-left: 2px solid $gray30;
  padding: 5px 5px;
  background-color: $gray20;
  color: $gray110;
  font-family: $poppins;
}


.togglespread {
  display: flex;
  align-items: center;
  gap: 1em;
  color: $gray60;
  font-size: 0.8em;
}
.tableContainer {
  font-family: $poppins;
  background-color: white;
  height: 100%;
  position: relative;
  max-height: 90%;
}