@import "assets/styles/globals.scss";

.container {
  min-height: 400px;
  width: 400px;
  max-height: 95vh;
  overflow: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 1.5rem;
  font-family: $poppins;
}

.title {
  padding: 0px 15px;
  border-bottom: 2px solid $sapphire20;
  text-align: center;
  font-weight: 400;
  color: $gray100;
}

.audienceName {
  color: $gray110;
  font-weight: 400;
}

.label {
  display: flex;
  align-items: center;
  color: $sapphire80;
  gap: 5px;
  font-size: .85rem;
}

.options {
  display: flex;
  flex-direction: column;
  // max-height: 70vh;
  overflow: auto;
  // margin: 0px 5px;
  // background-color: $gray20;
  border-radius: $radius2;
  box-shadow: $shadow6;
}

.option {
  transition: .3s all;
  cursor: pointer;
  color: $black;
  font-size: .85rem;
  padding: 4px 10px;

  &:hover {
    background-color: $gray22;
  }
}

.noData {
  text-align: center;
  padding: 4px 10px;
  font-size: .85rem;
  color: $gray100;
}

.chosenList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.chosen {
  padding: 3px 8px;
  font-size: .85rem;
  border-radius: .5rem;
  color: $sapphire80;
  background-color: $sapphire20;
  display: flex;
  align-items: center;
  gap: 5px;
}

.x {
  cursor: pointer;
  transition: .3s all;
  color: $gray100;

  &:hover {
    color: $black;
  }
}