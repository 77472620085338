@import "assets/styles/globals.scss";

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    // height: 4em;
    // padding-bottom: .5em;
    // padding-top: .5em;
    // padding-left: 1em;
    // padding-right: 1em;
    background-color: transparent;
    gap: 1em;
    color:white;
    // height: 100%;
    height: fit-content;
    // border: 2px solid black;
}
