@import "assets/styles/globals.scss";

.navContainer {
  border-radius: $radius2;
  transition: 0.3s all;
  border: 2px solid transparent;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  font-family: $poppins;
  color: $seafoam100;
  font-weight: 600;
  font-size: 0.8em;
  font-style: italic;
  letter-spacing: 0.05em;
  gap: 1em;
  padding: 1.2em 1em;
  position: relative;
  cursor: pointer;
}

.inactiveNavContainer:hover {
  border: 2px solid $blue100;
  .editSettings {
    opacity: 1;
  }
}

.activeNavContainer {
  border: 2px solid $blue100;
}

.editSettings {
  opacity: 0;
  position: absolute;
  left: 0;
  margin-left: 1em;
  transition: 0.5s all;

  font-weight: 400;
}
