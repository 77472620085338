@import "assets/styles/globals.scss";

.mainPage {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: $background;
}

.userDash {
  display: flex;
  flex-direction: column;
  width: fit-content;
  // border: 4px solid $gray60;
  // border-radius: 10px;
  padding: 0em 3em;
  max-height: 20em;
}

.userName {
  font-family: $montserrat;
  font-weight: 600;
  font-size: 1.8em;
  // color: $gray110;
  text-align: center;
}

.nameDivider {
  margin: 7px auto 25px;
  // margin: 7px 0px;
  // width: 300px;f
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: $sapphire60;
}

.imageContainer {
  position: relative;
  width: 200px;
  height: 200px;
  // border: 2px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: $shadow;

  margin: auto;
  .exitIcon {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
  }
  img {
    height: 200px;
    // width: 10em;
  }

  // label {
  //   font-family: $poppins;
  //   height: 100%;
  //   border: 3px solid $blue100;
  //   background-color: $blue100;
  //   color: white;
  //   border-radius: 5px;
  //   // padding: 0px 20px;
  //   transition: 0.2s all;
  //   font-weight: 600;
  //   font-size: 0.9em;
  //   text-align: center;
  //   // padding: 0px 20px;
  //   justify-content: center;
  //   display: flex;
  //   align-items: center;
  //   cursor: pointer;
  // }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.addImage {
  // display: flex;
  // align-items: center;
  position: absolute;
  width: 155px;
}

.fadein {
  animation: fadeIn 1.25s;
  -webkit-animation: fadeIn 1.25s;
  -moz-animation: fadeIn 1.25s;
  -o-animation: fadeIn 1.25s;
  -ms-animation: fadeIn 1.25s;
}

.cancelBtn {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30px;
  cursor: pointer;
  font-size: 0.85em;
  i {
    position: relative;
    top: 2px;
  }
}


.blurred {
  -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
  filter: blur(5px);
  cursor: default;
}

.companyInfo {
  // margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 5px;
  font-family: $poppins;
  color: $gray110;
  font-weight: 600;
  font-size: 0.95em;
}

.fieldsContainer {
  flex-grow: 1;
  // margin-top: 70px;
  // padding-left: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 400px;
}

.label {
  // color: #616565;
  // font-family: "Poppins", sans-serif;
  // font-weight: 600;
  // letter-spacing: 0.05em;
  // text-transform: uppercase;
  // font-size: .8em;
  // text-align: left;
  // margin-bottom: 4px;
  padding-right: 20px;
  color: $gray110;
  font-family: $poppins;
  // font-size: .75em;
  font-weight: 400;
  letter-spacing: 0.05em;
  padding-left: 10px;
  font-size: 1.1em;
  padding-bottom: 1em;
}

.fieldAndEdit {
  display: flex;
  gap: 35px;
  align-items: center;
}

.field {
  //taken from TextFieldSimple - .textField
  width: 100%;
  // border-radius: 5px;
  border: 2px solid white;
  border-bottom: 2px solid $sapphire60;
  color: $gray110;
  font-family: $poppins;
  padding: 6px 10px;
  overflow: none;
}

.edit {
  font-family: $montserrat;
  font-weight: 600;
  color: $sapphire100;
  font-size: 0.95em;
  cursor: pointer;
  &:active {
    color: $gray60;
  }
}

.roleDisplay {
  font-family: $poppins;
  color: $gray110;
  padding-left: 10px;
  border: 2px solid $gray60;
  width: 100%;
}

.pswdButton {
  height: 30px;
}

.saveChangesDiv {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  // margin-bottom: 10px;
  padding-right: 120px;
}

.changePasswordContainer {
  // height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  // margin-right: 120px;
  // margin-left: 30px;
  padding: 2em;

  & > div {
    width: 500px;
  }
}

// .changePasswordContainer div:nth-child(2) {
//   margin-top: 20px;
// }

.horizDivider {
  border-bottom: 2px solid $sapphire100;
  border-radius: 5px;
  color: $gray30;
  margin-top: 7px;
}

.entriesDontMatch {
  font-family: $poppins;
  font-weight: 600;
  color: $red60;
}

.checkmarkAndFieldDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkmark {
  color: $blue100;
  position: absolute;
  right: 25px;
}

.pswdSaveDiv {
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;

  align-self: flex-end;
}

.error {
  color: $red60;
  position: absolute;
  right: 25px;
}

.validating {
  position: absolute;
  right: 105px;
}

// @keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

.saved {
  font-family: $poppins;
  color: green;
  padding-left: 4px;
  font-size: 0.8em;

  position: relative;
  // top: 50px;
  left: 88px;
  bottom: 23px;

  animation-name: fadein;
  animation-duration: 0.5s;
}

.userFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  margin: auto;
}

.userFieldsHeader {
  padding: 5px 2em;
  text-align: center;
  border-bottom: 2px solid $seafoam60;
  font-family: $poppins;
  color: $gray100;
  font-weight: 500;
}
