@import "assets/styles/globals.scss";

.container {
  min-height: 400px;

  overflow: auto;
  // padding: 1rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 1rem;
  width: fit-content;
  // align-self: center;
}

.profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: $shadow6;
}

.title {
  padding: 0px 15px;
  color: $black;
  // border-bottom: 2px solid $sapphire20;
}

.subtitle {
  font-size: 0.75rem;
  font-family: $poppins;
  color: $sapphire80;
  padding: 0px 10px;
  width: fit-content;
  border-bottom: 1px solid $sapphire60;
  // align-self: center;
}

.input {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.invalid {
  padding: 3px 5px;
  border-radius: $radius2;
  background-color: $red10;
  color: $red60;
  width: 100%;
}

.addField {
  padding-left: 5px;
  font-family: $poppins;
  color: $gray100;
  font-size: 0.8rem;
  transition: 0.3s all;
  width: fit-content;
  cursor: pointer;

  &:hover {
    color: $gray60;
  }
}

.newField {
  align-self: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: $radius3;
  background-color: $gray22;
  padding: 10px;
}

.att {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
}

.text_2 {
  font-size: 0.75rem;
}

.attEntry {
  // border-radius: 0.5em;
  transition: 0.3s all;
  font-size: 0.8em;
  border-bottom: 2px solid $gray60;
  width: fit-content;
  min-width: 100px;
  background-color: $gray22;

  &:hover {
    background-color: $gray20;
  }
  &:focus {
    background-color: $gray20;
  }
}

.removeAtt {
  transition: 0.3s all;
  color: $gray100;
  cursor: pointer;

  &:hover {
    color: $black;
  }
}

.addAtt {
  font-size: 0.8rem;
  padding: 0px 10px;
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;
  color: $gray60;

  &:hover {
    color: $gray100;
  }
}

.newColumn {
  display: flex;
  align-items: center;
  gap: 7px;
}

.editCol {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  color: $sapphire80;
  font-family: $poppins;
  font-size: 0.75rem;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    color: $sapphire60;
  }
}
