@import "assets/styles/globals.scss";

.accordian {
    color: $sapphire100;
    // text-transform: uppercase;
    font-weight: 600;
    // letter-spacing: 1px;
    font-family: $montserrat;
    font-size: .85em;
    display: flex;
    flex-direction: column;
    gap: 7px;
    div {
        // border: 2px solid black;
        // margin-top: 7px;
    }
}

.listHeader {
    cursor: pointer;
    // transition: all .75s;
    padding-left: 10px;
    
    .icon {
        padding-right: 10px;
        transition: all .75s;
    }

    // &:hover {
    //     background-color: $gray30;
    //     border-radius: 5px;
    // }
}

.fold {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.accordianItem {
    cursor: pointer;
    padding: 0px 10px;
    &:hover{
        background-color: $gray30;
        border-radius: 5px;
    }
}

.hidden {
    display: none;
}

.active {
    .icon {
        transform: rotate(450deg);
        // border: 2px solid black;
    }
}

.selected {
    cursor: pointer;
    background-color: $sapphire100;
    color: white;
    font-weight: 600;
    font-family: $montserrat;
    // font-size: .85em;
    border-radius: 5px;
    padding: 0px 10px;
}



