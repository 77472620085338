@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-family: $poppins;
  width: 100%;
}

.props {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $black;
  font-size: 0.8em;
  // border: 1px solid black;
}

.addBucket {
  color: $blue100;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;

  .link:hover {
    text-decoration: underline;
  }
}

.bucketEditor {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 0.9em;
  padding: 1rem;
}

.editName {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .description {
    color: $seafoam60;
    font-size: 0.8em;
    padding-left: 5px;
  }
}

.entry {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $gray100;
  font-weight: 500;
}

// .inputHolder {
//   flex-grow: 1;
//   display: flex;
//   border-radius: 10px;
//   overflow: hidden;
//   padding: 0px 6px;

//   &:hover {
//     background-color: $gray20;
//   }
//   &:focus {
//     background-color: $gray20;
//   }
// }

.nameInput {
  // max-width: 200px;
  width: 100%;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid $sapphire20;
  font-family: $poppins;
  color: $black;
  outline: none;
  // padding: 3px 6px 0px;

  // padding: 0px 10px;
  display: flex;
  overflow-x: scroll;
  overflow: hidden;
  transition: 0.5s;
  // border-radius: 10px;
}

.nameInput:hover {
  background-color: $gray20;
}
.nameInput:focus {
  background-color: $white;
}
.nameInput::placeholder {
  color: $gray100;
}

.editProperties {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.propsHeader {
  border-bottom: 2px solid $gray30;
  // width: fit-content;
  padding-left: 5px;
  width: 40%;
  display: flex;
  gap: 7px;
  font-size: 0.95em;
}

.bucketIcons {
  position: relative;
  display: flex;
  width: fit-content;
  color: $gray110;
}

.tiltedBucket {
  position: absolute;
  top: -2px;
  right: -7px;

  color: $gray100;
  transform: rotateZ(77deg);
}

.prop {
  display: flex;
  align-items: center;
  gap: 7px;
}

.propEntry {
  border-radius: 0.5em;
  transition: 0.3s all;
  font-size: 0.9em;

  &:hover {
    background-color: $gray20;
  }
  &:focus {
    background-color: $gray20;
  }
}

.removeProp {
  transition: 0.3s all;
  color: $gray100;
  cursor: pointer;

  &:hover {
    color: $black;
  }
}

.addSubBucket {
  font-size: 0.8em;
  padding: 0px 10px;
  color: $gray100;
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;

  &:hover {
    color: $gray60;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 2rem;
  align-self: center;
}
