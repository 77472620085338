@import "assets/styles/globals.scss";

.legendNChart {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  // max-height: 300px;

  overflow-y: hidden;
  display: flex;
  // flex-direction: column;

  justify-content: center;
  align-items: center;

  // border: 2px solid red;
}

.chart {
  flex-grow: 1;
  overflow: hidden;
  // max-width: 100px;

  // border: 2px solid black;
}

.chart2 {
  flex-grow: 1;
  overflow: hidden;
  max-width: 400px;

  // border: 2px solid gray;
}

.textBox {
  position: absolute;
  top: 0;
  // z-index: 105;
  background-color: $white;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // padding: 1em;
  // cursor: pointer !important;
  transform: translate(0, 0);
  font-family: $poppins;
  width: fit-content;
  min-width: 120px;

  // z-index: 100;
  i {
    cursor: pointer;
  }
}

// .textBox:hover {
//   z-index: 119;
// }

.textBox:active {
  z-index: 130;

  i {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
  }
  box-shadow: none;
}
