@import "assets/styles/globals.scss";
.shareContainer {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 141;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  backdrop-filter:none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
}

.shareContent {
  background-color: white;
  height: fit-content;
  width: 600px;
  border-radius: $radius2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 10em;
}

.shareNav {
  font-family: $poppins;
  color: $gray110;
  border-bottom: 2px solid $gray30;
  padding: 10px;
  display: flex;

  justify-content: space-between;
  i {
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      color: $red60;
    }
  }
}

.shareFooter {
  font-family: $poppins;
  color: $gray110;
  // border-bottom: 2px solid $gray30;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.shareSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: sticky;
  padding: 0px 20px;
  z-index: 100;
  // border: 2px solid green;
}
.selectContainer {
  // position: absolute;
  // left: 52%;
  width: 150px;
  height: 100%;
  // // border: 2px solid black;
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  // max-width: 25em;
  // align-items: center;

  // select {
  //   outline: none;
  //   // border: 2px solid blue;

  //   max-height: 20px;
  //   cursor: pointer;
  //   font-family: $poppins;
  //   color: $gray110;
  //   font-size: 0.7em;
  // }
}

.shareBody {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}




.role {
  color: $gray110;
}

.publishAccess {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  span {
    // display: flex;
    // align-items: center;
    // justify-content: start;
    // gap: 1.5em;
    padding-left: 1em;
  }
  a {
    color: $blue100;
  }
}

.message {
  width: 100%;
  padding: 0px 20px;
  height: fit-content;
}

.roleEdit {
  cursor: pointer;
  outline: none;
  border-radius: $radius2;
  color: $gray110;
  transition: 0.3s all;
  padding: 2px 5px;
  font-family: $poppins;
  font-size: 0.9em;

  &:hover {
    background-color: $gray60;
  }
}

.roleContainer {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $white;
  box-shadow: $shadow;

  border-radius: $radius4;
  // gap: 1em;
  color: $gray110;
  bottom: 10px;
  left: 20px;
  z-index: 100;
  overflow: hidden;
  align-items: center;

  span {
    padding: 1em 1em;
    width: 100%;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: $gray30;
    }
  }
}
