@import "assets/styles/globals.scss";

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    // border: 1px solid $orange100;
    border-radius: 50%;
    // border-width: 75%;

    span {
        color: $orange100;
        font-family: $poppins;
        font-weight: 900;
        font-size: 2em;
    }
}

.textPercentage {
    // fill: blue;
    font-family: $poppins;
    font-weight: 500;
}

.circle {
    transition: .8s all;
}