@import "assets/styles/globals.scss";

.page {
  background-color: $background;
  width: 100%;
  height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.activity {
  width: 100%;
  //   height: 40px;
  min-height: fit-content;
  border-radius: $radius2;
  padding: 0.2rem 0.5rem;
  background-color: $white;
  display: flex;
  //   cursor: pointer;
  transition: 0.3s all;
  font-size: 0.9em;
  flex-shrink: 0;
  position: relative;
  gap: 2em;
  overflow: visible;
  max-width: 1000px;
  box-shadow: $shadow6;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;

  //   &:hover {
  //     background-color: $gray30;
  //   }
}

.activities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  padding: 1em 3em;
  font-size: 0.9em;
  align-items: center;
  padding-bottom: 4rem;
  height: fit-content;
  max-width: 40rem;
}

.time {
  @include normal-text($gray60, "", 0.8rem);
  min-width: fit-content;
}
