@import "assets/styles/globals.scss";

.accordian {
  @include col;
  background: $gray25;
  height: 100%;
  overflow: auto;
}

.dialog {
  margin: -1em;
  margin-top: 3em;
  padding: 2em 1em;
  border-bottom-right-radius: $radius4;
  border-bottom-left-radius: $radius4;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  font-family: $poppins;
  color: $gray110;
  font-weight: 400;
  background-color: $white;
}

.body {
  height: fit-content;
  padding: 1rem;
  border-top: 2px solid $gray60;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toggleLabel {
  width: 40px;
  display: flex;
  justify-content: right;
}

@include keyframes(show) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
