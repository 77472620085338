@import "assets/styles/globals.scss";

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.gradientContainer {
  animation: slideIn 0.5s;

  display: flex;
  flex-direction: column;
  gap: 30px;

  // height: 600px;

  .gText {
    font-size: 0.9em;
    color: $sapphire60;
    font-family: $poppins;
  }
}

.palette {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 20px;
}

.row {
  display: flex;
  gap: 5px;
}

.color {
  height: 1.25em;
  width: 1.25em;
  border-radius: 5px;
  transition: 0.5s all;
}

.color:hover {
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
}

.info {
  color: $sapphire60;
}

.button {
  font-size: 0.8em;
  font-weight: 500;
  color: $sapphire60;
  padding: 5px 10px;
  border: 2px solid $sapphire20;
  border-radius: 7px;
  transition: 0.3s all;
}
.button:hover {
  cursor: pointer;
  color: $white;
  background-color: $sapphire60;
  border: 2px solid $sapphire60;
}

.gradients {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.segment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.gradient {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;

  input {
    width: 50px;
    border: 2px solid $sapphire60;
    border-radius: 5px;
    color: $gray100;
  }
}

.exampleGradient {
  display: flex;
  flex-wrap: wrap;
  // max-width: 50px;
  gap: 3px;
}

.exampleSwatch {
  height: 10px;
  width: 10px;
  border-radius: 3px;
}

.blendControls {
  padding-top: 10px;
  font-size: 0.8em;
  font-family: $poppins;
  color: $sapphire60;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .heading {
    padding: 0px 10px;
    border-bottom: 2px solid $sapphire60;
  }
}

.setting {
  display: flex;
  align-items: center;
  gap: 5px;
}

.controlsExample {
  display: flex;
  gap: 3px;
  padding-left: 30px;
}