@import "assets/styles/globals.scss";

.shareSelect {
  display: flex;
  flex-direction: row;
  gap: 30px;
  position: sticky;
  width: 500px;
  max-width: 500px;
  // border: 2px solid green;
}

.selectContainer {
  position: absolute;
  right: 170px;
  //   width: 20%;
  height: 100%;
  //   border: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 25em;
  align-items: center;

  select {
    outline: none;
    max-height: 30px;
    cursor: pointer;
    font-family: $poppins;
    color: $gray110;
    background-color: $white;
    border-radius: $radius4;
    border: 2px solid $gray60;
    padding: 0em .5em;
    transition: all .2s;

    &:hover {
        background-color: $gray60;
    }
  }
}

.peopleWithAccess {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    max-width: 100%;
    // border: 2px solid black;
  
    table {
      padding: 0px 10px;

      .access {
        
        i {
            transition: all .2s;
            &:hover {
                color: $red60
            }
        }
      }
  
      td {
        // border: 2px solid black;
        padding: 0em 1em;
        width: fit-content;

        .div2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            width: 100%;
            justify-content: space-between;
        }
        div {
            // border: 2px solid black;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        //   justify-content: space-between;
        }
        font-family: $poppins;
        color: $gray110;
        img {
          height: 30px;
          border-radius: 50%;
        }

        .email {
            font-size: .7em;
            color: $gray100;
        }
      }
    }
  }
