@import "assets/styles/globals.scss";


.top {
    display: flex;
    flex-direction: column;
    .header {
        @include header-2-text
    }

    .description {
        @include description-text;
        font-size: .8em;
    }

    background-color: $background;
    padding: 1em;
    padding-top: 5em;
    flex-wrap: wrap;

    // box-shadow: $shadow;
}
.page {
    a {
        color: $blue100;
        font-family: $poppins;
        font-size: .9em;
        margin-left: 1em;
        cursor: pointer;
      }
      a:hover {
        text-decoration: underline;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 1em;
      
}
