@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
}

.minMax {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray100;
  font-size: 0.85em;
  padding: 0px 2px 5px;
  font-family: $poppins;
  // border: 1px solid orange;
}
