@import "assets/styles/globals.scss";

.container {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $poppins;
}

.placeholder {
  width: fit-content;
  position: absolute;
  pointer-events: none;
  padding: 2px 5px;
  width: 100%;
  height: 100%;
  color: $gray100;
}

.borderPlace {
  padding: 5px;
  padding-top: 7px;
}

.entry {
  padding: 2px 5px;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
}

.border {
  border: 2px solid $gray60;
  border-radius: $radius1;
  padding: 5px;
}
