@import "assets/styles/globals.scss";

.container {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $poppins;
  //  border: 2px solid black;
  border-radius: .5rem;
}

// .editable:hover .toolbar {
//   display: flex;
// }

.editable .entry:hover {
  // background-color: $gray30;
  // border-radius: 8px;
}

.entry {
  // font-family: $poppins;
  border-radius: .5rem;
  padding: 2px 5px;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  border: 2px solid transparent;

  // border-bottom: 2px solid transparent;
  // min-height: 11pt;
  // height: 100%;
}

.entryError {
  span {
    color: $red60 !important;
  }
}

.entryWarning {
  span {
    color: $yellow100 !important;
  }
}

.border {
  border: 2px solid $gray60;
  border-radius: .5rem;
  padding: 5px;
}
// .editable .entry:hover {
//   border-bottom: 2px solid $gray40;

// }

.entryActive {
  // border-bottom: 2px solid $gray60;
  background-color: $gray25;
}

.placeholderAnchor {
  width: fit-content;
  position: absolute;
  pointer-events: none;
  padding: 3px 7px;
  font-size: 11pt;
  color: $gray100;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
}
.placeholder {
  // font-family: $poppins;
}

.borderPlace {
  padding: 5px;
  padding-top: 6px;
}

.toolbarHolder {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.toolbar {
  position: absolute;
  right: 0;
  top: 5px;
  display: none;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  background-color: $gray30;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}

.bottom {
  display: flex;
  position: relative;
  width: fit-content;
}

.btn {
  transition: 0.5s all;
  padding: 0px 8px;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    background-color: $gray60;
  }
}

.active {
  background-color: $gray60;
}

.color {
  border: 2px solid $white;
  display: flex;
  align-items: center;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  overflow: hidden;
  margin: 0em 0.2em;
  cursor: pointer;
}

.fontSelect {
  height: 100%;
  width: 3em;
  border: none;
  border-radius: 0.5rem;
  outline: none;
  background-color: $gray30;
  cursor: pointer;
  transition: 0.3s all;
}
.fontSelect:hover {
  background-color: $gray60;
}
