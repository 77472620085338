@import "assets/styles/globals.scss";

.container {
  flex-direction: column;
  background-color: $gray25;
  border-radius: $radius2;
  padding: .5rem .7rem;
  display: flex;
  gap: .5rem;
  width: 100%;
  // height: 40px;
  position: relative;
  transition: 0.3s all;
  // cursor: pointer;
}

.label {
  @include header-4-text;
  font-weight: 400;
  font-size: 0.8em;
  color: #6793a5;
  white-space: wrap;
}

.input {
  display: flex;
  background-color: transparent;
  justify-content: center;
  border: none;
  outline: none;

  &::placeholder{
    color: $gray100;
  }
}

.dropdown {
  @include animation(open .3s 1);
  transition: .3s all;
  position: absolute;
  background-color: $white;
  box-shadow: $shadow;
  width: 100%;
  min-height: 240px;
  height: 240px;
  max-height: 240px;
  min-width: 160px;
  border-radius: $radius2;
  font-family: $poppins;
  color: $gray110;
  overflow: auto;
  left: 0;
  top: 108%;
  z-index: 100;
}

.optionName {
  flex-grow: 1;
}

.option {
  @include animation(show 0.3s 1);
  padding: 0px 20px;
  height: 40px;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  .helperText {
    color: $blue100;
    opacity: 0;
    font-size: 0.8em;
    transition: 0.3s all;
  }
  .checked {
    color: $blue100;
    font-size: 1.5em;
    transition: 0.3s all;
    margin-left: auto;
  }

  &:hover {
    background-color: $seafoam10;
    .helperText {
      opacity: 1;
    }
  }
}

.avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.remove {
  color: $gray110;
  cursor: pointer;
  transition: 0.2s all;
  
  &:hover {
    color: $red60;
  }
}

.input:focus::placeholder {
  font-style: italic;
  padding-left: 2px;
}

.chosenItem {
  color: $sapphire80;
  background-color: transparent;
  justify-content: space-between;
  max-width: 100%;
  padding: 5px 5px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0.2em;
  align-items: center;
  font-size: .85rem;
  white-space: nowrap;

  transition: 0.3s all;

  &:hover {
    background-color: $seafoam10;

    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  }

  .chosenText {
    white-space: wrap;
    text-wrap: wrap;
  }
}
