@import 'assets/styles/globals.scss';

.menuItem {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-shrink: 0;
  font-family: $poppins;
  font-size: 0.9em;
  color: $gray110;
  padding: 0.5em 1em;
  cursor: pointer;
  white-space: nowrap;
  i {
    font-size: 1.1em;
  }
  &:hover {
    background-color: $gray30;
  }
}

.dashLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.copyMenu {
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  height: fit-content;

  .menuItem {
    justify-content: flex-end;
    gap: 5px;
    background-color: $gray20;
    transition: 0.3s all;

    &:hover {
      background-color: $gray30;
    }
  }
}
