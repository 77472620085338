@import "assets/styles/globals.scss";

.page {
  height: fit-content;
  width: 100%;

  padding: 2rem;
  padding-bottom: 15rem;
  display: flex;
  justify-content: center;
  background-color: $gray22;

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.detailsContainer {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  // margin: auto;
}

.link2 {
  @include header-4-text;
  margin: 0;
  font-weight: 400;
  margin-bottom: -0.5rem;
  color: $black;
  span {
    cursor: pointer;
    &:hover {
      color: $blue100;
      text-decoration: underline;
    }
  }

  .small {
    font-size: 0.7em;
    color: $gray100;
  }
}

.topRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
  font-family: $poppins;
  width: 100%;
  border-radius: $radius4;
  padding: 1em;
  flex-wrap: wrap;
}

.surveySettings {
  display: flex;
  flex-direction: column;
  gap: 0em;
  font-size: 11pt;
  flex-wrap: wrap;
}

.startedText {
  color: $gray110;
  font-size: 0.9em;
  display: flex;
  gap: 0.5em;
  font-family: $poppins;
  margin-top: 0.5em;
  span {
    font-weight: 300;
    color: $seafoam100;
  }
}

.section {
  display: flex;
  // display: grid;
  // grid-template-columns: 100%;
  gap: 3em;
  width: 100%;
  padding: 0.5em;
  justify-content: space-around;
  flex-wrap: wrap;
}

.stats {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .statSection {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    // height: 70px;
    flex-wrap: wrap;
    // border: 2px solid black;
  }
}

.detailsLeft {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-width: 1200px;
  // align-items: center;
}

.resultStats2 {
  width: 100%;
  display: flex;
  gap: 0em;
  justify-content: space-around;
  font-size: 0.6em;
  align-items: center;
  padding: 0.5em;
  flex-wrap: wrap;
}

.statusLink {
  font-size: 0.9em;
  align-items: flex-end;
}

.statusLink.active {
  color: $green100;
}

.statusLink.inactive {
  color: $red60;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
}

.tip {
  font-family: $poppins;
  color: $gray110;
  display: flex;
  font-size: 0.8em;
  span {
    display: flex;
    gap: 0.5em;
  }
  a {
    font-weight: 500;
  }
  height: 100%;
  align-items: center;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  .headerTitle {
    @include header-2-text;
  }
}

.headerRight {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  padding-top: 4em;
}
.status {
  @include header-4-text;
  font-size: 1.25em;
  text-transform: capitalize;
}
.headerDescription {
  @include description-text;
  // max-width: 400px;
}
.link {
  @include link;
}

.buttons {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: flex-end;
}

.highlight {
  @include link;
}

.resultsOverview {
  display: flex;
  flex-direction: row;
  gap: 2em;
  // padding: 1em 0em;
}

.resultStats {
  width: 70%;
  min-width: 300px;
  height: fit-content;
}

.headerLeft1 {
  display: flex;
  flex-direction: column;
  width: 29%;
  max-width: 400px;
  .headerTitle {
    @include header-2-text;
  }
}

.statusStyle {
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
}

.statusStyle.delivered {
  background-color: $green20;
  color: $green100;
}
.statusStyle.draft {
  background-color: $orange20;
  color: $orange100;
}
.statusStyle.scheduled {
  background-color: $blue20;
  color: $blue100;
}
.statusStyle.inactive {
  background-color: $red60;
  color: red;
}
.statusStyle.active {
  background-color: $sapphire60;
  color: $sapphire100;
}

.number {
  color: $info;
}

.tableIcon {
  color: $blue100;
  padding-right: 0.5em;
}

.topDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding-bottom: 1em;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.method {
  padding: 3px 10px;
  background-color: $blue100;
  color: $white;
  border-radius: $radius2;
  width: fit-content;
  font-size: 0.8em;
  font-weight: 700;
  display: flex;
  gap: 0.5em;
  align-items: center;
  text-transform: capitalize;
  margin-bottom: -0.5rem;

  i {
    font-size: 1.4em;
  }
}

.method.email {
  background-color: $sapphire60;
  color: $white;
}

.method.sms {
  background-color: $green100;
  color: $white;
}

.method.link {
  background-color: $primary;
  color: $white;
}

.method.personalLink {
  background-color: $seafoam100;
  color: $white;
}

.select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  // border: 2px solid black;
  font-size: 0.9em;
  position: relative;
}
.selectField {
  box-shadow: $shadow;
  border: none;
  background-color: $background;
  height: 30px;
  width: 170px;
  color: $gray110;
  border-radius: $radius2;
  transition: 0.3s all;
  font-size: 0.9em;
  &:hover {
    border: none;
  }
}

.resultStat {
  position: absolute;
  left: 0em;
  top: 0px;
}

.changeStatus {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
}

.bubble  {
  white-space: nowrap;
}

.err {
  color: $red60;
}

.changeBox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: 0px;
  background-color: $white;
  box-shadow: $shadow8;
  white-space: nowrap;
  padding: 6px 20px;
  color: $blue100;
  font-size: 0.7rem;
  transition: 0.3s all;
  cursor: pointer;
}

.red {
  color: $red60;
  font-size: 0.8rem;

  &:hover {
    background: $red60;
    color: $white;
  }
}

.green {
  color: $green100;

  &:hover {
    background: $green100;
    color: $white;
  }
}
