@import "assets/styles/globals.scss";

.page {
  width: 100%;
  height: 100vh;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5em;
  background-color: $gray22;
}

.pillContainer {
  width: 100%;
  padding: 0rem 1rem;
  height: 100%;
  max-height: fit-content;
  border-bottom: 2px solid $gray40;
  background-color: $white;
  padding-top: .5rem;
}

.moreWays {
  width: 100%;
  padding: 0em 2em;
  margin-top: 7em;
}

.way {
  h2 {
    @include header-4-text;
    margin: 0em;
  }
  @include description-text;
  border: 2px solid $gray100;
  border-radius: $radius4;
  padding: 1em;
  max-width: 200px;
}
