@import "assets/styles/globals.scss";

.questionContainer {
  border-radius: 1em;
  background-color: $white;
  padding: 1em;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.number {
  font-family: $poppins;
  padding-top: 4px;
  width: 100%;
  max-width: fit-content;
}
