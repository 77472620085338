@import "assets/styles/globals.scss";

.page {
  background-color: $background;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.orgs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  padding: 1em 3em;
  font-size: 0.9em;
  align-items: center;
  padding-bottom: 4rem;
  height: fit-content;
}

.org {
  width: 100%;
  height: 80px;
  border-radius: $radius2;
  padding: 1em;
  background-color: $white;
  display: flex;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 0.9em;
  flex-shrink: 0;
  position: relative;
  padding-left: 2em;
  gap: 2em;
  overflow: visible;
  max-width: 1000px;
  box-shadow: $shadow6;
  align-items: center;
  overflow: hidden;
  padding-left: 0;

  &:hover {
    background-color: $gray30;
  }
}


.sortBox {
    width: 100%;
    display: flex;
    max-width: 1000px;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .pills {
    display: flex;
    gap: 10px;
    height: fit-content;
    align-items: center;
    position: relative;
    font-family: $poppins;
  }


.startedText {
    color: $gray110;
    font-size: 1em;
    display: flex;
    font-weight: 600;
    white-space: nowrap;
    // width: 100%;s
    gap: 0.5em;
  
    span {
      font-weight: 300;
      // min-width: 65px;
      // text-align: right;
      color: $gray110;
    }
    // flex-direction: column;
  }