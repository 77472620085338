@import "assets/styles/globals.scss";

.tosContainer {
    padding: 1rem 2rem;
    p {
        font-family:Verdana, Geneva, Tahoma, sans-serif;
    }
}

.tosContent {
    width: 80%;
    max-width: 800px;
    height: 70vh;
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.tosContainer button {
    width: 300px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
}

// h1 {
//     // border: 2px solid black;
//     font-family: $poppins;
//     color: $sapphire100;
//     font-weight: 400;
//     font-size: 1.5em;
// }


.page {
    height: 100%;
    overflow: auto;
    padding: 2rem;
}