@import "assets/styles/globals.scss";

// Gradient

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.gradientContainer {
  animation: slideIn 0.5s;

  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .gText {
    font-size: 0.9em;
    color: $sapphire60;
    font-family: $poppins;
  }
}

.gradient {
  // width: 80%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;

  input {
    width: 50px;
    border: 2px solid $sapphire60;
    border-radius: 5px;
    color: $gray100;
  }
}

// Palette Editor

.plainPalette {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.plainColor {
  height: 1.25em;
  width: 1.25em;
  border-radius: 5px;
}

.defaultSettings {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

}

.setting {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subSettings {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  gap: 7px;
}

.fillSetting {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.fillExampleGradient {
  display: flex;
  gap: 2px;
  padding-left: 35px;
}

.buildGradient {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: $sapphire60;
  font-family: $poppins;
  cursor: pointer;
  // border: 2px solid blue;
  padding: 5px 10px 10px;
  border-radius: 10px;
  transition: 0.5s all;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  }
}

.exampleGradient {
  display: flex;
  gap: 4px;
}

.gradientColor {
  width: 10px;
  height: 10px;
  border-radius: 3px;
}

.clear {
  font-size: 0.9em;
  color: $sapphire60;
  font-family: $poppins;
}

