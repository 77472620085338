@import "assets/styles/globals.scss";

.logoUpload {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 130;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: $radius4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  gap: 15px;
  justify-content: space-between;
}

.imageBay {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empty {
  color: $gray110;
  cursor: pointer;
  border: 2px dashed $gray60;
  padding: 20px;

}

.buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}