@import "assets/styles/globals.scss";

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: $radius4;
    // background-color: $sapphire20;
    // color: $sapphire100;
    background-color: $gray20;
    color: #949494;
    height: 32px;
    padding: 0px 15px;
    font-family: $poppins;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: .85em;
    justify-content: space-between;
    cursor: pointer;
    transition: all .5s;
    border: 2px solid $gray60;
    // box-shadow: $shadow;
    .checknName {
        // border: 2px solid black;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 1px;
        width: 100%;
        span {
            color: $blue60;
        }
    }
}

.accordionactive {
    // border-radius: 5px 5px 0px 0px;
    .accordionicon {
        transform:  rotate(270deg);
    }

}

.header .accordionicon {
    // position: absolute;
    transition: all .5s;

}

.body {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    transition: all .5s;
    // border: 2px solid $gray60;
    // margin-bottom: 7px;
    // margin-top: -10px;
    // max-height: 310px;
    height: fit-content;

    border-top: none;
    border-radius: 0px 0px 5px 5px;
    // padding: 8px 0px 8px 15px;
    padding-left: 3em;
    font-family: $poppins;
    font-size: .95em;
    color: #474848;
    background-color: $white;
    // box-shadow: $shadow;
    // letter-spacing: 1px;

}