@import "assets/styles/globals.scss";

.page {
  padding: 1em 2em;
  width: 100%;
}

.notactive {
  // border-radius: 5px;
  // padding: 3px 5px 3px 5px;
  font-family: $poppins;
}

.active {
  font-family: $poppins;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  background-color: $sapphire60;
  color: $sapphire100;
  text-align: center;
}

.html {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.thearea {
  flex-grow: 1;
  min-height: 400px;
}

.htmlPreview {
  padding: 1em;
  flex-shrink: 0;
}

.sigLabel {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-bottom: 10px;

  font-family: $poppins;
  color: $seafoam100;
  letter-spacing: 0.1em;
  font-weight: 050;
  font-size: 0.9em;
  //   padding-left: 1em;
}

.sigBuilderContainer {
  display: flex;
  // flex-direction: column;
  border: 2px solid $gray60;
  border-radius: $radius2;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  // border-left: 2px solid $gray20;
  border-right: 2px solid $gray20;
  flex-shrink: 0;
  // min-width: 200px;
  width: 235px;
}

.buttons {
  display: flex;
  width: fit-content;
  gap: 1rem;
  align-items: center;
}

.buttonSpace {
  display: flex;
  align-items: center;
  gap: 10px;
}

.manageUsers {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-family: $poppins;
  padding-left: 10px;
}

.defaultTip {
  font-size: 0.75rem;
  color: $gray100;
  padding-left: 10px;
}

.userList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.tiedTitle {
  width: fit-content;
  border-bottom: 1px solid $sapphire60;
  padding: 0px 10px;
  font-size: 0.85rem;
  color: $gray110;
}

.tiedUser {
  display: flex;
  align-items: center;
  gap: 5px;
}

.x {
  cursor: pointer;
  transition: 0.3s all;
  color: $gray100;
  height: fit-content;
  // display: flex;
  // align-items: center;

  &:hover {
    color: $red60;
  }
}

.makeDefault {
  color: $gray100;
  font-size: .75rem;
  transition: .3s all;
  cursor: pointer;
  &:hover {
    color: $blue100;
  }
}

.pipe {
  font-size: .85rem;
  color: $gray60;
}

.addUser {
  color: $gray100;
  font-size: 0.8rem;
  transition: 0.3s all;
  cursor: pointer;
  padding-top: 5px;
  padding-left: 5px;
  margin-bottom: 30px;
  &:hover {
    color: $gray110;
  }
}

.name {
  padding: 3px 8px;
  font-size: 0.85rem;
  border-radius: 0.5rem;
  color: $black;
  box-shadow: $shadow8;
}

.searchUsers {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
}

.searchResults {
  position: absolute;
  bottom: 120%;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 0.5rem;
  box-shadow: $shadow;
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.option {
  padding: 5px 10px;
  color: $gray110;
  font-size: .85rem;
  font-family: $poppins;
  transition: .3s all;
  cursor: pointer;

  &:hover {
    background-color: $gray22;
  }
}

.noData {
  text-align: center;
  color: $gray60;
  font-size: .85rem;
  padding: 15px;
}