@import "assets/styles/globals.scss";

.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subtitle {
  font-size: 0.75rem;
  font-family: $poppins;
  color: $sapphire80;
  padding: 0px 10px;
  width: fit-content;
  border-bottom: 1px solid $sapphire60;
  // align-self: center;
}

.option {
  font-size: 0.75rem;
  font-family: $poppins;
  color: $black;

  display: flex;
  align-items: center;
  gap: 7px;

  i {
    color: $gray100;
    transition: 0.3s all;
  }

  .applied {
    color: $sapphire60;
  }
}
