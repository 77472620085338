@import "assets/styles/globals.scss";


.children {
  box-shadow: $shadow6;
  border-radius: $radius2;
  padding: 1em;
  background-color: $white;
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
}
