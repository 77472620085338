@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 1rem;
  font-family: $poppins;
}

.topHeader {
  padding: 1em;
  border-bottom: 1px solid $gray60;
  .label2 {
    font-family: $poppins;
    font-weight: 400;
    color: $gray110;
    margin-left: 10px;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 15px;
  
  width: 300px;
  max-width: 300px;

  border-right: 1px solid $gray60;
  padding-right: 15px;
}

.label {
  font-family: $poppins;
  font-size: 0.75rem;
  color: $sapphire80;
  font-weight: 400;
  text-wrap: nowrap;
}

.accordion {
  padding-bottom: 5em;
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.audHeader {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-transform: none;
  justify-content: space-between;
  width: 100%;
  a {
    // color: $blue60;
    display: flex;
    align-items: center;
    gap: 0.5em;
    flex-wrap: wrap;
  }
  div {
    font-size: 0.8em;
    text-transform: none;
    color: $gray100;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $gray60;
  padding-top: 1rem;
  gap: 20px;
}

.dateDisplay {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: $georgia;
  padding: 5px 0px;

  border-bottom: 1px solid $gray60;
  padding: 0px 30px;
}

.fancy {
  font-family: $georgia;
  color: $gray110;
  font-size: 1rem;
}

.month {
  color: $black;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.day {
  color: $gray100;
  font-size: 2.5rem;
  padding-bottom: 5px;
}

.bpSuggestion {
  font-family: $georgia;
  color: $gray110;
  font-size: 0.8rem;
  text-align: left;
  width: 100%;
}

.red {
  color: #e03b25;
}

.green {
  color: #5aaf65;
}

.yellow {
  color: #e8a63c;
}

.localTime {
  color: $gray110;

}