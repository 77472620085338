@import "assets/styles/globals.scss";

.page {
  display: flex;
  //   align-items: center;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
}

.content {
  width: 100%;
  padding: 5rem 0rem;
  padding-top: 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow: auto;
}

.layout {
  width: 700px;
  padding: 2rem 0rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.itemsSplit {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.header {
  width: 100%;
  // height: 100%;

  padding: 1rem 1rem;
  display: flex;
  border-bottom: 2px solid $gray40;
  align-items: center;
  gap: 1rem;

  .header_3 {
    color: $black;
  }
}

.sortableItem {
  flex-grow: 1;
  height: fit-content;
  cursor: pointer;
  //   height: 10px;
}


.sortableItemContainer {
  display: flex;
  gap: 1rem;
  width: 100%;
  min-height: 3rem;
  position: relative;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row {
  //   border: 1px solid #ccc;
  padding: 0.5rem 0.5rem;
  border-radius: $radius2;
  transition: 0.3s all;
  //   &:hover {
  //     background-color: $blue10;
  //   }
}

.emptytext {
  font-size: 0.9rem;
  span {
    font-size: 0.8rem;
    color: $gray100;
  }
}

.emptyRow {
  border-radius: $radius2;
  //   border: 1px solid $gray60;
  min-height: 4.2rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-family: $poppins;
  color: $gray110;
}

.draggedRow {
  border-radius: $radius2;
  background-color: $blue10;
  padding: 1rem;
  cursor: grabbing;
  font-family: $poppins;
}

.draggedItem {
  //   pointer-events: none;
  cursor: grabbing;
  border-radius: $radius2;
  //   background-color: $blue10;
  opacity: 50%;
  //   max-height: 10px;
  //   overflow: visible;
  //   border: 2px solid black;
  //   display: flex;
  //   align-items: center;
}

.header_4 {
  margin: 0;
  color: $black;
  padding: 0rem 0.5rem;
  height: 4rem;
  cursor: pointer;
  transition: 0.3s all;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $radius2;
  &:hover {
    background-color: $blue10;
  }
}
.drag {
  background-color: $blue10;
}

.remove {
  transition: 0.3s all;
  font-weight: 400;
  font-size: 0.8rem;
  padding-right: 0.5rem;
  display: flex;
  gap: 1rem;

}


.addRow {
  font-family: $poppins;
  font-size: .8rem;
  background-color: $blue10;
  margin: 0rem .5rem;
  border-radius: $radius2;
  padding: .2rem 1rem;
}