@import "assets/styles/globals.scss";

.menu {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  max-width: fit-content;
  min-height: fit-content;
  height: 100%;
  gap: 10px;
  padding: 1em 1em;
  flex-wrap: wrap;
  transition: .3s all;
  border-right: 1px solid $gray60;

}


.flatten {
  flex-direction: row;
}