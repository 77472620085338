@import "assets/styles/globals.scss";

.phone {
  border: 4px solid $black;
  @include col;
  align-items: center;
  height: 600px;
  width: 300px;
  border-radius: 40px;
  // overflow: hidden;
  position: relative;
  box-shadow: $shadow6;
  background-color: $white;
}

.notch {
  width: 30%;
  height: 20px;
  background-color: #222;
  border-radius: 15px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.slider {
  position: absolute;
  background-color: $black;
  bottom: 5px;
  width: 35%;
  height: 5px;
  border-radius: 5px;
}

.textAppHeader {
  @include col;
  align-items: center;
  gap: 5px;
  padding-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray40;
  background-color: $gray20;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.profPic {
  overflow: 0;
  border-radius: 50%;
}

.numDisplay {
  font-size: 0.6rem;
  color: $black;
}

.textAppFooter {
  @include col;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 10px;
}

.fakeInput {
  padding: 3px 5px;
  border-radius: 1rem;
  font-size: 0.85rem;
  color: $gray60;
  @include row;
  width: 85%;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid $gray40;
}

.dot {
  background-color: $gray60;
  height: 4px;
  width: 4px;
  border-radius: 50%;
}
