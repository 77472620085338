@import "assets/styles/globals.scss";

.page {
  border-left: 2px solid $gray40;
  height: 100%;
  width: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  .header_4 {
    margin: 0;
    padding: 0 1rem;
    padding-top: 0.5rem;
    color: black;
  }
  .text_3 {
    padding: 0 1rem;
  }
  // padding: 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.search {
  width: 100%;

  margin-top: 0.5rem;
}

.groupHeader {
  font-family: $poppins;
  display: flex;
  gap: 0.5rem;
  i {
    color: $blue100;
  }
}
.fields {
  color: $gray110;
  font-family: $poppins;
  font-size: 0.8rem;
}

.group {
  padding: 1.5rem 2rem;
  box-shadow: $shadow6;
  margin: 1rem;
  border-radius: $radius2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

.tabmenu {
  border-bottom: 2px solid $gray40;
  padding: 0 1rem;
}

.groups {
  overflow: auto;
  height: 100%;
  position: relative;
  padding-bottom: 1rem;
}
.override {
  color: $gray100;
  i {
    color: $gray100;
  }
}

.addGroup {
  position: sticky;
  top: 1rem;
  bottom: 0rem;
  display: flex;
  justify-content: center;
}
