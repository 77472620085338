@import "assets/styles/globals.scss";

.progressContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0em 1em;
  gap: 1em;
  transition: 0.3s all;
}

.fillProgress {
  width: 100%;
  background-color: #d8d9d9;
  border-radius: 30px;
  height: 4px;
  transition: 0.3s all;
}

.progressBar {
  height: 4px;
  border-radius: 40px;
  transition: 0.3s all;
}

.progressReport {
  width: fit-content;
  color: #c0c0c0;
  font-style: $poppins;
  font-size: 0.8em;
  font-weight: bold;
  white-space: nowrap;
}
