@import "assets/styles/globals.scss";

.popoutBackground {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(42, 98, 124, 0.6);
  backdrop-filter: blur(2px);
  z-index: 500;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-family: $poppins;
  background-color: $white;
  padding: 1em;
  border-radius: 1em;
}

.prompt {
  color: $gray110;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 50px;
}