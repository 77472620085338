@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
}

.validateContainer {
  display: flex;
  flex-direction: column;
  padding: 1em;
  padding-bottom: 2rem;
  gap: 1em;
  font-family: $poppins;
}

.valHeader {
  text-align: center;
  @include header-2-text;
}
.valDescription {
  text-align: center;
  @include description-text;
  font-size: 1em;
}
.valDescription2 {
  text-align: center;
  @include description-text;
  font-size: 1em;
  font-weight: 600;
  color: $yellow100;
}
.valSection {
  display: flex;
  gap: 1em;
  align-items: center;
  // justify-content: center;
  font-family: $poppins;
  color: $gray110;
  font-weight: 600;
  // width: 650px;
  width: 100%;
}

.validateButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  height: 2em;
  padding: 2px;
}

.valButtons {
  display: flex;
  justify-content: center;
  gap: 1em;
  padding-bottom: 20px;
}
.blue {
  color: $blue100;
}

.aud {
  background-color: $orange20;
  border-radius: $radius2;
  padding: 5px 10px;
  font-weight: 400;
  color: $orange100;
  white-space: nowrap;
  width: fit-content;
}
.auds {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.scheduled {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 10px;
  font-family: $poppins;
  font-size: 1.5rem;
  color: $gray110;
  justify-content: center;
  padding: 30px;
  color: $sapphire80;
}
