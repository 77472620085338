@import "assets/styles/globals.scss";

.tableContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100vh;
  gap: 1em;
  align-items: center;
  width: 100%;
  background-color: $gray22;
}
.tableHeader {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 1em;
  width: 100%;
  max-width: 1100px;
}

.tableContents {
  display: flex;
  gap: 1em;
  align-items: center;
  flex-wrap: wrap;
 justify-content: flex-end;
  width: 100%;
}

.header {
  @include header-3-text($gray110);
}

.tableDescription {
  @include description-text($gray110);
  max-width: 40em;
}

.icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $gray110;
    font-size: 1.5em;
  }
  cursor: pointer;
}

.icon.active {
  box-shadow: $shadow;
  border-radius: $radius2;
}

.tableTitle {

  @include header-3-text($gray110);
  i {
    color: $info
  }
}

.tableTitleStar {
  @include header-3-text($gray110);
  padding-left: 1em;
  i {
    color: $yellow100;
  }
}


.viewingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1em 0em;
  padding-bottom: 1em;
  color: $gray110;
  font-family: $poppins;
  gap: 1em;
  


  .viewPill {
    border-radius: $radius4;
    padding: 0.25em 0.5em;
    padding-right: 0em;
    box-shadow: $shadow;
    cursor: pointer;
    &:hover {
      .x {
        color: $red60;
      }
      .addView {
        color: $blue100;
      }
    }
  }

  .x {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;
  }

  .addView {
    cursor: pointer;
    color: $gray110;
    padding: 0em 0.25em;

    &:hover {
      color: $blue100;
    }
  }
}

.search {
  // width: 12em;
}

.section {
  width: 100%;
}
.searchBox {
  position: relative;
  z-index: 100;
}

.items {
  position: absolute;
  right: 0;
  top: 2.7em;
  background-color: $white;
  border-radius: $radius4;
  box-shadow: $shadow;
  width: 500px;
  overflow: hidden;
  // border: 2px solid black;


  .item {
    padding: .5em 1em;
    color: $gray110;
    cursor: pointer;
    font-family: $poppins;
    display: flex;
    justify-content: space-between;
    // font-size: .9em;
    gap: 1em;
    // width: fit-content;
    .type {
      color: $blue100;
      font-size: .8em;
    }

    &:hover {
      background-color: $background;
    }
  }

}