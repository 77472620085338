@import "assets/styles/globals.scss";

.matrix {
  font-family: $poppins;
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8em;
  overflow: hidden;
  th {
    border-bottom: 2px solid $gray60;
    padding: 0px 10px 3px;
    font-weight: 400;
  }

  th:first-child {
    border-right: 2px solid $gray60;
    width: 1%;
    padding: 0px;
  }

  td:first-child {
    border-right: 2px solid $gray60;
  }
}

.noX {
  th {
    border-bottom: none;
  }
}

.noY {
  th:first-child {
    border-right: none;
  }
  td:first-child {
    border-right: none;
  }
}

.yAxisLabel {
  padding: 3px 10px 3px 5px;
}

.textEntry {
  padding: 5px;
  min-width: 100px;
}

.topAbove {
  th {
    border: none;
  }
  th:first-child {
    border: none;
  }
}

.minMaxContainer {
  padding: 0px 6px 10px;
}

.minMax {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray100;
}
