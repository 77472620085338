@import "assets/styles/globals.scss";


.page {
  display: flex;
  flex-direction: column;
  background-color: $background;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px;
}

