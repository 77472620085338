@import "assets/styles/globals.scss";

.container {
  height: fit-content;
  display: flex;
  position: relative;
  font-family: $poppins;
  align-items: center;
  gap: 5px;
  padding: 2px 5px;
  cursor: text;
  
  border-bottom: 1.5px solid $gray40;

  padding: 5px;
  font-size: .9em;

  // z-index: 2;
}

.label {
  white-space: nowrap;
  color: $sapphire100
}

.placeholderAnchor {
  width: fit-content;
  position: absolute;
  pointer-events: none;
  padding-top: 2px;
  padding-left: 3px;
  width: 100%;
  // border: 1px solid brown;
}
.placeholder {
  // font-family: $poppins;
  padding-left: 2px;
  color: $gray100;
}

.entry {
  // font-family: $poppins;
  flex-grow: 1;
  padding: 2px 5px;
  outline: none;
  transition: 0.3s all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  width: 100%;
  // border-bottom: 2px solid transparent;
}
