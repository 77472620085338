@import "assets/styles/globals.scss";

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  //   padding: 1em;
  overflow: hidden;
  align-items: center;
  background-color: $gray25;
  font-family: $poppins;
  //   border: 2px solid black;
}

.upload {
  width: 100%;
  max-width: 1000px;
  height: fit-content;
  box-shadow: $shadow;
  border-radius: $radius4;
  padding: 1em;
  background-color: $white;
  color: $gray110;
  font-size: 0.9em;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  color: $gray110;
}

.icon {
  @include link;
  display: flex;
  gap: 0.3em;
  color: $blue100;
  align-items: center;
  font-size: 0.9em;
}

.navBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  min-height: 70px;
  align-items: flex-end;
  padding: 0px 20px;
}

.navHeader {
  @include header-3-text;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 20px;
  width: 100%;
  //   border: 2px solid black;
  height: 100%;
  overflow: auto;
}

.search {
  display: flex;
  justify-content: center;
  width: 100%;
}

.searchClass {
  width: 100%;
  box-shadow: $shadow;
  border: none;
  height: 35px;
  max-width: 400px;
}

.uploadContent {
  display: flex;
  padding: 1em;
  justify-content: space-around;
  width: 90%;
  flex-wrap: wrap;
  gap: 1em;

  .column {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .item {
    display: flex;
    gap: 0.5em;
    a {
      @include link;
    }

    span {
      font-weight: 500;
    }

    .title {
      width: 120px;
      text-align: end;
    }
  }
}

.download {
  @include link;
  font-size: 1.1em;
}
