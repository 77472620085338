@import "assets/styles/globals.scss";

.completedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-direction: column;
  background-color: transparent;
  padding: 10px 10px;
  margin-top: 10px;
  padding-bottom: 20px;
  height: fit-content;
  width: 100%;
}

.textContainer {
  width: 100%;
}

.backButton {
  display: flex;
  align-items: center;
  color: $blue100;
  cursor: pointer;
  font-size: 1.5em;
}

.insideButton {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backArrow {
  transform: rotateX(180deg);
  padding-top: 3px;
}

.nonTypicalGoBack {
  font-family: $poppins;
  color: $gray110;
  font-size: .8em;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: $gray100;
  }
}
