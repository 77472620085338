@import "assets/styles/globals.scss";

.settingsContainer {
  width: 100%;
  padding: 1em 1.5em;
  padding-bottom: 1em;
  font-size: 0.85em;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0.3em;
  right: 0.5em;
  z-index: 100;
}

.questionName {
  font-family: $poppins;
  color: $gray110;
  padding-bottom: 5px;
  font-size: 0.95em;
}
// .conditions {
//   display: flex;
//   flex-direction: column;
//   // gap: 1em;
// }

.trigger {
  display: flex;
  flex-direction: column;
  border-left: 2px solid $gray30;
  padding: 1em 0em;
  padding-right: 0em;
  padding-left: 1em;
  // margin-left: 2em;
  gap: 0.5em;
  position: relative;

  .trash {
    position: absolute;
    top: 40%;
    left: -25px;
    color: $gray110;
    cursor: pointer;
    transition: 0.3s all;
  }
  .trash:hover {
    color: $red60;
  }
}

.configure {
  display: flex;
  flex-direction: column;
  gap: 2em;
  font-family: $poppins;
}

.opAndCondition {
  display: flex;
  gap: 1em;
  align-items: center;
}

.notifyWho {
  display: flex;
  gap: 1em;
  align-items: center;
}

.who {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.whoRow {
  display: flex;
  flex-direction: column;
  gap: 1em;
  // align-items: center;
}

.whoHeader {
  color: $seafoam100;
  font-weight: 500;
}

.plus {
  padding: 5px;
  color: $gray100;
  font-weight: 500;
  cursor: pointer;
}
.plus:hover {
  color: $gray110;
}

.users {
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
}

.pill {
  display: flex;
  border-radius: 1.5em;
  overflow: hidden;
  box-shadow: $shadow6;
}

.pillName {
  display: flex;
  align-items: center;
  padding: 0.4em;
  padding-right: 0.5em;
  padding-left: 1em;
  font-size: 0.8em;
  font-family: $poppins;
  font-weight: 600;
  cursor: default;
}

.deletePill {
  cursor: pointer;
  height: 30px;
  padding-left: 0em;
  padding-right: 0.2em;
  transition: all 0.2s;
  display: flex;
  align-items: center;
}

.user {
  background-color: $sapphire100;
  color: $white;
}

.deleteUser {
  background-color: $sapphire100;
  color: $black;
}
.deleteUser:hover {
  background-color: $gray60;
}

.emails {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
}

.email {
  border-bottom: 2px solid $sapphire20;
  padding: 0px 5px;
}

.customFields {
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
}

.customField {
  background-color: $blue100;
  color: $white;
}

.deleteField {
  background-color: $blue100;
  color: $black;
}
.deleteField:hover {
  background-color: $gray60;
}

// .group {
//   display: flex;
//   flex-direction: column;
//   // gap: 1em;
// }

.withMessage {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.addBox {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.apply {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid $blue20;
}

.label {
  color: $seafoam100;
  font-family: $poppins;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.emailToggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.anchor {
  position: relative;
  // width: 10px;
  // height: 10px;
}

.userList {
  position: absolute;
  top: -10px;
  // left: 25px;
  z-index: 130;
  min-width: 270px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.userSearch {
  border-radius: 10px;
  padding: 10px;
  background-color: $gray40;
  width: 100%;
  box-shadow: $shadow6;
}

.userResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1em;
  max-height: 500px;
  background-color: $white;
  overflow: hidden;
  box-shadow: $shadow6;
}

.dropDown {
  position: absolute;
  top: 0px;
  // left: 10px;
  z-index: 130;
  border-radius: 1em;
  overflow-y: visible;
  max-height: 500px;
  background-color: $white;
  box-shadow: $shadow6;
}

.option {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-family: $poppins;
  color: $gray110;
  &:hover {
    background-color: $gray20;
    cursor: pointer;
  }
}

.emailEntry {
  position: absolute;
  top: -10px;
  left: 25px;
  z-index: 130;
  border-radius: 10px;
  padding: 10px;
  background-color: $gray40;
  min-width: 270px;
  box-shadow: $shadow6;
}

.textField {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: 2px solid $blue60;
  font-family: $poppins;
  color: $gray110;
  font-size: 1em;
  outline: none;
  padding: 0.2em 0.5em;
  overflow: none;
  transition: 0.3s all;
}
.textField:focus {
  border: 2px solid $blue100;
}

.invalid {
  border: 2px solid $red60;
}
.invalid:focus {
  border: 2px solid $red60;
}

.matching {
  display: flex;
  align-items: center;
  gap: 5px;
}

.matchingInfo {
  position: relative;
  padding-top: 5px;
  font-size: 0.7em;
  cursor: help;
  display: flex;
  align-items: center;
}
.matchingInfo:hover .matchingTooltip {
  display: flex;
}

.matchingTooltip {
  position: absolute;
  left: 2em;
  font-size: 1.2em;
  display: none;
  text-align: center;
  border-radius: 1em;
  box-shadow: $shadow6;
  background-color: $white;
  padding: 0.5em 1em;
  z-index: 140;
}
