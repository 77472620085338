@import "assets/styles/globals.scss";

.label {
  font-family: $poppins;
  color: $seafoam100;
  letter-spacing: 0.1em;
  font-weight: 200;
  font-size: 0.9em;
}

.text {
  width: 200px;
  font-size: .9em;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
}