@import "assets/styles/globals.scss";

.linkBuilder {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;
}

.subSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.linkCheck {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkLabel {
  color: $gray100;
  transition: 0.3s all;
  font-size: 0.75rem;
}

.chosenCheckLabel {
  color: $seafoam100;
  letter-spacing: 0.05rem;
}

.textInputHolder {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.textInputLabel {
  color: $seafoam100;
  font-size: 0.7rem;
  padding-left: 5px;
  letter-spacing: 0.1rem;
}

.textInput {
  outline: none;
  border: none;
  border-bottom: 2px solid $gray30;
  padding: 3px 0px;
  // border-radius: 10px;
  color: $gray110;
  font-family: $poppins;


  transition: 0.3s all;

  &:hover {
    background-color: $gray20;
  }

  &:focus {
    background-color: $white;
    border-bottom: 2px solid $seafoam10;
  }

  &::placeholder {
    color: $gray100;
  }
}

.colorLabel {
  color: $seafoam100;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.urlInput {
  color: $seafoam100;
  padding-left: 0px;

  &:focus {
    background-color: $gray20;
    // border-bottom: 2px solid $seafoam10;
  }
}
