@import "assets/styles/globals.scss";
@import "assets/styles/globals.scss";

.container {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $poppins;
  flex-grow: 1;
  overflow: auto;
  padding: 5px;
  padding-top: 10px;
}

.entry {
  width: 85%;
  border-radius: 8px;
  padding: 5px;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  background-color: #efeeee;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  span {
    font-family: Arial, Helvetica, sans-serif;
  }
}

.poised {
  cursor: grabbing;
}
