@import "assets/styles/globals.scss";

.container {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $poppins;

  height: 100%;

  // background-color: $white;

  // min-height: 100%;
  overflow: auto;
}

.entry {
  border-radius: 5px;
  padding: 2px 5px;
  outline: none;
  transition: 0.3s all;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  border: 2px solid transparent;

  flex-grow: 1;
  overflow: auto;

  padding-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.poised {
  cursor: grabbing;
}

.toolbar {
  display: flex;
  align-items: center;
  gap: 2px;
  padding-left: 10px;
  padding-top: 3px;
  border-bottom: 2px solid $gray20;
}

.btn {
  transition: 0.5s all;
  padding: 0px 5px;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    background-color: $gray60;
  }
}

.active {
  background-color: $gray60;
}

.divider {
  color: $gray60;
}

.fontSizeSelect {
  width: 3em;
}

.select {
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray25;
  cursor: pointer;
  transition: 0.3s all;
}

.select:hover {
  background-color: $gray60;
}

.defaultFont {
  width: 100px;
}

.embeddedButton {
  background-color: $blue100;
  color: $white;
  font-weight: 600;
  padding: 0px 20px;
  outline: none;
  border: none;
  font-family: $poppins;
  // height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: $radius7;
  // border: 2px solid $blue100;

  // &:hover {
  //   background-color: $white;
  //   color: $blue100;
  // }
}

// Image Editing Box

.editBox {
  border: 1px solid transparent;
}

.activeEditBox {
  border: 1px solid $gray60;
  position: relative;
}

.adjuster {
  border: 1px solid black;
  height: 8px;
  width: 8px;
  background-color: white;

  position: absolute;
}

.topLeft {
  top: -4px;
  left: -4px;
  cursor: nwse-resize;
}

.topMiddle {
  top: -4px;
  cursor: ns-resize;
}

.topRight {
  top: -4px;
  right: -4px;
  cursor: nesw-resize;
}

.leftMiddle {
  left: -4px;
  cursor: ew-resize;
}

.rightMiddle {
  right: -4px;
  cursor: ew-resize;
}

.bottomLeft {
  bottom: -4px;
  left: -4px;
  cursor: nesw-resize;
}

.bottomMiddle {
  bottom: -4px;
  cursor: ns-resize;
}

.bottomRight {
  bottom: -4px;
  right: -4px;
  cursor: nwse-resize;
}
