@import "assets/styles/globals.scss";
.footer {
  // margin-top: 2em;
  font-family: $poppins;
  color: $gray110;
  text-align: center;
  a {
    color: $blue100;
  }
}


.pager {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: $white;
}
.header {
  @include header-3-text;
}

.desc {
  @include description-text;

  a {
    color: $blue100;
  }
}
.top {
  display: flex;
  gap: 2em;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $white;
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
}

.createContactsContainer {
  // width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  background-color: $gray25;
}

.formContainer {
  border: 2px solid $gray40;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 600px;
  min-width: 200px;
}

.settingsContainer {
  // border: 2px solid $gray40;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // min-width: 200px;
  // margin-left: 20px;
}

.settingsHeader {
  font-family: $poppins;
  color: $gray110;
  font-weight: 900;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.setting {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.switchBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.charlengthnum {
  border: 2px solid $gray40;
  border-radius: 5px;
  width: 40px;
}
.findAndReplaceInput {
  width: 90px;
  border: 2px solid $gray40;
  border-radius: 5px;
  outline: none;
  padding-left: 5px;
}
.findAndReplaceLabel {
  font-family: $poppins;
}

.addRuleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/*Button*/
.fileUploadButton::-webkit-file-upload-button {
  font-family: $poppins;
  cursor: pointer;
  background-color: $blue100;
  border-style: none;
  border-radius: 5px;
  border: 2px solid $blue100;
  color: white;
  padding: 3px 8px;
  margin-bottom: 20px;
  margin-right: 10px;
  transition: all 0.3s;

  /* box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5); */
}
.fileUploadButton::-webkit-file-upload-button:hover {
  font-family: $poppins;
  background-color: white;
  border: 2px solid $blue100;
  color: $blue100;
  transition: all 0.3s;
}

.finishButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  height: 2em;
}

.submitButton {
  border: 2px solid $blue100;
  background-color: $blue100;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: $poppins;
  color: $white;
  transition: all 0.5s;
}
.submitButton:hover {
  background-color: $white;
  border: 2px solid $blue100;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: $poppins;
  color: $blue100;
}

.previous {
  font-family: $poppins;
  color: $blue100;
  font-size: 0.9em;
  cursor: pointer;
  width: fit-content;
  margin-left: 1em;

  &:hover {
    text-decoration: underline;
  }
}

.manualContainer {
  padding: 20px;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
}

.profileImage {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 0.25rem 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  font-weight: 500;
  font-size: 0.8em;
  // border: 2px solid $gray110;
  text-align: center;
  // box-shadow: $shadow;
  overflow: hidden;
  span {
    color: $white;
    // border: 2px solid black;
    text-align: center;
    letter-spacing: 1px;
  }
}

.settings {
  position: absolute;
  right: 3rem;
  top: 0.5rem;
}

.bigOption {
  display: flex;
  flex-direction: column;
  width: 260px;
  color: $black;
  gap: 0.25rem;
  transition: 0.3s all;
  padding: 0.5rem;
}

.description2 {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  width: 100%;
  white-space: wrap;
  color: $gray110;
}


