@import "assets/styles/globals.scss";

.emailWidget {
  box-shadow: $shadow6;
  width: 100%;
  max-width: 1000px;
  height: 150px;
  border-radius: $radius2;
  padding: 0em 1em;
  background-color: $white;
  overflow: hidden;

  cursor: pointer;
  transition: 0.3s all;
  position: relative;

  &:hover {
    background-color: $gray30;
    // box-shadow: $shadow2;
    // .emailTitle {
    //   font-size: 1.75em;
    // }

    // .emailDescription {
    //   font-size: 0.8em;
    // }
  }
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 900px;
  margin: 0em 1em;
  flex-wrap: wrap;
  row-gap: 5em;
  column-gap: 2em;
  // border: 1px solid pink;
  // flex-wrap: nowrap;
}

.emailHeader {
  font-family: $poppins;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 0.5em;
  // border: 1px solid blue;
  // flex-wrap: nowrap;

  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.startedText {
  color: $gray110;
  font-size: 0.8em;
  display: flex;
  // justify-content: space-between;
  width: 100%;
  gap: 0.5em;
  align-items: center;
  font-weight: 600;

  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;

  span {
    font-weight: 300;
    color: $seafoam100;
    min-width: 65px;
    text-align: right;
    color: $gray110;
  }
  // flex-direction: column;
}

.infotext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.startedTextTop {
  color: $gray110;
  font-size: 0.8em;
  display: flex;
  // justify-content: space-between;
  width: 100%;
  gap: 0.3em;
  font-weight: 600;
  align-items: center;

  span {
    font-weight: 300;
    color: $gray110;
  }
  // flex-direction: column;
}

.owner {
  border-radius: $radius4;
  padding: 3px 10px;
  font-size: 0.8em;
  height: fit-content;
}

.owner.isuser {
  background-color: $blue20;
  color: $blue60;
}
.owner.isorg {
  background-color: $orange20;
  color: $orange60;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
  // flex-shrink: 0;
  height: 100%;
  span {
    color: $gray110;
    font-size: 1.2em;
  }
  // padding-top: 20px;

  .emailTitle {
    @include header-4-text;
    font-size: 1rem;
    text-align: center;
    transition: 0.3s all;
    // padding-left: 1em;
    display: flex;
    gap: 1.5em;

    a {
      color: $info;
    }
  }

  .emailDescription {
    @include description-text;
    transition: 0.3s all;
    padding-left: 2px;
  }
}

.isopen {
  color: $info;
  font-weight: 700;
  // font-size: 1.2em;
}

.isclosed {
  color: $orange100;
}

.isdraft {
  color: $yellow100;
}
.statsContainer {
  display: flex;
  justify-content: space-between;

  // flex-direction: column;

  .copySurvey {
    padding-left: 0.5em;
    transition: all 0.3s;
    color: $gray110;
    cursor: pointer;
    z-index: 100;
  }
  .copySurvey:hover {
    padding-right: 0.3em;
    color: $blue100;
  }
}

.stats {
  // border: 2px solid black;
  position: relative;
  transition: 0.3s all;
  display: flex;
  padding: 0em 0.5em;
  // justify-content: space-around;
  gap: 2em;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 0.8em;

  .flex {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    // width: 100%;
  }
}

.stats2 {
  // border: 2px solid black;
  height: 100%;
  width: fit-content;
  position: relative;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  justify-content: center;

  i {
    transition: 0.3s all;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    cursor: pointer;
    color: $gray110;
    &:hover {
      color: $blue100;
    }
  }
}

.reportIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid black;
  z-index: 0;
  color: $blue20;
  span {
    font-size: 13em;
    color: $blue20;
  }
}

.draft {
  color: $orange100;
  font-weight: 600;
}
.scheduled {
  color: $blue100;
  font-weight: 600;
}
.template {
  color: $dullBlue100;
  font-weight: 600;
}
.sent {
  color: $green100;
  font-weight: 600;
}

.audiences {
  // width: 100%;
  max-width: 100%;
  display: flex;
  // border: 2px solid black;
  overflow-x: auto;
  gap: 0.5em;
  padding-right: 2em;

  .audience {
    padding: 5px 5px;
    background-color: $orange20;
    color: $orange100;
    font-size: 0.8em;
    border-radius: $radius2;
  }
}

.settingsBox {
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  z-index: 110;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  // align-items: center;

  cursor: default;
  overflow: hidden;

  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-shrink: 0;
    font-family: $poppins;
    font-size: 0.9em;
    color: $gray110;
    padding: 0.5em 1em;
    cursor: pointer;
    i {
      font-size: 1.1em;
    }
    &:hover {
      background-color: $gray30;
    }
  }
}

.link {
  @include link;
  padding: 0em;
  margin: 0em;
}
.threeDots {
  color: $gray110;
  cursor: pointer;
  font-size: 1.3em;
  border-radius: $radius2;
  transition: 0.2s all;
  position: absolute;
  bottom: 0.5em;
  right: 1em;
  padding: 2px 5px;
  height: fit-content;

  &:hover {
    background-color: $gray25;
  }
}

.method {
  padding: 0rem 0.5rem;
  background-color: $blue100;
  color: $white;
  border-radius: $radius1;
  font-family: $poppins;
  width: fit-content;
  font-size: 0.7em;
  font-weight: 500;
  display: flex;
  gap: 0.5em;
  align-items: center;
  text-transform: capitalize;
  i {
    font-size: 1em;
  }
}
.check {
  color: $green100;
  font-size: 1.8em;
}

.method.email {
  background-color: $sapphire20;
  color: $sapphire100;
  border: 1px solid $sapphire100;
}
.method.cancelled {
  background-color: $red10;
  color: $red60;
  border: 1px solid $red60;
}

.method.draft {
  background-color: $yellow20;
  color: $yellow100;
  border: 1px solid $yellow100;
}

.method.sent {
  background-color: $green20;
  color: $green100;
  border: 1px solid $green100;
}

.method.scheduled {
  background-color: $blue20;
  color: $blue100;
  border: 1px solid $blue100;
}

.method.template {
  background-color: $gray20;
  color: $seafoam100;
}

.programVersion {
  font-family: $poppins;
  color: $gray110;
}

.send {
  font-family: $poppins;
  color: $gray110;
}
.player {
  // border: 2px solid black;
  overflow: visible;
  height: 25px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivered {
  span {
    font-weight: 400;
  }
}
.deliveredCount {
  color: $blue100;
}
