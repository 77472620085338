@import "assets/styles/globals.scss";

.anchor {
  position: relative;
  align-self: center;
  width: 10px;
  // height: 10px;
  display: flex;
  justify-content: center;
  z-index: 150;
}

.catalog {
  position: absolute;
  top: 3px;
  left: -70px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: $shadow3;
  background-color: $white;
  width: 278px;
  // justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.icon {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 27px;
  font-size: 12pt;
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 2px 0px;
  color: $white;
  background-color: $sapphire60;
  cursor: pointer;
}
