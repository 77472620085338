@import "assets/styles/globals.scss";

.accordian {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em 1em;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s all;
  background-color: $gray25;
  gap: 10px;
  overflow: auto;
}
.accordian:hover {
  background-color: $white;
}

.visible {
  background-color: $white;
  border-radius: $radius2;
  background-color: $white;
  box-shadow: $shadow6;
  display: flex;
  flex-direction: column;
  padding: 1em;
  z-index: 1;
  cursor: default;

  .accordionicon {
    transform: rotate(270deg);
  }

  &:hover {
    background-color: $white;
  }
}

.visible:first-child {
  border-top-right-radius: 0px;
}

.accordian .accordionicon {
  transition: all 0.5s;
  font-size: 1em;
}

.accordionTitle {
  cursor: pointer;
  font-family: $poppins;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.85em;
  color: $gray100;
}

.symbol {
  font-weight: 400;
  font-size: 0.85rem;
}

.body {
  gap: 10px;
  font-size: 0.85rem;
}


