@import "assets/styles/globals.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1em;
  // position: sticky;
  // bottom: 0;
  // border: 2px solid black
}