@import "assets/styles/globals.scss";

// COLORS
$primary-color: $blue100 !default;
$bg-color: #ffffff !default;
$sticky-bg-color: #ffffff !default;
$shadow-color: #000000 !default;
$resize-line-color: #74b9ff !default;
$resize-hint-bg-color: $resize-line-color !default;
$resize-handle-gb-color: $primary-color !default;
$cell-border-color: #e8e8e8 !default;
$cell-editor-bg-color: #ffffff !default;
$cell-editor-border-color: $primary-color !default;
$fillhandle-action-border-color: #000000 !default;
$partial-area-gb-color: $primary-color !default;
$partial-area-border-color: $primary-color !default;
$checkbox-checked: $primary-color !default;
$checkbox-unchecked: #eeeeee !default;
$checkbox-hover: #cccccc !default;
$checkbox-mark: #ffffff !default;
$rg-content-text-color: #000000 !default;
$cell-text-color: $gray110 !default;

$dropdown-focused-item-bg-color: lighten(
  $color: $primary-color,
  $amount: 35,
) !default;
$dropdown-menu-bg-color: #ffffff !default;

// SHADOWS
$shadow-blur-radius: 3px !default;
$shadow-spread-radius: 1px !default;
$shadow-length: 1px !default;
$cell-editor-shadow: 1px 1px 6px !default;

// CONTEXT MENU
$context-menu-shadow: 0px 0px 8px 2px !default;

// OPACITIES
$opacity-80: 0.8 !default;
$opacity-35: 0.35 !default;
$opacity-20: 0.2 !default;
$opacity-15: 0.15 !default;
$opacity-10: 0.1 !default;
$opacity-06: 0.06 !default;
$opacity-01: 0.01 !default;
