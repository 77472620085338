@import "assets/styles/globals.scss";

.container {
  background-color: $gray25;
  border-radius: $radius2;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  position: relative;
  transition: 0.3s all;
}

.label {
  display: flex;
  gap: 5px;
  align-items: center;

  .info {
    font-size: 0.7rem;
    color: $sapphire80;
    cursor: pointer;
    position: relative;

    &:hover .help {
      display: flex;
    }

    .help {
      display: none;
      position: absolute;
      top: 0px; 
      left: 150%;
      box-shadow: $shadow;
      border-radius: $radius2;
      padding: 7px 10px;
      width: 200px;
      white-space: wrap;
      font-size: .8rem;
      z-index: 300;
      background-color: $white;
      @include animation(appear 1s 1);
     
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.config {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: fit-content;
  width: 100%;
  align-self: center;
  gap: .7rem;
  font-size: 0.8rem;
  color: $gray110;
}

.select {
  border: none;
  border-radius: 0.5em;
  outline: none;
  background-color: $gray35;
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;
  padding: 0px;
  font-size: 0.8rem;
  color: $gray110;
}

.select:hover {
  background-color: $gray60;
}
