@import "assets/styles/globals.scss";

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.menuItem {
  position: relative;
  border-radius: $radius2;
  background-color: $gray25;
  border: 1px solid $gray100;
  color: $gray110;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.8em;
  font-weight: 500;
  font-family: $poppins;
  @include transition(background-color 300ms, color 300ms);
  overflow: visible;
  transition: 0.3s all;
  cursor: pointer;
  i {
    font-size: 1.3em;
  }

  div {
    line-height: 110%;
    transition: 0.3s all;
    .label {
      font-size: 0.9em;
      width: fit-content;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      height: 100%;
      max-width: 120px;
      padding: 0.3em 0em;
      transition: 0.3s all;
    }

    .sublabel {
      font-size: 0.8em;
    }
  }

  &:hover,
  &.activeItem {
    background-color: $gray60;
  }
}

.tooltipContainer {
  color: #ffffff;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 25px;
  width: fit-content;
  white-space: nowrap;
  position: absolute;
  left: 1.2rem;
  @include transition(opacity 300ms 100ms);
  z-index: 145;
}
.tooltip {
  // background-color: #ccd5e0;
  background-color: #dde7f3;
  color: $gray110;
  padding: 1.1em;
  border-radius: $radius2;
  height: 2.5rem;
  width: fit-content;
  box-shadow: $shadow6;
  align-items: center;
  display: flex;
}
