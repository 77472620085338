@import "assets/styles/globals.scss";

.progBar {
  border-radius: $radius2;
  transition: 0.5s all;
  overflow: hidden;
  border: 2px solid transparent;
  padding: 0.5em 0;
}

.progBarInactive:hover {
  // cursor: pointer;
  // border: 2px solid $blue100;
}

.progBarActive {
  // border: 2px solid $blue100;
}


.progBarSettings {
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  padding: 1em;
}

.progSetting {
  display: flex;
  align-items: center;
}

