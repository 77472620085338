@import "assets/styles/globals.scss";

.container {
  background-color: transparent;
  border-radius: $radius1;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  transition: 0.3s all;
  // cursor: pointer;
}

.label6 {
  margin: 0;
  padding: 0 .5rem;
}
.label5 {
  margin: 0;
}


.input {
  border: none;
  background-color: $white;
  outline: none;
  padding: 2px;
  font-family: $poppins;
  color: $black;
  transition: 0.3s all;
  width: 100%;
  cursor: text;
  height: 2.75rem;
  min-height: 35px;
  border: 2px solid $gray60;
  border-radius: $radius1;
  padding: 0.2em 1rem;

  &:hover {
    background-color: $gray30;
  }

  &:focus {
    background-color: $gray30;
  }
  &::placeholder {
    color: $gray60;
  }
}

.nohover {
  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
  }
}

.searchIcon {
  color: $gray60;
}
.searchinput {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 2px;
  font-family: $poppins;
  color: $black;
  transition: 0.3s all;
  border-radius: $radius2;
  width: 100%;
  cursor: text;
  white-space: wrap;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // background-color: $gray30;

  &:hover {
    background-color: $gray30;
  }

  &:focus {
    background-color: $gray30;
  }
  &::placeholder {
    color: $gray60;
  }
}

.select {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 2px;
  font-family: $poppins;
  color: $black;
  transition: 0.3s all;
  border-radius: $radius2;
  font-size: 0.8rem;
}

.selectMultiple {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 2px;
  font-family: $poppins;
  color: $black;
  transition: 0.3s all;
  border-radius: $radius2;
  //   cursor: pointer;
  // font-size: 0.85em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  white-space: nowrap;
  gap: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  color: $black;
  cursor: pointer;
}

@include keyframes(open) {
  0% {
    height: 0px;
  }

  // 100% {
  //   height: 100%;
  // }
}
@include keyframes(show) {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  // 100% {
  //   height: 100%;
  // }
}
.dropdown {
  @include animation(open 0.3s 1);
  transition: 0.3s all;
  position: absolute;
  background-color: $white;
  box-shadow: $shadow6;
  width: fit-content;
  height: 240px;
  max-height: 240px;
  min-width: 60px;
  border-radius: $radius1;
  font-family: $poppins;
  color: $black;
  overflow: auto;
  left: 0;
  top: 4.4rem;
  z-index: 100;

  .option {
    @include animation(show 0.3s 1);
    padding: 5px 20px;
    // padding-left: 20px;
    height: fit-content;
    min-height: 40px;
    transition: 0.3s all;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
    white-space: nowrap;
    gap: 1rem;
    .helperText {
      color: $blue100;
      opacity: 0;
      font-size: 0.8em;
      transition: 0.3s all;
    }
    .checked {
      color: $blue100;
      font-size: 1.5em;
      transition: 0.3s all;
    }

    &:hover {
      background-color: $seafoam10;
      .helperText {
        opacity: 1;
      }
    }
  }
}

.dropUp {
  top: auto;
  bottom: 108%;
}

.pushdropdown {
  @include animation(open 0.3s 1);
  transition: 0.3s all;
  // position: absolute;
  background-color: $white;
  box-shadow: $shadow;
  width: 100%;
  height: 240px;
  max-height: 240px;
  // min-height: 240px;
  min-width: 160px;
  border-radius: $radius2;
  font-family: $poppins;
  // font-size: 0.8em;
  color: $black;
  overflow: auto;
  left: 0;
  top: 108%;
  z-index: 100;

  .option {
    @include animation(show 0.3s 1);
    padding: 0px 20px;
    // padding-left: 20px;
    // height: 40px;
    min-height: 40px;
    transition: 0.3s all;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-size: 0.8em;
    .helperText {
      color: $blue100;
      opacity: 0;
      font-size: 0.8em;
      transition: 0.3s all;
    }
    .checked {
      color: $blue100;
      font-size: 1.5em;
      transition: 0.3s all;
    }

    &:hover {
      background-color: $seafoam10;
      .helperText {
        opacity: 1;
      }
    }
  }
}

.dropdownCalendar {
  height: fit-content;
  max-height: fit-content;
}

.shadow {
  // box-shadow: $shadow6;
  //
}

.chosen {
  color: $black;
  background-color: $gray30;
  border-radius: $radius2;
  width: fit-content;
  padding: .25rem .5rem;
  display: flex;
  gap: 0.2em;
  align-items: center;
  font-size: 0.8rem;

  .chosenText {
    white-space: wrap;
    text-wrap: wrap;
  }
}

.remove {
  color: $black;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    color: $red60;
  }
}

.calendar {
  font-family: $poppins;
}

.timePicker {
  height: 40px;
  font-family: $poppins;
}
